import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import * as Api from '../api/authApi'
import { AuthActions } from '../reducers/authSlice'
import { selectVerificationCode } from '../reducers/verificationCodeSlice';

// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* signUp(action) {
   try {
      const response = yield call(Api.signUpApi, action.payload);
      yield put(AuthActions.signUpSuccess(response.data));

      const { mobileNumber, password } = action.payload;
      yield* logIn({ payload: { mobileNumber, password } })
   } catch (error) {
      yield put(AuthActions.signUpFailure(error.response?.data?.error || error));
   }
}

function* logIn(action) {
  try {
    const response = yield call(Api.loginApi, action.payload)
    yield put(AuthActions.loginSuccess(response.data))
  } catch (error) {
    yield put(AuthActions.loginFailure(error.response?.data?.error || error))
  }
}

function* resetPassword({ payload: { password } }) {
  try {
    const { verificationToken } = yield select(selectVerificationCode)
    yield call(Api.resetPasswordApi, { password, verificationToken })
    yield put(AuthActions.resetPasswordSuccess())
  } catch (error) {
    yield put(AuthActions.resetPasswordFailure(error.response?.data?.error || error))
  }
}

function* changePassword(action) {
  try {
    const { requesterId, ...form } = action.payload
    yield call(Api.changePasswordApi, requesterId, form)
    yield put(AuthActions.changePasswordSuccess())
  } catch (error) {
    yield put(AuthActions.changePasswordFailure(error.response?.data?.error || error))
  }
}

function* createGuestSession(action) {
  try {
    const response = yield call(Api.createGuestSessionApi, action.payload)
    yield put(AuthActions.createGuestSessionSuccess(response.data))
  } catch (error) {
    yield put(AuthActions.createGuestSessionFailure(error.response?.data?.error || error))
  }
}

function* getGuestSession(action) {
  try {
    const response = yield call(Api.getGuestSessionApi, action.payload)
    yield put(AuthActions.getGuestSessionSuccess(response.data))
  } catch (error) {
    yield put(AuthActions.getGuestSessionFailure(error.response?.data?.error || error))
  }
}

function* refreshToken() {
  try {
    const response = yield call(Api.refreshTokenApi)
    yield put(AuthActions.refreshTokenSuccess(response.data))
  } catch (error) {
    console.warn("couldn't refresh token", error)
  }
}

function* setToken(action) {
  try {
    yield call(Api.setTokenApi, action.payload)
  } catch (error) {
    // none
  }
}

function* unsetToken() {
  try {
    const { userId, accessToken } = yield select(state => state.auth)
    yield all([
      setToken({ payload: { token: null, userId, accessToken }}),
      put(AuthActions.signOutSuccess())
    ])
  } catch (error) {
    // none
  }
}

function* authSaga() {
  yield takeLatest(AuthActions.signUpRequest, signUp);
  yield takeLatest(AuthActions.loginRequest, logIn);
  yield takeLatest(AuthActions.resetPasswordRequest, resetPassword)
  yield takeLatest(AuthActions.changePasswordRequest, changePassword)
  yield takeLatest(AuthActions.createGuestSessionRequest, createGuestSession)
  yield takeLatest(AuthActions.getGuestSessionRequest, getGuestSession)
  yield takeLatest(AuthActions.refreshTokenRequest, refreshToken)
  yield takeLatest(AuthActions.setTokenRequest, setToken)
  yield takeLatest(AuthActions.signOut, unsetToken)
}

export default authSaga;
import { forwardRef, useState, useRef, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import * as yup from 'yup'
import { Formik } from 'formik'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Spinner from 'react-bootstrap/Spinner'
import Image from 'react-bootstrap/Image'
import { PlusCircleFill } from 'react-bootstrap-icons'
import format from 'date-fns/format'
import _ from 'lodash'
import qs from 'query-string'

import { checkExistingTestConfig, GenderChoices, Genders, HowDidYouKnowUs, MOBILE_NUMBER_REGEX, ServiceProviderStatus } from '../../components/common'

import DynamicTable from '../../components/DynamicTable'
import { DashboardActions, DashboardResources, selectDashboard } from '../../redux/reducers/dashboardSlice'
import Tabs from '../components/Tabs'
import DetailedView from '../layout/DetailedView'
import IdentityInput from '../components/IdentityInput'
import Choice from '../../components/basic/Choice'
import Attachments from '../../components/basic/Attachments'
import { CityAreaSubform } from '../../components/LocationInfoForm'
import Select from '../../components/basic/Select'

const Actions = Object.freeze({
  CREATE: 'Create',
  UPDATE: 'Update'
})

const userType = 'ServiceProvider'

const schema = yup.object().shape({
  id: IdentityInput.schema,
  firstName: yup.string().required(),
  middleName: yup.string().nullable(),
  lastName: yup.string().required(),
  mobileNumber: yup.string().required()
    .matches(MOBILE_NUMBER_REGEX, 'invalid mobile number')
    .test(checkExistingTestConfig('mobileNumber', userType)),
  status: yup.string().required().oneOf(ServiceProviderStatus.getValues()).default(ServiceProviderStatus.ACTIVE.value),
  jobTitle: yup.string().required(),
  gender: yup.string().oneOf(GenderChoices.getValues()),
  birthDate: yup.date().nullable(),
  nationalIds: yup.lazy(val => {
    if(val && val.length > 0) return yup.array(yup.number()).min(2, 'You have to upload 2 national id photos');
    else return yup.array(yup.number()).nullable();
  }),
  yearsOfExperience: yup.number().min(0).nullable(),
  homeVisitFees: yup.number().min(0).nullable(),
  cities: yup.array(yup.number()).default([]),
  areas: yup.array(yup.number()).default([]),
  howDidYouKnowAboutUs: yup.string(),
  otherComments: yup.string(),
  whatsAppNumber: yup.string(),
  speciality: yup.string(),
  degree: yup.string(),
  elderlyExperience: yup.string(),
  howToHandleToughClients: yup.string(),
  previousExperienceWithCriticalCases: yup.string(),
  nurseId: yup.number().nullable(),
  graduationCertificate: yup.number().nullable(),
  expiryDate: yup.date().nullable(),
  timing: yup.string()
})

const Create = forwardRef(({ action }, ref) => {
  const dispatch = useDispatch()

  const submit = (body) => {
    const dispatchAction = action === Actions.CREATE ? DashboardActions.createRequest : DashboardActions.updateRequest
    console.log('body: ', body)
    dispatch(dispatchAction({ resource: DashboardResources.NURSES, body }))
  }

  return (
    <Formik
      validationSchema={schema}
      initialValues={schema.default()}
      validateOnChange={false}
      innerRef={ref}
      onSubmit={submit}
    >
      {({ handleSubmit,handleChange, values, errors, setFieldValue }) => (
        <Form noValidate onSubmit={handleSubmit}>
          {console.log('vals: ', values)}
          <Form.Group>
            <Form.Label>ID</Form.Label>
            <IdentityInput
              prefix='NU'
              onChange={v => setFieldValue('id', v)}
              isInvalid={errors.id}
              errorMessage={errors.id}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>First Name</Form.Label>
            <Form.Control
              name='firstName'
              value={values.firstName}
              isInvalid={errors.firstName}
              onChange={handleChange}
            />
            <Form.Control.Feedback type='invalid'>
              {errors.firstName}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Middle Name</Form.Label>
            <Form.Control
              name='middleName'
              value={values.middleName}
              isInvalid={errors.middleName}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              name='lastName'
              value={values.lastName}
              isInvalid={errors.lastName}
              onChange={handleChange}
            />
            <Form.Control.Feedback type='invalid'>
              {errors.lastName}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Mobile Number</Form.Label>
            <Form.Control
              name='mobileNumber'
              value={values.mobileNumber}
              isInvalid={errors.mobileNumber}
              onChange={handleChange}
            />
            <Form.Control.Feedback type='invalid'>
              {errors.mobileNumber}
            </Form.Control.Feedback>
            <Form.Check
              label='This number has WhatsApp account?'
              disabled={!values.mobileNumber}
              checked={!!values.whatsAppNumber}
              onChange={e => setFieldValue('whatsAppNumber', e.target.checked ? values.mobileNumber : null)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Status</Form.Label>
            <Form.Select
              name='status'
              value={values.status}
              onChange={handleChange}
            >
              {ServiceProviderStatus.getValues().map(value => (
                <option key={value} value={value}>{ServiceProviderStatus.getTextByValue(value)}</option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group>
            <Form.Label>Job Title</Form.Label>
            <Form.Control
              name='jobTitle'
              value={values.jobTitle}
              isInvalid={errors.jobTitle}
              onChange={handleChange}
            />
            <Form.Control.Feedback type='invalid'>
              {errors.jobTitle}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Gender</Form.Label>
            <Choice
              value={values.gender}
              options={Genders.getValues()}
              onChange={value => setFieldValue('gender', value)}
            />
            <Form.Control.Feedback type='invalid'>
              {errors.gender}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Birthdate</Form.Label>
            <Form.Control
              name='birthDate'
              type='date'
              value={values.birthDate}
              onChange={handleChange}
              isInvalid={errors.birthDate}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>National ID</Form.Label>
            <Attachments
              single={false}
              initialValue={values.nationalIds}
              onChange={value => setFieldValue('nationalIds', value?.map(nid => nid.id))}
              isInvalid={errors.nationalIds}
              errorMessage={errors.nationalIds}
              target={`nationalIds`}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Years Of Experience</Form.Label>
            <Form.Control
              type='number'
              name='yearsOfExperience'
              value={values.yearsOfExperience}
              isInvalid={errors.yearsOfExperience}
              onChange={handleChange}
            />
            <Form.Control.Feedback type='invalid'>
              {errors.yearsOfExperience}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Fees</Form.Label>
            <Form.Control
              type='number'
              name='homeVisitFees'
              value={values.homeVisitFees}
              isInvalid={errors.homeVisitFees}
              onChange={handleChange}
            />
            <Form.Control.Feedback type='invalid'>
              {errors.homeVisitFees}
            </Form.Control.Feedback>
          </Form.Group>
          <CityAreaSubform
            multiple
            labels={{
              city: 'Cities',
              area: 'Areas'
            }}
            values={values}
            errors={errors}
            onAreaChange={value => setFieldValue('areas', value)}
            onCityChange={value => setFieldValue('cities', value)}
          />
          <Form.Group>
            <Form.Label>How did you know about us?</Form.Label>
            <Select
              name='howDidYouKnowAboutUs'
              label='How did you know about us?'
              options={Object.values(HowDidYouKnowUs).map(value => ({ text: value, value }))}
              value={values.howDidYouKnowAboutUs}
              onSelect={value => setFieldValue('howDidYouKnowAboutUs', value, false)}
              isInvalid={errors.howDidYouKnowAboutUs}
              other
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Other Comments</Form.Label>
            <Form.Control
              name='otherComments'
              value={values.otherComments}
              isInvalid={errors.otherComments}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Speciality</Form.Label>
            <Form.Control
              name='speciality'
              value={values.speciality}
              isInvalid={errors.speciality}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Degree</Form.Label>
            <Form.Control
              name='degree'
              value={values.degree}
              isInvalid={errors.degree}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Timing</Form.Label>
            <Form.Control
              name='timing'
              value={values.timing}
              isInvalid={errors.timing}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Elderly Experience</Form.Label>
            <Form.Control
              type='textarea'
              name='elderlyExperience'
              value={values.elderlyExperience}
              isInvalid={errors.elderlyExperience}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>How to handle tough cases?</Form.Label>
            <Form.Control
              name='howToHandleToughClients'
              value={values.howToHandleToughClients}
              isInvalid={errors.howToHandleToughClients}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Previous experience with critical cases</Form.Label>
            <Form.Control
              name='previousExperienceWithCriticalCases'
              value={values.previousExperienceWithCriticalCases}
              isInvalid={errors.previousExperienceWithCriticalCases}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Nurse ID</Form.Label>
            <Attachments
              single
              initialValue={values.nurseId}
              onChange={value => setFieldValue('nurseId', value?.id)}
              isInvalid={errors.nurseId}
              errorMessage={errors.nurseId}
              target={`nurseId`}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Graduation Certificate</Form.Label>
            <Attachments
              single
              initialValue={values.graduationCertificate}
              onChange={value => setFieldValue('graduationCertificate', value?.id)}
              isInvalid={errors.graduationCertificate}
              errorMessage={errors.graduationCertificate}
              target={`graduationCertificate`}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Expiry Date</Form.Label>
            <Form.Control
              type='date'
              name='expiryDate'
              value={values.expiryDate}
              isInvalid={errors.expiryDate}
              onChange={handleChange}
            />
          </Form.Group>

        </Form>
      )}
    </Formik>
  )
})

const Nurses = () => {
  const { nurses, isLoading } = useSelector(selectDashboard)
  const [modalAction, setModalAction] = useState(null)
  const submitRef = useRef()

  const { id } = qs.parse(document.location.search)

  const closeModal = () => setModalAction(null)

  useEffect(() => {
    if (!isLoading) {
      setModalAction(null)
    }
  }, [isLoading])

  return (
    <div>
      <div className='sub-screen-title'>
        <h1>Nurses</h1>
        <Button float='right' onClick={() => setModalAction(Actions.CREATE)}><PlusCircleFill /> Create</Button>
      </div>

      <Tabs>
        <Tabs.Tab title='Completed'>
          <DynamicTable
            search
            sortable
            resource={DashboardResources.NURSES}
            data={nurses.items}
            headers={[
              { key: 'id', name: 'ID', sort: 'id.prefix,id.order' },
              { key: 'name', name: 'Name', sort: 'firstName,middleName,lastName' },
              { key: 'mobileNumber', name: 'Number' },
              { key: 'degree', name: 'Degree' },
              { key: 'yearsOfExperience', name: 'Years of Exp.' },
              { key: 'cityName', name: 'Cities', sort: '_citiesCount' },
              { key: 'areaName', name: 'Areas', sort: '_areasCount' },
              { key: 'homeVisitFees', name: 'Fees' },
              { key: 'totalRequests', name: 'Total Requests' },
              { key: 'activeRequests', name: 'Active Requests' },
              { key: 'rating', name: 'Rating' },
              { key: 'userApproved', name: 'User Approved' },
              { key: 'dateOfApproval', name: 'Date of Approval' },
            ]}
            mapper={item => {
              return [
                { key: 'id', value: item.id },
                { key: 'name', value: [item.firstName, item.middleName, item.lastName].join(' ') },
                { key: 'mobileNumber', value: item.mobileNumber },
                { key: 'degree', value: item.degree },
                { key: 'yearsOfExperience', value: item.yearsOfExperience },
                { key: 'cityName', value: item.cities?.map(city => city.arabicName).join(', ') },
                { key: 'areaName', value: item.areas?.map(area => area.arabicName).join(', ') },
                { key: 'homeVisitFees', value: item.homeVisitFees },
                { key: 'totalRequests', value: item.totalRequests },
                { key: 'activeRequests', value: item.activeRequests },
                { key: 'rating', value: item.rating },
                { key: 'userApproved', value: item.userApproved?.firstName },
                { key: 'dateOfApproval', value: item.userApproved?.firstName },
              ]
            }}
            pagesCount={nurses.pagesCount}
            detail
            initialFilters={[_.omitBy({ id }, _.isNil)]}
          />
        </Tabs.Tab>
      </Tabs>
      <Modal show={!!modalAction} dialogClassName='ltr dashboard-layout' onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>{modalAction} Nurse</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Create ref={submitRef} action={modalAction} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => submitRef.current?.submitForm()}>
            {isLoading ? <Spinner animation="border" size='sm' /> : modalAction}
          </Button>
          <Button variant="outline-primary" onClick={closeModal}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

Nurses.Detail = () => {
  const { id } = useParams()
  const dispatch = useDispatch()

  const handleUpdate = initialValue => body => {
    body.id = id
    body.deletedFields = _.keys(initialValue).filter(key => _.isNil(body[key]) && !_.isNil(initialValue[key]))

    dispatch(DashboardActions.updateRequest({ resource: DashboardResources.NURSES, body }))
  }

  return (
    <DetailedView
      id={id}
      serviceProvider
      resource={DashboardResources.NURSES}
      sectionMappers={[
        nurse => ({
          title: 'Profile Information',
          body: {
            "Name": [nurse.firstName, nurse.middleName, nurse.lastName].filter(n => n).join(' '),
            "Mobile Number": nurse.mobileNumber,
            "Gender": nurse.gender,
            "Email": nurse.email,
            "Birthdate": nurse.birthDate && format(Date.parse(nurse.birthDate), 'dd/MM/yyyy')
          },
          edit: () => ref => {
            const fields = ['id', 'firstName', 'middleName', 'lastName', 'mobileNumber', 'gender', 'email', 'birthDate']
            const initialValues = _.pick(nurse, fields)
            const _schema = yup.object().shape({
              ...schema.pick(fields).omit(['id']).fields,
              gender: yup.string().oneOf(Genders.getValues()).nullable(),
              email: yup.string().email().nullable().test(checkExistingTestConfig('email', userType)),
              birthDate: yup.date().nullable()
            })

            return (
              <Formik
                innerRef={ref}
                initialValues={initialValues}
                validationSchema={_schema}
                onSubmit={handleUpdate(initialValues)}
              >
                {({ handleSubmit, handleChange, setFieldValue, values, errors }) =>(
                  <Form noValidate onSubmit={handleSubmit}>
                    <Form.Group>
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        name='firstName'
                        value={values.firstName}
                        isInvalid={errors.firstName}
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type='invalid'>
                        {errors.firstName}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Middle Name</Form.Label>
                      <Form.Control
                        name='middleName'
                        value={values.middleName}
                        isInvalid={errors.middleName}
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type='invalid'>
                        {errors.middleName}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        name='lastName'
                        value={values.lastName}
                        isInvalid={errors.lastName}
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type='invalid'>
                        {errors.lastName}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Mobile Number</Form.Label>
                      <Form.Control
                        name='mobileNumber'
                        value={values.mobileNumber}
                        isInvalid={errors.mobileNumber}
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type='invalid'>
                        {errors.mobileNumber}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Gender</Form.Label>
                      <Choice
                        value={values.gender}
                        options={Genders.getValues()}
                        onChange={value => setFieldValue('gender', value)}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        name='email'
                        value={values.email}
                        isInvalid={errors.email}
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type='invalid'>
                        {errors.email}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Birthdate</Form.Label>
                      <Form.Control
                        type='date'
                        name='birthDate'
                        value={values.birthDate}
                        isInvalid={errors.birthDate}
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type='invalid'>
                        {errors.birthDate}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Form>
                )}
              </Formik>
            )
          }
        }),
        nurse => ({
          title: 'General Questions',
          body: {
            "Dealing with the elderly": nurse.elderlyExperience,
            "Dealing with difficult clients": nurse.howToHandleToughClients,
            "Dealing with critical situations": nurse.previousExperienceWithCriticalCases
          },
          edit: () => ref => {
            const fields = ['jobTitle', 'elderlyExperience', 'howToHandleToughClients', 'previousExperienceWithCriticalCases']
            const initialValues = _.pick(nurse, fields)
            const _schema = schema.pick(fields)

            return (
              <Formik
                innerRef={ref}
                initialValues={initialValues}
                validationSchema={_schema}
                onSubmit={handleUpdate(initialValues)}
              >
                {({ handleSubmit, handleChange, values, errors }) =>(
                  <Form noValidate onSubmit={handleSubmit}>
                    <Form.Group>
                      <Form.Label>Job Title</Form.Label>
                      <Form.Control
                        name='jobTitle'
                        value={values.jobTitle}
                        isInvalid={errors.jobTitle}
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type='invalid'>
                        {errors.jobTitle}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Elderly Experience</Form.Label>
                      <Form.Control
                        type='textarea'
                        name='elderlyExperience'
                        value={values.elderlyExperience}
                        isInvalid={errors.elderlyExperience}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>How to handle tough cases?</Form.Label>
                      <Form.Control
                        name='howToHandleToughClients'
                        value={values.howToHandleToughClients}
                        isInvalid={errors.howToHandleToughClients}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Previous experience with critical cases</Form.Label>
                      <Form.Control
                        name='previousExperienceWithCriticalCases'
                        value={values.previousExperienceWithCriticalCases}
                        isInvalid={errors.previousExperienceWithCriticalCases}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Form>
                )}
              </Formik>
            )
          }
        }),
        nurse => ({
          title: 'Occupation Information',
          body: {
            "Degree": nurse.degree,
            "Speciality": nurse.speciality,
            "Job Title": nurse.jobTitle,
            "Years of experience": nurse.yearsOfExperience
          },
          edit: () => ref => {
            const fields = [
              'degree',
              'speciality',
              'jobTitle',
              'yearsOfExperience'
            ]
            const initialValues = _.pick(nurse, fields)
            const _schema = schema.pick(fields)

            return (
              <Formik
                innerRef={ref}
                initialValues={initialValues}
                validationSchema={_schema}
                onSubmit={handleUpdate(initialValues)}
              >
                {({ handleSubmit, handleChange, values, errors }) =>(
                  <Form noValidate onSubmit={handleSubmit}>
                    <Form.Group>
                      <Form.Label>Job Title</Form.Label>
                      <Form.Control
                        name='jobTitle'
                        value={values.jobTitle}
                        isInvalid={errors.jobTitle}
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type='invalid'>
                        {errors.jobTitle}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Speciality</Form.Label>
                      <Form.Control
                        name='speciality'
                        value={values.speciality}
                        isInvalid={errors.speciality}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Degree</Form.Label>
                      <Form.Control
                        name='degree'
                        value={values.degree}
                        isInvalid={errors.degree}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Years Of Experience</Form.Label>
                      <Form.Control
                        type='number'
                        name='yearsOfExperience'
                        value={values.yearsOfExperience}
                        isInvalid={errors.yearsOfExperience}
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type='invalid'>
                        {errors.yearsOfExperience}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Form>
                )}
              </Formik>
            )
          }
        }),
        nurse => ({
          title: 'Work Shifts',
          body: {
            "Timing": nurse.timing
          },
          edit: () => ref => {
            const fields = [
              'timing',
            ]
            const initialValues = _.pick(nurse, fields)
            const _schema = schema.pick(fields)

            return (
              <Formik
                innerRef={ref}
                initialValues={initialValues}
                validationSchema={_schema}
                onSubmit={handleUpdate(initialValues)}
              >
                {({ handleSubmit, handleChange, values, errors }) =>(
                  <Form noValidate onSubmit={handleSubmit}>
                    <Form.Group>
                      <Form.Label>Timing</Form.Label>
                      <Form.Control
                        name='timing'
                        value={values.timing}
                        isInvalid={errors.timing}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Form>
                )}
              </Formik>
            )
          }
        }),
        nurse => ({
          title: 'Covered Areas & Timing',
          body: {
            "Cities": nurse.cities.map(city => city.arabicName).join(' - '),
            "Areas": nurse.areas.map(area => area.arabicName).join(' - '),
            "Visit Fees": nurse.homeVisitFees
          },
          edit: () => ref => {
            const fields = [
              'homeVisitFees',
              'cities',
              'areas'
            ]
            const initialValues = _.mapValues(_.pick(nurse, fields), (v, k) => ['cities', 'areas'].includes(k) ? v.map(vv => vv.id) : v)
            const _schema = schema.pick(fields)

            return (
              <Formik
                innerRef={ref}
                initialValues={initialValues}
                validationSchema={_schema}
                onSubmit={handleUpdate(initialValues)}
              >
                {({ handleSubmit, handleChange, setFieldValue, values, errors }) =>(
                  <Form noValidate onSubmit={handleSubmit}>
                    <Form.Group>
                      <Form.Label>Fees</Form.Label>
                      <Form.Control
                        type='number'
                        name='homeVisitFees'
                        value={values.homeVisitFees}
                        isInvalid={errors.homeVisitFees}
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type='invalid'>
                        {errors.homeVisitFees}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <CityAreaSubform
                      multiple
                      labels={{
                        city: 'Cities',
                        area: 'Areas'
                      }}
                      values={values}
                      errors={errors}
                      onAreaChange={value => setFieldValue('areas', value)}
                      onCityChange={value => setFieldValue('cities', value)}
                    />
                  </Form>
                )}
              </Formik>
            )
          }
        }),
        nurse => ({
          title: 'Attachments',
          body: {
            "National ID": (
              <>
                <Image src={nurse.nationalIds?.[0].url} />,
                <Image src={nurse.nationalIds?.[1].url} />,
              </>
            ),
            "Graduation Certificate": <Image src={nurse.graduationCertificate?.url} />,
            "Nurse ID": <Image src={nurse.nurseId?.url} />,
          },
          edit: () => ref => {
            const fields = [
              'nationalIds',
              'graduationCertificate',
              'nurseId'
            ]
            const initialValues = _.pick(nurse, fields)
            const _schema = schema.pick(fields)

            return (
              <Formik
                innerRef={ref}
                initialValues={initialValues}
                validationSchema={_schema}
                onSubmit={handleUpdate(initialValues)}
              >
                {({ handleSubmit, setFieldValue, values, errors }) =>(
                  <Form noValidate onSubmit={handleSubmit}>
                    <Form.Group>
                      <Form.Label>National ID</Form.Label>
                      <Attachments
                        single={false}
                        initialValue={values.nationalIds}
                        onChange={value => setFieldValue('nationalIds', value?.map(nid => nid.id))}
                        isInvalid={errors.nationalIds}
                        errorMessage={errors.nationalIds}
                        target={`nationalIds`}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Nurse ID</Form.Label>
                      <Attachments
                        single
                        initialValue={values.nurseId}
                        onChange={value => setFieldValue('nurseId', value?.id)}
                        isInvalid={errors.nurseId}
                        errorMessage={errors.nurseId}
                        target={`nurseId`}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Graduation Certificate</Form.Label>
                      <Attachments
                        single
                        initialValue={values.graduationCertificate}
                        onChange={value => setFieldValue('graduationCertificate', value?.id)}
                        isInvalid={errors.graduationCertificate}
                        errorMessage={errors.graduationCertificate}
                        target={`graduationCertificate`}
                      />
                    </Form.Group>
                  </Form>
                )}
              </Formik>
            )
          }
        })
      ]}
    />
  )
}

export default Nurses

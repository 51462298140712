import { forwardRef, useState, useRef, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import * as yup from 'yup'
import { Formik } from 'formik'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Spinner from 'react-bootstrap/Spinner'
import Image from 'react-bootstrap/Image'
import { PlusCircleFill } from 'react-bootstrap-icons'
import format from 'date-fns/format'
import _ from 'lodash'
import qs from 'query-string'

import { checkExistingTestConfig, GenderChoices, Genders, HowDidYouKnowUs, MOBILE_NUMBER_REGEX, ServiceProviderStatus, usePrevious } from '../../components/common'

import DynamicTable from '../../components/DynamicTable'
import { DashboardActions, DashboardResources, selectDashboard } from '../../redux/reducers/dashboardSlice'
import Tabs from '../components/Tabs'
import DetailedView from '../layout/DetailedView'
import IdentityInput from '../components/IdentityInput'
import Choice from '../../components/basic/Choice'
import Attachments from '../../components/basic/Attachments'
import { CityAreaSubform } from '../../components/LocationInfoForm'
import Select from '../../components/basic/Select'
import CertificatesFormGroup from '../components/CertificatesFormGroup'

const Actions = Object.freeze({
  CREATE: 'Create',
  UPDATE: 'Update'
})

const userType = 'ServiceProvider'

const schema = yup.object().shape({
  id: IdentityInput.schema,
  firstName: yup.string().required(),
  middleName: yup.string().nullable(),
  lastName: yup.string().required(),
  mobileNumber: yup.string().required()
    .matches(MOBILE_NUMBER_REGEX, 'invalid mobile number')
    .test(checkExistingTestConfig('mobileNumber', userType)),
  status: yup.string().required().oneOf(ServiceProviderStatus.getValues()).default(ServiceProviderStatus.ACTIVE.value),

  gender: yup.string().oneOf(GenderChoices.getValues()),
  birthDate: yup.date().nullable(),
  nationalIds: yup.lazy(val => {
    if(val && val.length > 0) return yup.array(yup.number()).min(2, 'You have to upload 2 national id photos');
    else return yup.array(yup.number()).nullable();
  }),
  yearsOfExperience: yup.number().min(0).nullable(),
  homeVisitFees: yup.number().min(0).nullable(),
  cities: yup.array(yup.number()).default([]),
  areas: yup.array(yup.number()).default([]),
  howDidYouKnowAboutUs: yup.string(),
  otherComments: yup.string(),
  whatsAppNumber: yup.string(),
  lastAcademicQualification: yup.string(),
  readingAndWritingAbility: yup.string(),
  address: yup.string(),
  serviceDetails: yup.string(),
  physicalAbility: yup.string(),
  canCarryPatient: yup.boolean().default(true),
  retardedElderlyExperience: yup.string(),
  criticalCasesExperience: yup.string(),
  certificates: yup.array(yup.string()),
  timing: yup.string(),
})

const Create = forwardRef(({ action }, ref) => {
  const dispatch = useDispatch()
  const submit = (body) => {
    const dispatchAction = action === Actions.CREATE ? DashboardActions.createRequest : DashboardActions.updateRequest

    dispatch(dispatchAction({ resource: DashboardResources.HOME_CARE_SPECIALISTS, body }))
  }

  return (
    <Formik
      validationSchema={schema}
      initialValues={schema.default()}
      validateOnChange={false}
      innerRef={ref}
      onSubmit={submit}
    >
      {({ handleSubmit,handleChange, values, errors, setFieldValue }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label>ID</Form.Label>
            <IdentityInput
              prefix='CO'
              onChange={v => setFieldValue('id', v)}
              isInvalid={errors.id}
              errorMessage={errors.id}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>First Name</Form.Label>
            <Form.Control
              name='firstName'
              value={values.firstName}
              isInvalid={errors.firstName}
              onChange={handleChange}
            />
            <Form.Control.Feedback type='invalid'>
              {errors.firstName}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Middle Name</Form.Label>
            <Form.Control
              name='middleName'
              value={values.middleName}
              isInvalid={errors.middleName}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              name='lastName'
              value={values.lastName}
              isInvalid={errors.lastName}
              onChange={handleChange}
            />
            <Form.Control.Feedback type='invalid'>
              {errors.lastName}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Mobile Number</Form.Label>
            <Form.Control
              name='mobileNumber'
              value={values.mobileNumber}
              isInvalid={errors.mobileNumber}
              onChange={handleChange}
            />
            <Form.Control.Feedback type='invalid'>
              {errors.mobileNumber}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Status</Form.Label>
            <Form.Select
              name='status'
              value={values.status}
              onChange={handleChange}
            >
              {ServiceProviderStatus.getValues().map(value => (
                <option key={value} value={value}>{ServiceProviderStatus.getTextByValue(value)}</option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group>
            <Form.Label>Gender</Form.Label>
            <Choice
              name='gender'
              value={values.gender}
              options={Genders.getValues()}
              onChange={value => setFieldValue('gender', value)}
            />
            <Form.Control.Feedback type='invalid'>
              {errors.gender}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Birthdate</Form.Label>
            <Form.Control
              name='birthDate'
              type='date'
              value={values.birthDate}
              onChange={handleChange}
              isInvalid={errors.birthDate}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>National ID</Form.Label>
            <Attachments
              single={false}
              initialValue={values.nationalIds}
              onChange={value => setFieldValue('nationalIds', value?.map(nid => nid.id))}
              isInvalid={errors.nationalIds}
              errorMessage={errors.nationalIds}
              target={`nationalIds`}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Years Of Experience</Form.Label>
            <Form.Control
              type='number'
              name='yearsOfExperience'
              value={values.yearsOfExperience}
              isInvalid={errors.yearsOfExperience}
              onChange={handleChange}
            />
            <Form.Control.Feedback type='invalid'>
              {errors.yearsOfExperience}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Fees</Form.Label>
            <Form.Control
              type='number'
              name='homeVisitFees'
              value={values.homeVisitFees}
              isInvalid={errors.homeVisitFees}
              onChange={handleChange}
            />
            <Form.Control.Feedback type='invalid'>
              {errors.homeVisitFees}
            </Form.Control.Feedback>
          </Form.Group>
          <CityAreaSubform
            multiple
            labels={{
              city: 'Cities',
              area: 'Areas'
            }}
            values={values}
            errors={errors}
            onAreaChange={value => setFieldValue('areas', value)}
            onCityChange={value => setFieldValue('cities', value)}
          />
          <Form.Group>
            <Form.Label>How did you know about us?</Form.Label>
            <Select
              name='howDidYouKnowAboutUs'
              label='How did you know about us?'
              options={Object.values(HowDidYouKnowUs).map(value => ({ text: value, value }))}
              value={values.howDidYouKnowAboutUs}
              onSelect={value => setFieldValue('howDidYouKnowAboutUs', value)}
              isInvalid={errors.howDidYouKnowAboutUs}
              other
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Other Comments</Form.Label>
            <Form.Control
              name='otherComments'
              value={values.otherComments}
              isInvalid={errors.otherComments}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Last Academic Qualification</Form.Label>
            <Form.Control
              name='lastAcademicQualification'
              value={values.lastAcademicQualification}
              isInvalid={errors.lastAcademicQualification}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Reading and writing ability</Form.Label>
            <Form.Control
              name='readingAndWritingAbility'
              value={values.readingAndWritingAbility}
              isInvalid={errors.readingAndWritingAbility}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Address</Form.Label>
            <Form.Control
              name='address'
              value={values.address}
              isInvalid={errors.address}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Service Details</Form.Label>
            <Form.Control
              name='serviceDetails'
              value={values.serviceDetails}
              isInvalid={errors.serviceDetails}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Physical Ability</Form.Label>
            <Form.Control
              name='physicalAbility'
              value={values.physicalAbility}
              isInvalid={errors.physicalAbility}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Can carry patient?</Form.Label>
            <Choice
              name='canCarryPatient'
              value={values.canCarryPatient ? 'Yes' : 'No'}
              options={['Yes', 'No']}
              onChange={value => setFieldValue('canCarryPatient', value === 'Yes')}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Retarded Elderly Experience</Form.Label>
            <Form.Control
              name='retardedElderlyExperience'
              value={values.retardedElderlyExperience}
              isInvalid={errors.retardedElderlyExperience}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Critical cases Experience</Form.Label>
            <Form.Control
              name='criticalCasesExperience'
              value={values.criticalCasesExperience}
              isInvalid={errors.criticalCasesExperience}
              onChange={handleChange}
            />
          </Form.Group>
          <CertificatesFormGroup
            certificates={values.certificates}
            onChange={v => setFieldValue('certificates', v)}
          />
          <Form.Group>
            <Form.Label>Timing</Form.Label>
            <Form.Control
              name='timing'
              value={values.timing}
              isInvalid={errors.timing}
              onChange={handleChange}
            />
          </Form.Group>
        </Form>
      )}
    </Formik>
  )
})

const HomeCareSpecialists = () => {
  const { homeCareSpecialists, isLoading } = useSelector(selectDashboard)
  const [modalAction, setModalAction] = useState(null)
  const submitRef = useRef()

  const { id } = qs.parse(document.location.search)

  const closeModal = () => setModalAction(null)

  useEffect(() => {
    if (!isLoading) {
      setModalAction(null)
    }
  }, [isLoading])

  return (
    <div>
      <div className='sub-screen-title'>
        <h1>Home Care Sepcialists</h1>
        <Button float='right' onClick={() => setModalAction(Actions.CREATE)}><PlusCircleFill /> Create</Button>
      </div>

      <Tabs>
        <Tabs.Tab title='Completed'>
          <DynamicTable
            search
            sortable
            resource={DashboardResources.HOME_CARE_SPECIALISTS}
            data={homeCareSpecialists.items}
            headers={[
              { key: 'id', name: 'ID', sort: 'id.prefix,id.order' },
              { key: 'name', name: 'Name', sort: 'firstName,middleName,lastName' },
              { key: 'mobileNumber', name: 'Number' },
              { key: 'lastAcademicQualification', name: 'Educational Qualification' },
              { key: 'yearsOfExperience', name: 'Years of Exp.' },
              { key: 'cityName', name: 'Cities', sort: '_citiesCount' },
              { key: 'areaName', name: 'Areas', sort: '_areasCount' },
              { key: 'homeVisitFees', name: 'Fees' },
              { key: 'totalRequests', name: 'Total Requests' },
              { key: 'activeRequests', name: 'Active Requests' },
              { key: 'rating', name: 'Rating' },
              { key: 'userApproved', name: 'User Approved' },
              { key: 'dateOfApproval', name: 'Date of Approval' },
            ]}
            mapper={item => {
              return [
                { key: 'id', value: item.id },
                { key: 'name', value: [item.firstName, item.middleName, item.lastName].join(' ') },
                { key: 'mobileNumber', value: item.mobileNumber },
                { key: 'lastAcademicQualification', value: item.lastAcademicQualification},
                { key: 'yearsOfExperience', value: item.yearsOfExperience },
                { key: 'cityName', value: item.cities?.map(city => city.arabicName).join(', ') },
                { key: 'areaName', value: item.areas?.map(area => area.arabicName).join(', ') },
                { key: 'homeVisitFees', value: item.homeVisitFees },
                { key: 'totalRequests', value: item.totalRequests },
                { key: 'activeRequests', value: item.activeRequests },
                { key: 'rating', value: item.rating },
                { key: 'userApproved', value: item.userApproved?.firstName },
                { key: 'dateOfApproval', value: item.userApproved?.firstName },
              ]
            }}
            pagesCount={homeCareSpecialists.pagesCount}
            detail
            initialFilters={[_.omitBy({ id }, _.isNil)]}
          />
        </Tabs.Tab>
      </Tabs>
      <Modal show={!!modalAction} dialogClassName='ltr dashboard-layout' onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>{modalAction} Home Care Specialist</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Create ref={submitRef} action={modalAction} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => submitRef.current?.submitForm()}>
            {isLoading ? <Spinner animation="border" size='sm' /> : modalAction}
          </Button>
          <Button variant="outline-primary" onClick={closeModal}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

HomeCareSpecialists.Detail = () => {
  const { id } = useParams()
  const dispatch = useDispatch()

  const handleUpdate = initialValue => body => {
    body.id = id
    body.deletedFields = _.keys(initialValue).filter(key => _.isNil(body[key]) && !_.isNil(initialValue[key]))

    dispatch(DashboardActions.updateRequest({ resource: DashboardResources.HOME_CARE_SPECIALISTS, body }))
  }

  return (
    <DetailedView
      id={id}
      serviceProvider
      resource={DashboardResources.HOME_CARE_SPECIALISTS}
      sectionMappers={[
        homeCareSpecialist => ({
          title: 'Profile Information',
          body: {
            "Name": [homeCareSpecialist.firstName, homeCareSpecialist.middleName, homeCareSpecialist.lastName].filter(n => n).join(' '),
            "Mobile Number": homeCareSpecialist.mobileNumber,
            "Gender": homeCareSpecialist.gender,
            "Email": homeCareSpecialist.email,
            "Birthdate": homeCareSpecialist.birthDate && format(Date.parse(homeCareSpecialist.birthDate), 'dd/MM/yyyy')
          },
          edit: () => ref => {
            const fields = ['firstName', 'middleName', 'lastName', 'mobileNumber', 'gender', 'email', 'birthDate']
            const initialValues = _.pick(homeCareSpecialist, fields, 'id')
            const _schema = yup.object().shape({
              ...schema.pick(fields).fields,
              gender: yup.string().oneOf(Genders.getValues()).nullable(),
              email: yup.string().email().nullable().test(checkExistingTestConfig('email', userType)),
              birthDate: yup.date().nullable()
            })

            return (
              <Formik
                innerRef={ref}
                initialValues={initialValues}
                validationSchema={_schema}
                onSubmit={handleUpdate(initialValues)}
              >
                {({ handleSubmit, handleChange, setFieldValue, values, errors }) =>(
                  <Form noValidate onSubmit={handleSubmit}>
                    <Form.Group>
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        name='firstName'
                        value={values.firstName}
                        isInvalid={errors.firstName}
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type='invalid'>
                        {errors.firstName}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Middle Name</Form.Label>
                      <Form.Control
                        name='middleName'
                        value={values.middleName}
                        isInvalid={errors.middleName}
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type='invalid'>
                        {errors.middleName}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        name='lastName'
                        value={values.lastName}
                        isInvalid={errors.lastName}
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type='invalid'>
                        {errors.lastName}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Mobile Number</Form.Label>
                      <Form.Control
                        name='mobileNumber'
                        value={values.mobileNumber}
                        isInvalid={errors.mobileNumber}
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type='invalid'>
                        {errors.mobileNumber}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Gender</Form.Label>
                      <Choice
                        value={values.gender}
                        options={Genders.getValues()}
                        onChange={value => setFieldValue('gender', value)}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        name='email'
                        value={values.email}
                        isInvalid={errors.email}
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type='invalid'>
                        {errors.email}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Birthdate</Form.Label>
                      <Form.Control
                        type='date'
                        name='birthDate'
                        value={values.birthDate}
                        isInvalid={errors.birthDate}
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type='invalid'>
                        {errors.birthDate}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Form>
                )}
              </Formik>
            )
          }
        }),
        homeCareSpecialist => ({
          title: 'General Questions',
          body: {
            "Reading and writing ability": homeCareSpecialist.readingAndWritingAbility,
            "Physical ability": homeCareSpecialist.physicalAbility,
            "Can carry patient?": homeCareSpecialist.canCarryPatient ? 'Yes' : 'No',
            "Retarded elderly experience": homeCareSpecialist.retardedElderlyExperience,
            "Critical cases experience": homeCareSpecialist.criticalCasesExperience,
            // readingAndWritingAbility: yup.string(),
            // address: yup.string(),
            // serviceDetails: yup.string(),
            // physicalAbility: yup.string(),
            // canCarryPatient: yup.boolean().default(true),
            // retardedElderlyExperience: yup.string(),
            // criticalCasesExperience: yup.string(),
            // certificates: yup.array(yup.string()),
          },
          edit: () => ref => {
            const fields = ['readingAndWritingAbility', 'physicalAbility', 'canCarryPatient', 'retardedElderlyExperience', 'criticalCasesExperience']
            const initialValues = _.pick(homeCareSpecialist, fields)
            const _schema = schema.pick(fields)

            return (
              <Formik
                innerRef={ref}
                initialValues={initialValues}
                validationSchema={_schema}
                onSubmit={handleUpdate(initialValues)}
              >
                {({ handleSubmit, handleChange, setFieldValue, values, errors }) =>(
                  <Form noValidate onSubmit={handleSubmit}>
                    <Form.Group>
                      <Form.Label>Reading and writing ability</Form.Label>
                      <Form.Control
                        name='readingAndWritingAbility'
                        value={values.readingAndWritingAbility}
                        isInvalid={errors.readingAndWritingAbility}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Physical Ability</Form.Label>
                      <Form.Control
                        name='physicalAbility'
                        value={values.physicalAbility}
                        isInvalid={errors.physicalAbility}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Can carry patient?</Form.Label>
                      <Choice
                        name='canCarryPatient'
                        value={values.canCarryPatient ? 'Yes' : 'No'}
                        options={['Yes', 'No']}
                        onChange={value => setFieldValue('canCarryPatient', value === 'Yes')}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Retarded Elderly Experience</Form.Label>
                      <Form.Control
                        name='retardedElderlyExperience'
                        value={values.retardedElderlyExperience}
                        isInvalid={errors.retardedElderlyExperience}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Critical cases Experience</Form.Label>
                      <Form.Control
                        name='criticalCasesExperience'
                        value={values.criticalCasesExperience}
                        isInvalid={errors.criticalCasesExperience}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Form>
                )}
              </Formik>
            )
          }
        }),
        homeCareSpecialist => ({
          title: 'Degrees',
          body: {
            "Last academic qualification": homeCareSpecialist.lastAcademicQualification,
            "Certificates": homeCareSpecialist.certificates.join(' - '),
          },
          edit: () => ref => {
            const fields = ['lastAcademicQualification', 'certificates']
            const initialValues = _.pick(homeCareSpecialist, fields)
            const _schema = schema.pick(fields)

            return (
              <Formik
                innerRef={ref}
                initialValues={initialValues}
                validationSchema={_schema}
                onSubmit={handleUpdate(initialValues)}
              >
                {({ handleSubmit, handleChange, setFieldValue, values, errors }) =>(
                  <Form noValidate onSubmit={handleSubmit}>
                    <Form.Group>
                      <Form.Label>Last Academic Qualification</Form.Label>
                      <Form.Control
                        name='lastAcademicQualification'
                        value={values.lastAcademicQualification}
                        isInvalid={errors.lastAcademicQualification}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <CertificatesFormGroup
                      certificates={values.certificates}
                      onChange={v => setFieldValue('certificates', v)}
                    />
                  </Form>
                )}
              </Formik>
            )
          }
        }),
        homeCareSpecialist => ({
          title: 'Work Shifts',
          body: {
            "Timing": homeCareSpecialist.timing
          },
          edit: () => ref => {
            const fields = [
              'timing',
            ]
            const initialValues = _.pick(homeCareSpecialist, fields)
            const _schema = schema.pick(fields)

            return (
              <Formik
                innerRef={ref}
                initialValues={initialValues}
                validationSchema={_schema}
                onSubmit={handleUpdate(initialValues)}
              >
                {({ handleSubmit, handleChange, values, errors }) =>(
                  <Form noValidate onSubmit={handleSubmit}>
                    <Form.Group>
                      <Form.Label>Timing</Form.Label>
                      <Form.Control
                        name='timing'
                        value={values.timing}
                        isInvalid={errors.timing}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Form>
                )}
              </Formik>
            )
          }
        }),
        homeCareSpecialist => ({
          title: 'Covered Areas & Timing',
          body: {
            "Cities": homeCareSpecialist.cities.map(city => city.arabicName).join(' - '),
            "Areas": homeCareSpecialist.areas.map(area => area.arabicName).join(' - '),
            "Visit Fees": homeCareSpecialist.homeVisitFees
          },
          edit: () => ref => {
            const fields = [
              'homeVisitFees',
              'cities',
              'areas'
            ]
            const initialValues = _.mapValues(_.pick(homeCareSpecialist, fields), (v, k) => ['cities', 'areas'].includes(k) ? v.map(vv => vv.id) : v)
            const _schema = schema.pick(fields)

            return (
              <Formik
                innerRef={ref}
                initialValues={initialValues}
                validationSchema={_schema}
                onSubmit={handleUpdate(initialValues)}
              >
                {({ handleSubmit, handleChange, setFieldValue, values, errors }) =>(
                  <Form noValidate onSubmit={handleSubmit}>
                    <Form.Group>
                      <Form.Label>Fees</Form.Label>
                      <Form.Control
                        type='number'
                        name='homeVisitFees'
                        value={values.homeVisitFees}
                        isInvalid={errors.homeVisitFees}
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type='invalid'>
                        {errors.homeVisitFees}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <CityAreaSubform
                      multiple
                      labels={{
                        city: 'Cities',
                        area: 'Areas'
                      }}
                      values={values}
                      errors={errors}
                      onAreaChange={value => setFieldValue('areas', value)}
                      onCityChange={value => setFieldValue('cities', value)}
                    />
                  </Form>
                )}
              </Formik>
            )
          }
        }),
        homeCareSpecialist => ({
          title: 'Attachments',
          body: {
            "National ID": (
              <>
                <Image src={homeCareSpecialist.nationalIds?.[0]?.url} />,
                <Image src={homeCareSpecialist.nationalIds?.[1]?.url} />,
              </>
            ),
          },
          edit: () => ref => {
            const fields = [
              'nationalIds',
            ]
            const initialValues = _.pick(homeCareSpecialist, fields)
            const _schema = schema.pick(fields)

            return (
              <Formik
                innerRef={ref}
                initialValues={initialValues}
                validationSchema={_schema}
                onSubmit={handleUpdate(initialValues)}
              >
                {({ handleSubmit, setFieldValue, values, errors }) =>(
                  <Form noValidate onSubmit={handleSubmit}>
                    <Form.Group>
                      <Form.Label>National ID</Form.Label>
                      <Attachments
                        single={false}
                        initialValue={values.nationalIds}
                        onChange={value => setFieldValue('nationalIds', value?.map(nid => nid.id))}
                        isInvalid={errors.nationalIds}
                        errorMessage={errors.nationalIds}
                        target={`nationalIds`}
                      />
                    </Form.Group>
                  </Form>
                )}
              </Formik>
            )
          }
        })
      ]}
    />
  )
}

export default HomeCareSpecialists

import { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'

import _ from 'lodash'
import format from 'date-fns/format'
import { arSA } from 'date-fns/locale'
import { Formik } from 'formik'
import * as yup from 'yup'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'

import Screen from '../components/layout/Screen'
import Header from '../components/layout/Header'
import Main from '../components/layout/Main'

import BackButton from '../components/basic/BackButton'
import Editable from '../components/basic/Editable'
import Select from '../components/basic/Select'
import DatePicker from '../components/basic/DatePicker'
import Choice from '../components/basic/Choice'

import LocationInfoForm from '../components/LocationInfoForm'

import { RequesterActions, selectRequesterSlice } from '../redux/reducers/requesterSlice'

import { Genders, chronicDiseases, MOBILE_NUMBER_REGEX } from '../components/common'
import NotificationButton from '../components/basic/NotificationButton'

const SubmitUpdateAndClose = ({ as: Renderer = Button, close, form, schema, initialValues, dispatch, requesterId, patientId, ...rest }) => {
  const [clicked, setClicked] = useState(false)
  const [updates, setUpdates] = useState({})

  const verified = _.isEmpty(updates) || _.isEqual(_.pick(initialValues, _.keys(updates)), updates)
  const isLoading = clicked && !verified

  const deletedFields = _.keys(updates).filter(key => _.isNil(updates[key]))

  useEffect(() => {
    if (clicked && verified) {
      close()
    }
  }, [clicked, close, verified])

  useEffect(() => {
    setUpdates(schema.cast(_.omitBy(form, (v, k) => initialValues[k] === v)))
  }, [form, initialValues, schema])

  const submitUpdatesAndClose = () => {
    if (!_.isEmpty(updates)) {
      schema.pick(_.keys(updates)).validate(updates)
        .then(() => {
          dispatch(RequesterActions.updatePatientRequest({ requesterId, patientId, ...updates, deletedFields }))
          setClicked(true)
        })
        .catch(error => {
          console.log({error, updates})
          document.getElementsByName(error.path)[0]?.focus()
        })
    }
    else {
      setClicked(true)
    }
  }

  const submitFormAndClose = (form) => {
    schema.validate(form)
      .then(() => {
        const updates = _.omitBy(form, (v, k) => initialValues[k] === v)
        if (!_.isEmpty(updates)) {
          dispatch(RequesterActions.updatePatientRequest({ requesterId, patientId, ...updates }))
        }
        setClicked(true)
      })
      .catch(error => {
        document.getElementsByName(error.path)[0]?.focus()
      })
  }

  return (
    <Renderer
      onClick={submitUpdatesAndClose}
      onSubmit={submitFormAndClose}
      disabled={isLoading}
      { ...rest }
    >
      {isLoading ? <Spinner animation="border" size='sm' /> : <span>تأكيد</span>}
    </Renderer>
  )
}

const AgeOrBirthdate = ({ know, control: { setFieldValue, errors, values } }) => {
  const [knowBirthdate, setKnowBirthdate] = useState(know)
  const ageRanges = useMemo(() => [
    { text: 'أقل من 18 عام', value: 'أقل من 18 عام' },
    { text: 'ما بين 18 حتى 30 عام', value: 'ما بين 18 حتى 30 عام' },
    { text: 'ما بين 30 حتى 45 عام', value: 'ما بين 30 حتى 45 عام' },
    { text: 'ما بين 45 حتى 60 عام', value: 'ما بين 45 حتى 60 عام' },
    { text: 'من 60 عام إلى أعلى ', value: 'من 60 عام إلى أعلى ' }
  ], [])

  return (
    <>
      <Form.Group>
        <Form.Label>هل تعلم تاريخ ميلاد المريض ؟</Form.Label>
        <Choice
          value={knowBirthdate ? 'نعم' : 'لا'}
          options={['نعم', 'لا']}
          onChange={() => {
            setKnowBirthdate(!knowBirthdate)
            // setInitialForm(values)
          }}
        />
      </Form.Group>

      {
        knowBirthdate
        ? <Form.Group>
            <Form.Label>تاريخ الميلاد</Form.Label>
            <DatePicker
              label='تحديد تاريخ الميلاد'
              onChange={date => {
                setFieldValue('birthDate', date)
                setFieldValue('age', null)
              }}
              isInvalid={errors.birthDate}
              value={!!values.birthDate && new Date(values.birthDate)}
              birthDate
            />
          </Form.Group>
        : <Form.Group>
            <Form.Label>السن التقريبى للمريض</Form.Label>
            <Select
              name='age'
              label='السن التقريبى للمريض'
              options={ageRanges}
              initialValue={values.age}
              value={values.age}
              onSelect={value => {
                setFieldValue('age', value)
                setFieldValue('birthDate', null)
              }}
              isInvalid={errors.age}
            />
            <Form.Control.Feedback type='invalid'>
              {errors.age}
            </Form.Control.Feedback>
          </Form.Group>
      }
    </>
  )
}

const PatientDataForm = ({ requesterId, patientId, source }) => {
  const dispatch = useDispatch()
  const { patient } = useSelector(selectRequesterSlice)

  useEffect(() => {
    dispatch(RequesterActions.getPatientDetailsRequest({ requesterId, patientId }))
  }, [dispatch, requesterId, patientId])


  const fieldTitles = {
    name: 'الاسم',
    birthDate: 'تاريخ الميلاد',
    mobileNumber: 'رقم الهاتف',
    gender: 'الجنس',
    height: 'الطول',
    weight: 'الوزن',
    medicalHistory: 'التاريخ الطبى',
    age: 'العمر التقريبي'
  }

  const initialValues = {
    name: patient?.name,
    birthDate: patient?.birthDate,
    mobileNumber: patient?.mobileNumber,
    gender: patient?.gender,
    chronicDiseases: patient?.chronicDiseases,
    height: patient?.height,
    weight: patient?.weight,
    medicalHistory: patient?.medicalHistory,
    cityId: patient?.city.id,
    areaId: patient?.area.id,
    address: patient?.address,
    age: patient?.age
  }

  const schema = yup.object().shape({
    name: yup.string().required('من فضلك قم بادخال المعلومات كاملة'),
    birthDate: yup.date().nullable(),
    mobileNumber: yup.string().matches(MOBILE_NUMBER_REGEX, 'من فضلك أدخل رقم هاتف صحيح').required('من فضلك قم بادخال المعلومات كاملة'),
    gender: yup.string().oneOf(Genders.getValues()).required('من فضلك قم بادخال المعلومات كاملة'),
    chronicDiseases: yup.array(yup.string()).default([]),
    height: yup.number().min(10, 'الطول الذي ادخلته غير صحيح').nullable().transform(v => isNaN(v) ? null : v),
    weight: yup.number().min(1, 'الوزن الذي ادخلته غير صحيح').nullable().transform(v => isNaN(v) ? null : v),
    medicalHistory: yup.string().nullable(),
    cityId: yup.number().required('من فضلك قم بادخال المعلومات كاملة'),
    areaId: yup.number().required('من فضلك قم بادخال المعلومات كاملة'),
    address: yup.string().required('من فضلك قم بادخال المعلومات كاملة'),
    age: yup.string().nullable()
  })

  return (
    <Formik
      validationSchema={schema}
      initialValues={initialValues}
      enableReinitialize={true}
    >
      {({ values, errors, handleChange, setFieldValue, handleSubmit }) => (
        <Form noValidate onSubmit={handleSubmit}>
          {source !== 'profile' && (
            <Form.Group>
              <Form.Label>البيانات الشخصية</Form.Label>
              <Editable
                editTitle='تعديل البيانات الشخصية'
                value={_.omitBy({
                  [fieldTitles.name]: patient?.name,
                  [fieldTitles.birthDate]: patient?.birthDate ? format(new Date(patient?.birthDate), 'd - MMMM - y', { locale: arSA }) : null,
                  [fieldTitles.age]: patient?.age,
                  [fieldTitles.mobileNumber]: patient?.mobileNumber,
                  [fieldTitles.gender]: Genders.getTextByValue(patient?.gender)
                }, _.isNil)}
              >
                {(close, ref) => (
                  <Form ref={ref}>
                    <Form.Group>
                      <Form.Label>{fieldTitles.name}</Form.Label>
                      <Form.Control
                        name='name'
                        value={values.name}
                        isInvalid={errors.name}
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type='invalid'>
                        {errors.name}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <AgeOrBirthdate
                      know={!!patient?.birthDate || !patient?.age}
                      control={{
                        setFieldValue,
                        values,
                        errors
                      }}
                    />

                    <Form.Group>
                      <Form.Label>{fieldTitles.mobileNumber}</Form.Label>
                      <Form.Control
                        name='mobileNumber'
                        value={values.mobileNumber}
                        isInvalid={errors.mobileNumber}
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type='invalid'>
                        {errors.mobileNumber}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group>
                      <Form.Label>{fieldTitles.gender}</Form.Label>
                      <Choice
                        name='gender'
                        value={Genders.getTextByValue(values.gender)}
                        options={Genders.getTexts()}
                        onChange={value => setFieldValue('gender', Genders.getValueByText(value))}
                      />
                    </Form.Group>

                    <SubmitUpdateAndClose
                      close={close}
                      form={_.pick(values, ['name', 'birthDate', 'mobileNumber' ,'gender', 'age'])}
                      schema={schema.pick(['name', 'birthDate', 'mobileNumber' ,'gender', 'age'])}
                      requesterId={requesterId}
                      patientId={patientId}
                      dispatch={dispatch}
                      initialValues={initialValues}
                    />
                  </Form>
                )}
              </Editable>
            </Form.Group>
          )}

          <Form.Group>
            <Form.Label>الأمراض المزمنة</Form.Label>
            <Editable
              name='chronicDiseases'
              value={values.chronicDiseases}
              editTitle='تغيير الأمراض المزمنة'
            >
              {close => (
                <Form>
                  <Form.Group>
                    <Select
                      label='الأمراض المزمنة'
                      multiple
                      other
                      onSelect={value => setFieldValue('chronicDiseases', value)}
                      options={chronicDiseases}
                      initialValue={values.chronicDiseases}
                      value={values.chronicDiseases}
                    />
                  </Form.Group>
                  <SubmitUpdateAndClose
                    close={close}
                    form={_.pick(values, ['chronicDiseases'])}
                    schema={schema.pick(['chronicDiseases'])}
                    requesterId={requesterId}
                    patientId={patientId}
                    dispatch={dispatch}
                    initialValues={initialValues}
                  />
                </Form>
              )}
            </Editable>
          </Form.Group>

          <Form.Group>
            <Form.Label>بيانات الطول والوزن</Form.Label>
            <Editable
              editTitle='تعديل بيانات الطول والوزن'
              value={{
                [fieldTitles.height]: patient?.height,
                [fieldTitles.weight]: patient?.weight,
                [fieldTitles.medicalHistory]: patient?.medicalHistory
              }}
            >
              {close => (
                <Form>
                  <Form.Group>
                    <Form.Label>الوزن بالكيلو جرام</Form.Label>
                    <Form.Control
                      type='number'
                      placeholder='الوزن بالكيلو جرام'
                      name='weight'
                      onChange={handleChange}
                      isInvalid={errors.weight}
                      value={values.weight}
                    />
                    <Form.Control.Feedback type='invalid'>
                      {errors.weight}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>الطول بالسنتيمتر</Form.Label>
                    <Form.Control
                      type='number'
                      placeholder='الطول بالسنتيمتر'
                      name='height'
                      onChange={handleChange}
                      isInvalid={errors.height}
                      value={values.height}
                    />
                    <Form.Control.Feedback type='invalid'>
                      {errors.height}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>التاريخ الطبى</Form.Label>
                    <Form.Control
                      type='text'
                      as='textarea'
                      placeholder='التاريخ الطبى'
                      name='medicalHistory'
                      onChange={handleChange}
                      isInvalid={errors.medicalHistory}
                      value={values.medicalHistory}
                    />
                  </Form.Group>

                  <SubmitUpdateAndClose
                    close={close}
                    form={_.pick(values, ['height', 'weight', 'medicalHistory'])}
                    schema={schema.pick(['height', 'weight', 'medicalHistory'])}
                    requesterId={requesterId}
                    patientId={patientId}
                    dispatch={dispatch}
                    initialValues={initialValues}
                  />
                </Form>
              )}
            </Editable>
          </Form.Group>

          <Form.Group>
            <Form.Label>عنوان تقديم الخدمة</Form.Label>
            <Editable
              editTitle='تغيير العنوان'
              value={[patient?.city.arabicName, patient?.area.arabicName, patient?.address].filter(x => x).join(' - ')}
            >
              {close => (
                <SubmitUpdateAndClose
                  as={LocationInfoForm}
                  close={close}
                  form={_.pick(values, ['cityId', 'areaId', 'address'])}
                  schema={schema.pick(['cityId', 'areaId', 'address'])}
                  requesterId={requesterId}
                  patientId={patientId}
                  dispatch={dispatch}
                  initialValues={initialValues}
                  data={values}
                />
              )}
            </Editable>
          </Form.Group>
        </Form>
      )}
    </Formik>
  )
}

const PatientScreen = () => {
  const { requesterId, patientId } = useParams()
  const history = useHistory()

  const source = history.location.state?.source

  return (
    <Screen>
      <Header
        middleItem={source === 'profile' ? <b>تعديل بياناتي الطبية</b> : <b>بيانات المريض</b>}
        leftItem={<NotificationButton />}
        rightItem={<BackButton />}
      />
      <Main className='home-main'>
        <PatientDataForm requesterId={requesterId} patientId={patientId} source={source} />
      </Main>
    </Screen>
  )
}

PatientScreen.Form = PatientDataForm

export default PatientScreen

import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import ReservationCard from '../components/ReservationCard'
import { ReservationStatus } from '../components/common'

import { ReservationActions, selectReservation } from '../redux/reducers/reservationSlice'
import { selectAuth } from '../redux/reducers/authSlice'

const ReservationsInformation = ({ requesterId }) => {
  const dispatch = useDispatch()
  const { isLoggedIn } = useSelector(selectAuth)
  const { reservations } = useSelector(selectReservation)

  useEffect(() => {
    dispatch(ReservationActions.listReservationsRequest({ requesterId }))
  }, [dispatch, requesterId])

  const pendingReservations = reservations.filter(r => r.status.startsWith('Pending'))
  const upcomingReservations = reservations.filter(r => r.status === ReservationStatus.ONGOING)

  const pending = !!pendingReservations.length && (
    <div key='pending'>
      <h3>زيارات قيد التنفيذ</h3>
      <ReservationCard.Group horizontal>
        {pendingReservations.map(reservation =>
          <ReservationCard reservation={reservation} />
        )}
      </ReservationCard.Group>
    </div>
  )

  const upcoming = !!upcomingReservations.length && (
    <div key='upcoming'>
      <h3>زيارات قيد التنفيذ</h3>
      <ReservationCard.Group horizontal>
        {upcomingReservations.map(reservation =>
          <ReservationCard reservation={reservation} />
        )}
      </ReservationCard.Group>
    </div>
  )

  return isLoggedIn && (
    <div>
      {upcoming}
      {pending}
    </div>
  )
}

export default ReservationsInformation

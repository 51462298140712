import Card from 'react-bootstrap/Card'
import CardGroup from 'react-bootstrap/CardGroup'
import Nav from 'react-bootstrap/Nav'
import Button from 'react-bootstrap/Button'

import { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import qs from 'query-string'

import { selectAuth } from '../redux/reducers/authSlice'
import { RequesterActions, selectRequesterSlice } from '../redux/reducers/requesterSlice'

import Screen from '../components/layout/Screen'
import Header from '../components/layout/Header'
import Main from '../components/layout/Main'
import Footer from '../components/layout/Footer'

import { EmptyActivePatientsBody } from './MyPatientsScreen'
import ResumeReservation from '../components/ResumeReservation'
import ReservationsInformation from '../components/ReservationsInformation'
import Survey from '../components/Survey'

import Svg from '../components/basic/Svg'

import {ReactComponent as DoctorRequestImage} from '../svg/doctor_request.svg'
import {ReactComponent as PhysicalTherapyRequestImage} from '../svg/physical_therapy_request.svg'
import {ReactComponent as NurseRequestImage} from '../svg/nurse_request.svg'
import {ReactComponent as HomeCareSpecialistRequestImage} from '../svg/home_care_specialist_request.svg'
import {ReactComponent as MedicalTestsRequestImage} from '../svg/medical_tests_request.svg'

import { ProviderTypes, ServiceTypes } from '../components/common'
import BottomSheet from '../components/basic/BottomSheet'
import NotificationButton from '../components/basic/NotificationButton'

const GuestDecision = Object.freeze({
  NEW_USER: 'new-user',
  EXISTING_USER: 'existing-user'
})

const HomeScreen = ({ requesterId }) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const [providerType, setProviderType] = useState()
  const [serviceType, setServiceType] = useState()
  const [showNoPatients, setShowNoPatients] = useState(false)
  const [showWarning, setShowWarning] = useState(false)
  const [guestFunction, setGuestFunction] = useState()
  const [guestDecision, setGuestDecision] = useState()
  const guestDecisionRef = useRef()

  const { userDetails, isLoggedIn } = useSelector(selectAuth)
  const { patients } = useSelector(selectRequesterSlice)

  const rightItem = isLoggedIn
    ? <div>أهلاً بك <b>{userDetails.firstName}</b></div>
    : <Link to='/signIn'>تسجيل الدخول</Link>


  useEffect(() => {
    dispatch(RequesterActions.listRequesterPatientsRequest({ requesterId }))
  }, [dispatch, requesterId])


  const cards = [
    {
      title: 'كشف طبي',
      image: DoctorRequestImage,
      provider: ProviderTypes.DOCTOR,
      services: [
        ServiceTypes.HOME_VISIT,
        ServiceTypes.CALL_CONSULTATION
      ],
      firstTimeWarning: false
    },
    {
      title: 'علاج طبيعي',
      image: PhysicalTherapyRequestImage,
      provider: ProviderTypes.PHYSICAL_THERAPIST,
      services: [],
      firstTimeWarning: false
    },
    {
      title: 'تمريض',
      image: NurseRequestImage,
      provider: ProviderTypes.NURSE,
      services: [],
      firstTimeWarning: true
    },
    {
      title: 'جليس',
      image: HomeCareSpecialistRequestImage,
      provider: ProviderTypes.HOME_CARE_SPECIALIST,
      services: [],
      firstTimeWarning: true
    },
    {
      title: 'أشعة وتحاليل',
      image: MedicalTestsRequestImage,
      provider: ProviderTypes.MEDICAL_RAYS_ANALYSIS,
      services: [
        ServiceTypes.MEDICAL_RAYS,
        ServiceTypes.MEDICAL_ANALYSIS
      ],
      firstTimeWarning: false
    },
  ]

  const handleServiceClick = (providerType, serviceType) => {
    if (patients.length || !isLoggedIn) {
      history.push({
        pathname: '/reservations/new',
        search: qs.stringify({
          providerType,
          serviceType
        })
      })
    }
    else {
      setShowNoPatients(true)
    }
  }

  const handleSelfReservationClick = () => {
    const state = {
      isRequester: true,
      providerType: providerType.value
    }
    if (serviceType) {
      state.serviceType = serviceType.value
    }
    history.push(`/requester/${requesterId}/patient/new`, state)
  }

  const getWarningPopupMessage = () => {
    console.log('inside getWarningPopupMessage', { providerType })
    switch (providerType?.value) {
      case ProviderTypes.NURSE.value:
        return <p><b>التمريض</b> هو المسؤول عن مراقبة الحالة الصحية للمريض خلال رقوده على سرير الشفاء والحد من المضاعفات التي قد ترافق المرض بناءاً على أسس طبية</p>
      case ProviderTypes.HOME_CARE_SPECIALIST.value:
      default:
        return <span><b>الجليس المنزلي</b> هو المسؤول عن مرافقة كبار السن وذوي الإحتياجات الخاصة وغير مسؤول عن متابعة متلقي الخدمة من الناحية الطبية أو المرضية لأنه غير مؤهل للقيام بذلك</span>
    }
  }

  return (
    <Screen>
      <Header
        floatingIcon
        middleItem={<b>DoktorCare</b>}
        rightItem={rightItem}
        leftItem={<NotificationButton />}
      />
      <Main className='home-main'>
        <ResumeReservation requesterId={requesterId} />
        <ReservationsInformation requesterId={requesterId} />
        <h3>إحجز خدمتك الآن</h3>
        <CardGroup className='home-cards'>
          {cards.map((card, index) => {
            const { provider, services, firstTimeWarning } = card
            return (
              <div key={`home-card-${index}`}>
                <Card
                  className='home-card'
                  onClick={() => {
                    setProviderType(provider)
                    setServiceType(services[0])
                    if (isLoggedIn) {
                      if (!services.length) {
                        if (firstTimeWarning) {
                          setShowWarning(true)
                        }
                        else {
                          handleServiceClick(provider.value)
                        }
                      }
                    }
                    else {
                      setGuestFunction(() => () => {
                        if (!services.length) {
                          if (firstTimeWarning) {
                            setShowWarning(true)
                          }
                          else {
                            handleServiceClick(provider.value)
                          }
                        }
                      })
                    }
                  }}
                >
                  <Card.Body className='home-card-image'>
                    <Svg as={card.image} />
                  </Card.Body>
                  <Card.Body className='home-card-title'>
                    <Card.Title>{card.title}</Card.Title>
                  </Card.Body>
                </Card>
                {!!services.length && (
                  <BottomSheet
                    title='إختر نوع الخدمة'
                    show={providerType === provider && !!services.length}
                    close={() => {
                      setProviderType(null)
                      setServiceType(null)
                    }}
                  >
                    <Nav className='service-selection' variant='pills' defaultActiveKey={services[0].value}>
                      {card.services.map((service, index) => (
                        <Nav.Item key={index}>
                          <Nav.Link eventKey={service.value} onClick={() => setServiceType(service)}>
                            <div className='service-selection-title'>{service.text}</div>
                            <div className='service-selection-body'>{service.description}</div>
                          </Nav.Link>
                        </Nav.Item>
                      ))}
                    </Nav>
                    <br/>
                    <Button onClick={() => handleServiceClick(providerType.value, serviceType.value)}>بدء الحجز</Button>
                  </BottomSheet>
                )}
              </div>
            )
          })}
        </CardGroup>
        <BottomSheet
          title='لا يوجد أى مرضى مضافة'
          show={showNoPatients}
          close={() => setShowNoPatients(false)}
        >
          <EmptyActivePatientsBody requesterId={requesterId} history={history} state={{providerType: providerType?.value, serviceType: serviceType?.value}}>
            <div>
              حتى تتمكن من حجز خدمة لابد من إضافة مريض جديد وإضافة بياناته الطبية والشخصية
            </div>
            <br />
          </EmptyActivePatientsBody>
          <br />
          <Button variant='outline-primary' onClick={handleSelfReservationClick}>طلب الخدمة لنفسي </Button>
        </BottomSheet>
        <BottomSheet
          title='تنويه'
          show={showWarning}
          close={() => setShowWarning(false)}
        >
          <div className='home-warning'>
            {getWarningPopupMessage()}
            <Button onClick={() => handleServiceClick(providerType.value)}>بدء الحجز</Button>
          </div>
        </BottomSheet>
        <BottomSheet
          title='تسجيل معلومات الحساب'
          show={guestFunction}
          close={() => {
            setProviderType(null)
            setServiceType(null)
            setGuestFunction(null)
          }}
        >
          <div className='selection-message'>
            فى حالة عدم وجود حساب يمكنك إستكمال حجز الخدمة وإدخال بيانات تسجيل الحساب في اخر خطوة.
          </div>
          <Nav
            className='service-selection'
            variant='pills'
            defaultActiveKey={GuestDecision.NEW_USER}
            ref={guestDecisionRef}
            onSelect={setGuestDecision}
          >
            <Nav.Item>
              <Nav.Link eventKey={GuestDecision.EXISTING_USER}>
                <div className='service-selection-title'>لدى حساب بالفعل</div>
                <div className='service-selection-body'>قم بتسجيل الدخول لحسابك الان</div>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey={GuestDecision.NEW_USER}>
                <div className='service-selection-title'>ليس لدى حساب</div>
                <div className='service-selection-body'>إستكمال الطلب وتسجيل الحساب في اخر خطوة</div>
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <br/>
          <Button onClick={() => {
            if (guestDecision === GuestDecision.EXISTING_USER) {
              history.push('/signIn')
            }
            else {
              guestFunction()
              setGuestFunction(null)
            }
          }}>إستمرار</Button>
        </BottomSheet>
        <Survey requesterId={requesterId} />
      </Main>
      <Footer />
    </Screen>
  )
}

export default HomeScreen

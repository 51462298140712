import axios from 'axios'
import { parseAttachment } from '../../components/common'

export const uploadProfilePictureApi = ({ file }) => {
  const formData = new FormData()
  formData.append("file", file)

  return axios.post(`${process.env.REACT_APP_BE_URL}/uplattachmentsoad/profilePictures`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export const uploadApi = ({ file, policy }) => {
  const formData = new FormData()
  formData.append("file", file)

  return axios.post(`${process.env.REACT_APP_BE_URL}/attachments/${policy}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export const dropAttachmentApi = ({ url }) => {
  const { bucket, key } = parseAttachment(url)

  return axios.delete(`${process.env.REACT_APP_BE_URL}/attachments?bucket=${bucket}&key=${key}`)
}

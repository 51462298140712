import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Button from 'react-bootstrap/Button'

import Screen from '../components/layout/Screen'
import Header from '../components/layout/Header'
import Main from '../components/layout/Main'
import Footer from '../components/layout/Footer'

import BottomSheet from '../components/basic/BottomSheet'

import Rating from '../components/Rating'
import Tree from '../components/Tree'

import {ReactComponent as EditProfileIcon} from '../svg/edit_profile.svg'
import {ReactComponent as EditIcon} from '../svg/edit.svg'
import {ReactComponent as LockIcon} from '../svg/lock.svg'
import {ReactComponent as DoctorMicrophoneIcon} from '../svg/doctor_microphone.svg'
import {ReactComponent as SettingsIcon} from '../svg/settings.svg'
import {ReactComponent as FileIcon} from '../svg/file.svg'
import {ReactComponent as PhoneIcon} from '../svg/phone.svg'
import {ReactComponent as LogOutIcon} from '../svg/log_out.svg'

import { RequesterActions, selectRequesterSlice } from '../redux/reducers/requesterSlice'
import { AuthActions } from '../redux/reducers/authSlice'
import { ReservationActions, selectReservation } from '../redux/reducers/reservationSlice'
import NotificationButton from '../components/basic/NotificationButton'
import { CacheActions } from '../redux/reducers/cacheSlice'

const ProfileScreen = ({ requesterId, location, history }) => {
  const dispatch = useDispatch()
  const [showSignout, setShowSignout] = useState(false)
  const [showRQTUniversalAgreement, setShowRQTUniversalAgreement] = useState(false)

  const { patients } = useSelector(selectRequesterSlice)
  const { agreement } = useSelector(selectReservation)

  const requesterPatientId = patients.find(patient => patient.isRequester)?.id

  useEffect(() => {
    dispatch(RequesterActions.getRequesterPatientRequest({ requesterId }))
    dispatch(ReservationActions.getAgreementRequest({ agreementType: 'RequesterUniversal' }))
  }, [dispatch, requesterId])

  const renderTree = () => {
    switch (location.hash.slice(1)) {
      case '/settings':
        return (
          <Tree>
            {/* <Tree.Item icon={FlagIcon}>
              اللغة
            </Tree.Item> */}
            <Tree.Item icon={FileIcon} onClick={toggleShowRQTUniversalAgreement}>
              الشروط والأحكام
            </Tree.Item>
            <Tree.Item icon={PhoneIcon}>
              إتصل بنا
            </Tree.Item>
            <Tree.Item icon={LogOutIcon} onClick={toggleShowSignout}>
              تسجيل الخروج
            </Tree.Item>
          </Tree>
        )
      case '/':
      default:
        return (
          <Tree>
            <Tree.Item
              icon={EditProfileIcon}
              onClick={() => history.push(`/requester/${requesterId}/edit`)}
            >
              تعديل معلوماتي الشخصية
            </Tree.Item>
            <Tree.Item
              icon={EditIcon}
              onClick={() => {
                history.push(`/requester/${requesterId}/patient/${requesterPatientId || 'new'}`, {
                  isRequester: true,
                  source: 'profile'
                })
              }}
            >
              تعديل بياناتي الطبية
            </Tree.Item>
            <Tree.Item
              icon={LockIcon}
              onClick={() => history.push(`/requester/${requesterId}/password`)}
            >
              تغيير كلمة المرور
            </Tree.Item>
            <Tree.Item
              icon={DoctorMicrophoneIcon}
              onClick={() => history.push(`/requester/${requesterId}/providers`)}
            >
              الخدمات السابقة
            </Tree.Item>
            <Tree.Item
              icon={SettingsIcon}
              onClick={() => history.push({ hash: '/settings' })}
            >
              الإعدادات
            </Tree.Item>
          </Tree>
        )
    }
  }

  const toggleShowSignout = () => setShowSignout(!showSignout)
  const toggleShowRQTUniversalAgreement = () => setShowRQTUniversalAgreement(!showRQTUniversalAgreement)

  const signOut = () => {
    dispatch(AuthActions.signOut())
    dispatch(CacheActions.reset())
    dispatch(ReservationActions.reset())
    dispatch(RequesterActions.reset())
    history.push('/')
  }

  return (
    <Screen>
      <Header
        middleItem={<b>حسابي</b>}
        leftItem={<NotificationButton />}
        rightItem={<Rating />}
      />
      <Main>
        {renderTree()}
        <BottomSheet
          close={toggleShowSignout}
          show={showSignout}
          title='تسجيل الخروج'
        >
          <span>هل أنت متأكد من عملية تسجيل الخروج ؟</span>
          <Button variant='danger' onClick={signOut}>تسجيل الخروج</Button>
        </BottomSheet>
        <BottomSheet
          close={toggleShowRQTUniversalAgreement}
          show={showRQTUniversalAgreement}
          title='الشروط والأحكام'
        >
          <form>{agreement?.details}</form>
        </BottomSheet>
      </Main>
      <Footer />
    </Screen>
  )
}

export default withRouter(ProfileScreen)

import Nav from 'react-bootstrap/Nav'
import { useEffect } from 'react'

const Choice = ({ options, onChange, value }) => {
  useEffect(() => {
    if (options.length && !options.includes(value)) {
      onChange(options[0])
    }
  }, [onChange, value, options])
  return (
    <Nav className='choice' variant="pills" defaultActiveKey={value || options[0]}>
      {options.map((option, index) =>
        <Nav.Item className='choice-option' key={index}>
          <Nav.Link
            eventKey={option}
            onClick={e => onChange(e.target.dataset.rrUiEventKey)}
            value={option}
            {...!!value && { active: value === option }}
          >
            {option}
          </Nav.Link>
        </Nav.Item>
      )}
    </Nav>
  )
}

export default Choice

import { call, put, takeLatest } from 'redux-saga/effects'
import * as Api from '../api/notificationsApi'

import { NotificationsActions } from '../reducers/notificationsSlice'

function* listNotifications(action) {
   try {
      const response = yield call(Api.listNotificationsApi, action.payload)
      yield put(NotificationsActions.listNotificationsSuccess({ notifications: response.data }))
   } catch (error) {
      yield put(NotificationsActions.listNotificationsFailure(error.response?.data?.error || error))
   }
}

export default function* notificationsSaga() {
   yield takeLatest(NotificationsActions.listNotificationsRequest, listNotifications)
}

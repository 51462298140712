import Button from 'react-bootstrap/Button'

import BottomSheet from './basic/BottomSheet'
import Svg from './basic/Svg'

import {ReactComponent as SuccessImage} from '../svg/success.svg'

const Success = ({ show, close, title, message, buttonText, onButtonClick }) => {
  return (
    <BottomSheet
      title={title}
      show={show}
      close={close}
    >
      <Svg as={SuccessImage} className='success-image' />
      <p className='success-message'>{message}</p>
      {buttonText && onButtonClick && <Button variant='primary' onClick={onButtonClick}>{buttonText}</Button>}
    </BottomSheet>
  )
}

export default Success

import { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PlusCircleFill, PenFill } from 'react-bootstrap-icons'
import { Formik } from 'formik'
import * as yup from 'yup'
import _ from 'lodash'
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import startOfToday from 'date-fns/startOfToday'

import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Spinner from 'react-bootstrap/Spinner'
import Image from 'react-bootstrap/Image'

import DynamicTable from '../../components/DynamicTable'
import Choice from '../../components/basic/Choice'
import PassordInput from '../../components/basic/PasswordInput'
import Attachments from '../../components/basic/Attachments'
import { DashboardActions, DashboardResources, selectDashboard } from '../../redux/reducers/dashboardSlice'
import { Genders, GetRef, MOBILE_NUMBER_REGEX, PASSWORD_REGEX, usePrevious } from '../../components/common'
import { checkExistingApi } from '../../redux/api/authApi'

const userType = 'SystemUser'

const Operators = () => {
  const dispatch = useDispatch()
  const { systemUsers, isLoading } = useSelector(selectDashboard)
  const previousSystemUsers = usePrevious(systemUsers)

  const [showCreate, setShowCreate] = useState(false)
  const [showUpdate, setShowUpdate] = useState(false)
  const [editOperator, setEditOperator] = useState({})
  const submitRef = useRef()

  const birthDateRef = useRef()

  const schema = yup.object().shape({
    role: yup.string().required().default('OperationsSpecialist'),
    firstName: yup.string().required(),
    middleName: yup.string().nullable(),
    lastName: yup.string().required(),
    mobileNumber: yup.string().matches(MOBILE_NUMBER_REGEX).required().test('unique-phone', 'Mobile Number already taken', async (value) => {
      if (value) {
        const ignore = editOperator.id
        const response = await checkExistingApi({ target: 'mobileNumber', userType, value, ignore })
        return !response.data
      }
      return true
    }),
    email: yup.string().email().required().test('unique-email', 'Email already taken', async (value) => {
      if (value) {
        const ignore = editOperator.id
        const response = await checkExistingApi({ target: 'email', userType, value, ignore })
        return !response.data
      }
      return true
    }),
    title: yup.string(),
    birthDate: yup.date().nullable(),
    gender: yup.string().required().oneOf(Genders.getValues()),
    profilePicture: yup.number().nullable(),
    ...showCreate
    ? {
        password: yup.string().matches(PASSWORD_REGEX).required(),
        passwordAgain: yup.string().oneOf([yup.ref('password')]).required(),
      }
    : {
        password: yup.string().matches(PASSWORD_REGEX).nullable(),
        passwordAgain: yup.string().oneOf([yup.ref('password')]).nullable(),
      }
  })

  const onSubmit = (form) => {

    const payload = {
      resource: DashboardResources.OPERATIONS_SPECIALISTS,
      body: {
        ...form,
        role: 'OperationsSpecialist'
      }
    }

    if (showCreate) {
      dispatch(DashboardActions.createRequest(payload))
    }
    else {
      payload.body.id = editOperator.id
      dispatch(DashboardActions.updateRequest(payload))
    }
  }

  const isRequired = field => field.spec.presence === 'required'

  const edit = operator => {
    const { birthDate, profilePicture } = operator
    setEditOperator({
      ...operator,
      birthDate: birthDate ? format(new Date(birthDate), 'yyyy-MM-dd') : null,
      profilePicture: profilePicture?.id,
      _profilePicture: profilePicture
    })
    setShowUpdate(true)
  }

  const handleCancel = () => {
    setShowCreate(false)
    setShowUpdate(false)
    setEditOperator({})
  }

  // useEffect(() => {
  //   dispatch(DashboardActions.listRequest({ resource: DashboardResources.OPERATIONS_SPECIALISTS }))
  // }, [dispatch])

  useEffect(() => {
    if (!_.isEqual(systemUsers, previousSystemUsers)) {
      setShowCreate(false)
      setShowUpdate(false)
    }
  }, [previousSystemUsers, systemUsers])

  const action = showCreate ? 'Create' : 'Update'

  const birthDateQueryMapper = query => {
    const custom = {}
    if (query.birthDate) {
      custom.birthDate = format(parse(query.birthDate, 'dd/MM/yyyy', startOfToday()), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'")
    }

    return { ...query, ...custom }
  }

  return (
    <div>
      <div className='sub-screen-title'>
        <h1>Opertaions Specialists</h1>
        <Button float='right' onClick={() => setShowCreate(true)}><PlusCircleFill /> Create</Button>
      </div>
      <DynamicTable
        search
        sortable
        resource={DashboardResources.OPERATIONS_SPECIALISTS}
        data={systemUsers.items}
        headers={[
          { key: 'id', name: 'ID', sort: 'id.prefix,id.order' },
          { key: 'name', name: 'Name', sort: 'firstName,middleName,lastName' },
          { key: 'role', name: 'Role' },
          { key: 'mobileNumber', name: 'Number' },
          { key: 'email', name: 'Email' },
          { key: 'gender', name: 'Gender' },
          { key: 'birthDate', name: 'Birthday' },
          { key: '$profilePicture', name: 'Profile Picture' },
          { key: '$edit', name: '' }
        ]}
        mapper={item => {
          return [
            { key: 'id', value: item.id },
            { key: 'name', value: [item.firstName, item.middleName, item.lastName].join(' ') },
            { key: 'role', value: item.role },
            { key: 'mobileNumber', value: item.mobileNumber },
            { key: 'email', value: item.email },
            // { key: 'title', name: 'Title', value: item.title },
            { key: 'gender', value: item.gender },
            { key: 'birthDate', value: item.birthDate && format(new Date(item.birthDate), 'dd/MM/yyyy') },
            { key: '$profilePicture', value: <Image src={item.profilePicture?.url} roundedCircle /> },
            { key: '$edit', value: <div onClick={() => edit(item)}><PenFill /></div> }
          ]
        }}
        pagesCount={systemUsers.pagesCount}
        queryMapper={birthDateQueryMapper}
      />
      <Modal show={showCreate || showUpdate} onHide={handleCancel} dialogClassName='ltr dashboard-layout'>
        <Modal.Header closeButton>
          <Modal.Title>{action} Operations Specialist</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            validationSchema={schema}
            onSubmit={onSubmit}
            initialValues={{...schema.default(), ...editOperator}}
            validateOnChange={false}
          >
            {({
              handleSubmit,
              handleChange,
              setFieldValue,
              values,
              errors
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Group required={isRequired(schema.fields.role)}>
                  <Form.Label>Role</Form.Label>
                  <Form.Select
                    name='role'
                    onChange={handleChange}
                    defaultValue={values.role}
                  >
                    <option value='OperationsSpecialist'>Operations Specialist</option>
                  </Form.Select>
                  <Form.Control.Feedback type='invalid'>
                    {errors.role}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group required={isRequired(schema.fields.firstName)}>
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    name='firstName'
                    value={values.firstName}
                    onChange={handleChange}
                    isInvalid={errors.firstName}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.firstName}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group required={isRequired(schema.fields.middleName)}>
                  <Form.Label>Middle Name</Form.Label>
                  <Form.Control
                    name='middleName'
                    value={values.middleName}
                    onChange={handleChange}
                    isInvalid={errors.middleName}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.middleName}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group required={isRequired(schema.fields.lastName)}>
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    name='lastName'
                    value={values.lastName}
                    onChange={handleChange}
                    isInvalid={errors.lastName}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.lastName}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group required={isRequired(schema.fields.mobileNumber)}>
                  <Form.Label>Mobile Number</Form.Label>
                  <Form.Control
                    name='mobileNumber'
                    value={values.mobileNumber}
                    onChange={handleChange}
                    isInvalid={errors.mobileNumber}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.mobileNumber}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group required={isRequired(schema.fields.email)}>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    name='email'
                    value={values.email}
                    onChange={handleChange}
                    isInvalid={errors.email}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.email}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group required={isRequired(schema.fields.title)}>
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    name='title'
                    value={values.title}
                    onChange={handleChange}
                    isInvalid={errors.title}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.title}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group required={isRequired(schema.fields.birthDate)}>
                  <Form.Label>Birthday</Form.Label>
                  <GetRef
                    ref={birthDateRef}
                    component={
                      <Form.Control
                        name='birthDate'
                        type='date'
                        ref={birthDateRef}
                        value={values.birthDate}
                        onChange={handleChange}
                        isInvalid={errors.birthDate}
                      />
                    }
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.birthDate}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group required={isRequired(schema.fields.gender)}>
                  <Form.Label>Gender</Form.Label>
                  <Choice
                    value={values.gender}
                    options={Genders.getValues()}
                    onChange={value => setFieldValue('gender', value)}
                  />
                </Form.Group>
                <Form.Group required={isRequired(schema.fields.password)}>
                  <Form.Label>Password</Form.Label>
                  <PassordInput
                    name='password'
                    onChange={handleChange}
                    isInvalid={errors.password}
                    errorMessage={errors.password}
                  />
                </Form.Group>
                <Form.Group required={isRequired(schema.fields.passwordAgain)}>
                  <Form.Label>Confirm Password</Form.Label>
                  <PassordInput
                    name='passwordAgain'
                    onChange={handleChange}
                    isInvalid={errors.passwordAgain}
                    errorMessage={errors.passwordAgain}
                  />
                </Form.Group>
                <Form.Group required={isRequired(schema.fields.profilePicture)}>
                  <Form.Label>Profile Picture</Form.Label>
                  <Attachments
                    single
                    initialValue={values._profilePicture}
                    onChange={value => setFieldValue('profilePicture', value?.id)}
                  />
                </Form.Group>
                <Button type='submit' ref={submitRef} style={{display:'none'}}>Create</Button>
              </Form>
            )}
          </Formik>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => submitRef.current?.click()}>
            {isLoading ? <Spinner animation="border" size='sm' /> : action}
          </Button>
          <Button variant="outline-primary" onClick={handleCancel}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default Operators

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  error: null,
  visit: null
}

export const visitSlice = createSlice({
  name: 'visit',
  initialState,
  reducers: {
    createRequest: (state) => {
      state.isLoading = true
      state.error = null
    },
    createSuccess: (state, action) => {
      state.isLoading = false
      state.visit = action.payload.visit
    },
    createFailure: (state, action) => {
      state.isLoading = false
      state.error = action.payload
    },
  }
});

export const selectVisit = (state) => state.visit

export const VisitActions = visitSlice.actions

export default visitSlice.reducer;

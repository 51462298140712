import Screen from '../components/layout/Screen'
import Header from '../components/layout/Header'
import Main from '../components/layout/Main'

import CaptionedLogo from '../components/basic/CaptionedLogo'
import BackButton from '../components/basic/BackButton'

import LoginForm from '../components/LoginForm'

const LoginScreen = ({ by, redirect }) => {
  const isDashboard = window.location.pathname.split('/')[1] === 'dashboard'
  return (
    <Screen>
      <Header
        middleItem={<b>تسجيل الدخول</b>}
        rightItem={isDashboard ? <></> : <BackButton />}
      />
      <Main>
        <CaptionedLogo caption='DoktorCare' subCaption='دكتورك لحد بيتك'/>
        <LoginForm by={by} redirect={redirect}/>
      </Main>
    </Screen>
  )
}

export default LoginScreen

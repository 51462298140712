import Svg from './basic/Svg'
import {ReactComponent as LeftArrowIcon} from '../svg/left_arrow.svg'

const Tree = ({ children }) => <div className='tree'>{children}</div>

Tree.Item = ({ children, icon, onClick = null }) => (
  <div className='tree-item' onClick={onClick}>
    <Svg as={icon} iconn />
    {children}
    <Svg as={LeftArrowIcon} iconn />
  </div>
)

export default Tree

import { call, put, select, takeLatest } from 'redux-saga/effects'
import { ReservationStatus } from '../../components/common'

import * as Api from '../api/providerAssignmentApi'
import { selectDashboard, DashboardActions, DashboardResources } from '../reducers/dashboardSlice'
import { ProviderAssignmentActions } from '../reducers/providerAssignmentSlice'

function* _reflectOnDashboard(providerAssignment) {
   const { reservations } = yield select(selectDashboard)
   const accepted = providerAssignment.status === 'Accepted'
   if (reservations.detail) {
      const updatedReservation = Object.assign({}, reservations.detail, {
         status: accepted ? ReservationStatus.PENDING_RQT : reservations.detail.status,
         providerAssignments: reservations.detail.providerAssignments.map(reservationProviderAssignment => {
            const copy = Object.assign({}, reservationProviderAssignment)
            if (copy.id === providerAssignment.id) {
               copy.status = providerAssignment.status
            }
            else if (copy.status === 'Pending' && accepted) {
               copy.status = 'Fulfilled'
            }
            return copy
         })
      })
      yield put(DashboardActions.detailSuccess({ resource: DashboardResources.RESERVATIONS, body: updatedReservation }))
   }
}

function* accept(action) {
   try {
      const response = yield call(Api.acceptApi, action.payload)
      const providerAssignment = response.data
      yield put(ProviderAssignmentActions.acceptSuccess({ providerAssignment }))
      yield _reflectOnDashboard(providerAssignment)
   } catch (error) {
      yield put(ProviderAssignmentActions.acceptFailure(error.response?.data?.error || error))
   }
}

function* reject(action) {
   try {
      const response = yield call(Api.rejectApi, action.payload)
      const providerAssignment = response.data
      yield put(ProviderAssignmentActions.rejectSuccess({ providerAssignment }))
      yield _reflectOnDashboard(providerAssignment)
   } catch (error) {
      yield put(ProviderAssignmentActions.rejectFailure(error.response?.data?.error || error))
   }
}

export default function* providerAssignmentSaga() {
  yield takeLatest(ProviderAssignmentActions.acceptRequest, accept)
  yield takeLatest(ProviderAssignmentActions.rejectRequest, reject)
}

import { Formik } from 'formik'
import * as yup from 'yup'
import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'

import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

import Select from '../../components/basic/Select'
import CollapsableForm from '../../components/basic/CollapsableForm'
import Editable from '../../components/basic/Editable'

import LocationInfoForm from '../../components/LocationInfoForm'
import { chronicDiseases, MOBILE_NUMBER_REGEX } from '../../components/common'

import { selectRequesterSlice, RequesterActions } from '../../redux/reducers/requesterSlice'
import { LookupsActions, selectLookups } from '../../redux/reducers/lookupsSlice'
import TaggedFormLabel from '../../components/basic/TaggedFormLabel'

const EditablePatient = ({ onSubmit, children, childRef, requesterId, data }) => {
  const dispatch = useDispatch()

  const { patients } = useSelector(selectRequesterSlice)
  const { cities, areas } = useSelector(selectLookups)

  const patientsOptions = patients.map(patient => {
    const option = {
      text: patient.name,
      value: patient.id
    }
    if (patient.isRequester) {
      option.tag = <span className='label-hint'>حسابي</span>
    }
    return option
  })

  const schema = yup.object().shape({
    patient: yup.string().required('من فضلك قم بادخال المعلومات كاملة'),
    hasCovid: yup.boolean().required('من فضلك قم بادخال المعلومات كاملة').default(false),
    address: yup.string().nullable(),
    cityId: yup.number().required('من فضلك قم بادخال المعلومات كاملة'),
    areaId: yup.number().required('من فضلك قم بادخال المعلومات كاملة'),
    mobileNumber: yup.string().matches(MOBILE_NUMBER_REGEX, 'من فضلك أدخل رقم هاتف صحيح').required('من فضلك قم بادخال المعلومات كاملة'),
    previousOperations: yup.array(yup.string()).nullable(),
    observer: yup.object().shape({
      name: yup.string().required('من فضلك قم بادخال المعلومات كاملة'),
      mobileNumber: yup.string().matches(MOBILE_NUMBER_REGEX, 'من فضلك أدخل رقم هاتف صحيح').required('من فضلك قم بادخال المعلومات كاملة')
    }).nullable(),
    chronicDiseases: yup.array(yup.string()).required('من فضلك قم بادخال المعلومات كاملة')
  })

  useEffect(() => {
    dispatch(RequesterActions.listRequesterPatientsRequest({ requesterId }))
    dispatch(LookupsActions.listAreasRequest())
    dispatch(LookupsActions.listCitiesRequest())
  }, [dispatch, requesterId])

  const getUpdatedPatientAndUpdates = (form) => {
    const oldPatient = patients.find(patient => form.patient === patient.id)
    const reshapedOldPatient = {
      patient: oldPatient.id,
      address: oldPatient.address,
      cityId: oldPatient.city.id,
      areaId: oldPatient.area.id,
      mobileNumber: oldPatient.mobileNumber,
      previousOperations: oldPatient.previousOperations,
      observer: oldPatient.observer,
      chronicDiseases: oldPatient.chronicDiseases,
      hasCovid: oldPatient.hasCovid
    }

    const updates = _.omitBy(form, (v, k) => {
      return reshapedOldPatient[k] === v;
    })

    const updatedPatient = { ...oldPatient }
    updatedPatient.city = cities.sources.find(city => city.id === form.cityId)
    updatedPatient.area = areas.sources.find(area => area.id === form.areaId)
    updatedPatient.address = form.address
    updatedPatient.mobileNumber = form.mobileNumber
    updatedPatient.previousOperations = form.previousOperations
    updatedPatient.observer = form.observer
    updatedPatient.chronicDiseases = form.chronicDiseases
    updatedPatient.hasCovid = form.hasCovid

    return { patient: updatedPatient, updates }
  }

  const submitAndUpdatePatient = (form) => {
    const { patient, updates } = getUpdatedPatientAndUpdates(form)

    if (!_.isEmpty(updates)) {
      console.log({ updates })
      dispatch(RequesterActions.updatePatientRequest({
        requesterId, patientId: patient.id, ...updates
      }))
    }
    if (childRef.current) {
      childRef.current.validateForm().then(errors => {
        if (_.isEmpty(errors)) {
          onSubmit({
            patient,
            reservation: {
              patientId: patient.id,
              hasCovid: form.hasCovid,
              ...data.reservation,
              ...childRef.current.values
            }
          })
        }
        else {
          const firstErrorName = _.first(_.keys(errors))
          document.getElementsByName(firstErrorName)[0]?.focus()
        }
      })
    } else {
      onSubmit({
        patient,
        reservation: {
          patientId: patient.id,
          hasCovid: form.hasCovid,
          ...data.reservation,
        }
      })
    }
  }

  const handlePatientSelection = (setFieldValue, patientId) => {
    setFieldValue('patient', patientId)
    const patient = patients.find(patient => patient.id === patientId)
    setFieldValue('hasCovid', patient.hasCovid)
    setFieldValue('address', patient.address)
    setFieldValue('cityId', patient.city.id)
    setFieldValue('areaId', patient.area.id)
    setFieldValue('mobileNumber', patient.mobileNumber)
    setFieldValue('previousOperations', patient.previousOperations)
    setFieldValue('observer', patient.observer)
    setFieldValue('chronicDiseases', patient.chronicDiseases)
  }

  return !!patients.length && (
    <Formik
      validationSchema={schema}
      onSubmit={submitAndUpdatePatient}
      initialValues={schema.default()}
      validateOnChange={false}
      enableReinitialize={true}
    >
      {({
        handleSubmit,
        handleChange,
        errors,
        setFieldValue,
        setFieldError,
        values
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label>اسم المريض</Form.Label>
            <Select
              label='تحديد المريض'
              name='patient'
              value={values.patient}
              isInvalid={errors.patient}
              options={patientsOptions}
              onSelect={value => handlePatientSelection(setFieldValue, value)}
            />
            <Form.Check
              type='checkbox'
              label='المريض يعأني من فيروس COVID19'
              name='hasCovid'
            />
          </Form.Group>
          {children}

          {
            values.patient && <>
              <Form.Group>
                <Form.Label>الأمراض المزمنة</Form.Label>
                <Editable
                  name='chronicDiseases'
                  value={values.chronicDiseases}
                  editTitle='تغيير الأمراض المزمنة'
                >
                  {() => (
                    <Select
                      label='الأمراض المزمنة'
                      multiple
                      other
                      onSelect={value => setFieldValue('chronicDiseases', value)}
                      options={chronicDiseases}
                      initialValue={values.chronicDiseases}
                      value={values.chronicDiseases}
                    />
                  )}
                </Editable>

              </Form.Group>

              <Form.Group>
                <Form.Label>عنوان الزيارة</Form.Label>
                <Editable
                  name='address'
                  value={[cities[values.cityId], areas[values.areaId], values.address].filter(x => x).join(' - ')}
                  editTitle='تغيير العنوان'
                >
                  {(close) => (
                    <LocationInfoForm
                      onSubmit={(form) => {
                        setFieldValue('cityId', form.cityId)
                        setFieldValue('areaId', form.areaId)
                        setFieldValue('address', form.address)
                        close()
                      }}
                      data={values}
                    />
                  )}
                </Editable>
              </Form.Group>

              <Form.Group>
                <Form.Label>رقم الهاتف</Form.Label>
                <Editable
                  name='mobileNumber'
                  value={values.mobileNumber}
                  editTitle='تغيير رقم الهاتف'
                >
                  {(close) => (
                    <Form>
                      <img src='bannerWhatsApp' alt=''/>
                      <Form.Group>
                        <Form.Label>رقم الهاتف</Form.Label>
                        <Form.Control
                          value={values.mobileNumber}
                          onChange={e => setFieldValue('mobileNumber', e.target.value)}
                        />
                      </Form.Group>
                      <Button onClick={close}>تأكيد</Button>
                    </Form>
                  )}
                </Editable>
              </Form.Group>

              <Form.Group>
                <TaggedFormLabel>العمليات إن وجدت</TaggedFormLabel>
                <Form.Control
                  placeholder='العمليات إن وجدت'
                  name='previousOperations'
                  onChange={handleChange}
                  value={values.previousOperations}
                />
              </Form.Group>
              {console.log({values, errors})}
              <CollapsableForm
                title='المريض له تابع يتابع حالته المرضيه'
                subTitle='في حاله أن شخص ما يتابع الحاله المرضيه للمريض'
                initiallyCollapsed={!values.observer}
                form={
                  <Form>
                    <Form.Group>
                      <Form.Label> اسم المسؤول عن المريض</Form.Label>
                      <Form.Control
                        name='observer.name'
                        value={values.observer?.name || ''}
                        onChange={handleChange}
                        placeholder='اسم المسؤول عن المريض'
                      />
                    </Form.Group>

                    <Form.Group>
                      <Form.Label>رقم الهاتف</Form.Label>
                      <Form.Control
                        name='observer.mobileNumber'
                        value={values.observer?.mobileNumber || ''}
                        onChange={handleChange}
                        placeholder='رقم الهاتف'
                        isInvalid={errors.observer?.mobileNumber}
                      />
                      <Form.Control.Feedback type='invalid'>
                        {errors.observer?.mobileNumber}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Form>
                }
                onChange={collapsed => {
                  if (collapsed) {
                    setFieldValue('observer', null)
                    setFieldError('observer', null)
                  }
                  else {
                    setFieldValue('observer', {})
                  }
                }}
              />
            </>
          }

          <Button type='submit'>
            إستمرار
          </Button>
        </Form>
      )}
    </Formik>
  )
}

export default EditablePatient

import { sendVerificationCode } from '../../../adapters/firebase/auth'
import { checkExistingApi } from '../authApi'
import { getUserType } from '../util'

const verifiers = {}

export const createApi = ({ mobileNumber, target }) => {
  return checkExistingApi({ target: 'mobileNumber', userType: getUserType(), value: mobileNumber })
    .then(response => {
      const valid = target === 'ResetPassword' ? response.data : !response.data
      if (valid) {
        return sendVerificationCode(mobileNumber)
          .then(confirmationResult => {
            verifiers[mobileNumber] = confirmationResult
          })
          .catch(error => {
            throw error
          })
      }
    })
}

export const verifyApi = ({ mobileNumber, code }) => {
  const result = verifiers[mobileNumber].confirm(code)
    .then(userCredentials => {
      return { data: { token: userCredentials.user.accessToken } }
    })
    .catch(error => {
      throw error
    })
  delete verifiers[mobileNumber]
  return result
}

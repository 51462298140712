import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import useTokenInterceptor from './authentication'
import { AuthActions } from '../redux/reducers/authSlice'

const AuthenticationManager = props => {
  const history = useHistory()
  const dispatch = useDispatch()

  const signOut = () => dispatch(AuthActions.signOut())

  useTokenInterceptor(history, signOut)

  return props.children
}

export default AuthenticationManager

import { useState, useEffect } from 'react'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import _ from 'lodash'

import Svg from './basic/Svg'
import BottomSheet from './basic/BottomSheet'

import {ReactComponent as FilterIcon} from '../svg/filter.svg'
import {ReactComponent as BadgedFilterIcon} from '../svg/filter_with_badge.svg'

/**
 * @typedef Filter
 * @type {Object}
 * @property {string} name
 * @property {(data: T[]) => T[]} apply
 */

/**
 * @typedef FilterGroup
 * @type {Object}
 * @property {string} title
 * @property {boolean} multiselect
 * @property {Filter[]} filters
 */

/**
 * @param {{ filterGroups: FilterGroup[] }}
 * @returns
 */
const Filter = ({ title, filterGroups, onFilterChange }) => {
  const initial = {
    orFunctions: filterGroups
      .filter(filterGroup => filterGroup.multiselect)
      .map(filterGroup => filterGroup.title)
      .reduce((acc, key) => ({ ...acc, [key]: [] }), {}),
    andFunctions: filterGroups
      .filter(filterGroup => !filterGroup.multiselect)
      .map(filterGroup => filterGroup.title)
      .reduce((acc, key) => ({ ...acc, [key]: [] }), {})
  }

  const initialValue = _.merge({}, initial.orFunctions, initial.andFunctions)

  const [orFunctions, setOrFunctions] = useState(initial.orFunctions)
  const [andFunctions, setAndFunctions] = useState(initial.andFunctions)

  const [filterFunctions, setFunctions] = useState(initialValue)

  const [confirmedFunctions, setConfirmedFunctions] = useState({ orFunctions, andFunctions })

  const [showFilter, setShowFilter] = useState(false)

  useEffect(() => {
    setFunctions(_.merge({}, orFunctions, andFunctions))
  }, [andFunctions, orFunctions])

  /**
   * @param {boolean} checked
   * @param {FilterGroup} filterGroup
   * @param {Filter} filter
   */
  const handleFilter = (checked, filterGroup, filter) => {
    const set = filterGroup.multiselect ? setOrFunctions : setAndFunctions
    const functions = filterGroup.multiselect ? orFunctions : andFunctions
    set({
      ...functions,
      [filterGroup.title]: filterGroup.multiselect
        ? checked
          ? functions[filterGroup.title].concat(filter.apply)
          : functions[filterGroup.title].filter(func => func !== filter.apply)
        : [filter.apply]
    })
  }

  const reset = () => {
    setOrFunctions(initial.orFunctions)
    setAndFunctions(initial.andFunctions)
  }

  const toggleShowFitler = () => {
    if (showFilter) {
      setOrFunctions(confirmedFunctions.orFunctions)
      setAndFunctions(confirmedFunctions.andFunctions)
    }
    setShowFilter(!showFilter)
  }

  const applyFilters = () => {
    const ors = Object.values(orFunctions).reduce((a, b) => a.concat(b))
    const ands = Object.values(andFunctions).reduce((a, b) => a.concat(b))

    setConfirmedFunctions({ orFunctions, andFunctions })

    const filter = input => ands.reduce((f1, f2) => f2(f1), input).filter(element => ors.length ? ors.some(f => f(element)) : true)

    onFilterChange(filter)
    setShowFilter(false)
  }

  const renderFilterGroups = () => {
    return (
      <Form className='filter-group'>
        {
          filterGroups.map(filterGroup => (
            <Form.Group>
              <Form.Label>{filterGroup.title}</Form.Label>
              {filterGroup.filters
                .reduce((a, b, i, arr) => i % 2 ? a : a.concat([arr.slice(i, i + 2)]), [])
                .map(filterRow =>
                  <Row>
                    {filterRow.map(filter => {
                      return (
                        <Col>
                          <Form.Check
                            type={filterGroup.multiselect ? 'checkbox' : 'radio'}
                            label={filter.name}
                            onChange={e => handleFilter(e.target.checked, filterGroup, filter)}
                            checked={filterFunctions[filterGroup.title].includes(filter.apply)}
                          />
                        </Col>
                      )
                    })}
                  </Row>
                )}
            </Form.Group>
          ))
        }
        <Button onClick={applyFilters}>تأكيد</Button>
      </Form>
    )
  }

  return (
    <div>
      <div onClick={toggleShowFitler}>
        <Svg as={_.isEqual(filterFunctions, initialValue) ? FilterIcon : BadgedFilterIcon} icon largeIcon />
      </div>
      <BottomSheet
        title={title}
        show={showFilter}
        close={toggleShowFitler}
        action={<div className='filter-reset' onClick={reset}>إعادة تعيين</div>}
      >
        {renderFilterGroups()}
      </BottomSheet>
    </div>
  )
}

export default Filter

import Svg from './Svg'
import { ReactComponent as DeleteIcon } from '../../svg/close.svg'

const Tag = ({ children, onDelete }) => {
  return (
    <div className='tag'>
      {children}
      {onDelete && <Svg as={DeleteIcon} className='tag-delete-icon' onClick={onDelete} />}
    </div>
  )
}

Tag.Group  = ({children}) => <div className='tag-group'>{children}</div>

export default Tag

import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Screen from '../components/layout/Screen'
import Header from '../components/layout/Header'
import Main from '../components/layout/Main'
import Footer from '../components/layout/Footer'

import Filter from '../components/Filter'


import { RequesterActions, selectRequesterSlice } from '../redux/reducers/requesterSlice'
import PersonCard from '../components/PersonCard'
import BackButton from '../components/basic/BackButton'

import { DoctorDegrees, ProviderTypes } from '../components/common'

const filterGroups = [
  {
    title: 'الدرجة العلمية',
    multiselect: true,
    filters: [
      { name: 'ممارس عام', apply: provider => provider.degree === DoctorDegrees.GENERAL_PRACTITIONER.value },
      { name: 'دكتور', apply: provider => provider.degree === DoctorDegrees.DOCTOR.value },
      { name: 'إستشاري', apply: provider => provider.degree === DoctorDegrees.CONSULTANT.value },
    ]
  },
  {
    title: 'التخصص الطبى',
    multiselect: true,
    filters: [
      { name: 'باطنة وقلب', apply: () => true  },
      { name: 'أسنأن', apply: () => true  },
      { name: 'عيون', apply: () => true  },
      { name: 'عظام', apply: () => true  },
      { name: 'أشعة وتحاليل', apply: () => true  },
      { name: 'غدد', apply: () => true  },
      { name: 'أطفال', apply: () => true  },
      { name: 'علاج طبيعي', apply: () => true  },
      { name: 'جلدية', apply: () => true  },
      { name: 'أنف واذن وحنجرة', apply: () => true  },
    ]
  },
  {
    title: 'الترتيب زمنياً',
    multiselect: false,
    filters: [
      { name: 'من الأقرب للأبعد', apply: providers => providers.sort((a, b) => Date.parse(a.lastServiceDate) - Date.parse(b.lastServiceDate))  },
      { name: 'من الأبعد للأقرب ', apply: providers => providers.sort((a, b) => Date.parse(b.lastServiceDate) - Date.parse(a.lastServiceDate))  }
    ]
  }
]

const ProvidersScreen = ({ requesterId }) => {
  const dispatch = useDispatch()
  const [visibleProviders, setVisibleProviders] = useState([])

  const { providers } = useSelector(selectRequesterSlice)

  const getProviderName = ({ provider }) => {
    return [provider.firstName, provider.middleName, provider.lastName].filter(x => x).join(' ')
  }
  const getProviderTitle = provider => {
    switch (provider.type) {
      case ProviderTypes.DOCTOR.value:
        return `${DoctorDegrees.getTextByValue(provider.provider.degree)} - ${provider.provider.speciality.arabicName}`
      default:
        return ProviderTypes.getTextByValue(provider.type)
    }
  }
  const getProviderSubTitle = provider => {
    switch (provider.type) {
      case ProviderTypes.DOCTOR.value:
        return provider.provider.detailedSpeciality
      case ProviderTypes.NURSE.value:
        return provider.provider.jobTitle
      default:
        return ''
    }
  }

  useEffect(() => {
    dispatch(RequesterActions.listProvidersRequest({ requesterId }))
  }, [dispatch, requesterId])

  useEffect(() => {
    setVisibleProviders(providers)
  }, [providers])

  return (
    <Screen>
      <Header
        middleItem={<b>الخدمات السابقة</b>}
        rightItem={<BackButton />}
        leftItem={
          <Filter
            icon
            title='تصفية الأطباء'
            onFilterChange={filter => setVisibleProviders(filter(providers))}
            filterGroups={filterGroups}
          />
        }
      />
      <Main className='home-main'>
        <PersonCard.Group>
          {visibleProviders.map(provider => ({
            id: provider.provider.id,
            name: getProviderName(provider),
            title: getProviderTitle(provider),
            subTitle: getProviderSubTitle(provider)
          })).map(provider => {
            return (
              <PersonCard key={provider.id}>
                <PersonCard.Avatar />
                <PersonCard.Title>{provider.name}</PersonCard.Title>
                <PersonCard.Content>{provider.title}</PersonCard.Content>
                <PersonCard.FadedContent>{provider.subTitle}</PersonCard.FadedContent>
              </PersonCard>
            )
          })}
        </PersonCard.Group>
      </Main>
      <Footer />
    </Screen>
  )
}

export default withRouter(ProvidersScreen)

import { createSlice } from '@reduxjs/toolkit';
import jwtDecode from 'jwt-decode';

const storedAccessToken = window.localStorage.getItem('accessToken')
const decodedAccessToken = storedAccessToken && jwtDecode(storedAccessToken)

const storedGuestToken = decodedAccessToken ? null : window.localStorage.getItem('guestToken')
const decodedGuestToken = storedGuestToken && jwtDecode(storedGuestToken)

const getFullName = (firstName, middleName, lastName) => {
  const names = [firstName, middleName, lastName].filter(n => !!n && n.length)
  return names.length ? names.join(' ') : null
}

const initialState = {
  status: 'idle',
  isLoggedIn: !!decodedAccessToken,
  userId: decodedAccessToken?.sub,
  role: decodedAccessToken?.role,
  accessToken: storedAccessToken,
  userDetails: {
    firstName: decodedAccessToken?.firstName,
    middleName: decodedAccessToken?.middleName,
    lastName: decodedAccessToken?.lastName,
    fullName: getFullName(
      decodedAccessToken?.firstName,
      decodedAccessToken?.middleName,
      decodedAccessToken?.lastName
    ),
    profilePicture: decodedAccessToken?.profilePictureUrl ? { url: decodedAccessToken?.profilePictureUrl } : null
  },
  guestSession: !decodedGuestToken ? null : {
    guestId: decodedGuestToken.sub,
    token: storedGuestToken
  },
  error: null
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginRequest: state => {
      state.status = 'loading'
      state.error = null
    },
    loginSuccess: (state, action) => {
      const { user: { firstName, middleName, lastName, profilePicture }, accessToken, refreshToken } = action.payload

      window.localStorage.setItem('accessToken', accessToken)
      window.localStorage.setItem('refreshToken', refreshToken)

      state.status = 'idle'
      state.accessToken = accessToken
      state.isLoggedIn = true

      const { sub, role } = jwtDecode(state.accessToken)
      state.userId = sub
      state.role = role
      state.userDetails = {
        firstName,
        middleName,
        lastName,
        fullName: getFullName(firstName, middleName, lastName),
        profilePicture
      }
    },
    loginFailure: (state, action) => {
      state.error = action.payload
      state.status = 'idle'
    },

    signUpRequest: state => {
      state.status = 'loading'
      state.error = null
    },
    signUpSuccess: (state) => {
      state.status = 'idle'
    },
    signUpFailure: (state, action) => {
      state.error = action.payload
      state.status = 'idle'
    },

    resetPasswordRequest: (state) => {
      state.status = 'loading'
      state.error = null
    },
    resetPasswordSuccess: (state) => {
      state.status = 'success'
    },
    resetPasswordFailure: (state, action) => {
      state.status = 'idle'
      state.error = action.payload
    },

    changePasswordRequest: state => {
      state.status = 'loading'
      state.error = null
    },
    changePasswordSuccess: state => {
      state.status = 'password_changed'
    },
    changePasswordFailure: (state, action) => {
      state.status = 'idle'
      state.error = action.payload
    },

    createGuestSessionRequest: (state) => {
      state.status = 'loading'
      state.error = null
    },
    createGuestSessionSuccess: (state, action) => {
      state.status = 'idle'
      state.guestSession = {
        ...state.guestSession,
        guestId: action.payload.guest.id,
        token: action.payload.token
      }
      localStorage.setItem('guestToken', action.payload.token)
    },
    createGuestSessionFailure: (state, action) => {
      state.status = 'idle'
      state.error = action.payload
    },

    getGuestSessionRequest: (state) => {
      state.status = 'loading'
      state.error = null
    },
    getGuestSessionSuccess: (state, action) => {
      state.status = 'idle'
      state.guestSession = {
        ...state.guestSession,
        token: action.payload.token
      }
      localStorage.setItem('guestToken', action.payload.token)
    },
    getGuestSessionFailure: (state, action) => {
      state.status = 'idle'
      state.error = action.payload
    },

    resetStatus: state => {
      state.status = 'idle'
      state.error = null
    },

    signOut: () => {},
    signOutSuccess: state => {
      state.isLoggedIn = false
      state.userId = null
      state.role = null
      state.accessToken = null
      state.userDetails = null
      window.localStorage.removeItem('accessToken')
      window.localStorage.removeItem('refreshToken')
    },

    refreshTokenRequest: state => {
      state.status = 'loading'
    },
    refreshTokenSuccess: (state, action) => {
      state.status = 'idle'
      state.accessToken = action.payload.accessToken
      const { role, firstName } = jwtDecode(action.payload.accessToken)
      state.role = role
      state.userDetails.firstName = firstName

      localStorage.setItem('accessToken', action.payload.accessToken)
      localStorage.setItem('refreshToken', action.payload.refreshToken)
    },

    setTokenRequest: () => {}
  },
});

export const AuthActions = authSlice.actions

export const selectAuth = (state) => state.auth

export default authSlice.reducer;

import Tab from 'react-bootstrap/Tab'
import Nav from 'react-bootstrap/Nav'
import _ from 'lodash'
import { useHistory } from 'react-router-dom'

const SingleTab = ({ children, title }) => (
  <Tab.Pane key={_.camelCase(title)} eventKey={_.camelCase(title)} mountOnEnter={true} unmountOnExit={true}>
    {children}
  </Tab.Pane>
)

const Tabs = ({ children }) => {
  const history = useHistory()
  const singleTabChildren = _.castArray(children).filter(child => child.type === SingleTab).reverse()
  const titles = singleTabChildren.map(child => child.props.title)

  if (!history.location.hash) {
    history.push({ hash: _.camelCase(titles[0]) })
  }
  return (
    <Tab.Container defaultActiveKey={history.location.hash?.slice(1)} onSelect={hash => history.push({ hash })}>
      <Nav variant="tabs" style={{flexDirection: 'row-reverse'}}>
        {titles.map(title => (
          <Nav.Item key={_.camelCase(title)}>
            <Nav.Link eventKey={_.camelCase(title)}>{title}</Nav.Link>
          </Nav.Item>
        ))}
      </Nav>
      <Tab.Content>
        {singleTabChildren}
      </Tab.Content>
    </Tab.Container>
  )
}

Tabs.Tab = SingleTab

export default Tabs

import { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import qs from 'query-string'
import _ from 'lodash'
import { Formik } from 'formik'
import * as yup from 'yup'

import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Spinner from 'react-bootstrap/Spinner'
import Image from 'react-bootstrap/Image'

import { PlusCircleFill } from 'react-bootstrap-icons'

import DynamicTable from '../../components/DynamicTable'
import { DashboardActions, DashboardResources, selectDashboard } from '../../redux/reducers/dashboardSlice'
import Tabs from '../components/Tabs'
import { LookupsActions, selectLookups } from '../../redux/reducers/lookupsSlice'
import { checkExistingTestConfig, MOBILE_NUMBER_REGEX, ServiceTypes } from '../../components/common'
import Select from '../../components/basic/Select'
import Attachments from '../../components/basic/Attachments'
import DetailedView from '../layout/DetailedView'

const schema = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().email().nullable(),
  mobileNumber: yup.string()
    .matches(MOBILE_NUMBER_REGEX, 'Invalid Mobile Number')
    .test(checkExistingTestConfig('mobileNumber', 'ServiceProvider'))
    .required(),
  whatsAppNumber: yup.string().nullable(),
  address: yup.string().required(),
  contactPersonName: yup.string().required(),
  financialResponsibleName: yup.string().required(),
  servicesIds: yup.array(yup.number()).min(1),
  syndicateRegistrationCertificate: yup.number().required(),
  profilePicture: yup.number().nullable()
})

const CreateMedicalCenter = ({ show, onHide }) => {
  const dispatch = useDispatch()
  const submitRef = useRef()

  const { medicalCenterServices } = useSelector(selectLookups)
  const { isLoading } = useSelector(selectDashboard)

  useEffect(() => {
    dispatch(LookupsActions.listMedicalCenterServicesRequest())
  }, [dispatch])

  useEffect(() => {
    if (!isLoading) {
      onHide()
    }
  }, [isLoading])

  return (
    <Modal show={show} dialogClassName='ltr dashboard-layout' onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Create Medical Center</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          validationSchema={schema}
          initialValues={{}}
          onSubmit={body =>
            dispatch(DashboardActions.createRequest({ resource: DashboardResources.MEDICAL_CENTERS, body }))
          }
          validateOnChange={false}
          innerRef={submitRef}
        >
          {({ handleSubmit, handleChange, setFieldValue, values, errors }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Form.Group>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  name='name'
                  value={values.name}
                  isInvalid={errors.name}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.name}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  name='email'
                  value={values.email}
                  isInvalid={errors.email}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.email}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label>Mobile Number</Form.Label>
                <Form.Control
                  name='mobileNumber'
                  value={values.mobileNumber}
                  isInvalid={errors.mobileNumber}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.mobileNumber}
                </Form.Control.Feedback>
                <Form.Check
                  label='This number has WhatsApp account?'
                  disabled={errors.mobileNumber || !values.mobileNumber}
                  checked={!!values.whatsAppNumber}
                  onChange={e => setFieldValue('whatsAppNumber', e.target.checked ? values.mobileNumber : null)}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Address</Form.Label>
                <Form.Control
                  name='address'
                  value={values.address}
                  isInvalid={errors.address}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.address}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label>Contact Person Name</Form.Label>
                <Form.Control
                  name='contactPersonName'
                  value={values.contactPersonName}
                  isInvalid={errors.contactPersonName}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.contactPersonName}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label>Financial Responsible Name</Form.Label>
                <Form.Control
                  name='financialResponsibleName'
                  value={values.financialResponsibleName}
                  isInvalid={errors.financialResponsibleName}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.financialResponsibleName}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label>Services</Form.Label>
                <Select
                  multiple
                  name='servicesIds'
                  label='Services'
                  value={values.servicesIds}
                  options={medicalCenterServices.values}
                  onSelect={value => setFieldValue('servicesIds', value)}
                  isInvalid={errors.servicesIds}
                  errorMessage={errors.servicesIds}
                  // wrapper={ModalSelection}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Syndicate Registration Certificate</Form.Label>
                <Attachments
                  single
                  policy={Attachments.Policies.PRIVATE}
                  target='syndicate-registration-certificate'
                  onChange={value => setFieldValue('syndicateRegistrationCertificate', value?.id)}
                  isInvalid={errors.syndicateRegistrationCertificate}
                  errorMessage={errors.syndicateRegistrationCertificate}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Profile Picture</Form.Label>
                <Attachments
                  single
                  policy={Attachments.Policies.PUBLIC}
                  target='profile-picture'
                  onChange={value => setFieldValue('profilePicture', value?.id)}
                  isInvalid={errors.profilePicture}
                  errorMessage={errors.profilePicture}
                />
              </Form.Group>
            </Form>
          )}
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => submitRef.current?.submitForm()}>
          {isLoading ? <Spinner animation="border" size='sm' /> : 'Create'}
        </Button>
        <Button variant="outline-primary" onClick={onHide}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

const MedicalCenters = () => {
  const { medicalCenters } = useSelector(selectDashboard)
  const { id } = qs.parse(document.location.search)

  const [showCreate, setShowCreate] = useState(false)

  return (
    <div>
      <div className='sub-screen-title'>
        <h1>Medical Centers</h1>
        <Button float='right' onClick={() => setShowCreate(true)}><PlusCircleFill /> Create</Button>
      </div>

      <CreateMedicalCenter show={showCreate} onHide={() => setShowCreate(false)} />

      <Tabs>
        {/* <Tabs.Tab title='Pending'>
          <DynamicTable
            search
            resource={DashboardResources.MEDICAL_CENTERS}
            data={medicalCenters.items}
            mapper={item => {
              return [
                { key: 'id', name: 'ID', value: item.id },
                { key: 'name', name: 'Name', value: item.name },
                { key: 'mobileNumber', name: 'Number', value: item.mobileNumber },
                { key: 'cityName', sort: 'cities.englishName', name: 'Cities', value: item.cities.map(city => city.englishName).join(', ') },
                { key: 'areaName', sort: 'areas.englishName', name: 'Areas', value: item.areas.map(area => area.englishName).join(', ') },
                { key: 'serviceType', name: 'Service Type', value: item.serviceType },
                { key: 'services', name: 'Services', value: item.services.map(service => service.englishName).join(', ') },
                { key: 'homeVisitFees', name: 'Fees', value: item.homeVisitFees },
                { key: 'status', name: 'Status', value: item.status }
              ]
            }}
            pagesCount={medicalCenters.pagesCount}
          />
        </Tabs.Tab> */}
        <Tabs.Tab title='Completed'>
          <DynamicTable
            search
            sortable
            resource={DashboardResources.MEDICAL_CENTERS}
            data={medicalCenters.items}
            headers={[
              { key: 'id', name: 'ID', sort: 'id.prefix,id.order' },
              { key: 'name', name: 'Name' },
              { key: 'mobileNumber', name: 'Number' },
              // { key: 'cityName', name: 'Cities', sort: '_citiesCount' },
              // { key: 'areaName', name: 'Areas', sort: '_areasCount' },
              // { key: 'serviceType', name: 'Service Type' },
              { key: 'service', name: 'Services', sort: '_servicesCount' },
              // { key: 'homeVisitFees', name: 'Fees' },
              { key: 'status', name: 'Status' },
              // { key: 'totalRequests', name: 'Total Requests' },
              // { key: 'activeRequests', name: 'Active Requests' },
              { key: 'rating', name: 'Rating' },
              // { key: 'userApproved', name: 'User Approved' },
              // { key: 'dateOfApproval', name: 'Date of Approval' },
            ]}
            mapper={item => {
              return [
                { key: 'id', value: item.id },
                { key: 'name', value: item.name },
                { key: 'mobileNumber', value: item.mobileNumber },
                // { key: 'cityName', value: item.cities.map(city => city.englishName).join(', ') },
                // { key: 'areaName', value: item.areas.map(area => area.englishName).join(', ') },
                // { key: 'serviceType', value: item.services.some(s => s.type === 'MedicalRays') && item.services.some(s => s.type === 'MedicalAnalysis') ? 'Both' : item.services.some(s => s.type === 'MedicalRays') ? 'Medical Rays' : 'Medical Analysis' },
                { key: 'service', value: item.services.map(service => service.arabicName).join(', ') },
                // { key: 'homeVisitFees', value: item.homeVisitFees },
                { key: 'status', value: item.status },
                // { key: 'totalRequests', value: item.totalRequests },
                // { key: 'activeRequests', value: item.activeRequests },
                { key: 'rating', value: item.rating },
                // { key: 'userApproved', value: item.userApproved?.firstName },
                // { key: 'dateOfApproval', value: item.userApproved?.firstName },
              ]
            }}
            pagesCount={medicalCenters.pagesCount}
            initialFilters={[_.omitBy({ id }, _.isNil)]}
            detail
          />
        </Tabs.Tab>
      </Tabs>
    </div>
  )
}

MedicalCenters.Detail = () => {
  const { id } = useParams()
  const dispatch = useDispatch()

  const handleUpdate = initialValue => body => {
    body.id = id
    body.deletedFields = _.keys(initialValue).filter(key => _.isNil(body[key]) && !_.isNil(initialValue[key]))

    dispatch(DashboardActions.updateRequest({ resource: DashboardResources.MEDICAL_CENTERS, body }))
  }

  return (
    <DetailedView
      id={id}
      // serviceProvider
      resource={DashboardResources.MEDICAL_CENTERS}
      sectionMappers={[
        medicalCenter => ({
          title: 'Profile Information',
          body: {
            "Name": medicalCenter.name,
            "Mobile Number": medicalCenter.mobileNumber,
            "Email": medicalCenter.email,
            "Address": medicalCenter.address
          },
          edit: () => ref => {
            const fields = ['id', 'name', 'mobileNumber', 'whatsAppNumber', 'email', 'address']
            const validationSchema = schema.pick(fields)
            const initialValue = _.pick(medicalCenter, fields)
            return (
              <Formik
                innerRef={ref}
                validationSchema={validationSchema}
                initialValues={initialValue}
                validateOnChange={false}
                onSubmit={handleUpdate(initialValue)}
              >
                {({ handleSubmit, handleChange, values, errors, setFieldValue }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <Form.Group>
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        name='name'
                        value={values.name}
                        isInvalid={errors.name}
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type='invalid'>
                        {errors.name}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        name='email'
                        value={values.email}
                        isInvalid={errors.email}
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type='invalid'>
                        {errors.email}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Mobile Number</Form.Label>
                      <Form.Control
                        name='mobileNumber'
                        value={values.mobileNumber}
                        isInvalid={errors.mobileNumber}
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type='invalid'>
                        {errors.mobileNumber}
                      </Form.Control.Feedback>
                      <Form.Check
                        label='This number has WhatsApp account?'
                        disabled={errors.mobileNumber || !values.mobileNumber}
                        checked={!!values.whatsAppNumber}
                        onChange={e => setFieldValue('whatsAppNumber', e.target.checked ? values.mobileNumber : null)}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Address</Form.Label>
                      <Form.Control
                        name='address'
                        value={values.address}
                        isInvalid={errors.address}
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type='invalid'>
                        {errors.address}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Form>
                )}
              </Formik>
            )
          }
        }),
        medicalCenter => ({
          title: 'Services',
          body: _.mapValues(
            medicalCenter.services.reduce(
              (o, service) => {
                o[service.type === 'MedicalRays' ? 'Medical Rays' : 'Medical Analysis']
                  .push(service.arabicName)
                return o
              },
              { 'Medical Rays': [], 'Medical Analysis': []}
            ),
            v => v.join(' - ')
          ),
          edit: () => ref => {
            const fields = ['id', 'servicesIds']
            const validationSchema = schema.pick(fields)
            const initialValue = {
              servicesIds: medicalCenter.services.map(service => service.id)
            }

            const { medicalCenterServices } = useSelector(selectLookups)

            useEffect(() => {
              dispatch(LookupsActions.listMedicalCenterServicesRequest())
            }, [dispatch])

            return (
              <Formik
                innerRef={ref}
                validationSchema={validationSchema}
                initialValues={initialValue}
                validateOnChange={false}
                onSubmit={handleUpdate(initialValue)}
              >
                {({ handleSubmit, setFieldValue, values, errors }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <Form.Group>
                      <Form.Label>Services</Form.Label>
                      <Select
                        multiple
                        name='servicesIds'
                        label='Services'
                        initialValue={values.servicesIds}
                        value={values.servicesIds}
                        options={medicalCenterServices.values}
                        onSelect={value => setFieldValue('servicesIds', value)}
                        isInvalid={errors.servicesIds}
                        errorMessage={errors.servicesIds}
                        // wrapper={ModalSelection}
                      />
                    </Form.Group>
                  </Form>
                )}
              </Formik>
            )
          }
        }),
        medicalCenter => ({
          title: 'Attachments',
          body: {
            "Profile Picture": !!medicalCenter.profilePicture && <Image src={medicalCenter.profilePicture?.url} />,
            "Syndicate Registration Certificate": <Image src={medicalCenter.syndicateRegistrationCertificate?.url} />
          },
          edit: () => ref => {
            const fields = ['id', 'profilePicture', 'syndicateRegistrationCertificate']
            const validationSchema = schema.pick(fields)
            const initialValue = _.pick(medicalCenter, fields)

            return (
              <Formik
                innerRef={ref}
                validationSchema={validationSchema}
                initialValues={initialValue}
                validateOnChange={false}
                onSubmit={handleUpdate(initialValue)}
              >
                {({ handleSubmit, setFieldValue, values, errors }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <Form.Group>
                      <Form.Label>Syndicate Registration Certificate</Form.Label>
                      <Attachments
                        single
                        initialValue={values.syndicateRegistrationCertificate}
                        policy={Attachments.Policies.PRIVATE}
                        target='syndicate-registration-certificate'
                        onChange={value => setFieldValue('syndicateRegistrationCertificate', value?.id)}
                        isInvalid={errors.syndicateRegistrationCertificate}
                        errorMessage={errors.syndicateRegistrationCertificate}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Profile Picture</Form.Label>
                      <Attachments
                        single
                        initialValue={values.profilePicture}
                        policy={Attachments.Policies.PUBLIC}
                        target='profile-picture'
                        onChange={value => setFieldValue('profilePicture', value?.id)}
                        isInvalid={errors.profilePicture}
                        errorMessage={errors.profilePicture}
                      />
                    </Form.Group>
                  </Form>
                )}
              </Formik>
            )
          }
        }),
        medicalCenter => ({
          title: 'Officials Data',
          body: {
            "Contact Person Name": medicalCenter.contactPersonName,
            "Finanials Responsible Name": medicalCenter.financialResponsibleName
          },
          edit: () => ref => {
            const fields = ['id', 'contactPersonName', 'financialResponsibleName']
            const validationSchema = schema.pick(fields)
            const initialValue = _.pick(medicalCenter, fields)

            return (
              <Formik
                innerRef={ref}
                validationSchema={validationSchema}
                initialValues={initialValue}
                validateOnChange={false}
                onSubmit={handleUpdate(initialValue)}
              >
                {({ handleSubmit, handleChange, values, errors }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <Form.Group>
                      <Form.Label>Contact Person Name</Form.Label>
                      <Form.Control
                        name='contactPersonName'
                        value={values.contactPersonName}
                        isInvalid={errors.contactPersonName}
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type='invalid'>
                        {errors.contactPersonName}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Financial Responsible Name</Form.Label>
                      <Form.Control
                        name='financialResponsibleName'
                        value={values.financialResponsibleName}
                        isInvalid={errors.financialResponsibleName}
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type='invalid'>
                        {errors.financialResponsibleName}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Form>
                )}
              </Formik>
            )
          }
        }),
      ]}
    />
  )
}


export default MedicalCenters

import { Formik } from 'formik'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import * as yup from 'yup'
import { useState, useEffect, useRef, forwardRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import qs from 'query-string'

import Screen from '../components/layout/Screen'
import Header from '../components/layout/Header'
import Main from '../components/layout/Main'

import Select from '../components/basic/Select'
import Editable from '../components/basic/Editable'
import Choice from '../components/basic/Choice'
import Attachments from '../components/basic/Attachments'

import Success from '../components/Success'

import { LookupsActions, selectLookups } from '../redux/reducers/lookupsSlice'
import { ReservationActions, selectReservation } from '../redux/reducers/reservationSlice'

import EditablePatient from './topics/EditablePatient'
import ReservationConfirmation from './topics/ReservationConfirmation'
import { GenderChoices, MOBILE_NUMBER_REGEX, ProviderTypes, ServiceTypes } from '../components/common'
import CollapsableForm from '../components/basic/CollapsableForm'

import {ReactComponent as AttachmentIcon} from '../svg/attachment.svg'

import { CacheActions } from '../redux/reducers/cacheSlice'
import { Spinner } from 'react-bootstrap'
import TaggedFormLabel from '../components/basic/TaggedFormLabel'

const DoctorInfo = ({ onSubmit, data }) => {
  const { doctorSpecialities } = useSelector(selectLookups)

  const degrees = ['ممارس عام', 'أخصائي', 'إستشاري', 'أي منهم']
  const dateRanges = ['في أقرب وقت', 'خلال يومين', 'خلال ثلاثه أيام', 'خلال أربعة أيام']

  const isDoctor = data.reservation.providerType === ProviderTypes.DOCTOR.value

  let schema = yup.object().shape({
    degree: yup.string().oneOf(degrees).required('من فضلك قم بادخال المعلومات كاملة').default(degrees[0]),
    gender: yup.string().oneOf(GenderChoices.getValues()).required('من فضلك قم بادخال المعلومات كاملة').default(GenderChoices.MALE.value),
    dateRange: yup.string().default(dateRanges[0]),
    ...isDoctor ? { speciality: yup.string().required('من فضلك قم بادخال المعلومات كاملة').default(data.reservation.speciality) } : {}
  })

  const collectServiceInformationAndSubmit = form => {
    const serviceInformation = {
      'نوع الخدمة': ServiceTypes.getTextByValue(data.reservation.serviceType),
      'التخصص': doctorSpecialities[form.speciality],
      'الدرجة العلمية': form.degree,
      'فترة الزيارة': form.dateRange
    }

    onSubmit({ reservation: { ...data.reservation, ...form }, serviceInformation, tag: form.speciality })
  }

  return (
    <Formik
      validationSchema={schema}
      onSubmit={collectServiceInformationAndSubmit}
      initialValues={schema.default()}
      validateOnChange={false}
    >
      {({
        handleSubmit,
        setFieldValue,
        values
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          {console.log({ values, isDoctor })}
          {isDoctor && (
            <Form.Group>
                {console.log({doctorSpecialities})}
              <Form.Label>تخصص الطبيب</Form.Label>
              <Editable editTitle='التخصصات الطبية' name='speciality' value={doctorSpecialities[values.speciality]}>
                {() =>
                  <Select
                    label='التخصصات الطبية'
                    options={doctorSpecialities.values}
                    onSelect={value => setFieldValue('speciality', value)}
                    value={values.speciality}
                  />
                }
              </Editable>
            </Form.Group>
          )}
          <Form.Group>
            <Form.Label>الدرجة العلمية</Form.Label>
            <Choice
              name='degree'
              options={degrees}
              value={values.degree}
              onChange={value => setFieldValue('degree', value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>الجنس</Form.Label>
            <Choice
              name='gender'
              options={GenderChoices.getTexts()}
              value={GenderChoices.getTextByValue(values.gender)}
              onChange={value => setFieldValue('gender', GenderChoices.getValueByText(value))}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>فترة الزيارة</Form.Label>
            <Choice
              name='dateRange'
              options={dateRanges}
              value={values.dateRange}
              onChange={value => setFieldValue('dateRange', value)}
            />
          </Form.Group>

          <Button type='submit'>إستمرار</Button>
        </Form>
      )}
    </Formik>
  )
}

export const DoctorSpecialityAndComplaint = forwardRef((props, ref) => {
  const { doctorSpecialities } = useSelector(selectLookups)
  const dispatch = useDispatch()

  const schema = yup.object().shape({
    speciality: yup.string().required('من فضلك قم بادخال المعلومات كاملة'),
    notCertainSpeciality: yup.boolean().default(false),
    reason: yup.string().required('من فضلك قم بادخال المعلومات كاملة')
  })

  useEffect(() => {
    dispatch(LookupsActions.listDoctorSpecialitiesRequest())
  }, [dispatch])

  return (
    <Formik
      validationSchema={schema}
      initialValues={schema.default()}
      validateOnChange={false}
      innerRef={ref}
    >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        values,
        errors
      }) => {
        return doctorSpecialities.values.length && (
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label>تخصص الطبيب</Form.Label>
              <Select
                label='التخصصات الطبية'
                options={doctorSpecialities.values}
                onSelect={value => setFieldValue('speciality', value)}
                value={values.speciality}
              />
              <Form.Check
                label='غير متأكد من التخصص المطلوب'
                name='notCertainSpeciality'
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>شكوي المريض</Form.Label>
              <Form.Control
                as='textarea'
                name='reason'
                onChange={handleChange}
                placeholder='شكوي المريض'
                isInvalid={errors.reason}
              />
              <Form.Control.Feedback type='invalid'>
                {errors.reason}
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
        )
      }}
    </Formik>
  )
})

const DurationAndServiceType = ({ onSubmit, serviceLabel, data, dispatch }) => {
  const providerType = data.reservation?.providerType
  const { nurseTasks, homeCareSpecialistTasks } = useSelector(selectLookups)
  const tasks = providerType === ProviderTypes.NURSE.value ? nurseTasks : homeCareSpecialistTasks

  const durationOptions = [
    { text: 'زيارة منزلية سريعة', value: 'زيارة منزلية سريعة' },
    { text: 'رعاية 6 ساعات (بدون إقامة)', value: 'رعاية 6 ساعات (بدون إقامة)' },
    { text: 'رعاية 8 ساعات (بدون إقامة)', value: 'رعاية 8 ساعة (بدون إقامة)' },
    { text: 'رعاية 12 ساعة (بدون إقامة)', value: 'رعاية 12 ساعة (بدون إقامة)' },
    { text: 'رعاية 24 ساعة (إقامة ولكن فترة العمل 12 ساعة)', value: 'رعاية 24 ساعة (إقامة ولكن فترة العمل 12 ساعة)' },
    { text: 'رعاية 26 يوم (فترة العمل 12 ساعة اجازه ٤ ايام شهرياً)', value: 'رعاية 26 يوم (فترة العمل 12 ساعة اجازه ٤ ايام شهرياً)' },
    { text: 'رعاية 30 يوم (إقامة ولكن فترة العمل 12 ساعة)', value: 'رعاية 30 يوم (إقامة ولكن فترة العمل 12 ساعة)' }
  ]

  useEffect(() => {
    if (providerType === ProviderTypes.NURSE.value) {
      dispatch(LookupsActions.listNurseTasksRequest())
    }
    else {
      dispatch(LookupsActions.listHomeCareSpecialistTasksRequest())
    }
  }, [dispatch, providerType])

  const collectServiceInformationAndSubmit = form => {
    const serviceInformation = {
      'نوع الخدمة': form.serviceType,
      'مدة الرعاية': form.duration,
      [serviceLabel]: form.serviceType
    }

    const serviceType = tasks.values.find(item => item.text === form.serviceType).value

    onSubmit({
      reservation: {
        ...data.reservation,
        ...form,
        serviceType
      },
      serviceInformation,
      tag: serviceType
    })
  }
  const schema = yup.object().shape({
    duration: yup.string().required('من فضلك قم بادخال المعلومات كاملة'),
    serviceType: yup.string().required('من فضلك قم بادخال المعلومات كاملة').default(tasks.values[0]?.text)
  })
  return (
    <Formik
      validationSchema={schema}
      initialValues={schema.default()}
      validateOnChange={false}
      onSubmit={collectServiceInformationAndSubmit}
      enableReinitialize={true}
    >
      {({
        handleSubmit,
        setFieldValue,
        values,
        errors
      }) => {
        return (
          <Form noValidate onSubmit={handleSubmit} className='bottom-button-form'>
            {console.log({ values, errors })}
            <Form.Group>
              <Form.Label>مدة الرعاية المطلوبة</Form.Label>
              <Select
                name='duration'
                options={durationOptions}
                value={values.duration}
                onSelect={value => setFieldValue('duration', value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>{serviceLabel}</Form.Label>
              <Choice
                options={tasks.values.map(({ text }) => text)}
                value={values.serviceType}
                onChange={value => setFieldValue('serviceType', value)}
              />
            </Form.Group>
            <Button type='submit'>
              إستمرار
            </Button>
          </Form>
        )
      }}
    </Formik>
  )
}

const Questions = ({ onSubmit, data }) => {
  const questions = [
    {
      question: 'هل يستخدم المريض محاليل طبية؟',
      answers: ['نعم', 'لا', 'ربما']
    },
    {
      question: 'هل يعأنى المريض من قرح الفراش؟',
      answers: ['لا يوجد', 'درجة أولى', 'درجة ثأنية', 'درجة ثالثة', 'درجة رابعة']
    },
    {
      question: 'ما هى الحالة العقلية والنفسية للمريض؟',
      answers: ['طبيعى ومتزن نفسياً', 'مدرك ولكن يحتاج تنبيه أحيأناً', 'غير مدرك لما حوله', 'يعأنى من الإضطرابات النفسية']
    },
    {
      question: 'هل يستطيع التحرك بمفرده؟',
      answers: ['نعم', 'لا', 'ربما']
    },
    {
      question: 'هل يرتدى الحفاضات؟',
      answers: ['نعم', 'لا', 'ربما']
    },
    {
      question: 'مع من يعيش المعنى بالخدمة (المريض)؟',
      answers: ['بمفرده', 'الزوج /الزوجة', 'أحد الأقارب', 'أحد الأصدقاء']
    }
  ]

  const schema = yup.object().shape({
    questions: yup.object().default(questions.reduce((a, b) => ({ ...a, [b.question]: b.answers[0] }), {})),
    observingDoctor: yup.object().nullable().default(null).shape({
      name: yup.string().required('من فضلك قم بادخال المعلومات كاملة'),
      mobileNumber: yup.string().matches(MOBILE_NUMBER_REGEX, 'من فضلك أدخل رقم هاتف صحيح').required('من فضلك قم بادخال المعلومات كاملة')
    })
  })

  const handleSubmit = form => {
    onSubmit({ reservation: { ...data.reservation, ...form } })
  }

  return (
    <Formik
      validationSchema={schema}
      initialValues={schema.default()}
      validateOnChange={false}
      onSubmit={handleSubmit}
    >
      {({
        handleSubmit,
        setFieldValue,
        setFieldError,
        values,
        handleChange,
        errors
      }) => {
        return (
          <Form noValidate onSubmit={handleSubmit}>
            {questions.map(({ question, answers }, index) => {
              return (
                <Form.Group key={index}>
                  <Form.Label>{question}</Form.Label>
                  <Choice
                    options={answers}
                    value={values.questions[question]}
                    onChange={value => setFieldValue(`questions.${question}`, value)}
                  />
                </Form.Group>
              )
            })}
            <CollapsableForm
              title='هل يوجد دكتور متابع لحالة المريض؟'
              subTitle='سيقوم التمريض بمتابعه ارشادات الطبيب'
              initiallyCollapsed={!values.observingDoctor}
              onChange={collapsed => {
                if (collapsed) {
                  setFieldValue('observingDoctor', null)
                  setFieldError('observingDoctor', null)
                }
                else {
                  setFieldValue('observingDoctor', {})
                }
              }}
              form={
                <Form>
                  <Form.Group>
                    <Form.Label>اسم الطبيب</Form.Label>
                    <Form.Control
                      name='observingDoctor.name'
                      value={values.observingDoctor && values.observingDoctor.name}
                      onChange={handleChange}
                      placeholder='اسم الطبيب'
                      isInvalid={errors.observingDoctor && errors.observingDoctor.name}
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>رقم الهاتف</Form.Label>
                    <Form.Control
                      name='observingDoctor.mobileNumber'
                      value={values.observingDoctor?.mobileNumber}
                      onChange={handleChange}
                      placeholder='رقم الهاتف'
                      isInvalid={errors.observingDoctor?.mobileNumber}
                    />
                    <Form.Control.Feedback type='invalid'>
                      {errors.observingDoctor?.mobileNumber}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form>
              }
            />
            <Button type='submit'>
              إستمرار
            </Button>
          </Form>
        )
      }}
    </Formik>
  )
}

const MedicalRaysInfo = ({ onSubmit, data, dispatch }) => {
  const { medicalCenterServices } = useSelector(selectLookups)

  const schema = yup.object().shape({
    serviceId: yup.number().required('من فضلك قم بادخال المعلومات كاملة'),
    attachmentIds: yup.array(yup.number()).default([]),
    followUpCallNeeded: yup.boolean().default(false)
  })

  const collectServiceInformationAndSubmit = form => {
    const serviceInformation = {
      'نوع الخدمة': ServiceTypes.getTextByValue(data.reservation.serviceType),
      'نوع الأشعة': form.type
    }
    if (form.prescriptionImage) {
      serviceInformation[''] = <span><AttachmentIcon /> مرفق مع المعلومات صورة الروشتة</span>
    }

    onSubmit({ reservation: { ...data.reservation, ...form }, serviceInformation, tag: form.serviceId })
  }

  useEffect(() => {
    dispatch(LookupsActions.listMedicalCenterServicesRequest({ type: data.reservation.serviceType }))
  }, [dispatch])

  return (
    <Formik
      validationSchema={schema}
      initialValues={schema.default()}
      validateOnChange={false}
      onSubmit={collectServiceInformationAndSubmit}
      enableReinitialize={true}
    >
      {({
        handleSubmit,
        setFieldValue,
        values
      }) => {
        return (
          <Form noValidate onSubmit={handleSubmit} className='bottom-button-form'>
            {console.log('MEDICAL RAYS INFO', {values})}
            {
              medicalCenterServices.values.length
              ? <Form.Group>
                  <Form.Label>نوع الأشعة</Form.Label>
                  <Select
                    name='serviceId'
                    value={values.serviceId}
                    options={medicalCenterServices.values}
                    onSelect={value => setFieldValue('serviceId', value)}
                  />
                </Form.Group>
              : <Spinner animation="border" size='sm' />
            }
            <Form.Group>
              <TaggedFormLabel>صورة روشتة الطبيب بطلب الأشعة</TaggedFormLabel>
              <Attachments
                name='attachmentIds'
                onChange={attachments => setFieldValue('attachmentIds', attachments.map(attachment => attachment.id))}
              />
            </Form.Group>

          <div>
            <Form.Check
              label='اريد مكالمة هاتفيه من الدكتور لمتابعة التحليل أو الأشعه'
              onChange={e => setFieldValue('followUpCallNeeded', e.target.checked)}
            />
            <br />
            <Button type='submit'>
              إستمرار
            </Button>
          </div>
          </Form>
        )
      }}
    </Formik>
  )
}

const ProviderSpecific = {
  [ProviderTypes.DOCTOR.value]: [
    {
      title: 'معلومات الطبيب',
      component: DoctorInfo
    }
  ],
  [ProviderTypes.NURSE.value]: [
    {
      title: 'معلومات التمريض',
      component: DurationAndServiceType,
      params: {
        serviceLabel: 'نوع خدمة التمريض المنزلى'
      }
    },
    {
      title: 'معلومات إضافية',
      component: Questions
    }
  ],
  [ProviderTypes.HOME_CARE_SPECIALIST.value]: [
    {
      title: 'معلومات الرعاية المنزلية',
      component: DurationAndServiceType,
      params: {
        serviceLabel: 'نوع خدمة الرعاية المنزلية',
        services: [
          'رعاية كبار السن',
          'رعاية ذوي الإحتياجات الخاصة',
          'رعاية غير طبية لمصابين الكسور أو ما شابه'
        ]
      }
    },
    {
      title: 'معلومات إضافية',
      component: Questions
    }
  ],
  [ProviderTypes.MEDICAL_RAYS_ANALYSIS.value]: [
    {
      title: 'معلومات الأشعة والتحاليل',
      component: MedicalRaysInfo
    }
  ],
  [ProviderTypes.PHYSICAL_THERAPIST.value]: [
    {
      title: 'معلومات الطبيب',
      component: DoctorInfo
    }
  ]
}

const CreateReservationScreen = ({ history, location, requesterId }) => {
  const dispatch = useDispatch()
  const [activeIndex, setActiveIndex] = useState(history.location.state?.activeIndex || 0)

  const [form, setForm] = useState(history.location.state || {
    reservation: {
      ...qs.parse(location.search),
      requesterId
    }
  })

  const childRef = useRef()

  const { reservation } = useSelector(selectReservation)

  const providerHelpers = ProviderSpecific[form.reservation.providerType]

  const providerSpecificTopics = providerHelpers.map(item => item.component)
  const providerSpecificTitles = providerHelpers.map(item => item.title)

  const topics = [EditablePatient, ...providerSpecificTopics, ReservationConfirmation]
  const titles = ['معلومات المريض', ...providerSpecificTitles, 'تفاصيل الطلب']

  const Topic = topics[activeIndex]

  const onSubmit = (data) => {
    const updatedForm = { ...form, ...data }
    if (activeIndex < topics.length - 1) {
      setForm(updatedForm)
      setActiveIndex(activeIndex + 1)
      window.scrollTo({ top: 0 }) // to handle small screens
    }
    else {
      const { reservation } = updatedForm
      console.log({ reservation })
      dispatch(ReservationActions.createReservationRequest(reservation))
      dispatch(CacheActions.deleteCacheRequest({ id: requesterId, target: 'cachedReservation' }))
    }
  }

  const resetAndRedirect = url => {
    dispatch(ReservationActions.reset())
    history.push(url)
  }

  const getTopicParams = () => {
    if (activeIndex > 0 && activeIndex < topics.length - 1) {
      return providerHelpers[activeIndex - 1].params
    }
  }

  const close = () => {
    dispatch(CacheActions.saveCacheRequest({
      id: requesterId,
      target: 'cachedReservation',
      payload: { ...form, activeIndex },
      timestamp: Date.now(),
    }))
    history.goBack()
  }

  return (
    <Screen>
      <Header
        middleItem={<b>{titles[activeIndex]}</b>}
        leftItem={<div onClick={close}>إلغاء</div>}
      />
      <Main>
        <Topic
          onSubmit={onSubmit}
          data={form}
          childRef={childRef}
          dispatch={dispatch}
          requesterId={requesterId}
          { ...getTopicParams() }
        >
          {(() => {
            if (form.reservation.providerType === ProviderTypes.DOCTOR.value) {
              return <DoctorSpecialityAndComplaint ref={childRef} />
            }
          })()}
        </Topic>
        <Success
          show={!!reservation}
          title='تم ارسال طلبك بنجاح'
          message='جاري التواصل مع الطاقم الطبي وسوف يتم توضيح البيانات وتكلفة الخدمة في أقرب وقت'
          buttonText='تم'
          close={() => resetAndRedirect('/')}
          onButtonClick={() => resetAndRedirect(`/requester/${requesterId}/reservations`)}
        />
      </Main>
    </Screen>
  )
}

export default withRouter(CreateReservationScreen)

import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Toast from 'react-bootstrap/Toast'

import { AuthActions, selectAuth } from '../redux/reducers/authSlice'
import useNotificationsInterceptor from './notifications'

const defaultNotificationFactory = () => {
  const [notifications, setNotifications] = useState([])

  const notify = notification => {
    console.log('notifying ...', notification)
    setNotifications(notifications.concat(notification.notification))
  }

  return {
    notify,
    Renderer: () => (
      <div
        style={{
          position: 'fixed',
          top: '10px',
          right: '10px',
          display: 'grid',
          columnGap: '10px',
          zIndex: 1
        }}
      >
        {notifications.map((notification, i) => (
          <Toast
            key={i}
            onClose={() => setNotifications(notifications.filter((n, j) => i !== j))}
            show={true}
            delay={3000}
            autohide
            animation
          >
            {console.log({notifications})}
            <Toast.Header>
              <strong className="mr-auto">{notification?.title}</strong>
            </Toast.Header>
            <Toast.Body>{notification?.body}</Toast.Body>
          </Toast>
        ))}
      </div>
    )
  }
}

const NotificationManager = ({ notificationRendrer = defaultNotificationFactory }) => {
  const dispatch = useDispatch()
  const { userId, isLoggedIn } = useSelector(selectAuth)

  const updateToken = token => dispatch(AuthActions.setTokenRequest({ token, userId }))
  const { notify, Renderer } = notificationRendrer()

  useNotificationsInterceptor(isLoggedIn, updateToken, notify)

  return (
    <Renderer />
  )
}

export default NotificationManager

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  status: 'idle',
  isCreated: false,
  isVerified: false,
  failed: false,
  error: null,
  resendLoading: false,
  isResent: false,
  verificationToken: null,
  mobileNumber: null
}

export const verificationCodeSlice = createSlice({
  name: 'verificationCode',
  initialState,
  reducers: {
    createRequest: (state, action) => {
      state.status = 'loading'
      state.error = null
      state.mobileNumber = action.payload.mobileNumber
    },
    creationSuccess: state => {
      state.status = 'idle'
      state.isCreated = true
    },
    creationFailure: (state, action) => {
      state.error = action.payload
      state.status = 'idle'
    },

    verifyRequest: state => {
      state.status = 'loading'
      state.error = null
      state.verificationToken = null
    },
    verificationSuccess: (state, action) => {
      state.status = 'idle'
      state.isVerified = true
      state.verificationToken = action.payload.token
    },
    verificationFailure: (state, action) => {
      state.error = action.payload
      state.status = 'idle'
    },

    resendCodeRequest: state => {
      state.resendLoading = true
      state.verificationToken = null
    },
    resendCodeSuccess: (state) => {
      state.resendLoading = false
      state.isResent = true
    },
    resendCodeFailure: (state, action) => {
      state.resendLoading = false
      state.isResent = false
      state.error = action.payload
    },

    resetVerificationCode: (state) => {
      state.status = 'idle'
      state.isCreated = false
      state.isVerified = false
      state.failed = false
      state.error = null
      state.resendLoading = false
      state.isResent = false
      state.verificationToken = null
      state.mobileNumber = null
    }
  }
});

export const selectVerificationCode = (state) => state.verificationCode

export const VerificationCodeActions = verificationCodeSlice.actions

export default verificationCodeSlice.reducer;


import useArabicNumbersInterceptor from './arabicNumbers'

const Interceptors = ({ children }) => {
  useArabicNumbersInterceptor()

  return children
}

export default Interceptors

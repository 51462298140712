import { useState, useEffect, useRef } from 'react'

import Form from 'react-bootstrap/Form'

import Link from '../../components/basic/Link'
import { usePrevious } from '../../components/common'

const CertificatesFormGroup = ({ certificates = [], onChange }) => {
  const [certificatesInputs, setCertificatesInputs] = useState(certificates?.length || 1)
  const previousCertificateInputs = usePrevious(certificatesInputs)
  const incrementCertificatesInputs = () => setCertificatesInputs(x => x + 1)

  const ref = useRef()

  useEffect(() => {
    if (previousCertificateInputs && (previousCertificateInputs !== certificatesInputs)) {
      ref.current?.focus()
    }
  }, [certificatesInputs, previousCertificateInputs])

  return (
    <Form.Group>
      <Form.Label>Certificates <Link onClick={incrementCertificatesInputs}>Add one more certificate</Link></Form.Label>
      {[...Array(certificatesInputs || 1).keys()].map(index => {
        const id = `certificate-${index}`
        return (
          <div key={index}>
            {index > 0 && (<br />)}
            <Form.Control
              id={id}
              value={certificates?.[index] || ''}
              onChange={e => {
                const { value } = e.target
                let _certificates = [...certificates]
                if (_certificates[index]) {
                  _certificates[index] = value
                }
                else {
                  _certificates.push(value)
                }
                const filteredCertificates = _certificates.filter(certificate => !!certificate)
                onChange(filteredCertificates)
                setCertificatesInputs(filteredCertificates.length)
              }}
              onKeyDown={e => {
                const key = e.code
                if (document.activeElement.id === id) {
                  if(key === 'Backspace' && !certificates[index]) {
                    setCertificatesInputs(certificatesInputs - 1)
                  }
                }
              }}
              {...(index === certificatesInputs - 1) && { ref }}
            />
          </div>
        )
      })}
    </Form.Group>
  )
}

export default CertificatesFormGroup

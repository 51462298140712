import { call, put, select, takeLatest } from 'redux-saga/effects'
import { ReservationStatus } from '../../components/common'

import * as Api from '../api/visitApi'
import { DashboardActions, DashboardResources, selectDashboard } from '../reducers/dashboardSlice'

import { VisitActions } from '../reducers/visitSlice'

function* _reflectOnDashboard(visit) {
   const { reservations } = yield select(selectDashboard)
   if (reservations.detail) {
      const updatedReservation = Object.assign({}, reservations.detail, {
         status: ReservationStatus.COMPLETED,
         visits: reservations.detail.visits.concat(visit)
      })
      yield put(DashboardActions.detailSuccess({ resource: DashboardResources.RESERVATIONS, body: updatedReservation }))
   }
}

function* create(action) {
   try {
      const response = yield call(Api.createApi, action.payload)
      const visit = response.data
      yield put(VisitActions.createSuccess({ visit }))
      yield _reflectOnDashboard(visit)
   } catch (error) {
      yield put(VisitActions.createFailure(error.response?.data?.error || error))
   }
}

export default function* visitSaga() {
  yield takeLatest(VisitActions.createRequest, create)
}

import { initializeApp } from 'firebase/app'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'

const useNotificationsInterceptor = (isLoggedIn, setToken, notify) => {
  window.addEventListener('__set_fcm_token__', event => setToken(event.detail))

  try {
    if ('ServiceWorker' in self) {
      const firebaseConfig = {
        apiKey: "AIzaSyBZjBYfl9gJNU4pa5UuZcCy3CIsbHbwjgg",
        authDomain: "sehatena-937dc.firebaseapp.com",
        projectId: "sehatena-937dc",
        storageBucket: "sehatena-937dc.appspot.com",
        messagingSenderId: "352793355413",
        appId: "1:352793355413:web:a2248880bec141fb1c279c",
        measurementId: "G-R17G8QV4MN"
      }

      const app = initializeApp(firebaseConfig);
      const messaging = getMessaging(app)

      if (isLoggedIn) {
        const mobile = !!window.ReactNativeWebView?.postMessage
        if (mobile) {
          window.ReactNativeWebView.postMessage(JSON.stringify({
            type: 'fcm/requestToken'
          }))
        }
        else {
          getToken(messaging, { vapidKey: 'BGw--P8FkMo4o5bjB3T9dngtu3V1KyzVUYwInWHTW88yzpUlnhlFxjMzJqY1Q0MYPgV1p8C13cXykEwjYnSKiGg' })
            .then(setToken)
            .catch(error => console.error('Cannot get token', error))
        }

        onMessage(messaging, notify);
      }
    }
  } catch (e) {
    console.log('Notifications disabled ...', e)
  }

}

export default useNotificationsInterceptor

import { Formik } from 'formik'
import * as yup from 'yup'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import BackButton from '../components/basic/BackButton'
import Header from '../components/layout/Header'
import Main from '../components/layout/Main'
import Screen from '../components/layout/Screen'
import PasswordInput from '../components/basic/PasswordInput'

import { AuthActions, selectAuth } from '../redux/reducers/authSlice'

import { PASSWORD_REGEX } from '../components/common'

const schema = yup.object().shape({
  oldPassword: yup.string().matches(PASSWORD_REGEX, 'كلمة المرور ضعيفة').required('من فضلك قم بادخال المعلومات كاملة'),
  newPassword: yup.string().matches(PASSWORD_REGEX, 'كلمة المرور ضعيفة').required('من فضلك قم بادخال المعلومات كاملة'),
  newPasswordAgain: yup.string().required('من فضلك قم بادخال المعلومات كاملة').oneOf([yup.ref('newPassword')], 'كلمة المرور غير متطابقة')
})

const ChangePasswordScreen = ({ requesterId }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { status, error } = useSelector(selectAuth)

  useEffect(() => {
    if (status === 'password_changed') {
      history.goBack()
    }
  }, [status, history])

  const onSubmit = form => {
    dispatch(AuthActions.changePasswordRequest({
      requesterId,
      oldPassword: form.oldPassword,
      newPassword: form.newPassword
    }))
  }

  return (
    <Screen>
      <Header
        middleItem={<b>تغيير كلمة المرور</b>}
        rightItem={<BackButton />}
      />
      <Main className='home-main'>
        <Formik
          validationSchema={schema}
          onSubmit={onSubmit}
          validateOnChange={false}
          initialValues={{}}
        >
          {({
            values,
            errors,
            handleChange,
            handleSubmit
          }) => (
            <Form noValidate onSubmit={handleSubmit} className='bottom-button-form'>
              <Form.Group>
                <Form.Label>كلمة المرور  الحالية</Form.Label>
                <PasswordInput
                  name='oldPassword'
                  value={values?.oldPassword}
                  isInvalid={errors?.oldPassword || !!error}
                  errorMessage={errors?.oldPassword || 'كلمة المرور الحالية غير صحيحة'}
                  onChange={handleChange}
                  placeholder='ادخل كلمة المرور  الحالية'
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>كلمة المرور الجديدة</Form.Label>
                <PasswordInput
                  name='newPassword'
                  value={values?.newPassword}
                  isInvalid={errors?.newPassword}
                  errorMessage={errors?.newPassword}
                  onChange={handleChange}
                  placeholder='ادخل كلمة المرور الجديدة'
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>اعد كتابة كلمة المرور الجديدة</Form.Label>
                <PasswordInput
                  name='newPasswordAgain'
                  value={values?.newPasswordAgain}
                  isInvalid={errors?.newPasswordAgain}
                  errorMessage={errors?.newPasswordAgain}
                  onChange={handleChange}
                  placeholder='اعد كتابة كلمة المرور الجديدة'
                />
              </Form.Group>

              <Button type='submit'>تعديل</Button>
            </Form>
          )}
        </Formik>
      </Main>
    </Screen>
  )
}

export default ChangePasswordScreen

import { useEffect, useState, useMemo, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { PlusCircleFill } from "react-bootstrap-icons";
import { Formik } from "formik";
import * as yup from "yup";
import format from "date-fns/format";
import addDays from "date-fns/addDays";
import startOfWeek from "date-fns/startOfWeek";
import formatISO from "date-fns/formatISO";
import _ from "lodash";
import qs from "query-string";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import {
  withProps,
  Directions,
  MOBILE_NUMBER_REGEX,
  usePrevious,
  DoctorDegrees,
  Genders,
  shiftSchema,
} from "../../components/common";
import DynamicTable from "../../components/DynamicTable";
import Tabs from "../components/Tabs";
import Choice from "../../components/basic/Choice";
import Select from "../../components/basic/Select";

import CreateProvider from "../../screens/provider/CreateProvider/CreateProvider";
import DetailedView from "../layout/DetailedView";

import {
  DashboardActions,
  DashboardResources,
  selectDashboard,
} from "../../redux/reducers/dashboardSlice";
import {
  LookupsActions,
  selectLookups,
} from "../../redux/reducers/lookupsSlice";
import { CityAreaSubform } from "../../components/LocationInfoForm";
import Attachments from "../../components/basic/Attachments";
import { checkExistingApi } from "../../redux/api/authApi";
import TimeInput from "../../components/basic/TimeInput";
import IdentityInput from "../components/IdentityInput";

const userType = "ServiceProvider";

const schema = yup.object().shape({
  firstName: yup.string().required(),
  middleName: yup.string().nullable(),
  lastName: yup.string().required(),
  mobileNumber: yup
    .string()
    .required()
    .matches(MOBILE_NUMBER_REGEX, "Please enter valid Mobile Number")
    .test(
      "unique-phone",
      "Mobile Number already taken",
      async (value, { parent: { id } }) => {
        if (value) {
          const response = await checkExistingApi({
            target: "mobileNumber",
            userType,
            value,
            ignore: id,
          });
          return !response.data;
        }
        return true;
      }
    ),
  gender: yup.string().oneOf(Genders.getValues()),
  email: yup
    .string()
    .email("Please enter valid email")
    .nullable()
    .test(
      "unique-email",
      "Email already taken",
      async (value, { parent: { id } }) => {
        if (value) {
          const response = await checkExistingApi({
            target: "email",
            userType,
            value,
            ignore: id,
          });
          return !response.data;
        }
        return true;
      }
    ),
  birthDate: yup.date(),
  specialityId: yup.number().required(),
  detailedSpeciality: yup.string(),
  degree: yup.string().oneOf(DoctorDegrees.getValues()),
  level: yup.string().nullable(),
  clinic: yup.object().shape({
    cityId: yup.number().required(),
    areaId: yup.number().required(),
    address: yup.string().required(),
    mobileNumber: yup
      .string()
      .matches(MOBILE_NUMBER_REGEX, "Please enter a valid number")
      .required(),
  }),
  cityIds: yup.array(yup.number()),
  areaIds: yup.array(yup.number()),
  homeVisitFees: yup.number().min(0).required(),
  covidFees: yup.number().min(0).nullable(),
  morningShift: shiftSchema({ shift: { max: "eveningShift.from" } }),
  eveningShift: shiftSchema({ shift: { min: "morningShift.to" } }),
  weekDays: yup.array(yup.number()),
  nationalId: yup.number().required(),
  syndicateId: yup.number().required(),
  graduationCertificate: yup.number().nullable(),
});

const CreateDoctorWithName = ({ onDone }) => {
  const [form, setForm] = useState({});
  const [activeIndex, setActiveIndex] = useState(0);

  const BasicInformationForm = ({ onSubmit }) => {
    const namesSchema = yup.object().shape({
      id: IdentityInput.schema,
      firstName: yup.string().required(),
      middleName: yup.string(),
      lastName: yup.string().required(),
      mobileNumber: yup
        .string()
        .matches(MOBILE_NUMBER_REGEX)
        .required()
        .test(
          "unique-phone",
          "Mobile Number already taken",
          async (value, { parent: { id } }) => {
            if (value) {
              const ignore = id ? { ignore: id } : null;
              const response = await checkExistingApi({
                target: "mobileNumber",
                userType,
                value,
                ...ignore,
              });
              return !response.data;
            }
            return true;
          }
        ),
    });

    return (
      <Formik
        validationSchema={namesSchema}
        validateOnChange={false}
        initialValues={{password: 'Abc@123456'}}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, handleChange, values, errors, setFieldValue }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label>ID</Form.Label>
              <IdentityInput
                prefix="DR"
                onChange={(v) => setFieldValue("id", v)}
                isInvalid={errors.id}
                value={values.id}
                errorMessage={errors.id}
              />
            </Form.Group>
            <Form.Group required>
              <Form.Label>First Name</Form.Label>
              <Form.Control
                name="firstName"
                value={values.firstName}
                onChange={handleChange}
                isInvalid={errors.firstName}
              />
              <Form.Control.Feedback type="invalid">
                {errors.firstName}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Middle Name</Form.Label>
              <Form.Control
                name="middleName"
                value={values.middleName}
                onChange={handleChange}
                isInvalid={errors.middleName}
              />
              <Form.Control.Feedback type="invalid">
                {errors.middleName}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group required>
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                name="lastName"
                value={values.lastName}
                onChange={handleChange}
                isInvalid={errors.lastName}
              />
              <Form.Control.Feedback type="invalid">
                {errors.lastName}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group required>
              <Form.Label>Mobile Number</Form.Label>
              <Form.Control
                name="mobileNumber"
                value={values.mobileNumber}
                onChange={handleChange}
                isInvalid={errors.mobileNumber}
              />
              <Form.Control.Feedback type="invalid">
                {errors.mobileNumber}
              </Form.Control.Feedback>
            </Form.Group>
            <Button type="submit">Continue</Button>
          </Form>
        )}
      </Formik>
    );
  };

  const CreateDoctorForms = (props) =>
    withProps(
      CreateProvider.Form({
        ...props,
        onError: (e) => console.log,
        initialValues: form,
        wrapper: ({ title, children }) => (
          <div>
            <h1>{title}</h1>
            <br />
            {children}
          </div>
        ),
      }),
      { direction: Directions.LTR }
    );

  const topics = [BasicInformationForm, CreateDoctorForms];
  const Topic = topics[activeIndex];

  const handleSubmit = (data) => {
    setForm(data);
    setActiveIndex(1);
  };

  return <Topic onSubmit={handleSubmit} initialValues={form} onDone={onDone} />;
};

const Doctors = () => {
  const { doctors } = useSelector(selectDashboard);
  const previousDoctors = usePrevious(doctors);
  const tableRef = useRef();

  const { id } = qs.parse(document.location.search);

  const [showCreate, setShowCreate] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);

  const handleCancelPopup = () => {
    setShowCreate(false);
    setShowUpdate(false);
  };

  useEffect(() => {
    if (doctors.items.length > previousDoctors.items.length) {
      setShowCreate(false);
    }
  }, [previousDoctors, doctors]);

  const action = showCreate ? "Create" : "Update";

  return (
    <div>
      <div className="sub-screen-title">
        <h1>Doctors</h1>
        <Button float="right" onClick={() => setShowCreate(true)}>
          <PlusCircleFill /> Create
        </Button>
      </div>

      <Tabs>
        <Tabs.Tab title="Completed">
          <DynamicTable
            search
            sortable
            ref={tableRef}
            resource={DashboardResources.DOCTORS}
            initialFilters={[_.omitBy({ id }, _.isNil)]}
            data={doctors.items}
            headers={[
              { key: "id", name: "ID", sort: "id.prefix,id.order" },
              {
                key: "name",
                name: "Name",
                sort: "firstName,middleName,lastName",
              },
              { key: "mobileNumber", name: "Number" },
              {
                key: "specialityName",
                name: "Speciality",
                sort: "speciality.arabicName",
              },
              { key: "detailedSpeciality", name: "Detailed Speciality" },
              { key: "degree", name: "Degree" },
              { key: "yearsOfExperience", name: "Years of Exp." },
              { key: "cityName", name: "Cities", sort: "_citiesCount" },
              { key: "areaName", name: "Areas", sort: "_areasCount" },
              { key: "homeVisitFees", name: "Fees" },
              { key: "rating", name: "Rating" },
            ]}
            queryMapper={(query) => {
              if (query.degree) {
                query.degree = DoctorDegrees.getValueByText(query.degree);
              }
              return query;
            }}
            mapper={(item) => {
              return [
                { key: "id", value: item.id },
                {
                  key: "name",
                  value: [item.firstName, item.middleName, item.lastName].join(
                    " "
                  ),
                },
                { key: "mobileNumber", value: item.mobileNumber },
                { key: "specialityName", value: item.speciality.arabicName },
                { key: "detailedSpeciality", value: item.detailedSpeciality },
                {
                  key: "degree",
                  value: DoctorDegrees.getTextByValue(item.degree),
                },
                { key: "yearsOfExperience", value: item.yearsOfExperience },
                {
                  key: "cityName",
                  value: item.cities.map((city) => city.arabicName).join(", "),
                },
                {
                  key: "areaName",
                  value: item.areas.map((area) => area.arabicName).join(", "),
                },
                { key: "homeVisitFees", value: item.homeVisitFees },
                // { key: 'totalRequests', name: 'Total Requests', value: item.totalRequests },
                // { key: 'activeRequests', name: 'Active Requests', value: item.activeRequests },
                { key: "rating", value: item.rating },
                // { key: 'userApproved', name: 'User Approved', value: item.userApproved.firstName },
                // { key: 'dateOfApproval', name: 'Date of Approval', value: item.userApproved.firstName },
              ];
            }}
            pagesCount={doctors.pagesCount}
            detail
          />
        </Tabs.Tab>
      </Tabs>

      <Modal
        show={showCreate || showUpdate}
        onHide={handleCancelPopup}
        dialogClassName="ltr dashboard-layout"
      >
        <Modal.Header closeButton>
          <Modal.Title>{action} Doctor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CreateDoctorWithName
            onDone={() => {
              handleCancelPopup();
              tableRef.current?.refreshTable();
            }}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

Doctors.Detail = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const handleUpdate = (initialValue) => (body) => {
    body.id = id;
    body.deletedFields = _.keys(initialValue).filter(
      (key) => _.isNil(body[key]) && !_.isNil(initialValue[key])
    );

    dispatch(
      DashboardActions.updateRequest({
        resource: DashboardResources.DOCTORS,
        body,
      })
    );
  };

  return (
    <DetailedView
      id={id}
      serviceProvider
      resource={DashboardResources.DOCTORS}
      sectionMappers={[
        (doctor) => ({
          title: "Profile Information",
          body: {
            Name: [doctor.firstName, doctor.middleName, doctor.lastName]
              .filter((n) => n)
              .join(" "),
            "Mobile Number": doctor.mobileNumber,
            Gender: doctor.gender,
            Email: doctor.email,
            Birthdate:
              doctor.birthDate &&
              format(Date.parse(doctor.birthDate), "dd/MM/yyyy"),
          },
          edit: () => (ref) => {
            const fields = [
              "id",
              "firstName",
              "middleName",
              "lastName",
              "mobileNumber",
              "email",
              "gender",
              "birthDate",
            ];
            const initialValues = _.pick(doctor, fields);

            return (
              <Formik
                innerRef={ref}
                initialValues={initialValues}
                validationSchema={schema.pick(fields)}
                onSubmit={handleUpdate(initialValues)}
              >
                {({
                  handleSubmit,
                  handleChange,
                  values,
                  errors,
                  setFieldValue,
                }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <Form.Group>
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        name="firstName"
                        value={values.firstName}
                        isInvalid={errors.firstName}
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.firstName}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Middle Name</Form.Label>
                      <Form.Control
                        name="middleName"
                        value={values.middleName}
                        isInvalid={errors.middleName}
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.middleName}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        name="lastName"
                        value={values.lastName}
                        isInvalid={errors.lastName}
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.lastName}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Mobile Number</Form.Label>
                      <Form.Control
                        name="mobileNumber"
                        value={values.mobileNumber}
                        isInvalid={errors.mobileNumber}
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.mobileNumber}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Gender</Form.Label>
                      <Choice
                        value={values.gender}
                        options={Genders.getValues()}
                        onChange={(value) => setFieldValue("gender", value)}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.gender}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        name="email"
                        value={values.email}
                        isInvalid={errors.email}
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.email}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Birthdate</Form.Label>
                      <Form.Control
                        name="birthDate"
                        value={
                          values.birthDate &&
                          formatISO(new Date(values.birthDate), {
                            representation: "date",
                          })
                        }
                        isInvalid={errors.birthDate}
                        onChange={handleChange}
                        type="date"
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.birthDate}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Form>
                )}
              </Formik>
            );
          },
        }),
        (doctor) => ({
          title: "Occupation Information",
          body: {
            Speciality: doctor.speciality.arabicName,
            "Detailed Speciality": doctor.detailedSpeciality,
            Degree: DoctorDegrees.getTextByValue(doctor.degree),
            Level: doctor.level,
            Thesis: doctor.thesis,
          },
          edit: () => (ref) => {
            const fields = [
              "specialityId",
              "detailedSpeciality",
              "degree",
              "level",
              "thesis",
            ];
            const initialValues = {
              ..._.pick(doctor, fields),
              specialityId: doctor.speciality.id,
            };

            const { doctorSpecialities } = useSelector(selectLookups);
            useEffect(() => {
              dispatch(LookupsActions.listDoctorSpecialitiesRequest());
            }, [dispatch]);

            return (
              <Formik
                innerRef={ref}
                initialValues={initialValues}
                validationSchema={schema.pick(fields)}
                onSubmit={handleUpdate(initialValues)}
              >
                {({
                  handleSubmit,
                  handleChange,
                  values,
                  errors,
                  setFieldValue,
                }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    {!!doctorSpecialities.values.length && (
                      <Form.Group>
                        <Form.Label>Speciality</Form.Label>
                        <Select
                          name="specialityId"
                          label="Speciality"
                          value={values.specialityId}
                          options={doctorSpecialities.values}
                          onSelect={(value) =>
                            setFieldValue("specialityId", value)
                          }
                        />
                      </Form.Group>
                    )}
                    <Form.Group>
                      <Form.Label>Detailed Speciality</Form.Label>
                      <Form.Control
                        name="detailedSpeciality"
                        value={values.detailedSpeciality}
                        isInvalid={errors.detailedSpeciality}
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.detailedSpeciality}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Degree</Form.Label>
                      <Choice
                        options={DoctorDegrees.getTexts()}
                        value={DoctorDegrees.getTextByValue(values.degree)}
                        onChange={(v) =>
                          setFieldValue(
                            "degree",
                            DoctorDegrees.getValueByText(v)
                          )
                        }
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Level</Form.Label>
                      <Form.Control
                        name="level"
                        value={values.level}
                        isInvalid={errors.level}
                        onChange={handleChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.level}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Form>
                )}
              </Formik>
            );
          },
        }),
        (doctor) => ({
          title: "Clinic's Info",
          body: {
            "Clinic's City": doctor.clinics[0]?.city.arabicName,
            "Clinic's Area": doctor.clinics[0]?.area.arabicName,
            Address: doctor.clinics[0]?.address,
            "Clinic's Phone": doctor.clinics[0]?.mobileNumber,
          },
          edit: () => (ref) => {
            const fields = ["clinic"];
            const [clinic] = doctor?.clinics;

            const initialValues = {
              clinic: clinic
                ? { ...clinic, areaId: clinic.area.id, cityId: clinic.city.id }
                : {},
            };

            return (
              <Formik
                innerRef={ref}
                initialValues={initialValues}
                validationSchema={schema.pick(fields)}
                onSubmit={handleUpdate(initialValues)}
                validateOnChange={false}
                enableReinitialize={true}
              >
                {({
                  handleSubmit,
                  handleChange,
                  values,
                  errors,
                  setFieldValue,
                }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <Form.Group>
                      <Form.Label>Clinic's Phone</Form.Label>
                      <Form.Control
                        name="clinic.mobileNumber"
                        defaultValue={values.clinic?.mobileNumber}
                        isInvalid={errors.clinic?.mobileNumber}
                        onChange={handleChange}
                        placeholder="Clinic's Phone"
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.clinic?.mobileNumber}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <CityAreaSubform
                      values={values.clinic}
                      errors={errors.clinic || {}}
                      onAreaChange={(areaId) =>
                        setFieldValue("clinic.areaId", areaId)
                      }
                      onCityChange={(cityId) =>
                        setFieldValue("clinic.cityId", cityId)
                      }
                      labels={{ city: "Clinic's City", area: "Clinic's Area" }}
                    />
                    <Form.Group>
                      <Form.Label>Clinic's Address</Form.Label>
                      <Form.Control
                        name="clinic.address"
                        defaultValue={values.clinic?.address}
                        isInvalid={errors.clinic?.address}
                        onChange={handleChange}
                        placeholder="Clinic's Address"
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.clinic?.address}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Form>
                )}
              </Formik>
            );
          },
        }),
        (doctor) => ({
          title: "Covered Areas & Timing",
          body: {
            "Working Cities": doctor.cities.map((c) => c.arabicName).join(", "),
            "Working Areas": doctor.areas.map((a) => a.arabicName).join(", "),
            "Visit Fees": doctor.homeVisitFees,
            "Morning Shift": [
              doctor.morningShift?.from,
              doctor.morningShift?.to,
            ]
              .filter(_.identity)
              .join(" - "),
            "Evening Shift": [
              doctor.eveningShift?.from,
              doctor.eveningShift?.to,
            ]
              .filter(_.identity)
              .join(" - "),
            "Visit Fees (If Corona)": doctor.covidFees,
            "Working Days": doctor.weekDays
              ?.map((day) =>
                format(addDays(startOfWeek(new Date()), day), "EEEE")
              )
              .join(", "),
          },
          edit: () => (ref) => {
            const fields = [
              "cityIds",
              "areaIds",
              "homeVisitFees",
              "covidFees",
              "morningShift",
              "eveningShift",
              "weekDays",
            ];
            const firstDOW = startOfWeek(new Date());
            const weekDayOptions = Array.from(Array(7)).map((e, i) => {
              return {
                text: format(addDays(firstDOW, i), "EEEE"),
                value: i,
              };
            });
            const initialValues = {
              ..._.pick(doctor, fields),
              cityIds: doctor.cities?.map((city) => city.id),
              areaIds: doctor.areas?.map((area) => area.id),
            };

            return (
              <Formik
                innerRef={ref}
                initialValues={initialValues}
                validationSchema={schema.pick(fields)}
                onSubmit={handleUpdate(initialValues)}
                validateOnChange={false}
                enableReinitialize={true}
              >
                {({
                  handleSubmit,
                  handleChange,
                  values,
                  errors,
                  setFieldValue,
                }) => {
                  const workingCitiesAreasSubform = useMemo(() => {
                    console.log(
                      "Re-computing WORKING CITIES AREAS SUBFORM",
                      values.cityIds,
                      values.areaIds,
                      errors.cityIds,
                      errors.areaIds
                    );
                    return (
                      <CityAreaSubform
                        multiple
                        values={{
                          cities: values.cityIds,
                          areas: values.areaIds,
                        }}
                        errors={{
                          cities: errors.cityIds,
                          areas: errors.areaIds,
                        }}
                        onAreaChange={(areaIds) =>
                          setFieldValue("areaIds", areaIds)
                        }
                        onCityChange={(cityIds) =>
                          setFieldValue("cityIds", cityIds)
                        }
                        placeholders={{
                          city: "Working Cities",
                          area: "Working Areas",
                        }}
                        labels={{
                          city: "Working Cities",
                          area: "Working Areas",
                        }}
                      />
                    );
                  }, [
                    values.cityIds,
                    values.areaIds,
                    errors.cityIds,
                    errors.areaIds,
                  ]);

                  return (
                    <Form noValidate onSubmit={handleSubmit}>
                      {workingCitiesAreasSubform}
                      <Form.Group>
                        <Form.Label>Visit Fees</Form.Label>
                        <Form.Control
                          name="homeVisitFees"
                          value={values.homeVisitFees}
                          isInvalid={errors.homeVisitFees}
                          onChange={handleChange}
                          placeholder="Visit Fees"
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.homeVisitFees}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>Covid Fees</Form.Label>
                        <Form.Control
                          name="covidFees"
                          value={values.covidFees}
                          isInvalid={errors.covidFees}
                          onChange={handleChange}
                          placeholder="Covid Fees"
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.covidFees}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>Morning Shift</Form.Label>
                        <Row className="mb-3">
                          <Form.Group as={Col}>
                            <Form.Label>From</Form.Label>
                            <TimeInput
                              name="morningShift.from"
                              value={values.morningShift?.from}
                              isInvalid={errors.morningShift?.from}
                              onChange={TimeInput.onChange(
                                values.morningShift,
                                "morningShift.from",
                                setFieldValue
                              )}
                              placeholder="Morning Shift From"
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.morningShift?.from}
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group as={Col}>
                            <Form.Label>To</Form.Label>
                            <TimeInput
                              name="morningShift.to"
                              value={values.morningShift?.to}
                              isInvalid={errors.morningShift?.to}
                              onChange={TimeInput.onChange(
                                values.morningShift,
                                "morningShift.to",
                                setFieldValue
                              )}
                              placeholder="Morning Shift To"
                              minTime={values.morningShift?.from}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.morningShift?.to}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Row>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>Evening Shift</Form.Label>
                        <Row className="mb-3">
                          <Form.Group as={Col}>
                            <Form.Label>From</Form.Label>
                            <TimeInput
                              name="eveningShift.from"
                              value={values.eveningShift?.from}
                              isInvalid={errors.eveningShift?.from}
                              onChange={TimeInput.onChange(
                                values.eveningShift,
                                "eveningShift.from",
                                setFieldValue
                              )}
                              placeholder="Evening Shift From"
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.eveningShift?.from}
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group as={Col}>
                            <Form.Label>To</Form.Label>
                            <TimeInput
                              name="eveningShift.to"
                              value={values.eveningShift?.to}
                              isInvalid={errors.eveningShift?.to}
                              onChange={TimeInput.onChange(
                                values.eveningShift,
                                "eveningShift.to",
                                setFieldValue
                              )}
                              placeholder="Evening Shift To"
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.eveningShift?.to}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Row>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>Week Days</Form.Label>
                        <Select
                          multiple
                          options={weekDayOptions}
                          name="weekDays"
                          initialValue={weekDayOptions
                            .map(({ value }) => value)
                            .filter((day) => values.weekDays.includes(day))}
                          value={weekDayOptions
                            .map(({ value }) => value)
                            .filter((day) => values.weekDays.includes(day))}
                          onSelect={(weekDays) =>
                            setFieldValue("weekDays", weekDays)
                          }
                          placeholder="Week Days"
                          label="Week Days"
                        />
                      </Form.Group>
                    </Form>
                  );
                }}
              </Formik>
            );
          },
        }),
        (doctor) => ({
          title: "Attachments",
          body: {
            "National ID": <Image src={doctor.nationalId?.url} />,
            "Graduation Certificate": doctor.graduationCertificate && (
              <Image src={doctor.graduationCertificate?.url} />
            ),
            "Syndicate ID": <Image src={doctor.syndicateId?.url} />,
            ...(doctor.scientificCertificates.length && {
              "Post Graduate Certificate": doctor.scientificCertificates.map(
                (c) => c.certificatePhoto?.url
              ),
            }),
          },
          edit: () => (ref) => {
            const fields = [
              "nationalId",
              "syndicateId",
              "graduationCertificate",
            ];
            const initialValues = {
              ..._.pick(doctor, fields),
              nationalId: doctor.nationalId?.id,
              graduationCertificate: doctor.graduationCertificate?.id,
              syndicateId: doctor.syndicateId?.id,
            };
            return (
              <Formik
                innerRef={ref}
                initialValues={initialValues}
                validationSchema={schema.pick(fields)}
                onSubmit={handleUpdate(initialValues)}
              >
                {({ handleSubmit, errors, setFieldValue }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <Form.Group>
                      <Form.Label>National ID</Form.Label>
                      <Attachments
                        single
                        initialValue={doctor.nationalId}
                        onChange={(value) =>
                          setFieldValue("nationalId", value?.id)
                        }
                        isInvalid={errors.nationalId}
                        errorMessage={errors.nationalId}
                        target={`${doctor.id}-nationalId`}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Graduation Certificate</Form.Label>
                      <Attachments
                        single
                        initialValue={doctor.graduationCertificate}
                        onChange={(value) =>
                          setFieldValue("graduationCertificate", value?.id)
                        }
                        isInvalid={errors.graduationCertificate}
                        errorMessage={errors.graduationCertificate}
                        target={`${doctor.id}-graduationCertificate`}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Syndicate ID</Form.Label>
                      <Attachments
                        single
                        initialValue={doctor.syndicateId}
                        onChange={(value) =>
                          setFieldValue("syndicateId", value?.id)
                        }
                        isInvalid={errors.syndicateId}
                        errorMessage={errors.syndicateId}
                        target={`${doctor.id}-syndicateId`}
                      />
                    </Form.Group>
                  </Form>
                )}
              </Formik>
            );
          },
        }),
      ]}
    />
  );
};

export default Doctors;

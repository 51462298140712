
import { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PlusCircleFill, PenFill } from 'react-bootstrap-icons'
import { Formik } from 'formik'
import * as yup from 'yup'

import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Alert from 'react-bootstrap/Alert'
import Spinner from 'react-bootstrap/Spinner'

import DynamicTable from '../../components/DynamicTable'
import { ProviderTypes, usePrevious } from '../../components/common'
import { DashboardActions, DashboardResources, selectDashboard } from '../../redux/reducers/dashboardSlice'
import { LookupsActions, selectLookups } from '../../redux/reducers/lookupsSlice'

const Pricing = () => {
  const dispatch = useDispatch()
  const { error, pricing, isLoading } = useSelector(selectDashboard)
  const { areas, doctorSpecialities, nurseTasks, homeCareSpecialistTasks, medicalCenterServices } = useSelector(selectLookups)
  const [show, setShow] = useState(false)
  const [initialValues, setInitialValues] = useState()
  const [cachedError, setCachedError] = useState()
  const formRef = useRef()
  const previousIsLoading = usePrevious(isLoading)

  const schema = yup.object().shape({
    providerType: yup.string().required().oneOf(ProviderTypes.getValues()).default(ProviderTypes.DOCTOR.value),
    areaId: yup.number().required().default(areas.values[0]?.value),
    tag: yup.number().nullable().default(doctorSpecialities.values[0]?.value),
    normalPriceFrom: yup.number().required().min(0),
    normalPriceTo: yup.number().required().min(yup.ref('normalPriceFrom')),
    covidPrice: yup.number().nullable().min(0),
    moreDetails: yup.string().nullable()
  })

  const submit = body => {
    const action = initialValues ? DashboardActions.updateRequest : DashboardActions.createRequest
    dispatch(action({ resource: DashboardResources.PRICING, body }))
  }

  const hide = () => {
    setShow(false)
    setInitialValues(null)
    setCachedError(null)
  }

  const edit = item => {
    setInitialValues({ ...item, tag: item.tag?.id, areaId: item.area.id })
    setShow(true)
  }

  const getDefaultTag = providerType => {
    switch(providerType) {
      case ProviderTypes.DOCTOR.value:
        return doctorSpecialities.values[0]?.value
      case ProviderTypes.NURSE.value:
        return nurseTasks.values[0]?.value
      case ProviderTypes.HOME_CARE_SPECIALIST.value:
        return homeCareSpecialistTasks.values[0]?.value
      case ProviderTypes.MEDICAL_RAYS_ANALYSIS.value:
        return medicalCenterServices.values[0]?.value
      default:
        return null
    }
  }

  useEffect(() => {
    dispatch(LookupsActions.listAreasRequest())
    dispatch(LookupsActions.listDoctorSpecialitiesRequest())
    dispatch(LookupsActions.listNurseTasksRequest())
    dispatch(LookupsActions.listHomeCareSpecialistTasksRequest())
    dispatch(LookupsActions.listMedicalCenterServicesRequest())
  }, [dispatch])


  useEffect(() => {
    setCachedError(error)
  }, [error])

  useEffect(() => {
    if(!isLoading && previousIsLoading && !error) {
      hide()
    }
  }, [isLoading, previousIsLoading])

  return (
    <div>
      <div className='sub-screen-title'>
        <h1>Pricing</h1>
        <Button className='full-width-horizontal-group' onClick={() => setShow(true)}>
          <PlusCircleFill />
          <div>Create</div>
        </Button>
      </div>

      <DynamicTable
        search
        sortable
        resource={DashboardResources.PRICING}
        data={pricing.items}
        headers={[
          { key: 'id', name: 'ID' },
          { key: 'providerType', name: 'Provider Type' },
          { key: 'areaName', sort: 'area.englishName', name: 'Area' },
          { key: 'tag', name: 'Tag' },
          { key: 'normalPriceFrom', name: 'From' },
          { key: 'normalPriceTo', name: 'To' },
          { key: 'covidPrice', name: 'Covid Price' },
          { key: 'moreDetails', name: 'More Details' },
          { key: '$edit', name: '' },
        ]}
        mapper={item => {
          return [
            { key: 'id', value: item.id },
            { key: 'providerType', value: item.providerType },
            { key: 'areaName', value: item.area.englishName },
            { key: 'tag', value: item.tag?.arabicName },
            { key: 'normalPriceFrom', value: item.normalPriceFrom },
            { key: 'normalPriceTo', value: item.normalPriceTo },
            { key: 'covidPrice', value: item.covidPrice },
            { key: 'moreDetails', value: item.moreDetails },
            { key: '$edit', value: <div onClick={() => edit(item)}><PenFill /></div>},
          ]
        }}
        pagesCount={pricing.pagesCount}
      />

      <Modal show={show} onHide={hide} dialogClassName='ltr dashboard-layout'>
        <Modal.Header closeButton>
          <Modal.Title>Create Pricing</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            validationSchema={schema}
            initialValues={initialValues || schema.default()}
            onSubmit={submit}
            innerRef={formRef}
            enableReinitialize={true}
            validateOnChange={false}
          >
            {({ handleSubmit, handleChange, setFieldValue, values, errors }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Group required>
                  <Form.Label>Provider Type</Form.Label>
                  <Form.Select
                    name='providerType'
                    value={values.providerType}
                    onChange={e => {
                      handleChange(e)
                      setFieldValue('tag', getDefaultTag(e.target.value))
                    }}
                  >
                    <option value={ProviderTypes.DOCTOR.value}>{ProviderTypes.DOCTOR.text}</option>
                    <option value={ProviderTypes.NURSE.value}>{ProviderTypes.NURSE.text}</option>
                    <option value={ProviderTypes.HOME_CARE_SPECIALIST.value}>{ProviderTypes.HOME_CARE_SPECIALIST.text}</option>
                    <option value={ProviderTypes.PHYSICAL_THERAPIST.value}>{ProviderTypes.PHYSICAL_THERAPIST.text}</option>
                    <option value={ProviderTypes.MEDICAL_RAYS_ANALYSIS.value}>{ProviderTypes.MEDICAL_RAYS_ANALYSIS.text}</option>
                  </Form.Select>
                </Form.Group>
                {values.providerType === ProviderTypes.DOCTOR.value && (
                  <Form.Group required>
                    <Form.Label>Doctor Speciality</Form.Label>
                    <Form.Select
                      name='tag'
                      value={values.tag}
                      onChange={handleChange}
                    >
                      {doctorSpecialities.values.map(item => <option index={item.value} value={item.value}>{item.text}</option>)}
                    </Form.Select>
                  </Form.Group>
                )}
                {values.providerType === ProviderTypes.NURSE.value && (
                  <Form.Group required>
                    <Form.Label>Nurse Task</Form.Label>
                    <Form.Select
                      name='tag'
                      value={values.tag}
                      onChange={handleChange}
                    >
                      {nurseTasks.values.map(item => <option index={item.value} value={item.value}>{item.text}</option>)}
                    </Form.Select>
                  </Form.Group>
                )}
                {values.providerType === ProviderTypes.HOME_CARE_SPECIALIST.value && (
                  <Form.Group required>
                    <Form.Label>Home Care Specialist Task</Form.Label>
                    <Form.Select
                      name='tag'
                      value={values.tag}
                      onChange={handleChange}
                    >
                      {homeCareSpecialistTasks.values.map(item => <option index={item.value} value={item.value}>{item.text}</option>)}
                    </Form.Select>
                  </Form.Group>
                )}
                {values.providerType === ProviderTypes.MEDICAL_RAYS_ANALYSIS.value && (
                  <Form.Group required>
                    <Form.Label>Medical Center Service</Form.Label>
                    <Form.Select
                      name='tag'
                      value={values.tag}
                      onChange={handleChange}
                    >
                      {medicalCenterServices.values.map(item => <option index={item.value} value={item.value}>{item.text}</option>)}
                    </Form.Select>
                  </Form.Group>
                )}
                <Form.Group required>
                  <Form.Label>Area</Form.Label>
                  <Form.Select
                    name='areaId'
                    value={values.areaId}
                    onChange={handleChange}
                  >
                    {areas.values.map(area => <option index={area.value} value={area.value}>{area.text}</option>)}
                  </Form.Select>
                </Form.Group>
                <Form.Group required>
                  <Form.Label>Normal Price From</Form.Label>
                  <Form.Control
                    name='normalPriceFrom'
                    value={values.normalPriceFrom}
                    isInvalid={errors.normalPriceFrom}
                    onChange={handleChange}
                    type='number'
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.normalPriceFrom}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group required>
                  <Form.Label>Normal Price To</Form.Label>
                  <Form.Control
                    name='normalPriceTo'
                    value={values.normalPriceTo}
                    isInvalid={errors.normalPriceTo}
                    onChange={handleChange}
                    type='number'
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.normalPriceTo}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Covid Price</Form.Label>
                  <Form.Control
                    name='covidPrice'
                    value={values.covidPrice}
                    isInvalid={errors.covidPrice}
                    onChange={handleChange}
                    type='number'
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.covidPrice}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                  <Form.Label>More Details</Form.Label>
                  <Form.Control
                    name='moreDetails'
                    value={values.moreDetails}
                    isInvalid={errors.moreDetails}
                    onChange={handleChange}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.moreDetails}
                  </Form.Control.Feedback>
                </Form.Group>
                {cachedError && (
                  <Alert variant='danger'>
                    {error === 'pricing already exist' ? 'Pricing for the selected criteria already exists!' : 'Something went wrong!'}
                  </Alert>
                )}
              </Form>
            )}
          </Formik>
        </Modal.Body>
        <Modal.Footer>
          <Button type='submit' onClick={() => formRef.current?.submitForm()}>
            {isLoading ? <Spinner animation='border' size='sm' /> : initialValues ? 'Update' : 'Create'}
          </Button>
          <Button variant='outline-primary' onClick={hide}>Cancel</Button>
        </Modal.Footer>
      </Modal>
    </div>
  )

}

export default Pricing

import { useState, useRef, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { Formik } from 'formik'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import * as yup from 'yup'
import qs from 'query-string'
import _ from 'lodash'

import * as VerifyCode from './topics/VerifyCode'

import Screen from '../components/layout/Screen'
import Main from '../components/layout/Main'
import Header from '../components/layout/Header'

import Choice from '../components/basic/Choice'
import Svg from '../components/basic/Svg'
import Select from '../components/basic/Select'

import PasswordInput from '../components/basic/PasswordInput'
import { ProviderTypes, PASSWORD_REGEX, MOBILE_NUMBER_REGEX } from '../components/common'
import { DoctorSpecialityAndComplaint } from './CreateReservationScreen'
import { CityAreaSubform } from '../components/LocationInfoForm'
import { selectVerificationCode, VerificationCodeActions } from '../redux/reducers/verificationCodeSlice'

import {ReactComponent as OrangeInfoIcon} from '../svg/info_orange.svg'
import {ReactComponent as DoneIcon} from '../svg/done.svg'

import { ReservationActions, selectReservation } from '../redux/reducers/reservationSlice'
import { AuthActions } from '../redux/reducers/authSlice'
import { LookupsActions, selectLookups } from '../redux/reducers/lookupsSlice'
import Attachments from '../components/basic/Attachments'
import TaggedFormLabel from '../components/basic/TaggedFormLabel'

const ServiceDetails = ({ onSubmit, providerType, serviceType, history, dispatch }) => {
  const childRef = useRef()
  const { medicalCenterServices } = useSelector(selectLookups)

  const patientOptions = ['انا', 'الوالد / الوالدة', 'الإبن / الإبنة', 'الاخ / الاخت', 'شخص مقرب']

  let schema = yup.object().shape({
    patient: yup.string().required('من فضلك قم بادخال المعلومات كاملة').default(patientOptions[0]),
    cityId: yup.number().required('من فضلك قم بادخال المعلومات كاملة'),
    areaId: yup.number().required('من فضلك قم بادخال المعلومات كاملة')
  })

  if (providerType === ProviderTypes.MEDICAL_RAYS_ANALYSIS.value) {
    schema = schema.concat(yup.object().shape({
      medicalCenterServiceId: yup.number().required(),
      attachmentIds: yup.array(yup.number()).default([])
    }))
  }

  const validateChildAndSubmit = form => {
    console.log('submitting....', form)
    form.isRequester = form.patient === patientOptions[0]

    if (childRef.current) {
      childRef.current.validateForm().then(errors => {
        if (_.isEmpty(errors)) {
          onSubmit({ ...form, ...childRef.current.values })
        }
      })
    }
    else {
      onSubmit(form)
    }
  }

  useEffect(() => {
    dispatch(LookupsActions.listMedicalCenterServicesRequest({ type: serviceType }))
  }, [dispatch, serviceType])

  return (
    <Screen>
      <Header
        leftItem={<div onClick={history.goBack}>إلغاء</div>}
        middleItem={<b>بيانات الخدمة</b>}
      />
      <Main>
        <Formik
          validationSchema={schema}
          initialValues={schema.default()}
          onSubmit={validateChildAndSubmit}
          validateOnChange={false}
        >
          {({
            handleSubmit,
            setFieldValue,
            values,
            errors
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              {console.log({values, errors})}
              <Form.Group>
                <Form.Label>قم بتحديد هوية المريض</Form.Label>
                <Choice
                  name='patient'
                  value={values.patient}
                  options={patientOptions}
                  onChange={value => setFieldValue('patient', value)}
                />
              </Form.Group>
              {(() => {
                if (providerType === ProviderTypes.DOCTOR.value) {
                  return <DoctorSpecialityAndComplaint ref={childRef} />
                }
                else if (providerType === ProviderTypes.MEDICAL_RAYS_ANALYSIS.value) {
                  return (
                    medicalCenterServices.values.length
                    ? [
                        <Form.Group>
                          <Form.Label>نوع الأشعة</Form.Label>
                          <Select
                            name='medicalCenterServiceId'
                            value={values.medicalCenterServiceId}
                            options={medicalCenterServices.values}
                            onSelect={value => setFieldValue('medicalCenterServiceId', value)}
                          />
                        </Form.Group>,
                        <Form.Group>
                          <TaggedFormLabel>صورة روشتة الطبيب بطلب الأشعة</TaggedFormLabel>
                          <Attachments
                            name='attachmentIds'
                            onChange={attachments => setFieldValue('attachmentIds', attachments.map(attachment => attachment.id))}
                          />
                        </Form.Group>
                      ]
                    : <Spinner animation="border" size='sm' />
                  )
                }
              })()}

              <CityAreaSubform
                values={{areaId: values.areaId, cityId: values.cityId}}
                errors={{areaId: errors.areaId, cityId: errors.cityId}}
                onAreaChange={value => setFieldValue('areaId', value)}
                onCityChange={value => setFieldValue('cityId', value)}
              />

              <Button type='submit'>إستمرار</Button>
            </Form>
          )}
        </Formik>
      </Main>
    </Screen>
  )
}

const AccountDetails = ({ onSubmit, history, form, failed }) => {
  const schema = yup.object().shape({
    firstName: yup.string().required('من فضلك قم بادخال المعلومات كاملة'),
    lastName: yup.string().required('من فضلك قم بادخال المعلومات كاملة'),
    mobileNumber: yup.string().matches(MOBILE_NUMBER_REGEX, 'من فضلك أدخل رقم هاتف صحيح').required('من فضلك قم بادخال المعلومات كاملة'),
    password: yup.string().matches(PASSWORD_REGEX, 'كلمة المرور ضعيفة').required('من فضلك قم بادخال المعلومات كاملة'),
    passwordAgain: yup.string().required('من فضلك قم بادخال المعلومات كاملة').oneOf([yup.ref('password')], 'كلمة المرور غير متطابقة')
  })

  const manipulateThenSubmit = data => {
    if (form.isRequester) {
      data.patient = [data.firstName, data.lastName].join(' ')
    }
    onSubmit(data)
  }

  return (
    <Screen>
      <Header
        leftItem={<div onClick={history.goBack}>إلغاء</div>}
        middleItem={<b>بيانات الحساب</b>}
      />
      <Main>
        <Formik
          initialValues={schema.default()}
          onSubmit={manipulateThenSubmit}
          validationSchema={schema}
          validateOnChange={false}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            errors
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Form.Group>
                <Form.Label>الإسم الاول</Form.Label>
                <Form.Control
                  placeholder='ادخل الإسم الاول'
                  onChange={handleChange}
                  name='firstName'
                  value={values.firstName}
                  isInvalid={errors.firstName}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.firstName}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group>
                <Form.Label>الإسم الأخير</Form.Label>
                <Form.Control
                  placeholder='ادخل الإسم الأخير'
                  onChange={handleChange}
                  name='lastName'
                  value={values.lastName}
                  isInvalid={errors.lastName}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.lastName}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group>
                <Form.Label>رقم هاتف صاحب الحساب</Form.Label>
                <Form.Control
                  placeholder='ادخل رقم هاتف صاحب الحساب'
                  onChange={handleChange}
                  name='mobileNumber'
                  value={values.mobileNumber}
                  isInvalid={errors.mobileNumber || failed}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.mobileNumber || 'رقم الهاتف الذي ادخلته موجود بالفعل'}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group>
                <Form.Label>كلمة المرور</Form.Label>
                <PasswordInput
                  name='password'
                  placeholder='ادخل كلمة المرور'
                  onChange={handleChange}
                  isInvalid={errors.password}
                  errorMessage={errors.password}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>أعد كتابة كلمة المرور</Form.Label>
                <PasswordInput
                  name='passwordAgain'
                  placeholder='أعد كتابة كلمة المرور'
                  onChange={handleChange}
                  isInvalid={errors.passwordAgain}
                  errorMessage={errors.passwordAgain}
                />
              </Form.Group>

              <Button type='submit'>إستمرار</Button>
            </Form>
          )}
        </Formik>
      </Main>
    </Screen>
  )
}

const PostSubmission = ({ dispatch, history, form }) => {
  const { pricing, reservation } = useSelector(selectReservation)
  const [submitDCC, setSubmitDCC] = useState(false)

  const redirectToHome = useCallback(() => {
    history.push('/')
  }, [history])

  const submitDataCompletionCallRequest = () => {
    setSubmitDCC(true)
  }

  useEffect(() => {
    return () => {
      dispatch(AuthActions.loginRequest({
        mobileNumber: form.mobileNumber,
        password: form.password
      }))
      dispatch(ReservationActions.reset())
      if (submitDCC) {
        dispatch(ReservationActions.submitDataCompletionCallRequest({
          reservationId: reservation?.reservation.id,
          mobileNumber: form.mobileNumber,
          password: form.password
        }))
      }
    }
  }, [dispatch, form, reservation, submitDCC])

  useEffect(() => {
    if (submitDCC) {
      redirectToHome()
    }
  }, [submitDCC, redirectToHome])

  return (
    <Screen>
      <Header middleItem={<b>تم تسجيلكم وطلب الخدمة بنجاح !</b>} />
      <Main>
        <div className='bottom-button-form'>
          <div className='post-submission-info'>
            <Svg as={OrangeInfoIcon} iconn />
            برجاء إستكمال بعض البيانات حتي نتمكن من تقديم الخدمة بالشكل المطلوب
          </div>
          <DoneIcon />
          <div className='post-submission-pricing'>
            متوسط سعر الخدمة بين <span className='highlighted-text'>
              {pricing?.normalPriceFrom} - {pricing?.normalPriceTo} جنيه مصري
            </span> وهذا السعر قد يكون تقريبياً وسوف يتم الرد عليكم خلال 30 دقيقه من ساعات العمل <b>(10 صباحاً حتي 10 مساءاً)</b>
          </div>
          <div className='vertical-button-group'>
            <Button onClick={redirectToHome}>إستكمال البيانات بمفردي</Button>
            <Button onClick={submitDataCompletionCallRequest} variant='outline-primary'>الإستكمال من خلال خدمة العملاء</Button>
          </div>
        </div>
      </Main>
    </Screen>
  )
}
const GuestReservationScreen = ({ history, location }) => {
  const dispatch = useDispatch()
  const { providerType, serviceType } = qs.parse(location.search)

  const [activeIndex, setActiveIndex] = useState(0)
  const [form, setForm] = useState({ providerType, serviceType })

  const { status: verificationStatus, error, isVerified, isCreated } = useSelector(selectVerificationCode)
  const { reservation } = useSelector(selectReservation)

  const topics = [ServiceDetails, AccountDetails, VerifyCode.Component, PostSubmission]
  const Topic = topics[activeIndex]
  const onSubmit = (data) => {
    const updatedForm = { ...form, ...data }
    console.log('submitting ...', activeIndex, updatedForm)
    if (topics[activeIndex + 1] === VerifyCode.Component) {
      dispatch(VerificationCodeActions.createRequest({
        mobileNumber: updatedForm.mobileNumber,
        target: 'Signup'
      }))
    }
    else if (activeIndex < topics.length - 1) {
      setActiveIndex(activeIndex + 1)
      window.scrollTo({ top: 0 }) // to handle small screens
    }
    setForm(updatedForm)
  }


  useEffect(() => {
    const getPricingTag = () => {
      switch(providerType) {
        case ProviderTypes.DOCTOR.value:
          return form.speciality
        case ProviderTypes.NURSE.value:
        case ProviderTypes.HOME_CARE_SPECIALIST.value:
          return form.serviceType
        case ProviderTypes.MEDICAL_RAYS_ANALYSIS.value:
          return form.medicalCenterServiceId
        default:
          return null
      }
    }

    if (isVerified) {
      dispatch(ReservationActions.getPricingRequest({
        areaId: form.areaId,
        providerType,
        tag: getPricingTag()
      }))
      dispatch(ReservationActions.createGuestReservationRequest(form))
    }
  }, [isVerified, dispatch, providerType, form])

  useEffect(() => {
    if (reservation) {
      setActiveIndex(prevActiveIndex => prevActiveIndex + 1)
    }
  }, [reservation, setActiveIndex])

  useEffect(() => {
    if (isCreated) {
      setActiveIndex(index => index + 1)
    }
  }, [isCreated])

  useEffect(() => () => {
    dispatch(VerificationCodeActions.resetVerificationCode())
  }, [dispatch])

  const isVerifyingCode = isCreated && (verificationStatus === 'loading')

  return (
    <Topic
      providerType={providerType}
      serviceType={serviceType}
      onSubmit={onSubmit}
      history={history}
      dispatch={dispatch}
      mobileNumber={form.mobileNumber}
      isLoading={isVerifyingCode}
      form={form}
      failed={error}
      target='Signup'
    />
  )
}

export default withRouter(GuestReservationScreen)

import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Spinner from 'react-bootstrap/Spinner'

import { Formik } from 'formik'
import * as yup from 'yup'

import Screen from '../../components/layout/Screen'
import Header from '../../components/layout/Header'
import Main from '../../components/layout/Main'

import PasswordInput from '../../components/basic/PasswordInput'

import { AuthActions } from '../../redux/reducers/authSlice'
import { PASSWORD_REGEX } from '../../components/common'

const schema = yup.object().shape({
  password: yup.string().matches(PASSWORD_REGEX, 'كلمة المرور ضعيفة').required('من فضلك قم بادخال المعلومات كاملة'),
  passwordAgain: yup.string().required('من فضلك قم بادخال المعلومات كاملة').oneOf([yup.ref('password')], 'كلمة المرور غير متطابقة')
})

export const Hash = 'form'

export const Component = ({ dispatch, isLoading }) => {

  const handleSubmit = ({ password }) => {
    dispatch(AuthActions.resetPasswordRequest({ password }))
  }

  return (
    <Screen>
      <Header
        middleItem={<b>إعادة تعيين كلمة المرور</b>}
      />
      <Main>
      <Formik
          validationSchema={schema}
          onSubmit={handleSubmit}
          initialValues={{}}
          validateOnChange={false}
        >
          {({
            handleSubmit,
            handleChange,
            errors
          }) => (
            <Form noValidate onSubmit={handleSubmit} className='bottom-button-form'>
              <Form.Group>
                <Form.Label>كلمة المرور الجديدة</Form.Label>
                <PasswordInput
                  placeholder='ادخل كلمة المرور الجديدة'
                  id='password'
                  name='password'
                  onChange={handleChange}
                  isInvalid={errors.password}
                  errorMessage={errors.password}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>اعد كتابة كلمة المرور الجديدة</Form.Label>
                <PasswordInput
                  placeholder='اعد كتابة كلمة المرور الجديدة'
                  id='passwordAgain'
                  name='passwordAgain'
                  onChange={handleChange}
                  isInvalid={errors.passwordAgain}
                  errorMessage={errors.passwordAgain}
                />
              </Form.Group>
              <Button type='submit'>
                {isLoading ? <Spinner animation="border" size='sm' /> : <span>إعادة تعيين كلمة المرور</span>}
              </Button>
            </Form>
          )}
        </Formik>
      </Main>
    </Screen>
  )
}

import { PersonFill } from 'react-bootstrap-icons'
import { useRef, useState, useEffect } from 'react'
import Image from 'react-bootstrap/Image'

import {ReactComponent as AddIcon} from '../svg/add.svg'

import Svg from './basic/Svg'
import Attachments from './basic/Attachments'

const ProfilePictureUploader = ({ initialValue, onChange }) => {
  const attacherRef = useRef()
  const [url, setUrl] = useState(initialValue)

  const handleClick = () => {
    attacherRef.current.firstElementChild.firstElementChild.click()
  }

  const handleChange = (value) => {
    setUrl(value?.url)
    onChange(value)
  }

  useEffect(() => setUrl(v => v || initialValue), [initialValue])

  return (
    <div>
      <div className='profile-picture-uploader'>
        {
          url
          ? <Image src={url} alt='profilePicture' />
          : <PersonFill width='120px' height='120px' color='#cce6d8' />
        }
      </div>
      <div className='add-wrapper' onClick={handleClick}>
        <Svg as={AddIcon} iconn />
      </div>
      <div hidden ref={attacherRef}>
        <Attachments single onChange={handleChange} policy={Attachments.Policies.PUBLIC} target='profile-picture'/>
      </div>
    </div>
  )
}

export default ProfilePictureUploader

import Svg from './basic/Svg'

import {ReactComponent as StarIcon} from '../svg/star.svg'

const Rating = () => (
  <div className='rating'>
    <Svg as={StarIcon} iconn />
    <div>25</div>
  </div>
)

export default Rating

import { call, put, takeLatest, all } from 'redux-saga/effects'

import * as Api from '../api/lookupsApi'
import { LookupsActions } from '../reducers/lookupsSlice';

function* listCities() {
  try {
    const response = yield call(Api.listCitiesApi)
    yield put(LookupsActions.listCitiesSuccess({ cities: response.data }))
  } catch (error) {
    yield put(LookupsActions.listCitiesFailure(error.response?.data?.error || error))
  }
}

function* listAreas() {
  try {
    const response = yield call(Api.listAreasApi)
    yield put(LookupsActions.listAreasSuccess({ areas: response.data }))
  } catch (error) {
    yield put(LookupsActions.listAreasFailure(error.response?.data?.error || error))
  }
}

function* listDoctorSpecialities() {
  try {
    const response = yield call(Api.listDoctorSpecialitiesApi)
    yield put(LookupsActions.listDoctorSpecialitiesSuccess({ doctorSpecialities: response.data }))
  } catch (error) {
    yield put(LookupsActions.listDoctorSpecialitiesFailure(error.response?.data?.error || error))
  }
}

function* listNurseTasks() {
  try {
    const response = yield call(Api.listNurseTasksApi)
    yield put(LookupsActions.listNurseTasksSuccess({ nurseTasks: response.data }))
  } catch (error) {
    yield put(LookupsActions.listNurseTasksFailure(error.response?.data?.error || error))
  }
}

function* listHomeCareSpecialistTasks() {
  try {
    const response = yield call(Api.listHomeCareSpecialistTasksApi)
    yield put(LookupsActions.listHomeCareSpecialistTasksSuccess({ homeCareSpecialistTasks: response.data }))
  } catch (error) {
    yield put(LookupsActions.listHomeCareSpecialistTasksFailure(error.response?.data?.error || error))
  }
}

function* listMedicalCenterServices(action) {
  try {
    const response = yield call(Api.listMedicalCenterServicesApi, action.payload || {})
    yield put(LookupsActions.listMedicalCenterServicesSuccess({ medicalCenterServices: response.data }))
  } catch (error) {
    yield put(LookupsActions.listMedicalCenterServicesFailure(error.response?.data?.error || error))
  }
}

function* listAreasByCities(action) {
  try {
    const response = yield call(Api.listAreasByCitiesApi, action.payload)
    yield put(LookupsActions.listAreasByCitiesSuccess({ areas: response.data }))
  } catch (error) {
    yield put(LookupsActions.listAreasByCitiesFailure(error.response?.data?.error || error))
  }
}

function* listCitiesAndAreas(action) {
  const { fetchCities } = action.payload
  const actions = [listCities]
  if (fetchCities) {
    actions.push(listAreasByCities)
  }

  yield all(actions.map(f => f(action)))
}

function* lookupSaga() {
  yield takeLatest(LookupsActions.listCitiesRequest, listCities)
  yield takeLatest(LookupsActions.listAreasRequest, listAreas)
  yield takeLatest(LookupsActions.listDoctorSpecialitiesRequest, listDoctorSpecialities)
  yield takeLatest(LookupsActions.listNurseTasksRequest, listNurseTasks)
  yield takeLatest(LookupsActions.listHomeCareSpecialistTasksRequest, listHomeCareSpecialistTasks)
  yield takeLatest(LookupsActions.listMedicalCenterServicesRequest, listMedicalCenterServices)
  yield takeLatest(LookupsActions.listAreasByCitiesRequest, listAreasByCities)
  yield takeLatest(LookupsActions.listCitiesAndAreasRequest, listCitiesAndAreas)
}

export default lookupSaga;
import { withRouter } from 'react-router-dom'
import { Formik } from 'formik'
import * as yup from 'yup'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'

import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import _ from 'lodash'

import BackButton from '../components/basic/BackButton'
import Header from '../components/layout/Header'
import Main from '../components/layout/Main'
import Screen from '../components/layout/Screen'
import { Genders, MOBILE_NUMBER_REGEX } from '../components/common'
import Choice from '../components/basic/Choice'
import { RequesterActions, selectRequesterSlice } from '../redux/reducers/requesterSlice'

import * as VerifyCode from './topics/VerifyCode'
import { selectVerificationCode, VerificationCodeActions } from '../redux/reducers/verificationCodeSlice'
import TaggedFormLabel from '../components/basic/TaggedFormLabel'

const schema = yup.object().shape({
  firstName: yup.string().required('من فضلك قم بادخال المعلومات كاملة'),
  lastName: yup.string().required('من فضلك قم بادخال المعلومات كاملة'),
  gender: yup.string().oneOf(Genders.getValues()),
  email: yup.string().email('من فضلك أدخل بريد إلكتروني صحيح').nullable(),
  mobileNumber: yup.string().matches(MOBILE_NUMBER_REGEX, 'من فضلك أدخل رقم هاتف صحيح').required('من فضلك قم بادخال المعلومات كاملة'),
  whatsAppNumber: yup.string().nullable()
})

const EditProfileForm = ({ onSubmit, details, requesterId, errorField, isLoading }) => {
  return (
    <Screen>
      <Header
        middleItem={<b>تعديل معلوماتي الشخصية</b>}
        rightItem={<BackButton />}
      />
      <Main className='home-main'>
        <Formik
          validationSchema={schema}
          onSubmit={onSubmit}
          initialValues={{
            firstName: details?.firstName,
            lastName: details?.lastName,
            gender: details?.gender,
            email: details?.email,
            mobileNumber: details?.mobileNumber,
            whatsAppNumber: details?.whatsAppNumber,
            requesterId
          }}
          validateOnChange={false}
          enableReinitialize={true}
        >
          {({
            values,
            errors,
            handleChange,
            handleSubmit,
            setFieldValue
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Form.Group>
                <Form.Label>الاسم الأول</Form.Label>
                <Form.Control
                  name='firstName'
                  value={values.firstName}
                  isInvalid={errors.firstName}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.firstName}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group>
                <Form.Label>الاسم الثاني</Form.Label>
                <Form.Control
                  name='lastName'
                  value={values.lastName}
                  isInvalid={errors.lastName}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.lastName}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group>
                <Form.Label>الجنس</Form.Label>
                <Choice
                  name='gender'
                  options={Genders.getTexts()}
                  value={Genders.getTextByValue(values.gender)}
                  onChange={value => setFieldValue('gender', Genders.getValueByText(value))}
                />
              </Form.Group>

              <Form.Group>
                <TaggedFormLabel>البريد الإلكتروني</TaggedFormLabel>
                <Form.Control
                  name='email'
                  value={values.email}
                  isInvalid={errors.email || errorField === 'email'}
                  onChange={handleChange}
                  placeholder='عنوان البريد الإلكتروني'
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.email || 'هذا البريد الإلكتروني موجود بالفعل'}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group>
                <Form.Label>رقم الهاتف</Form.Label>
                <Form.Control
                  name='mobileNumber'
                  value={values.mobileNumber}
                  isInvalid={errors.mobileNumber || errorField === 'mobileNumber'}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.mobileNumber || 'هذا الرقم موجود بالفعل'}
                </Form.Control.Feedback>
                <Form.Check
                  label='هذا الرقم لديه حساب واتساب'
                  checked={!!values.whatsAppNumber}
                  onChange={e => e.target.checked
                    ? setFieldValue('whatsAppNumber', values.mobileNumber)
                    : setFieldValue('whatsAppNumber', null)
                  }
                />
              </Form.Group>

              <Button type='submit' disabled={isLoading}>
                {isLoading ? <Spinner animation="border" size='sm' /> : <span>تعديل</span>}
              </Button>
            </Form>
          )}
        </Formik>
      </Main>
    </Screen>
  )
}

const EditProfileScreen = ({ requesterId, location }) => {
  const target = 'ModifyMobileNumber'
  const dispatch = useDispatch()
  const history = useHistory()

  const [form, setForm] = useState()
  const [activeTopicIndex, setActiveTopicIndex] = useState(0)
  const [profileUpdates, setProfileUpdates] = useState()
  const [done, setDone] = useState(false)

  const topics = [EditProfileForm, VerifyCode.Component]
  const Topic = topics[activeTopicIndex]

  const {
    details,
    error,
    status: requesterStatus,
  } = useSelector(selectRequesterSlice)

  const {
    isCreated,
    isVerified,
    error: verificationCodeError,
    status: verificationCodeStatus
  } = useSelector(selectVerificationCode)

  const errorField = (schema.fields[error?.split?.(':')[0]] && error?.split(':')[0]) || (verificationCodeError && 'mobileNumber')

  const onSubmit = form => {
    if (form.mobileNumber !== details?.mobileNumber) {
      dispatch(VerificationCodeActions.createRequest({
        mobileNumber: form.mobileNumber,
        target
      }))
      setForm(form)
    }
    else {
      setProfileUpdates(form)
      dispatch(RequesterActions.updateRequesterRequest(form))
    }
  }

  useEffect(() => {
    if (isVerified) {
      setProfileUpdates(form)
      dispatch(RequesterActions.updateRequesterRequest(form))
      dispatch(VerificationCodeActions.resetVerificationCode())
      setActiveTopicIndex(0)
    }
    else if (isCreated) {
      setActiveTopicIndex(index => index + 1)
      history.push({ hash: '#verify' })
    }

  }, [isCreated, isVerified, dispatch, form, history])

  useEffect(() => {
    setProfileUpdates(profileUpdates => {
      if (profileUpdates) {
        const updatedProfile = _.omitBy(details, (v, k) => !_.keys(profileUpdates).includes(k))
        updatedProfile.requesterId = requesterId
        if (_.isEqual(profileUpdates, updatedProfile)) {
          setDone(true)
        }
      }
    })
  }, [details, requesterId])

  useEffect(() => {
    if (done) {
      history.goBack()
    }
  }, [done, history])

  useEffect(() => {
    dispatch(RequesterActions.getRequesterDetailsRequest({ requesterId }))
  }, [dispatch, requesterId])

  useEffect(() => {
    switch(location.hash) {
      case '#verify':
        setActiveTopicIndex(1)
        break
      default:
        setActiveTopicIndex(0)
    }
  }, [location.hash])

  const isVerifying = isCreated && verificationCodeStatus === 'loading'
  const isCreating = !isCreated && verificationCodeStatus === 'loading'
  const isSubmitting = requesterStatus === 'loading'

  const isLoading = isCreating || isVerifying || isSubmitting

  return (
    <Topic
      details={details}
      errorField={errorField}
      onSubmit={onSubmit}
      requesterId={requesterId}
      target={target}
      mobileNumber={form?.mobileNumber}
      dispatch={dispatch}
      failed={verificationCodeError}
      isLoading={isLoading}
    />
  )
}

export default withRouter(EditProfileScreen)

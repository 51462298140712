import axios from 'axios'
import { getUserType } from './util'

export const loginApi = (data) => {
  return axios.post(`${process.env.REACT_APP_BE_URL}/login`, {
    ...data,
    userType: getUserType()
  })
}

export const signUpApi = (data) => {
  return axios.post(`${process.env.REACT_APP_BE_URL}/requesters`, data)
}

export const resetPasswordApi = ({ password, verificationToken }) => {
  return axios.patch(`${process.env.REACT_APP_BE_URL}/resetPassword`, {
    password,
    userType: getUserType()
  }, {
    headers: {
      "Verification": verificationToken,
      "Verification-Authority": process.env.REACT_APP_VERIFICATION_AUTHORITY
    }
  })
}

export const changePasswordApi = (requesterId, data) => {
  return axios.patch(
    `${process.env.REACT_APP_BE_URL}/users/${requesterId}/password`,
    data
  )
}

export const createGuestSessionApi = (data) => {
  return axios.post(`${process.env.REACT_APP_BE_URL}/guests`, data)
}

export const getGuestSessionApi = ({ id }) => {
  return axios.get(`${process.env.REACT_APP_BE_URL}/guests/${id}`)
}

export const refreshTokenApi = () => {
  const refreshToken = localStorage.getItem('refreshToken')
  return axios.get(`${process.env.REACT_APP_BE_URL}/login`, { params: { token: refreshToken } })
}

export const setTokenApi = ({ userId, token, accessToken }) => {
  const config = {}
  if (accessToken) {
    config.headers = { Authorization: `Bearer ${accessToken}`}
  }
  return axios.patch(
    `${process.env.REACT_APP_BE_URL}/users/${userId}/fcmToken`,
    { fcmToken: token },
    config
  )
}

export const checkExistingApi = ({ target, value, ignore, userType }) => {
  return axios.get(`${process.env.REACT_APP_BE_URL}/checkExisting`, {
    params: { [target]: value, ignore, userType }
  })
}

import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga'

import auth from '../reducers/authSlice'
import verificationCode from '../reducers/verificationCodeSlice';
import language from '../reducers/languageSlice';
import requester from '../reducers/requesterSlice';
import lookups from '../reducers/lookupsSlice';
import reservation from '../reducers/reservationSlice';
import cache from '../reducers/cacheSlice';
import notifications from '../reducers/notificationsSlice';
import attachments from '../reducers/attachmentsSlice';
import dashboard from '../reducers/dashboardSlice'
import provider from '../reducers/providerSlice'
import visit from '../reducers/visitSlice'
import providerAssignment from '../reducers/providerAssignmentSlice'

import saga from '../saga'

const sagaMiddleware = createSagaMiddleware()

export const store = configureStore({
  reducer: {
    auth,
    verificationCode,
    language,
    requester,
    lookups,
    reservation,
    cache,
    notifications,
    attachments,
    dashboard,
    provider,
    visit,
    providerAssignment
  },
  middleware: [sagaMiddleware]
});

sagaMiddleware.run(saga)

import axios from 'axios';
import jwtDecode from 'jwt-decode';
import _ from 'lodash'
import qs from 'query-string'

const useTokenInterceptor = (history, signOut) => {
  console.log('adding refresh token interceptor ...')

  axios.interceptors.request.use(config => {
    config.paramsSerializer = params => {
      return qs.stringify(params, { arrayFormat: 'repeat' })
    }

    if (config.url !== `${process.env.REACT_APP_BE_URL}/login`) {
      const accessToken = localStorage.getItem('accessToken')
      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`
      }
    }
    config.headers['Access-Control-Allow-Origin'] = '*'
    return config
  })

  axios.interceptors.response.use(_.identity, function (error) {
    const { response, config } = error
    const { location } = history
    if (config?.url !== `${process.env.REACT_APP_BE_URL}/login`) {
      if (response?.status === 401) {
        const token = localStorage.getItem('refreshToken')
        const exp = (token && jwtDecode(token))?.exp

        if (exp > (Date.now() / 1000)) {
          return axios.get(`${process.env.REACT_APP_BE_URL}/login`, { params: { token } })
            .then(refresh => {
              localStorage.setItem('accessToken', refresh.data.accessToken)
              localStorage.setItem('refreshToken', refresh.data.refreshToken)
              config.headers.Authorization = `Bearer ${refresh.data.accessToken}`
              return axios(config)
            })
            .catch (() => Promise.reject(error))
        }
        else {
          if (location.pathname !== '/signIn') {
            signOut()
            history.push('/signIn', { redirect: location })
            return Promise.resolve();
          }
        }
      }
      else {
        return Promise.reject(error)
      }
    }
    // Do something with response error
    return Promise.reject(error);
  })
}

export default useTokenInterceptor;

import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import format from 'date-fns/format'
import { arSA } from 'date-fns/locale'
import addDays from 'date-fns/addDays'
import startOfWeek from 'date-fns/startOfWeek'

import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'

import Editable from '../../../../components/basic/Editable'
import Divider from '../../../../components/basic/Divider'

import PersonCard from '../../../../components/PersonCard'

import PersonalInfo from './PersonalInfo'
import SpecialityInfo from './SpecialityInfo'
import CitiesAreasAndFees from './CitiesAreasAndFees'
import WeekdaysAndShifts from './WeekdaysAndShifts'
import ScientificCertificates from './ScientificCertificate'
import Experiences from './Experiences'
import ClinicData from './ClinicData'

import { LookupsActions, selectLookups } from '../../../../redux/reducers/lookupsSlice'

import { DoctorDegrees, Genders, ScientificDegrees } from '../../../../components/common'

const Summary = ({ onSubmit, data, dispatch, isLoading }) => {
  const [form, setForm] = useState(data)
  const { cities, areas, doctorSpecialities } = useSelector(selectLookups)

  const handleEdit = close => update => {
    setForm({ ...form, ...update })
    close()
  }

  const handleConfirm = () => {
    onSubmit(form)
  }

  useEffect(() => {
    dispatch(LookupsActions.listCitiesRequest())
    dispatch(LookupsActions.listAreasRequest())
  }, [dispatch])

  return (
    <Form noValidate onSubmit={onSubmit}>
      <Form.Group>
        <Form.Label>البيانات التي ستظهر لمتلقي الخدمة</Form.Label>
        <PersonCard>
          <PersonCard.Avatar src={form.profilePicture?.url} />
          <PersonCard.Title>{[form.firstName, form.middleName, form.lastName].join(' ')}</PersonCard.Title>
          <PersonCard.Content>{DoctorDegrees.getTextByValue(form.degree)}</PersonCard.Content>
        </PersonCard>
      </Form.Group>
      <Divider />
      <Form.Group>
        <Form.Label>بيانات شخصية</Form.Label>
        <Editable
          editTitle='تعديل البيانات الشخصية'
          value={{
            'الجنس': Genders.getTextByValue(form.gender),
            'تاريخ الميلاد': format(form.birthDate, 'dd/MM/yyyy'),
            'البريد الإلكتروني': form.email,
            'الهوية الشخصية': (
              <>
                <img src={form.nationalIds[0].file} alt='' className='attachment-thumbnail'/>
                <img src={form.nationalIds[1].file} alt='' className='attachment-thumbnail'/>
              </>
            )
          }}
        >
          {close => <PersonalInfo onSubmit={handleEdit(close)} data={form} edit />}
        </Editable>
      </Form.Group>
      <Form.Group>
        <Form.Label>بيانات التخصص والتوثيق</Form.Label>
        <Editable
          editTitle='تعديل بيانات التخصص والتوثيق'
          value={{
            'الدرجة المهنية': DoctorDegrees.getTextByValue(form.degree),
            'صورة كارنية النقابة': <img src={form.syndicateId.file} alt='' className='attachment-thumbnail'/>,
            'التخصص العام': doctorSpecialities[form.specialityId],
            'التخصص الدقيق': form.detailedSpeciality,
            'الوصف': form.description
          }}
        >
          {close => <SpecialityInfo onSubmit={handleEdit(close)} data={form} edit dispatch={dispatch} />}
        </Editable>
      </Form.Group>
      <Form.Group>
        <Form.Label>محافظات العمل</Form.Label>
        <Editable
          editTitle='تعديل محافظات العمل'
          value={{
            'المحافظة': form.cityIds.map(id => cities[id]).join(', '),
            'المراكز / الأحياء': form.areaIds.map(id => areas[id]).join(', '),
            'صافي الرسوم  من الزيازة المنزلية': `${form.homeVisitFees} جنيه مصري`
          }}
        >
          {close => <CitiesAreasAndFees onSubmit={handleEdit(close)} data={form} edit />}
        </Editable>
      </Form.Group>
      <Form.Group>
        <Form.Label>مواعيد العمل</Form.Label>
        <Editable
          editTitle='تعديل مواعيد العمل'
          value={{
            'الفتره الصباحية': form.morningShift ? `${form.morningShift.from} - ${form.morningShift.to}` : 'لم تحدد',
            'الفتره المسائية': form.eveningShift ? `${form.eveningShift.from} - ${form.eveningShift.to}` : 'لم تحدد',
            'الايام التي تفضل ان تقوم بزيارات منزليه فيها': form.weekDays.map(day => format(addDays(startOfWeek(new Date()), day), 'EEEEEE', { locale: arSA })).join(', '),
          }}
        >
          {close => <WeekdaysAndShifts onSubmit={handleEdit(close)} data={form} edit />}
        </Editable>
      </Form.Group>
      {!!form.scientificCertificates.length && (
        <Form.Group>
          <Form.Label>الدراسات العلمية</Form.Label>
          <Editable
            editTitle='تعديل الدراسات العلمية'
            value={form.scientificCertificates.map((scientificCertificate, index) => (
              <div key={index}>
                <b>{ScientificDegrees.getTextByValue(scientificCertificate.scientificDegree)} في {scientificCertificate.scientificSpeciality}</b>
                <div>تاريخ الحصول {format(scientificCertificate.grantedOn, 'MMMM yyyy', { locale: arSA })}</div>
              </div>
            )).reduce((a, b) => <>{a}<Divider />{b}</>)}
          >
            {close => <ScientificCertificates onSubmit={handleEdit(close)} data={form} edit />}
          </Editable>
        </Form.Group>
      )}

      {!!form.experiences.length && (
        <Form.Group>
          <Form.Label>الخبرات</Form.Label>
          <Editable
            editTitle='تعديل الخبرات'
            value={
              <div>
                {
                  form.experiences.map((experience, index) => (
                    <div key={index}>
                      <b>{experience.title}</b>
                      <div>{experience.employer}</div>
                      <div>{experience.yearsOfExperience}</div>
                    </div>
                  )).reduce((a, b) => <>{a}<Divider />{b}</>)
                }
              </div>
            }
          >
            {close => <Experiences onSubmit={handleEdit(close)} data={form} edit />}
          </Editable>
        </Form.Group>
      )}

      {!!form.clinic && (
        <Form.Group>
          <Form.Label>بيانات العيادة</Form.Label>
          <Editable
            editTitle='تعديل بيانات العيادة'
            value={{
              'رقم تليفون حجز العيادة': form.clinic?.mobileNumber,
              'المحافظة': cities[form.clinic?.cityId],
              'العنوان بالتفصيل': form.clinic?.address
            }}
          >
            {close => <ClinicData onSubmit={handleEdit(close)} data={form} edit />}
          </Editable>
        </Form.Group>
      )}
      <Button onClick={handleConfirm}>
        {isLoading ? <Spinner animation='border' size='sm' /> : <span>تأكيد</span>}
      </Button>
    </Form>
  )
}

Summary.title = <b>ملخص عام</b>

export default Summary

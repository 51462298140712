import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  action: null,
  error: null,
  providerAssignment: null
}

export const providerAssignmentSlice = createSlice({
  name: 'providerAssignment',
  initialState,
  reducers: {
    acceptRequest: (state, action) => {
      state.isLoading = true
      state.action = action.type
      state.error = null
    },
    acceptSuccess: (state, action) => {
      state.isLoading = false
      state.action = null
      state.providerAssignment = action.payload.providerAssignment
    },
    acceptFailure: (state, action) => {
      state.isLoading = false
      state.action = null
      state.error = action.payload
    },


    rejectRequest: (state, action) => {
      state.isLoading = true
      state.action = action.type
      state.error = null
    },
    rejectSuccess: (state, action) => {
      state.isLoading = false
      state.action = null
      state.providerAssignment = action.payload.providerAssignment
    },
    rejectFailure: (state, action) => {
      state.isLoading = false
      state.action = null
      state.error = action.payload
    },
  }
});

export const selectProviderAssignment = (state) => state.providerAssignment

export const ProviderAssignmentActions = providerAssignmentSlice.actions

export default providerAssignmentSlice.reducer;

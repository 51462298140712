import { Directions, withProps } from '../common'

const Screen = ({ children, className='', direction=Directions.RTL }) => {
  const classes = [direction]
  classes.push(...className.split(' '))
  children.length === 2 ? classes.push('header-main-screen') : classes.push('header-main-footer-screen')

  return (
    <div className={classes.join(' ')}>{withProps(children, { direction })}</div>
  )
}

export default Screen

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  error: null,
  provider: null
}

export const providerSlice = createSlice({
  name: 'provider',
  initialState,
  reducers: {
    signUpRequest: state => {
      state.isLoading = true
      state.error = null
    },
    signUpSuccess: (state, action) => {
      state.isLoading = false
      state.provider = action.payload.provider
    },
    signUpFailure: (state, action) => {
      state.isLoading = false
      state.error = action.payload
    },

    resetError: state => {
      state.error = null
    }
  }
});

export const selectProvider = (state) => state.provider

export const ProviderActions = providerSlice.actions

export default providerSlice.reducer;

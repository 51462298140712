import { Formik } from 'formik'
import * as yup from 'yup'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

import Choice from '../../../../components/basic/Choice'
import DatePicker from '../../../../components/basic/DatePicker'
import Attachments from '../../../../components/basic/Attachments'

import ProfilePictureUploader from '../../../../components/ProfilePictureUploader'
import Info from '../../../../components/Info'

import { Genders, attachmentSchema, multipleAtachmentSchema } from '../../../../components/common'
import TaggedFormLabel from '../../../../components/basic/TaggedFormLabel'

import * as AuthApi from '../../../../redux/api/authApi'

const PersonalInfo = ({ onSubmit, data, edit }) => {
  const schema = yup.object().shape({
    gender: yup.string().oneOf(Genders.getValues()),
    email: yup.string().nullable().email('من فضلك أدخل بريد الإلكتروني صحيح').test('unique-email', 'البريد الالكتروني موجود بالفعل', async value => {
      if (value) {
        const response = await AuthApi.checkExistingApi({ target: 'email', userType: 'ServiceProvider', value })
        return !response.data
      }
      return true
    }),
    profilePicture: attachmentSchema.nullable().default(null),
    nationalIds: multipleAtachmentSchema.min(2, 'من فضلك قم برفع صورتين من هويتك').required('من فضلك قم بادخال المعلومات كامله'),
    birthDate: yup.date().required('من فضلك قم بادخال المعلومات كاملة')
  })

  return (
    <Formik
      validationSchema={schema}
      initialValues={{
        gender: data.gender,
        email: data.email,
        profilePicture: data.profilePicture ? { ...data.profilePicture } : data.profilePicture,
        nationalIds: data.nationalIds ? { ...data.nationalIds } : data.nationalIds,
        birthDate: data.birthDate
      }}
      validateOnChange={false}
      onSubmit={onSubmit}
      enableReinitialize={true}
    >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        values,
        errors
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          {console.log('PersonalInfo', { errors })}
          {console.log('values', { values })}
          <Info>برجاء إرفاق صورة حديثة تؤكد شخصية الطبيب</Info>
          <Form.Group>
            <ProfilePictureUploader
              initialValue={values.profilePicture?.url}
              onChange={value => setFieldValue('profilePicture', value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>الجنس</Form.Label>
            <Choice
              options={Genders.getTexts()}
              value={Genders.getTextByValue(values.gender)}
              onChange={value => setFieldValue('gender', Genders.getValueByText(value))}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>تاريخ الميلاد</Form.Label>
            <DatePicker
              value={values.birthDate}
              onChange={date => setFieldValue('birthDate', date)}
              isInvalid={errors.birthDate}
              errorMessage={errors.birthDate}
              birthDate
            />
          </Form.Group>
          <Form.Group>
            <TaggedFormLabel tag='لن نقوم بعرضها علي متلقي الخدمة'>
              الهوية الشخصية
            </TaggedFormLabel>
            <Attachments
              single={false}
              initialValue={values.nationalIds}
              onChange={value => setFieldValue('nationalIds', value)}
              isInvalid={errors.nationalIds}
              errorMessage='من فضلك قم برفع صورتين من هويتك'
            />
          </Form.Group>
          <Form.Group>
            <TaggedFormLabel>البريد الإلكتروني</TaggedFormLabel>
            <Form.Control
              name='email'
              value={values.email}
              isInvalid={errors.email}
              onChange={handleChange}
              placeholder='البريد الإلكتروني'
            />
            <Form.Control.Feedback type='invalid'>
              {errors.email}
            </Form.Control.Feedback>
          </Form.Group>

          <Button type='submit'>{edit ? 'تأكيد' : 'استمرار'}</Button>
        </Form>
      )}
    </Formik>
  )
}

PersonalInfo.title = <b>بيانات شخصية</b>

export default PersonalInfo

import { createSlice } from '@reduxjs/toolkit';

const Status = Object.freeze({
  IDLE: 'idle',
  LOADING: 'loading'
})

const initialState = {
  patients: [],
  details: {},
  patient: null,
  providers: [],
  status: Status.IDLE,
  error: null
}

export const requesterSlice = createSlice({
  name: 'requester',
  initialState,
  reducers: {
    listRequesterPatientsRequest: state => {
      state.status = Status.LOADING
      state.error = null
    },
    listRequesterPatientsSuccess: (state, action) => {
      state.status = Status.IDLE
      state.patients = action.payload.patients
    },
    listRequesterPatientsFailure: (state, action) => {
      state.status = Status.IDLE
      state.error = action.payload
    },

    createRequesterPatientRequest: state => {
      state.status = Status.LOADING
      state.error = null
    },
    createRequesterPatientSuccess: (state, action) => {
      state.patients = [...state.patients, action.payload.patient]
      state.status = Status.IDLE
    },
    createRequesterPatientFailure: (state, action) => {
      state.status = Status.IDLE
      state.error = action.payload
    },

    updatePatientRequest: state => {
      state.status = Status.LOADING
      state.error = null
    },
    updatePatientSuccess: (state, action) => {
      state.status = Status.IDLE
      const patients = [...state.patients]
      const index = patients.findIndex(patient => patient.id = action.payload.patient.id)
      patients.splice(index, 1, action.payload.patient)
      state.patients = patients
      state.patient = action.payload.patient
    },
    updatePatientFailure: (state, action) => {
      state.status = Status.IDLE
      state.error = action.payload
    },

    getRequesterDetailsRequest: (state) => {
      state.status = Status.LOADING
      state.error = null
    },
    getRequesterDetailsSuccess: (state, action) => {
      state.status = Status.IDLE
      state.details = action.payload.requester
    },
    getRequesterDetailsFailure: (state, action) => {
      state.status = Status.IDLE
      state.error = action.payload
    },

    updateRequesterRequest: state => {
      state.status = Status.LOADING
      state.error = null
    },
    updateRequesterSuccess: (state, action) => {
      state.status = Status.IDLE
      state.details = action.payload.requester
    },
    updateRequesterFailure: (state, action) => {
      state.status = Status.IDLE
      state.error = action.payload
    },

    getRequesterPatientRequest: state => {
      state.status = Status.LOADING
      state.error = null
    },
    getRequesterPatientSuccess: (state, action) => {
      if (state.patients.length) {
        state.patients = state.patients.map(patient => {
          if (patient.id === action.payload.patient.id) {
            return action.payload.patient
          }
          return patient
        })
      }
      else {
        state.patients = [action.payload.patient]
      }
      state.status = Status.IDLE
    },
    getRequesterPatientFailure: (state, action) => {
      state.status = Status.IDLE
      state.error = action.payload
    },

    getPatientDetailsRequest: state => {
      state.status = Status.LOADING
      state.error = null
    },
    getPatientDetailsSuccess: (state, action) => {
      state.patient = action.payload.patient
      state.status = Status.IDLE
    },
    getPatientDetailsFailure: (state, action) => {
      state.status = Status.IDLE
      state.error = action.payload
    },

    listProvidersRequest: state => {
      state.status = Status.LOADING
      state.error = null
    },
    listProvidersSuccess: (state, action) => {
      state.status = Status.IDLE
      state.providers = action.payload.providers
    },
    listProvidersFailure: (state, action) => {
      state.status = Status.IDLE
      state.error = action.payload
    },

    reset: state => {
      state.patients = []
      state.details = {}
      state.patient = null
      state.providers = []
      state.status = Status.IDLE
      state.error = null
    }
  }
});

export const selectRequesterSlice = (state) => state.requester

export const RequesterActions = requesterSlice.actions

export default requesterSlice.reducer;

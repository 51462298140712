import Svg from './basic/Svg'
import {ReactComponent as InfoBlueIcon} from '../svg/info_blue.svg'

const Info = ({ children }) => {
  return (
    <div className='info'>
      <Svg as={InfoBlueIcon} iconn />
      {children}
    </div>
  )
}

export default Info

import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Button from 'react-bootstrap/Button'

import Link from './basic/Link'
import BottomSheet from './basic/BottomSheet'

import { ReservationActions, selectReservation } from '../redux/reducers/reservationSlice'

const TermsAndConditions = ({ agreementType, onAccept }) => {
  const dispatch = useDispatch()
  const [show, setShow] = useState(false)

  const { agreement } = useSelector(selectReservation)

  const toggleShow = () => setShow(!show)

  useEffect(() => {
    dispatch(ReservationActions.getAgreementRequest({ agreementType }))
  }, [dispatch, agreementType])

  return (
    <span onClick={toggleShow}>
      <Link to='#' >الشروط والأحكام</Link>
      <BottomSheet
        close={toggleShow}
        show={show}
        title='الشروط والأحكام'
      >
        <form>{agreement?.details}</form>
        {onAccept && (
          <>
            <br />
            <Button onClick={() => {onAccept(); toggleShow()}}>أوافق على الشروط والأحكام</Button>
          </>
        )}
      </BottomSheet>
    </span>
  )
}

export default TermsAndConditions

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  language: 'ar',
  direction: 'rtl'
}

export const langaugeSlice = createSlice({
  name: 'language',
  initialState
});

export const selectLanguage = (state) => state.language


export default langaugeSlice.reducer;

import { useState, useEffect, forwardRef, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Formik } from 'formik'
import * as yup from 'yup'

import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'

import { selectLookups, LookupsActions } from '../redux/reducers/lookupsSlice'
import Select from './basic/Select'
import Maps from './Maps'

export const CityAreaSubform = ({
  onCityChange,
  onAreaChange,
  values,
  errors,
  multiple,
  placeholders={city: '', area: ''},
  labels={city: 'المحافظة', area: 'المنطقة'}
 }) => {
  const dispatch = useDispatch()
  const { cities, areas } = useSelector(selectLookups)

  const [selectedCities, setSelectedCities] = useState(values.cityId ? [values.cityId] : values.cities || [])

  useEffect(() => {
    dispatch(LookupsActions.listCitiesAndAreasRequest({ cities: selectedCities.length ? selectedCities : null }))
  }, [dispatch, selectedCities])

  const citySection = useMemo(() => {
    return (
      <Form.Group>
        <Form.Label>{labels.city}</Form.Label>
        <Select
          multiple={multiple}
          name='cityId'
          label={labels.city}
          options={cities.values}
          initialValue={multiple ? selectedCities : selectedCities[0]}
          value={multiple ? values.cities : values.cityId}
          onSelect={value => {
            onCityChange(value)
            if (multiple) setSelectedCities(value)
            else setSelectedCities([value])
          }}
          isInvalid={multiple ? errors.cities : errors.cityId}
          errorMessage={multiple ? errors.cities : errors.cityId}
          placeholder={placeholders.city}
        />
      </Form.Group>
    )
  }, [cities, areas, errors])

  const areaSection = useMemo(() => {
    return (
      <Form.Group>
        <Form.Label>{labels.area}</Form.Label>
        <Select
          multiple={multiple}
          name='areaId'
          label={labels.area}
          options={areas.values}
          readOnly={!areas.values?.length}
          initialValue={multiple ? (values.areas || []) : areas[values.areaId] ? values.areaId : areas.values[0]?.value}
          value={multiple ? values.areas : values.areaId}
          onSelect={onAreaChange}
          isInvalid={!areas.values?.length || (multiple ? errors.areas : errors.areaId)}
          errorMessage={areas.values?.length ? (multiple ? errors.areas : errors.areaId) : 'لا يوجد مناطق مسجلة للمدن المختارة'}
          placeholder={placeholders.area}
        />
      </Form.Group>
    )
  }, [selectedCities, areas, errors])

  if (!cities.isLoading) {
    return (
      <>
        {citySection}
        {areaSection}
      </>
    )
  }
  else {
    return <Spinner animation="border" />
  }
}

const LocationInfoForm = forwardRef(({ onSubmit, data }, ref) => {
  const schema = yup.object().shape({
    cityId: yup.number().required('من فضلك قم بادخال المعلومات كاملة'),
    areaId: yup.number().required('من فضلك قم بادخال المعلومات كاملة'),
    address: yup.string().required('من فضلك قم بادخال المعلومات كاملة')
      .transform(v => v === '' ? undefined : v),
    latitude: yup.number(),
    longitude: yup.number()
  })

  return (
    <Formik
      validationSchema={schema}
      onSubmit={(form, ...rest) => onSubmit(schema.cast(form), ...rest)}
      initialValues={{
        cityId: data.cityId,
        areaId: data.areaId,
        address: data.address
      }}
      validateOnChange={false}
      enableReinitialize={true}
      innerRef={ref}
    >
      {({
        handleSubmit,
        handleChange,
        errors,
        setFieldValue,
        values
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <CityAreaSubform
            values={values}
            errors={errors}
            onAreaChange={value => setFieldValue('areaId', value)}
            onCityChange={value => setFieldValue('cityId', value)}
          />

          <Form.Group>
            <Form.Label>العنوان بالتفصيل</Form.Label>
            <Form.Control
              type='text'
              placeholder='العنوان بالتفصيل'
              name='address'
              onChange={handleChange}
              isInvalid={errors.address}
              value={values.address}
            />
            <Form.Control.Feedback type='invalid'>
              {errors.address}
            </Form.Control.Feedback>
          </Form.Group>

          <div className='button-group'>
            <Button type='submit'>تأكيد</Button>
            <Maps onConfirm={({ latitude, longitude }) => {
              setFieldValue('latitude', latitude)
              setFieldValue('longitude', longitude)
            }}/>
          </div>
        </Form>
      )}
    </Formik>
  )
})

export default LocationInfoForm

import { call, put, takeLatest } from 'redux-saga/effects'
import * as Api from '../api/providerApi'

import { ProviderActions } from '../reducers/providerSlice'

function* signUp(action) {
   try {
      const response = yield call(Api.signUpApi, action.payload)
      yield put(ProviderActions.signUpSuccess({ provider: response.data }))
   } catch (error) {
      yield put(ProviderActions.signUpFailure(error.response?.data?.error || error))
   }
}

export default function* providerSaga() {
  yield takeLatest(ProviderActions.signUpRequest, signUp)
}

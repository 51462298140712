import { call, put, select, takeLatest } from 'redux-saga/effects'

import * as Api from '../api/reservationApi'
import { DashboardActions, DashboardResources, selectDashboard } from '../reducers/dashboardSlice'
import { ReservationActions } from '../reducers/reservationSlice'

function* _reflectOnDashboard(reservation) {
  const { reservations } = yield select(selectDashboard)
  if (reservations.detail?.id === reservation.id) {
    yield put(DashboardActions.detailSuccess({ resource: DashboardResources.RESERVATIONS, body: reservation }))
  }
}

function* getPricing(action) {
  try {
    const response = yield call(Api.getPricingApi, action.payload)
    yield put(ReservationActions.getPricingSuccess({ pricing: response.data }))
  } catch (error) {
    yield put(ReservationActions.getPricingFailure(error.response?.data?.error || error))
  }
}

function* getAgreement(action) {
  try {
    const response = yield call(Api.getAgreementApi, action.payload)
    yield put(ReservationActions.getAgreementSuccess({ agreement: response.data }))
  } catch (error) {
    yield put(ReservationActions.getAgreementFailure(error.response?.data?.error || error))
  }
}

function* createReservation(action) {
  try {
    const response = yield call(Api.createReservationApi, action.payload)
    yield put(ReservationActions.createReservationSuccess({ reservation: response.data }))
  } catch (error) {
    yield put(ReservationActions.createReservationFailure(error.response?.data?.error || error))
  }
}

function* listReservations(action) {
  try {
    const response = yield call(Api.listReservationsApi, action.payload)
    yield put(ReservationActions.listReservationsSuccess({ reservations: response.data }))
  } catch (error) {
    yield put(ReservationActions.listReservationsFailure(error.response?.data?.error || error))
  }
}

function* acceptReservation(action) {
  try {
    const response = yield call(Api.acceptReservationApi, action.payload)
    yield put(ReservationActions.acceptReservationSuccess())
    yield _reflectOnDashboard(response.data)
  } catch (error) {
    yield put(ReservationActions.acceptReservationFailure(error.response?.data?.error || error))
  }
}

function* rejectReservation(action) {
  try {
    yield call(Api.rejectReservationApi, action.payload)
    yield put(ReservationActions.rejectReservationSuccess())
  } catch (error) {
    yield put(ReservationActions.rejectReservationFailure(error.response?.data?.error || error))
  }
}

function* getReservation(action) {
  try {
    const response = yield call(Api.getReservationApi, action.payload)
    yield put(ReservationActions.getReservationDetailsSuccess({ reservation: response.data }))
  } catch (error) {
    yield put(ReservationActions.getReservationDetailsFailure(error.response?.data?.error || error))
  }
}

function* cancelReservation(action) {
  try {
    const response = yield call(Api.cancelReservationApi, action.payload)
    const reservation = response.data
    yield put(ReservationActions.cancelReservationSuccess({ reservation }))
    // reflect change on dashboard
    const { reservations } = yield select(selectDashboard)
    if (reservations.detail?.id === reservation.id) {
      yield put(DashboardActions.detailSuccess({ resource: DashboardResources.RESERVATIONS, body: reservation }))
    }
    if (reservations.items.length) {
      yield put(DashboardActions.listSuccess({ resource: DashboardResources.RESERVATIONS, body: { ...reservations, items: reservations.items.filter(r => r.id !== reservation.id) } }))
    }
  } catch (error) {
    yield put(ReservationActions.cancelReservationFailure(error.response?.data?.error || error))
  }
}

function* getLastReservation(action) {
  try {
    const response = yield call(Api.getLastReservationApi, action.payload)
    yield put(ReservationActions.getLastReservationSuccess({ reservation: response.data }))
  } catch (error) {
    yield put(ReservationActions.getLastReservationFailure(error.response?.data?.error || error))
  }
}

function* submitReview(action) {
  try {
    const response = yield call(Api.submitReviewApi, action.payload)
    yield put(ReservationActions.submitReviewSuccess({ reservation: response.data }))
  } catch (error) {
    yield put(ReservationActions.submitReviewFailure(error.response?.data?.error || error))
  }
}

function* createGuestReservation(action) {
  try {
    const response = yield call(Api.createGuestReservationApi, action.payload)
    yield put(ReservationActions.createGuestReservationSuccess({ reservation: response.data }))
  } catch (error) {
    yield put(ReservationActions.createGuestReservationFailure(error.response?.data?.error || error))
  }
}

function* submitDataCompletionCall(action) {
  try {
    const response = yield call(Api.submitDataCompletionCallApi, action.payload)
    yield put(ReservationActions.submitDataCompletionCallSuccess({ reservation: response.data }))
  } catch (error) {
    yield put(ReservationActions.submitDataCompletionCallFailure(error.response?.data?.error || error))
  }
}

function* assignProviders(action) {
  try {
    const response = yield call(Api.assignProvidersApi, action.payload)
    yield put(ReservationActions.assignProvidersSuccess({ reservation: response.data }))
    const { reservations } = yield select(selectDashboard)
    if (reservations.detail?.id === response.data.id) {
      yield put(DashboardActions.detailSuccess({ resource: DashboardResources.RESERVATIONS, body: response.data }))
    }
  } catch (error) {
    yield put(ReservationActions.assignProvidersFailure(error.response?.data?.error || error))
  }
}

function* reservationSaga() {
  yield takeLatest(ReservationActions.getPricingRequest, getPricing)
  yield takeLatest(ReservationActions.getAgreementRequest, getAgreement)
  yield takeLatest(ReservationActions.createReservationRequest, createReservation)
  yield takeLatest(ReservationActions.listReservationsRequest, listReservations)
  yield takeLatest(ReservationActions.acceptReservationRequest, acceptReservation)
  yield takeLatest(ReservationActions.rejectReservationRequest, rejectReservation)
  yield takeLatest(ReservationActions.getReservationDetailsRequest, getReservation)
  yield takeLatest(ReservationActions.cancelReservationRequest, cancelReservation)
  yield takeLatest(ReservationActions.getLastReservationRequest, getLastReservation)
  yield takeLatest(ReservationActions.submitReviewRequest, submitReview)
  yield takeLatest(ReservationActions.createGuestReservationRequest, createGuestReservation)
  yield takeLatest(ReservationActions.submitDataCompletionCallRequest, submitDataCompletionCall)
  yield takeLatest(ReservationActions.assignProvidersRequest, assignProviders)
}

export default reservationSaga;

import { Formik } from 'formik'
import * as yup from 'yup'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

import Choice from '../../../../components/basic/Choice'
import Info from '../../../../components/Info'

import { MOBILE_NUMBER_REGEX } from '../../../../components/common'
import { CityAreaSubform } from '../../../../components/LocationInfoForm'
import TaggedFormLabel from '../../../../components/basic/TaggedFormLabel'

const ClinicData = ({ onSubmit, data, edit }) => {
  const schema = yup.object().shape({
    mobileNumber: yup.string()
      .matches(MOBILE_NUMBER_REGEX, 'من فضلك أدخل رقم هاتف صحيح')
      .required('من فضلك أدخل رقم الهاتف')
      .typeError('من فضلك أدخل رقم الهاتف'),
    cityId: yup.number(),
    areaId: yup.number(),
    address: yup.string().required('من فضلك قم بادخال المعلومات كاملة'),
    hasOtherClinic: yup.boolean(),
    notes: yup.string()
  })

  return (
    <Formik
      validationSchema={schema}
      validateOnChange={false}
      initialValues={{
        mobileNumber: data.clinic?.mobileNumber,
        cityId: data.clinic?.cityId,
        areaId: data.clinic?.areaId,
        address: data.clinic?.address,
        hasOtherClinic: data.clinic?.hasOtherClinic || false,
        notes: data.clinic?.notes,
      }}
      onSubmit={clinic => onSubmit({ clinic })}
      enableReinitialize={true}
    >
      {({ handleSubmit, handleChange, setFieldValue, values, errors }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Info>بعض الحالات تحتاج الي الكشف بعيادة الطبيب</Info>
          <Form.Group>
            <Form.Label>رقم تليفون حجز العيادة</Form.Label>
            <Form.Control
              name='mobileNumber'
              value={values.mobileNumber}
              isInvalid={errors.mobileNumber}
              onChange={handleChange}
              placeholder='رقم تليفون حجز العيادة'
            />
            <Form.Control.Feedback type='invalid'>
              {errors.mobileNumber}
            </Form.Control.Feedback>
          </Form.Group>
          <CityAreaSubform
            values={values}
            errors={errors}
            onAreaChange={areaId => setFieldValue('areaId', areaId)}
            onCityChange={cityId => setFieldValue('cityId', cityId)}
          />
          <Form.Group>
            <Form.Label>عنوان العيادة بالتفصيل</Form.Label>
            <Form.Control
              name='address'
              value={values.address}
              isInvalid={errors.address}
              onChange={handleChange}
              placeholder='عنوان العيادة بالتفصيل'
            />
            <Form.Control.Feedback type='invalid'>
              {errors.address}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>هل لديك عيادة اخرى</Form.Label>
            <Choice
              options={['نعم', 'لا']}
              value={values.hasOtherClinic ? 'نعم' : 'لا'}
              onChange={value => setFieldValue('hasOtherClinic', value === 'نعم')}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>ملاحظات عن العيادة الاخرى</Form.Label>
            <Form.Control
              as='textarea'
              name='notes'
              value={values.notes}
              isInvalid={errors.notes}
              onChange={handleChange}
              placeholder='بيانات العيادة الاخري'
            />
          </Form.Group>
          <div className='vertical-button-group'>
            <Button type='submit'>{edit ? 'تأكيد' : 'استمرار'}</Button>
            <Button variant='outline-primary' onClick={() => onSubmit({})}>تخطي</Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

ClinicData.title = <TaggedFormLabel>بيانات العيادة الرئيسية</TaggedFormLabel>

export default ClinicData

import { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PenFill } from 'react-bootstrap-icons'
import Form from 'react-bootstrap/Form'
import Spinner from 'react-bootstrap/Spinner'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

import { Formik } from 'formik'
import * as yup from 'yup'
import _ from 'lodash'

import Editable from '../../components/basic/Editable'
import PersonCard from '../../components/PersonCard'

import { DashboardActions, selectDashboard } from '../../redux/reducers/dashboardSlice'
import { usePrevious } from '../../components/common'
import { ProviderActions } from '../../redux/reducers/providerSlice'

const ModalEditor = ({ show, title, close, children }) => {
  const ref = useRef()
  const { isLoading, error } = useSelector(selectDashboard)
  const previousIsLoading = usePrevious(isLoading)

  useEffect(() => {
    if (!isLoading && previousIsLoading && !error) {
      close()
    }
  }, [close, isLoading, previousIsLoading, error])

  return (
    <Modal show={show} onHide={close} dialogClassName='ltr dashboard-layout'>
      <Modal.Header closeButton>
        <Modal.Title>Edit {title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {children(ref)}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => ref.current?.submitForm()} disabled={isLoading || ref.current?.errors}>
          {isLoading ? <Spinner animation="border" size='sm' /> : 'Update'}
        </Button>
        <Button variant='outline-primary' onClick={close}>Cancel</Button>
      </Modal.Footer>
    </Modal>
  )
}

const EditIcon = ({ open }) => {
  return <PenFill onClick={open} className='edit-form-input' />
}

/**
 *
 * @param {object} param0
 * @param {string} param0.resource
 * @param {number} param0.id
 * @param {(function(object):{title: string, body: object})[]} param0.sectionMappers
 * @returns
 */
const DetailedView = ({ sectionMappers = [], resource, id, edit: Edit, avatar=null, serviceProvider }) => {
  const dispatch = useDispatch()
  const [showUpdate, setShowUpdate] = useState(false)
  const submitRef = useRef()

  const dashboardSlice = useSelector(selectDashboard)
  const { detail } = dashboardSlice[resource]

  useEffect(() => {
    dispatch(DashboardActions.detailRequest({ resource, body: { id } }))
  }, [dispatch, resource, id])

  const hideUpdate = () => setShowUpdate(false)

  if (detail?.id != id) {
    return <Spinner animation="border"/>
  }
  else {
    return (
      <div>
        <Row style={{verticalAlign: 'middle'}} className='requester-form-switches'>
          <Col>
            <Row>
              {detail?.profilePicture && (
                <Col md='auto'>
                  <PersonCard.Avatar src={detail?.profilePicture?.url} />
                </Col>
              )}
              {(detail?.firstName || detail?.middleName || detail?.lastName) && (
                <Col as='h1' md='auto'>
                  {[detail?.firstName, detail?.middleName, detail?.lastName].filter(x => x).join(' ')}
                </Col>
              )}
              {(detail?.name) && (
                <Col as='h1' md='auto'>
                  {detail?.name}
                </Col>
              )}
              {Edit && (
                <Col>
                  <PenFill className='icon' onClick={() => setShowUpdate(true)} />
                </Col>
              )}
            </Row>
          </Col>
          {serviceProvider && (
            <Col>
              <Formik
                onSubmit={({ status }) => dispatch(DashboardActions.updateServiceProviderStatusRequest({ id, status }))}
                validationSchema={yup.object().shape({ status: yup.string() })}
                initialValues={{ status: detail?.status }}
              >
                {({ handleSubmit, handleChange, values, submitForm }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <Form.FloatingLabel label='Status'>
                      <Form.Select
                        defaultValue={values.status}
                        value={values.status}
                        name='status'
                        onChange={e => {
                          handleChange(e)
                          setImmediate(submitForm)
                        }}
                      >
                        <option value='Active'>Active</option>
                        <option value='InActive'>In Active</option>
                        <option value='Blocked'>Blocked</option>
                      </Form.Select>
                    </Form.FloatingLabel>
                  </Form>
                )}
              </Formik>
            </Col>
          )}
        </Row>
        <br />
        <Row>
          <Form>
            {sectionMappers.map((sectionMapper, index) => {
              const section = sectionMapper(detail)
              return (
                <Form.Group key={index}>
                  <Form.Label>{section.title}</Form.Label>
                  <Editable value={section.body} wrapper={ModalEditor} editTitle={section.title} editComponent={EditIcon}>
                    {section.edit}
                  </Editable>
                </Form.Group>
              )
            })}
          </Form>
        </Row>
        {Edit && (
          <Modal show={showUpdate} onHide={hideUpdate} dialogClassName='ltr dashboard-layout'>
            <Modal.Header closeButton>
              <Modal.Title>Update {_.capitalize(resource).slice(0, resource.length - 1)}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Edit ref={submitRef} detail={detail} />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={() => submitRef.current?.submitForm()}>
                Update
              </Button>
              <Button variant="outline-primary" onClick={hideUpdate}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>
    )
  }
}

export default DetailedView

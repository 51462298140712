import { useEffect, useState, useRef } from 'react'
import FormControl from 'react-bootstrap/FormControl'

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
const DiscreteInput = ({ size = 5, onFinish, isInvalid, errorMessage = '', emptyMessage = '' }) => {
  const refs = []
  const [value, setValue] = useState([])
  const previousIsInvaid = usePrevious(isInvalid)
  const [localInvalid, setLocalInvalid] = useState(false)
  const [invalidMessage, setInvalidMessage] = useState(errorMessage)

  const handleChange = (e) => {
    setLocalInvalid(false)
    if (e.target.value.match(/[0-9]/)) {
      if (isInvalid && value.length === size) {
        setValue([e.target.value])
      }
      else {
        setValue([...value, e.target.value])
      }
    }
  }

  useEffect(() => {
    refs[0].focus()
  })

  useEffect(() => {
    if (isInvalid && !previousIsInvaid) {
      setLocalInvalid(true)
      setValue([])
      refs[0].focus()
      if (value.length) {
        setInvalidMessage(errorMessage)
      }
      else {
        setInvalidMessage(emptyMessage)
      }
    }
  }, [isInvalid, refs, previousIsInvaid, value.length, emptyMessage, errorMessage])

  useEffect(() => {
    if (value.length < size) {
      refs[value.length].focus()
    }
    if (value.length === size) {
      onFinish(value.join(''))
    }
  }, [value, refs, onFinish, size])

  const handleKey = event => {
    if (event.keyCode === 8 && value.length) {
      refs[value.length - 1].value = ''
      refs[value.length - 1].focus()
      setValue(value.slice(0, value.length - 1))
    }
  }

  return (
    <div>
      <div className={localInvalid ? 'discrete-input-error' : 'discrete-input'}>
        {new Array(size).fill(0).map((v, index) => {
          return (
            <FormControl
              key={`child-${index}`}
              id={`discrete-${index}`}
              index={index}
              ref={(ref) => refs[index] = ref}
              onChange={handleChange}
              type='number'
              isInvalid={localInvalid}
              value={value[index] || ''}
              onKeyDown={handleKey}
            />
          )
        })}
      </div>
      {localInvalid && <div className='discrete-input-error-message'>{invalidMessage}</div>}
    </div>
  )
}

export default DiscreteInput

import {ReactComponent as MainLogo} from '../../svg/transparent_logo.svg'
import Svg from './Svg'

const CaptionedLogo = ({ caption, subCaption, small }) =>
  <div>
    <Svg as={MainLogo} className='logo' />
    <h1 className={`caption ${small && 'small'}`}>{caption}</h1>
    <h2 className={`sub-caption ${small && 'small'}`}>{subCaption}</h2>
  </div>

export default CaptionedLogo

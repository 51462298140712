import axios from 'axios'
import qs from 'query-string'

export const listApi = ({ resource, query }) => {
  const q = qs.parseUrl(resource)
  return axios.get(
    `${process.env.REACT_APP_BE_URL}/dashboard/${q.url}`, { params: { ...q.query, ...query } }
  )
}

export const createApi = ({ resource, body }) => {
  return axios.post(
    `${process.env.REACT_APP_BE_URL}/dashboard/${resource}`,
    body
  )
}

export const updateApi = ({ resource, body: { id, ...data } }) => {
  return axios.put(
    `${process.env.REACT_APP_BE_URL}/dashboard/${resource.split('?')[0]}/${id}`,
    data
  )
}

export const detailApi = ({ resource, body: { id } }) => {
  return axios.get(
    `${process.env.REACT_APP_BE_URL}/dashboard/${resource.split('?')[0]}/${id}`
  )
}

export const searchApi = (params) => {
  return axios.get(
    `${process.env.REACT_APP_BE_URL}/dashboard/search`,
    {
      params
    }
  )
}

export const updatePatientStatusApi = ({ requesterId, patientId, status }) => {
  return axios.patch(
    `${process.env.REACT_APP_BE_URL}/requesters/${requesterId}/patients/${patientId}/status`,
    { status }
  )
}

export const updateServiceProviderStatusApi = ({ id, status }) => {
  return axios.patch(
    `${process.env.REACT_APP_BE_URL}/serviceProvider/${id}/status`,
    null,
    {
      params: {
        status
      }
    }
  )
}

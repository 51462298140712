import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  status: 'idle',
  error: null,
  pricing: null,
  agreement: null,
  reservation: null,
  detail: null,
  last: null,
  reservations: []
}

export const reservationSlice = createSlice({
  name: 'reservation',
  initialState,
  reducers: {
    getPricingRequest: state => {
      state.status = 'loading'
      state.error = null
    },
    getPricingSuccess: (state, action) => {
      state.status = 'idle'
      state.pricing = action.payload.pricing
    },
    getPricingFailure: (state, action) => {
      state.status = 'idle'
      state.error = action.payload
    },

    getAgreementRequest: state => {
      state.status = 'loading'
      state.error = null
    },
    getAgreementSuccess: (state, action) => {
      state.status = 'idle'
      state.agreement = action.payload.agreement
    },
    getAgreementFailure: (state, action) => {
      state.status = 'idle'
      state.error = action.payload
    },

    createReservationRequest: state => {
      state.status = 'loading'
      state.error = null
    },
    createReservationSuccess: (state, action) => {
      state.status = 'idle'
      state.reservation = action.payload.reservation
      state.reservations = state.reservations.concat(state.reservation)
    },
    createReservationFailure: (state, action) => {
      state.status = 'idle'
      state.error = action.payload
    },

    listReservationsRequest: state => {
      state.status = 'loading'
      state.error = null
    },
    listReservationsSuccess: (state, action) => {
      state.status = 'idle'
      state.reservations = action.payload.reservations
    },
    listReservationsFailure: (state, action) => {
      state.status = 'idle'
      state.error = action.payload
    },

    acceptReservationRequest: state => {
      state.status = 'loading'
      state.error = null
    },
    acceptReservationSuccess: (state, action) => {
      state.status = 'idle'
      state.error = null
      state.reservations = state.reservations.map(reservation => {
        if (reservation.id === action.payload.reservation.id) {
          return { ...reservation, status: action.payload.reservation }
        }
        return reservation
      })
    },
    acceptReservationFailure: (state, action) => {
      state.status = 'idle'
      state.error = action.payload
    },

    rejectReservationRequest: state => {
      state.status = 'loading'
      state.error = null
    },
    rejectReservationSuccess: (state, action) => {
      state.status = 'idle'
      state.error = null
      state.reservations = state.reservations.map(reservation => {
        if (reservation.id === action.payload.reservation.id) {
          return { ...reservation, status: action.payload.reservation.status }
        }
        return reservation
      })
    },
    rejectReservationFailure: (state, action) => {
      state.status = 'idle'
      state.error = action.payload
    },

    getReservationDetailsRequest: state => {
      state.status = 'loading'
      state.error = null
    },
    getReservationDetailsSuccess: (state, action) => {
      state.status = 'idle'
      state.detail = action.payload.reservation
    },
    getReservationDetailsFailure: (state, action) => {
      state.status = 'idle'
      state.error = action.payload
    },

    cancelReservationRequest: state => {
      state.status = 'loading'
      state.error = null
    },
    cancelReservationSuccess: (state, action) => {
      state.status = 'idle'
      state.detail = action.payload.reservation
      state.reservations = state.reservations.map(reservation => {
        if (reservation.id === action.payload.reservation.id) {
          return { ...reservation, status: action.payload.reservation.status }
        }
        return reservation
      })
    },
    cancelReservationFailure: (state, action) => {
      state.status = 'idle'
      state.error = action.payload
    },

    getLastReservationRequest: state => {
      state.status = 'loading'
      state.error = null
    },
    getLastReservationSuccess: (state, action) => {
      state.status = 'idle'
      state.last = action.payload.reservation
    },
    getLastReservationFailure: (state, action) => {
      state.status = 'idle'
      state.error = action.payload
    },

    submitReviewRequest: state => {
      state.status = 'loading'
      state.error = null
    },
    submitReviewSuccess: (state, action) => {
      state.status = 'idle'
      if (state.last.id === action.payload.reservation.id) {
        state.last = action.payload.reservation
      }
    },
    submitReviewFailure: (state, action) => {
      state.status = 'idle'
      state.error = action.payload
    },

    createGuestReservationRequest: (state) => {
      state.status = 'loading'
      state.error = null
    },
    createGuestReservationSuccess: (state, action) => {
      state.status = 'idle'
      state.reservation = action.payload.reservation
    },
    createGuestReservationFailure: (state, action) => {
      state.status = 'idle'
      state.error = action.payload
    },

    submitDataCompletionCallRequest: (state) => {
      state.status = 'loading'
      state.error = null
    },
    submitDataCompletionCallSuccess: (state, action) => {
      state.status = 'idle'
      state.reservation = action.payload.reservation
    },
    submitDataCompletionCallFailure: (state, action) => {
      state.status = 'idle'
      state.error = action.payload
    },

    assignProvidersRequest: (state) => {
      state.status = 'loading'
      state.error = null
    },
    assignProvidersSuccess: (state, action) => {
      state.status = 'idle'
      state.reservation = action.payload.reservation
    },
    assignProvidersFailure: (state, action) => {
      state.status = 'idle'
      state.error = action.payload
    },

    reset: state => {
      state.status = 'idle'
      state.error = null
      state.pricing = null
      state.agreement = null
      state.reservation = null
      state.cachedReservationData = null
      state.last = null
      state.reservations = []
    }
  }
});

export const selectReservation = (state) => state.reservation

export const ReservationActions = reservationSlice.actions

export default reservationSlice.reducer

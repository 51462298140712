import { Formik } from 'formik'
import * as yup from 'yup'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

import Info from '../../../../components/Info'

import { CityAreaSubform } from '../../../../components/LocationInfoForm'

const CitiesAreasAndFees = ({ onSubmit, data, edit }) => {
  const schema = yup.object().shape({
    cityIds: yup.array(yup.number()).min(1, 'من فضلك قم بادخال المعلومات كاملة'),
    areaIds: yup.array(yup.number()).min(1, 'من فضلك قم بادخال المعلومات كاملة'),
    homeVisitFees: yup.number().required('من فضلك قم بادخال المعلومات كاملة')
  })

  const handleKey = event => {
    if (event.keyCode < 48 || event.keyCode > 57) {
      if (![8, 13, 27].includes(event.keyCode)) {
        return event.preventDefault()
      }
    }
  }

  return (
    <Formik
      validationSchema={schema}
      initialValues={{
        cityIds: data.cityIds,
        areaIds: data.areaIds,
        homeVisitFees: data.homeVisitFees
      }}
      validateOnChange={false}
      enableReinitialize={true}
      onSubmit={onSubmit}
    >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        values,
        errors
      }) => (
        <Form  noValidate onSubmit={handleSubmit}>
          <Info>برجاء اختيار اماكن العمل للزيارات المنزلية</Info>
          <CityAreaSubform
            multiple
            values={{ cities: values.cityIds, areas: values.areaIds }}
            errors={{ cities: errors.cityIds, areas: errors.areaIds }}
            onAreaChange={value => setFieldValue('areaIds', value)}
            onCityChange={value => setFieldValue('cityIds', value)}
            placeholders={{
              city: 'اختر المحافظات التي يمارس به المهنة',
              area: 'اختر المراكز او الاحياء التي يمارس بها المهنة'
            }}
            labels={{
              city: 'المحافظة',
              area: 'المراكز / الأحياء'
            }}
          />
          <Form.Group>
            <Form.Label>صافي الرسوم  من الزيازة المنزلية</Form.Label>
            <Form.Control
              name='homeVisitFees'
              type='number'
              onChange={handleChange}
              defaultValue={values.homeVisitFees}
              isInvalid={errors.homeVisitFees}
              placeholder='حدد صافي الرسوم  من الزيازة المنزلية'
              onKeyDown={handleKey}
            />
            <Form.Control.Feedback type='invalid'>
              {errors.homeVisitFees}
            </Form.Control.Feedback>
          </Form.Group>
          <Button type='submit'>{edit ? 'تأكيد' : 'استمرار'}</Button>
        </Form>
      )}
    </Formik>
  )
}

CitiesAreasAndFees.title = <b>اماكن العمل</b>

export default CitiesAreasAndFees

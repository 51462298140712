import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  attachments: {},
  isLoading: {},
  error: {}
}

export const attachmentsSlice = createSlice({
  name: 'attachments',
  initialState,
  reducers: {
    uploadRequest: (state, action) => {
      const { target } = action.payload
      state.isLoading[target] = true
      state.error[target] = null
      if (!state.attachments[target]) {
        state.attachments[target] = []
      }
    },
    uploadSuccess: (state, action) => {
      const { target } = action.payload
      state.isLoading[target] = false
      state.attachments[target] = [...state.attachments[target], ...action.payload.attachments]
    },
    uploadFailure: (state, action) => {
      const { target } = action.payload
      state.isLoading[target] = false
      state.error[target] = action.payload
    },

    dropAttachmentRequest: (state, action) => {
      const { target } = action.payload
      state.isLoading[target] = true
      state.error[target] = null
      if (!state.attachments[target]) {
        state.attachments[target] = []
      }
    },
    dropAttachmentSuccess: (state, action) => {
      const { target, url } = action.payload
      console.log('deleting attachment in slice, ', url, target)
      state.isLoading[target] = false
      state.attachments = {
        ...state.attachments,
        [target]: state.attachments[target].filter(attachment => attachment.url !== url)
      }
    },
    dropAttachmentFailure: (state, action) => {
      const { target } = action.payload
      state.isLoading[target] = false
      state.error[target] = action.payload
    },

    reset: state => {
      state.attachments = {}
      state.isLoading = {}
      state.error = {}
    },
    initialize: (state, action) => {
      const { target, attachments } = action.payload
      state.attachments[target] = attachments
      state.isLoading[target] = false
      state.error[target] = null
    }
  }
});

export const selectAttachments = (state) => state.attachments

export const AttachmentsActions = attachmentsSlice.actions

export default attachmentsSlice.reducer;

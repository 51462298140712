import { Formik } from 'formik'
import * as yup from 'yup'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

import Info from '../../../../components/Info'

import Appender from './Appender'
import TaggedFormLabel from '../../../../components/basic/TaggedFormLabel'

const Experiences = ({ onSubmit, data, edit }) => {
  const schema = yup.object().shape({
    title: yup.string().required('من فضلك قم بادخال المعلومات كاملة'),
    employer: yup.string().required('من فضلك قم بادخال المعلومات كاملة'),
    yearsOfExperience: yup.number().integer().required('من فضلك قم بادخال المعلومات كاملة')
  })

  return (
    <Appender
      onSubmit={experiences => onSubmit({
        experiences,
        yearsOfExperience: experiences.reduce((total, experience) => total + parseInt(experience.yearsOfExperience), 0)
      })}
      mapper={experience => (
        <div>
          <b>{experience.title}</b>
          <div>{experience.employer}</div>
          <div>{experience.yearsOfExperience}</div>
        </div>
      )}
      initialValue={data.experiences}
    >
      {({ initialValue, onSubmit }) => (
        <Formik
          validationSchema={schema}
          initialValues={initialValue}
          validateOnChange={false}
          onSubmit={onSubmit}
          enableReinitialize={true}
        >
          {({ handleSubmit, handleChange, values, errors, dirty }) => (
            <Form noValidate onSubmit={handleSubmit} className='bottom-button-form'>
              <Info>برجاء الإدخال بداية بالخبرات الأكبر قيمة</Info>
              <Form.Group>
                <Form.Label>المسمي الوظيفي</Form.Label>
                <Form.Control
                  name='title'
                  placeholder='مثال : مدير قسم، مدير مستشفي ….إلخ'
                  value={values.title}
                  onChange={handleChange}
                  isInvalid={errors.title}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.title}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label>مكان العمل</Form.Label>
                <Form.Control
                  name='employer'
                  placeholder='ادخل مكان العمل'
                  onChange={handleChange}
                  value={values.employer}
                  isInvalid={errors.employer}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.employer}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label>عدد سنوات الخبرة</Form.Label>
                <Form.Control
                  name='yearsOfExperience'
                  placeholder='حدد عدد سنوات الخبرة'
                  onChange={handleChange}
                  value={values.yearsOfExperience}
                  isInvalid={errors.yearsOfExperience}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.yearsOfExperience}
                </Form.Control.Feedback>
              </Form.Group>
              <Button
                type={dirty && 'submit'}
                onClick={() => {
                  if(!dirty) {
                    onSubmit({})
                  }
                }}
              >
                {edit ? 'تأكيد' : 'استمرار'}
              </Button>
            </Form>
          )}
        </Formik>
      )}
    </Appender>
  )
}

Experiences.title = <TaggedFormLabel>الخبرات</TaggedFormLabel>

export default Experiences

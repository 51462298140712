import { useEffect, useState, useMemo, useCallback, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { PlusCircleFill, XCircle } from 'react-bootstrap-icons'
import { Formik } from 'formik'
import * as yup from 'yup'
import _ from 'lodash'
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import startOfToday from 'date-fns/startOfToday'
import qs from 'query-string'
import { TriangleFill, Dash } from 'react-bootstrap-icons'

import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Spinner from 'react-bootstrap/Spinner'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Table from 'react-bootstrap/Table'
import Image from 'react-bootstrap/Image'
import Container from 'react-bootstrap/Container'

import DynamicTable from '../../components/DynamicTable'
import Tabs from '../components/Tabs'

import CreateReservationScreen from '../../screens/CreateReservationScreen'

import { DashboardActions, DashboardResources, selectDashboard } from '../../redux/reducers/dashboardSlice'
import { Directions, enrichEnumObject, ProviderTypes, ReservationStatus, ServiceTypes, usePrevious, withProps } from '../../components/common'
import DetailedView from '../layout/DetailedView'
import { ReservationActions, selectReservation } from '../../redux/reducers/reservationSlice'
import Editable from '../../components/basic/Editable'
import Attachments from '../../components/basic/Attachments'
import { selectVisit, VisitActions } from '../../redux/reducers/visitSlice'
import Info from '../../components/Info'
import { ProviderAssignmentActions, selectProviderAssignment } from '../../redux/reducers/providerAssignmentSlice'

const CreateReservationPreparation = ({ onSubmit }) => {
  const schema = yup.object().shape({
    providerType: yup.string().oneOf(ProviderTypes.getValues()).default(ProviderTypes.getValues()[0]),
    serviceType: yup.string().oneOf(ServiceTypes.getValues()).default(ServiceTypes.getValues()[0]),
    requesterId: yup.string().required('من فضلك قم بادخال المعلومات كاملة')
  })

  const validServiceTypes = (providerType) => {
    switch (providerType) {
      case ProviderTypes.DOCTOR.value:
        return enrichEnumObject(_.pick(ServiceTypes, ['HOME_VISIT', 'CALL_CONSULTATION']))
      case ProviderTypes.MEDICAL_RAYS_ANALYSIS.value:
        return enrichEnumObject(_.pick(ServiceTypes, ['MEDICAL_RAYS', 'MEDICAL_ANALYSIS']))
      default:
        return null
    }
  }

  return (
    <Formik
      validationSchema={schema}
      initialValues={schema.default()}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, handleChange, setFieldValue, values, errors}) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label>Type</Form.Label>
            <Form.Select name='providerType' onChange={e => {
              handleChange(e)
              setFieldValue('serviceType', validServiceTypes(e.target.value)?.getValues()?.[0])
            }}>
              {ProviderTypes.options().map(provider => (
                <option key={provider.value} value={provider.value}>{provider.text}</option>
              ))}
            </Form.Select>
          </Form.Group>
          {validServiceTypes(values.providerType) && (
            <Form.Group>
              <Form.Label>Service</Form.Label>
              <Form.Select name='serviceType' onChange={handleChange}>
                {validServiceTypes(values.providerType).options().map(service => (
                  <option key={service.value} value={service.value}>{service.text}</option>
                ))}
              </Form.Select>
            </Form.Group>
          )}
          <Form.Group>
            <Form.Label>Requester ID</Form.Label>
            <Form.Control
              name='requesterId'
              value={values.requesterId}
              onChange={handleChange}
              isInvalid={errors.requesterId}
            />
            <Form.Control.Feedback type='invalid'>
              {errors.requesterId}
            </Form.Control.Feedback>
          </Form.Group>
          <Button type='submit'>Next</Button>
        </Form>
      )}
    </Formik>
  )
}

const CreateReservationCore = ({ providerType, serviceType, requesterId, onFinish }) => {
  const history = useHistory()
  const search = { providerType }
  if (serviceType) {
    search.serviceType = serviceType
  }
  return withProps(
    CreateReservationScreen.WrappedComponent({
      history: Object.assign({}, history, { push: onFinish, goBack: onFinish }),
      location: Object.assign({}, history.location, { search: qs.stringify(search) }),
      requesterId
    }),
    {
      direction: Directions.LTR
    }
  )
}

const CreateReservation = ({ onFinish }) => {
  const [activeTopicIndex, setActiveTopicIndex] = useState(0)
  const [form, setForm] = useState()

  const onSubmit = form => {
    if (activeTopicIndex === 0) {
      setForm(form)
      setActiveTopicIndex(activeTopicIndex + 1)
    }
  }

  const topics = [CreateReservationPreparation, CreateReservationCore]
  const Topic = topics[activeTopicIndex]

  return (
    <Topic
      onSubmit={onSubmit}
      providerType={form?.providerType}
      serviceType={form?.serviceType}
      requesterId={form?.requesterId}
      onFinish={onFinish}
    />
  )
}

const Reservations = () => {
  const dispatch = useDispatch()
  const { reservations } = useSelector(selectDashboard)
  const { status } = useSelector(selectReservation)
  const previousReservations = usePrevious(reservations)

  const isLoading = status === 'loading'
  const previousLoading = usePrevious(isLoading)

  const [showCreate, setShowCreate] = useState(false)
  const [showUpdate, setShowUpdate] = useState(false)
  const [showCancel, setShowCancel] = useState()


  const handleHide = () => {
    setShowCreate(false)
    setShowUpdate(false)
    setShowCancel(null)
  }

  useEffect(() => {
    if (!_.isEqual(reservations, previousReservations)) {
      setShowCreate(false)
      setShowUpdate(false)
    }
  }, [previousReservations, reservations])


  useEffect(() => {
    if (!_.isEqual(isLoading, previousLoading)) {
      setShowCancel(null)
    }
  }, [isLoading, previousLoading])

  const action = showCreate ? 'Create' : 'Update'

  const dateQueryMapper = query => {
    const reshape = date => format(parse(date, 'dd/MM/yyyy', startOfToday()), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'")
    const custom = {}
    if (query.createdAt) {
      custom.createdAt = reshape(query.createdAt)
    }
    if (query.updatedAt) {
      custom.updatedAt = reshape(query.updatedAt)
    }
    if (query.date) {
      custom.date = reshape(query.date)
    }

    return { ...query, ...custom }
  }

  const showCancellationForm = reservationId => () => setShowCancel(reservationId)

  const cancelReservation = form => {
    dispatch(ReservationActions.cancelReservationRequest(form))
  }

  return (
    <div>
      <div className='sub-screen-title'>
        <h1>Reservations</h1>
        <Button flo at='right' onClick={() => setShowCreate(true)}><PlusCircleFill /> Create</Button>
      </div>
      <Tabs>
        <Tabs.Tab title='New Request'>
          <DynamicTable
            search
            sortable
            resource={DashboardResources.RESERVATIONS}
            initialQuery={{status: ReservationStatus.PENDING_OPS}}
            data={reservations.items}
            headers={[
              { key: 'id', name: "ID" },
              { key: 'requesterName', name: "Requester's Name" },
              { key: 'requesterMobileNumber', name: "Requester's Mobile Number" },
              { key: 'patientName', name: "Patient's Name" },
              { key: 'patientMobileNumber', name: "Patient's Mobile Number" },
              { key: 'cityName', name: "City" },
              { key: 'areaName', name: "Area" },
              { key: 'providerType', name: "Service Type" },
              { key: 'speciality', name: "Speciality" },
              { key: 'detailedSpeciality', name: "Detailed Speciality" },
              { key: 'degree', name: "Degree" },
              { key: 'serviceType', name: "Request Type" },
              { key: 'reason', name: "Sick Complain" },
              { key: 'createdAt', name: "Request Date" },
              { key: 'dateRange', name: "Visit Date" },
              { key: '$cancel', name: ''}
            ]}
            mapper={item => {
              return [
                { key: 'id', value: item.id },
                { key: 'requesterName', value: item.requesterName },
                { key: 'requesterMobileNumber', value: item.requesterMobileNumber },
                { key: 'patientName', value: item.patientName },
                { key: 'patientMobileNumber', value: item.patientMobileNumber },
                { key: 'cityName', value: item.cityName },
                { key: 'areaName', value: item.areaName },
                { key: 'providerType', value: item.providerType },
                { key: 'speciality', value: item.speciality },
                { key: 'detailedSpeciality', value: item.detailedSpeciality },
                { key: 'degree', value: item.degree },
                { key: 'serviceType', value: item.serviceType },
                { key: 'reason', value: item.reason },
                { key: 'createdAt', value: format(new Date(item.createdAt), 'dd/MM/yyyy') },
                { key: 'dateRange', value: item.dateRange },
                // { key: 'requesterSource', value: item.source }
                { key: '$cancel', value: <XCircle onClick={showCancellationForm(item.id)} />}
              ]
            }}
            pagesCount={reservations.pagesCount}
            queryMapper={dateQueryMapper}
            detail
          />
        </Tabs.Tab>
        <Tabs.Tab title='Under Processing'>
          <DynamicTable
            search
            sortable
            resource={DashboardResources.RESERVATIONS}
            initialQuery={{status: [ReservationStatus.PENDING_SPR, ReservationStatus.PENDING_RQT]}}
            data={reservations.items}
            headers={[
              { key: 'id', name: "ID" },
              { key: 'patientName', name: "Patient's Name" },
              { key: 'patientMobileNumber', name: "Patient's Mobile Number" },
              { key: 'areaName', name: "Area" },
              { key: 'providerType', name: "Service Type" },
              { key: 'gender', name: "Gender" },
              { key: 'speciality', name: "Speciality" },
              { key: 'degree', name: "Degree" },
              { key: 'serviceType', name: "Request Type" },
              { key: 'status', name: "Status" },
              { key: '$cancel', name: '' }
            ]}
            mapper={item => {
              return [
                { key: 'id', value: item.id },
                { key: 'patientName', value: item.patientName },
                { key: 'patientMobileNumber', value: item.patientMobileNumber },
                { key: 'areaName', value: item.areaName },
                { key: 'providerType', value: item.providerType },
                { key: 'gender', value: item.gender },
                { key: 'speciality', value: item.speciality },
                { key: 'degree', value: item.degree },
                { key: 'serviceType', value: item.serviceType },
                { key: 'status', value: item.status },
                { key: '$cancel', value: <XCircle onClick={showCancellationForm(item.id)} />}
              ]
            }}
            pagesCount={reservations.pagesCount}
            queryMapper={dateQueryMapper}
            detail
          />
        </Tabs.Tab>
        <Tabs.Tab title='Coming'>
          <DynamicTable
            search
            sortable
            resource={DashboardResources.RESERVATIONS}
            initialQuery={{status: ReservationStatus.UPCOMING}}
            data={reservations.items}
            headers={[
              { key: 'id', name: "ID" },
              { key: 'patientName', name: "Patient's Name" },
              { key: 'patientMobileNumber', name: "Patient's Mobile Number" },
              { key: 'areaName', name: "Area" },
              { key: 'providerType', name: "Service Type" },
              { key: 'serviceType', name: "Request Type" },
              { key: 'assigneeName', name: "Provider's Name" },
              { key: 'assigneeMobileNumber', name: 'MobileNumber' },
              { key: 'price', name: "Salary" },
              { key: 'date', name: "Visit Day" },
              { key: '$cancel', name: '' }
            ]}
            mapper={item => {
              return [
                { key: 'id', value: item.id },
                { key: 'patientName', value: item.patientName },
                { key: 'patientMobileNumber', value: item.patientMobileNumber },
                { key: 'areaName', value: item.areaName },
                { key: 'providerType', value: item.providerType },
                { key: 'serviceType', value: item.serviceType },
                { key: 'assigneeName', value: item.assigneeName },
                { key: 'assigneeMobileNumber', value: item.assigneeMobileNumber },
                { key: 'price', value: item.price },
                { key: 'date', value: item.date && format(new Date(item.date), 'dd/MM/yyyy')},
                { key: '$cancel', value: <XCircle onClick={showCancellationForm(item.id)} /> }
              ]
            }}
            pagesCount={reservations.pagesCount}
            queryMapper={dateQueryMapper}
            detail
          />
        </Tabs.Tab>
        <Tabs.Tab title='Cancelled'>
          <DynamicTable
            search
            sortable
            resource={DashboardResources.RESERVATIONS}
            initialQuery={{status: ReservationStatus.CANCELLED}}
            data={reservations.items}
            headers={[
              { key: 'id', name: "ID" },
              { key: 'patientName', name: "Patient's Name" },
              { key: 'patientMobileNumber', name: "Patient's Mobile Number" },
              { key: 'areaName', name: "Area" },
              { key: 'providerType', name: "Service Type" },
              { key: 'serviceType', name: "Request Type" },
              { key: 'speciality', name: "Speciality" },
              { key: 'price', name: "Salary" },
              { key: 'createdAt', name: "Req Date" },
              { key: 'cancellationReason', name: "Cancellation Reason" },
              { key: 'updatedAt', name: "Cancellation Date" }
            ]}
            mapper={item => {
              return [
                { key: 'id', value: item.id },
                { key: 'patientName', value: item.patientName },
                { key: 'patientMobileNumber', value: item.patientMobileNumber },
                { key: 'areaName', value: item.areaName },
                { key: 'providerType', value: item.providerType },
                { key: 'serviceType', value: item.serviceType },
                { key: 'speciality', value: item.speciality },
                { key: 'price', value: item.price },
                { key: 'createdAt', value: format(new Date(item.createdAt), 'dd/MM/yyyy') },
                { key: 'cancellationReason', value: item.cancellationReason },
                { key: 'updatedAt', value: format(new Date(item.updatedAt), 'dd/MM/yyyy') }
              ]
            }}
            pagesCount={reservations.pagesCount}
            queryMapper={dateQueryMapper}
            detail
          />
        </Tabs.Tab>
        <Tabs.Tab title='Done'>
          <DynamicTable
            search
            sortable
            resource={DashboardResources.RESERVATIONS}
            initialQuery={{status: ReservationStatus.COMPLETED}}
            data={reservations.items}
            headers={[
              { key: 'id', name: "ID" },
              { key: 'patientName', name: "Patient's Name" },
              { key: 'patientMobileNumber', name: "Patient's Mobile Number" },
              { key: 'areaName', name: "Area" },
              { key: 'providerType', name: "Service Type" },
              { key: 'serviceType', name: "Request Type" },
              { key: 'assigneeName', name: "Provider's Name" },
              { key: 'assigneeMobileNumber', name: 'MobileNumber' },
              { key: 'price', name: "Salary" },
              { key: 'date', name: "Visit Day" }
            ]}
            mapper={item => {
              return [
                { key: 'id', value: item.id },
                { key: 'patientName', value: item.patientName },
                { key: 'patientMobileNumber', value: item.patientMobileNumber },
                { key: 'areaName', value: item.areaName },
                { key: 'providerType', value: item.providerType },
                { key: 'serviceType', value: item.serviceType },
                { key: 'assigneeName', value: item.assigneeName },
                { key: 'assigneeMobileNumber', value: item.assigneeMobileNumber },
                { key: 'price', value: item.price },
                { key: 'date', value: item.date && format(new Date(item.date), 'dd/MM/yyyy')}
              ]
            }}
            pagesCount={reservations.pagesCount}
            queryMapper={dateQueryMapper}
            detail
          />
        </Tabs.Tab>
      </Tabs>

      <Modal show={showCreate || showUpdate} onHide={handleHide} dialogClassName='ltr dashboard-layout'>
        <Modal.Header closeButton>
          <Modal.Title>{action} Reservation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CreateReservation onFinish={handleHide} />
        </Modal.Body>
      </Modal>

      <Modal show={showCancel} onHide={handleHide} dialogClassName='ltr dashboard-layout'>
        <Modal.Header closeButton>
          <Modal.Title>Cancel Reservation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            validationSchema={yup.object().shape({ reason: yup.string().required() })}
            initialValues={{ reservationId: showCancel }}
            onSubmit={cancelReservation}
          >
            {({ handleSubmit, handleChange, values, errors }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <h1>Are you sure that you want to cancel this reservation?</h1>
                <Form.Group>
                  <Form.Label>Reason of cancellation</Form.Label>
                  <Form.Control
                    as='textarea'
                    name='reason'
                    value={values.reason}
                    isInvalid={errors.reason}
                    onChange={handleChange}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.reason}
                  </Form.Control.Feedback>
                </Form.Group>
                <div className='full-width-horizontal-group'>
                  <Button type='submit'>{isLoading ? <Spinner animation='border' size='sm' /> : 'Yes'}</Button>
                  <Button variant='outline-primary' onClick={handleHide}>No</Button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div>
  )
}

Reservations.Detail = () => {
  const dispatch = useDispatch()
  const { id } = useParams()
  const { reservations } = useSelector(selectDashboard)
  const { status } = useSelector(selectReservation)

  const [showAssignProvider, setShowAssignProvider] = useState(false)
  const [showSubmitVisitResult, setShowSubmitVisitResult] = useState(false)
  const [showAssignmentActions, setShowAssignmentActions] = useState()
  const [confirmationMessage, setConfirmationMessage] = useState()

  const sectionMappers = [
    reservation => ({
      title: 'Reservation Information',
      body: {
        "Requester's Name": [reservation?.requester.firstName, reservation?.requester.middleName, reservation?.requester.lastName].filter(_.identity).join(' '),
        "Requester's Mobile Number": reservation.requester.mobileNumber,
        "Patient's Name": reservation.patient.name,
        "Patient's Mobile Number": reservation.patient.mobileNumber,
        "Request Type": reservation.serviceType instanceof Object ? reservation.serviceType.englishName : reservation.serviceType,
        "Provider": reservation.providerType,
        "Speciality": reservation.speciality?.englishName,
        "Detailed Speciality": reservation.detailedSpeciality,
        "Degree": reservation.degree,
        "Gender": reservation.gender,
        "Visit Day": (reservation.date && format(new Date(reservation.date), 'dd/MM/yyyy')) || reservation.dateRange,
        "Request Date": format(new Date(reservation.createdAt), 'dd/MM/yyyy'),
        "Patient's Address": [reservation.patient.city.arabicName, reservation.patient.area.arabicName, reservation.patient.address].filter(x => x).join(' - '),
        ...(reservation.providerType === ProviderTypes.DOCTOR.value) && {
          "Sick Complain": reservation.reason
        }
      }
    })
  ]

  if ([ReservationStatus.UPCOMING, ReservationStatus.COMPLETED].includes(reservations.detail?.status)) {
    const provider = reservations.detail?.assignee?.provider
    sectionMappers.push(reservation => ({
      title: 'Assignee Details',
      body: {
        "Type": ProviderTypes.getTextByValue(reservation.providerType),
        "Name": provider?.name || [provider?.firstName, provider?.middleName, provider?.lastName].filter(_.identity).join(' '),
        "Salary": provider?.homeVisitFees
      }
    }))
  }

  if (reservations.detail?.status === ReservationStatus.COMPLETED) {
    reservations.detail?.visits.forEach(visit =>
      sectionMappers.push(() => ({
        title: 'Visit Details',
        body: {
          "Diagnosis": visit.diagnosis,
          "More Investigations": visit.moreInvestigations,
          "Medicines": visit.medicines,
          "Instructions": visit.instructions,
          "Next Visit Date": visit.nextVisitDate && format(new Date(visit.nextVisitDate), 'dd/MM/yyyy'),
          "Other Services Needed": visit.otherServicesNeeded,
          "Attachments": !!visit.attachments.length && (
            <Container fluid>
              {
                visit.attachments
                  .reduce((rows, attachment, index) =>
                    (index % 3 == 0 ? rows.push([attachment]) : rows[rows.length-1].push(attachment)) && rows,
                    []
                  )
                  .map((row, index) => (
                    <Row key={index}>
                      {row.map((attachment, index) => (
                        <Col key={index}>
                          <Image src={attachment.url} />
                        </Col>
                      ))}
                    </Row>
                  ))
              }
            </Container>
          )
        }
      }))
    )
  }

  const acceptOnBehalfOfRequester = () => {
    dispatch(ReservationActions.acceptReservationRequest({ reservationId: reservations?.detail?.id }))
  }

  return (
    <div>
      {[ReservationStatus.PENDING_OPS, ReservationStatus.PENDING_SPR].includes(reservations?.detail?.status) && (
        <div className='sub-screen-title'>
          <h1>Reservation Details</h1>
          <Button className='full-width-horizontal-group' onClick={() => setShowAssignProvider(true)}>
            <PlusCircleFill />
            <div>Assign Provider</div>
          </Button>
        </div>
      )}
      {[ReservationStatus.UPCOMING].includes(reservations?.detail?.status) && (
        <div className='sub-screen-title'>
          <h1>Reservation Details</h1>
          <Button className='full-width-horizontal-group' onClick={() => setShowSubmitVisitResult(true)}>
            <PlusCircleFill />
            <div>Convert To Done</div>
          </Button>
        </div>
      )}
      {[ReservationStatus.PENDING_RQT].includes(reservations?.detail?.status) && (
        <div className='sub-screen-title'>
          <h1>Reservation Details</h1>
          <Button onClick={() => setConfirmationMessage('Are you sure that you want to accept this visit on behalf of the requester?')}>
            Accept To Requester
          </Button>
        </div>
      )}
      <DetailedView
        id={id}
        resource={DashboardResources.RESERVATIONS}
        sectionMappers={sectionMappers}
      />
      {!!reservations.detail?.providerAssignments?.length && (
        <div>
          <br />
          <SimpleTable
            onRowClick={setShowAssignmentActions}
            headers={['ID', 'Name', 'Visit Fees', 'Mobile Number', 'Speciality', 'Detailed Speciality', 'Degree', 'City', 'Area', 'Years of Exp.', 'Rating', 'Status']}
            rows={reservations.detail.providerAssignments.map(item => {
              const { provider } = item
              return [
                provider.id,
                provider.id.startsWith('MC') ? provider.name : [provider.firstName, provider.middleName, provider.lastName].filter(_.identity).join(' '),
                provider.homeVisitFees,
                provider.mobileNumber,
                provider.speciality?.englishName,
                provider.detailedSpeciality,
                provider.degree,
                provider.cities?.map(city => city.englishName).join(', '),
                provider.areas?.map(area => area.englishName).join(', '),
                provider.yearsOfExperience,
                provider.rating,
                item.status
              ]
            })}
            rowClickCondition={row => row[row.length - 1] === 'Pending'}
          />
        </div>
      )}
      <AssignProvidersPopup
        show={showAssignProvider}
        onHide={() => setShowAssignProvider(false)}
        dispatch={dispatch}
        reservation={reservations.detail || {}}
      />
      <CreateVisitPopup
        show={showSubmitVisitResult}
        onHide={() => setShowSubmitVisitResult(false)}
        dispatch={dispatch}
        reservation={reservations.detail || {}  }
      />
      {!!showAssignmentActions && (
        <ProviderAssignmentActionsPopup
          show={!!showAssignmentActions}
          onHide={() => setShowAssignmentActions(null)}
          providerId={showAssignmentActions}
          reservation={reservations.detail}
          dispatch={dispatch}
        />
      )}
      <ConfirmationPopup
        show={!!confirmationMessage}
        message={confirmationMessage}
        onCancel={() => setConfirmationMessage()}
        onConfirm={acceptOnBehalfOfRequester}
        isLoading={status === 'loading'}
      />
    </div>
  )
}

const ConfirmationPopup = ({ show, message, onConfirm, onCancel, isLoading }) => {
  const previousIsLoading = usePrevious(isLoading)

  useEffect(() => {
    if (!isLoading && previousIsLoading) {
      onCancel()
    }
  }, [isLoading, previousIsLoading])

  return (
    <Modal
      show={show}
      onHide={onCancel}
      dialogClassName='dashboard-layout'
    >
      <Modal.Header>
        <Modal.Title>{message}</Modal.Title>
      </Modal.Header>
      <Modal.Footer>
        <div className='full-width-horizontal-group'>
          <Button disabled={isLoading} onClick={onConfirm}>
            {isLoading ? <Spinner animation='border' size='sm' /> : 'Yes'}
          </Button>
          <Button variant='outline-primary' onClick={onCancel}>No</Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

const AssignProvidersPopup = ({ show, onHide, dispatch, reservation }) => {
  const { providers } = useSelector(selectDashboard)
  const [query, setQuery] = useState({})
  const [selection, setSelection] = useState([])

  const getIdPrefix = (providerType) => {
    switch (providerType) {
      case ProviderTypes.DOCTOR.value:
        return 'DR'
      case ProviderTypes.NURSE.value:
        return 'NU'
      case ProviderTypes.PHYSICAL_THERAPIST.value:
        return 'PH'
      case ProviderTypes.HOME_CARE_SPECIALIST.value:
        return 'CO'
      case ProviderTypes.MEDICAL_RAYS_ANALYSIS.value:
      default:
        return 'MC'
    }
  }

  const hide = () => {
    onHide()
    setQuery({ idNotIn: reservation.providerAssignments.map(x => x.provider.id), id: getIdPrefix(reservation.providerType) })
    setSelection([])
  }

  const assign = () => {
    dispatch(ReservationActions.assignProvidersRequest({ reservationId: reservation.id, assigneeIds: selection }))
    hide()
  }

  useEffect(() => {
    const prefix = getIdPrefix(reservation.providerType)
    dispatch(DashboardActions.listRequest({
      resource: DashboardResources.PROVIDERS,
      query: {
        id: prefix,
        ..._.mapValues(_.omitBy(query, _.isEmpty), (v, k) => {
          if (k === 'id') {
            if (!isNaN(parseInt(v))) {
              return `${prefix}-${v}`
            }
            if (!v.startsWith(prefix.slice(0, v.length))) {
              return 'xxxx'
            }
          }
          return v
        }),
        page: 0,
        limit: 10
      }
    }))
  }, [dispatch, query, reservation])

  useEffect(() => {
    setQuery(q => ({ ...q, id: getIdPrefix(reservation.providerType)}))
  }, [reservation])

  const selectAllCheckbox = useMemo(() => {
    return (
      <Form.Check.Input
        checked={providers.items.length && providers.items.every(p => selection.includes(p.id))}
        onChange={e => e.target.checked ? setSelection(providers.items.map(p => p.id)) : setSelection([])}
      />
    )
  }, [selection, providers.items])

  const selectOneCheckbox = useCallback(provider => {
    const selected = selection.includes(provider.id)
    return (
      <Form.Check.Input
        checked={selected}
        onChange={e => setSelection(old => e.target.checked ? old.concat(provider.id) : old.filter(i => i !== provider.id))}
      />
    )
  }, [selection, providers.items])

  return (
    <Modal
      show={show}
      size="lg"
      onHide={hide}
    >
      <Modal.Header closeButton>
        <Modal.Title>Assign Providers</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row>
            <Form.Group as={Col} controlId="formGridId">
              <Form.Label>ID</Form.Label>
              <Form.Control placeholder="ID" onChange={e => setQuery({ ...query, id: e.target.value.trim() })}/>
            </Form.Group>
            <Form.Group as={Col} controlId="formGridName">
              <Form.Label>Name</Form.Label>
              <Form.Control placeholder="Name"  onChange={e => setQuery({ ...query, name: e.target.value.trim() })}/>
            </Form.Group>
            <Form.Group as={Col} controlId="formGridFees">
              <Form.Label>Visit Fees</Form.Label>
              <Form.Control placeholder="Visit Fees"  onChange={e => setQuery({ ...query, homeVisitFees: e.target.value.trim() })}/>
            </Form.Group>
          </Row>
        </Form>
        <DynamicTable
          initialQuery={query}
          resource={DashboardResources.PROVIDERS}
          data={providers.items}
          pagesCount={providers.pagesCount}
          headers={[
            { key: 'id', name: 'ID', sort: 'id.prefix,id.order' },
            { key: 'name', name: 'Name', sort: 'serviceProvider.firstName,serviceProvider.middleName,serviceProvider.lastName' },
            { key: 'visitFees', name: 'Visit Fees' },
            { key: 'mobileNumber', name: 'Mobile Number' },
            { key: '$checkbox', name: selectAllCheckbox }
          ]}
          mapper={provider => {
            const name = provider.type === 'HUMAN' ? [provider.provider.firstName, provider.provider.middleName, provider.provider.lastname].filter(_.identity).join(' ') : provider.provider.name

            return [
              { key: 'id', value: provider.id },
              { key: 'name', value: name},
              { key: 'visitFees', value: provider.provider?.homeVisitFees },
              { key: 'mobileNumber', value: provider.provider.mobileNumber },
              { key: '$checkbox', value: selectOneCheckbox(provider) }
            ]
          }}
        />
      </Modal.Body>
      <Modal.Footer>
        <div>
          <Button onClick={assign}>Assign</Button>
        </div>
        <div>
          <Button variant='outline-primary' onClick={hide}>Cancel</Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

const CreateVisitPopup = ({ show, onHide, dispatch, reservation }) => {
  const formRef = useRef()
  const { visit, isLoading } = useSelector(selectVisit)

  const schema = yup.object().shape({
    diagnosis: yup.string().required(),
    moreInvestigations: yup.string(),
    medicines: yup.string(),
    instructions: yup.string(),
    nextVisitDate: yup.date(),
    otherServicesNeeded: yup.string(),
    attachmentIds: yup.array(yup.number())
  })

  const submitVisit = form => {
    dispatch(VisitActions.createRequest(form))
  }

  useEffect(() => {
    if (visit) {
      onHide()
    }
  }, [visit])

  return (
    <Modal
      show={show}
      size="lg"
      onHide={onHide}
      dialogClassName='dashboard-layout ltr'
    >
      <Modal.Header closeButton>
        <Modal.Title>Visit Result</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          validationSchema={schema}
          initialValues={{ reservationId: reservation.id, attachmentIds: [] }}
          onSubmit={submitVisit}
          innerRef={formRef}
        >
          {({ handleSubmit, handleChange, setFieldValue, values, errors }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Row>
                <Form.Group as={Col}>
                  <Form.Label>Diagnosis</Form.Label>
                  <Form.Control
                    as='textarea'
                    name='diagnosis'
                    value={values.diagnosis}
                    isInvalid={errors.diagnosis}
                    onChange={handleChange}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.diagnosis}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>More Investigations</Form.Label>
                  <Form.Control
                    as='textarea'
                    name='moreInvestigations'
                    value={values.moreInvestigations}
                    isInvalid={errors.moreInvestigations}
                    onChange={handleChange}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.moreInvestigations}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col}>
                  <Form.Label>Medicines</Form.Label>
                  <Form.Control
                    as='textarea'
                    name='medicines'
                    value={values.medicines}
                    isInvalid={errors.medicines}
                    onChange={handleChange}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.medicines}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>Instructions</Form.Label>
                  <Form.Control
                    as='textarea'
                    name='instructions'
                    value={values.instructions}
                    isInvalid={errors.instructions}
                    onChange={handleChange}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.instructions}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col}>
                  <Form.Label>Next Visit Date</Form.Label>
                  <Form.Control
                    type='date'
                    name='nextVisitDate'
                    value={values.nextVisitDate}
                    isInvalid={errors.nextVisitDate}
                    onChange={handleChange}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.nextVisitDate}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>Other Services Needed</Form.Label>
                  <Form.Control
                    name='otherServicesNeeded'
                    value={values.otherServicesNeeded}
                    isInvalid={errors.otherServicesNeeded}
                    onChange={handleChange}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.otherServicesNeeded}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Form.Group>
                <Form.Label>Attachments</Form.Label>
                <Attachments
                  multiple
                  name='attachmentIds'
                  onChange={attachments => setFieldValue('attachmentIds', attachments.map(attachment => attachment.id))}
                  isInvalid={errors.attachmentIds}
                  errorMessage={errors.attachmentIds}
                />
              </Form.Group>
            </Form>
          )}
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <Button onClick={() => formRef.current?.submitForm()}>
            {isLoading ? <Spinner animation='border' size='sm' /> : 'Submit'}
          </Button>
        </div>
        <div>
          <Button variant='outline-primary' onClick={onHide}>Cancel</Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

const ProviderAssignmentActionsPopup = ({ show, onHide, providerId, reservation, dispatch }) => {
  const assignment = reservation.providerAssignments?.find(x => x.provider.id === providerId)

  const { isLoading, action } = useSelector(selectProviderAssignment)
  const previousIsLoading = usePrevious(isLoading)
  const updateStatus = status => () => {
    const action = status === 'Accepted' ? ProviderAssignmentActions.acceptRequest : ProviderAssignmentActions.rejectRequest
    dispatch(action({ id: assignment.id }))
  }

  useEffect(() => {
    if (!isLoading && previousIsLoading) {
      onHide()
    }
  }, [isLoading])

  return (
    <Modal
      show={show}
      onHide={onHide}
      dialogClassName='dashboard-layout ltr'
    >
      <Modal.Header closeButton>
        <Modal.Title>Actions</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Info>Please note that any taken action here is taken on behalf of the provider</Info>
        <Row>
          <Col>
            <label><b>Reservation</b></label>
            <Editable
              value={{
                "Requester's Name": [reservation.requester?.firstName, reservation.requester?.middleName, reservation.requester?.lastName].filter(_.identity).join(' '),
                "Patient's Name": reservation.patient?.name,
                "Provider Type": reservation.providerType,
                "Service Type": reservation.serviceType instanceof Object ? reservation.serviceType.englishName : reservation.serviceType,
                "Address": [reservation.patient?.city.englishName, reservation.patient?.area.englishName, reservation.patient?.address].filter(_.identity).join(' - ')
              }}
            />
          </Col>
          <Col>
            <label><b>Provider</b></label>
            <Editable
              value={{
                "ID": assignment?.provider.id,
                "Name": assignment?.provider.name || [assignment?.provider.firstName, assignment?.provider.middleName, assignment?.provider.lastName].filter(_.identity).join(' '),
                "Fees": assignment?.provider.homeVisitFees
              }}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={updateStatus('Accepted')}>
          {isLoading && action.includes('accept') ? <Spinner animation='border' size='sm' /> : 'Accept'}
        </Button>
        <Button variant='outline-danger' onClick={updateStatus('Rejected')}>
          {isLoading && action.includes('reject') ? <Spinner animation='border' size='sm' /> : 'Reject'}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

const SimpleTable = ({ headers, rows, onRowClick = null, rowClickCondition }) => {
  const [sort, setSort] = useState()
  const [direction, setDirection] = useState('asc')

  const toggleSort = index => () => {
    if (sort === index) {
      setDirection(prev => prev === 'asc' ? 'desc' : 'asc')
    }
    else {
      setSort(index)
      setDirection('asc')
    }
  }

  const sortedRows = _.orderBy(rows.map(row => row.reduce((acc, cell, index) => ({ ...acc, [index]: cell }), {})), sort, direction).map(Object.values)

  return (
    <div>
      <Table hover className='dynamic-table'>
        <thead>
          <tr>
            {headers.map((header, index) => {
              return (
                <th key={index} onClick={toggleSort(index)}>
                  {header}
                  {
                    sort === index
                    ? <TriangleFill
                        style={{
                          transform: sort === index && direction === 'desc' ? 'rotate(0deg)' : 'rotate(60deg)',
                          float: 'right',
                          width: '10px'
                        }}
                      />
                    : <Dash style={{float: 'right', width: '10px'}}/>
                  }
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody>
          {sortedRows.map(row => {
            const meta = { key: row[0] }
            if (rowClickCondition(row) && onRowClick) {
              meta.onClick = () => onRowClick(row[0])
              meta.style = {'cursor': 'pointer'}
            }
            return (
              <tr { ...meta }>
                {row.map((cell, index) => (
                  <td key={index}>{cell}</td>
                ))}
              </tr>
            )
          })}
        </tbody>
      </Table>
    </div>
  )
}

export default Reservations

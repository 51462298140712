import * as VerifyMobileNumber from './VerifyMobileNumber'
import * as SignupForm from './SignupForm'
import * as VerifyCode from './VerifyCode'
import * as ResetPasswordForm from './ResetPasswordForm'

export const SignupTopics = {
  VerifyMobileNumber,
  VerifyCode,
  SignupForm
}

export const ForgotPasswordTopics = {
  VerifyMobileNumber,
  VerifyCode,
  ResetPasswordForm
}

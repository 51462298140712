import { useState } from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import _ from 'lodash'

import Editable from '../../../../components/basic/Editable'

const Appender = ({ onSubmit, mapper, initialValue=[], children }) => {
  const [objects, setObjects] = useState(initialValue)
  const [showAddObject, setShowAddObject] = useState(true)

  const appendObject = object => {
    if (!_.isEmpty(object)) {
      setObjects([...objects, object])
      setShowAddObject(false)
    }
    else {
      onSubmit(objects)
    }
  }

  const editObject = (close, index) => data => {
    setObjects(objects.map((study, i) => {
      if (index === i) return data
      else return study
    }))
    close()
  }

  const MyForm = children

  return (
    showAddObject
    ? <MyForm initialValue={{}} onSubmit={appendObject}/>
    : (
      <Form className='bottom-button-form'>
        {objects.map((study, index) => (
          <div key={index}>
            <Editable value={mapper(study)}>
              {close => (
                <MyForm initialValue={study} onSubmit={editObject(close, index)}/>
              )}
            </Editable>
          </div>
        ))}
        <div className='vertical-button-group'>
          <Button onClick={() => onSubmit(objects)}>استمرار</Button>
          <Button variant='outline-primary' onClick={() => setShowAddObject(true)}>إضافة جديد</Button>
        </div>
      </Form>
    )
  )
}

export default Appender

import axios from 'axios'
import _ from 'lodash'
import { fromArabic } from 'arabic-digits'

const useArabicNumbersInterceptor = () => {
  axios.interceptors.request.use(config => {
    const { data, headers } = config

    if (headers['Content-Type'] != 'multipart/form-data') {

      config.data = _.mapValues(data, (value, key) => {
        if (key.toLowerCase().includes('mobile')) {
          return fromArabic(value)
        }
        return value
      })
    }

    return config
  })
}

export default useArabicNumbersInterceptor

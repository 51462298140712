import { call, put, takeLatest } from 'redux-saga/effects'

import * as Api from '../api/requesterApi'
import { AuthActions } from '../reducers/authSlice'
import { RequesterActions } from '../reducers/requesterSlice'

function* listPatients (action) {
  try {
    const response = yield call(Api.listPatientsApi, action.payload)
    const patients = response.data
    yield put(RequesterActions.listRequesterPatientsSuccess({ patients }))
  } catch (error) {
    yield put(RequesterActions.listRequesterPatientsFailure(error.response?.data?.error || error))
  }
}

function* createPatient (action) {
  try {
    const { requesterId, ...data } = action.payload
    const response = yield call(Api.createPatientApi, requesterId, data)
    yield put(RequesterActions.createRequesterPatientSuccess({ patient: response.data }))
  } catch (error) {
    yield put(RequesterActions.createRequesterPatientFailure(error.response?.data?.error || error))
  }
}

function* updatePatient (action) {
  try {
    const { requesterId, patientId, ...data } = action.payload
    const response = yield call(Api.updatePatientApi, requesterId, patientId, data)
    yield put(RequesterActions.updatePatientSuccess({ patient: response.data }))
  } catch (error) {
    yield put(RequesterActions.updatePatientFailure(error.response?.data?.error || error))
  }
}

function* getRequesterDetails(action) {
  try {
    const response = yield call(Api.getRequesterDetailsApi, action.payload)
    yield put(RequesterActions.getRequesterDetailsSuccess({ requester: response.data }))
  } catch (error) {
    yield put(RequesterActions.getRequesterDetailsFailure(error.response?.data?.error || error))
  }
}

function* updateRequester(action) {
  try {
    const response = yield call(Api.updateRequesterApi, action.payload)
    yield put(RequesterActions.updateRequesterSuccess({ requester: response.data }))
    yield put(AuthActions.refreshTokenRequest())
  } catch (error) {
    yield put(RequesterActions.updateRequesterFailure(error.response?.data?.error || error))
  }
}

function* getRequesterPatient(action) {
  try {
    const response = yield call(Api.getRequesterPatientApi, action.payload)
    yield put(RequesterActions.getRequesterPatientSuccess({ patient: response.data }))
  } catch (error) {
    yield put(RequesterActions.getRequesterPatientFailure(error.response?.data?.error || error))
  }
}

function* getPatientDetails(action) {
  try {
    const response = yield call(Api.getPatientDetailsApi, action.payload)
    yield put(RequesterActions.getPatientDetailsSuccess({ patient: response.data }))
  } catch (error) {
    yield put(RequesterActions.getPatientDetailsFailure(error.response?.data?.error || error))
  }
}

function* listProviders(action) {
  try {
    const response = yield call(Api.listProvidersApi, action.payload)
    yield put(RequesterActions.listProvidersSuccess({ providers: response.data }))
  } catch (error) {
    yield put(RequesterActions.listProvidersFailure(error.response?.data?.error || error))
  }
}

export default function* requesterSaga() {
  yield takeLatest(RequesterActions.listRequesterPatientsRequest, listPatients)
  yield takeLatest(RequesterActions.createRequesterPatientRequest, createPatient)
  yield takeLatest(RequesterActions.updatePatientRequest, updatePatient)
  yield takeLatest(RequesterActions.getRequesterDetailsRequest, getRequesterDetails)
  yield takeLatest(RequesterActions.updateRequesterRequest, updateRequester)
  yield takeLatest(RequesterActions.getRequesterPatientRequest, getRequesterPatient)
  yield takeLatest(RequesterActions.getPatientDetailsRequest, getPatientDetails)
  yield takeLatest(RequesterActions.listProvidersRequest, listProviders)
}

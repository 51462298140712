import axios from 'axios'
import { ReservationStatus } from '../../components/common'
import { loginApi } from './authApi'

export const getPricingApi = ({ areaId, tag, providerType }) => {
  const params = { areaId, providerType }
  if (tag) params.tag = tag

  return axios.get(
    `${process.env.REACT_APP_BE_URL}/pricing`,
    {
      params
    }
  )
}

export const getAgreementApi = ({ agreementType }) => {
  return axios.get(
    `${process.env.REACT_APP_BE_URL}/agreement?agreementType=${agreementType}`
  )
}

export const createReservationApi = (data) => {
  return axios.post(`${process.env.REACT_APP_BE_URL}/reservations`, data)
}

export const listReservationsApi = ({ requesterId }) => {
  return axios.get(`${process.env.REACT_APP_BE_URL}/reservations`, {
    params: { requesterId }
  })
}

export const acceptReservationApi = ({ reservationId }) => {
  return axios.patch(`${process.env.REACT_APP_BE_URL}/reservations/${reservationId}/status`, {
    status: ReservationStatus.UPCOMING
  })
}

export const rejectReservationApi = ({ reservationId }) => {
  return axios.patch(`${process.env.REACT_APP_BE_URL}/reservations/${reservationId}/status`, {
    status: ReservationStatus.PENDING_SPR
  })
}

export const cancelReservationApi = ({ reservationId, reason }) => {
  return axios.patch(`${process.env.REACT_APP_BE_URL}/reservations/${reservationId}/status`, {
    status: ReservationStatus.CANCELLED,
    reason
  })
}

export const getReservationApi = ({ reservationId }) => {
  return axios.get(`${process.env.REACT_APP_BE_URL}/reservations/${reservationId}`)
}

export const getLastReservationApi = ({ requesterId }) => {
  return axios.get(`${process.env.REACT_APP_BE_URL}/reservations/last?requesterId=${requesterId}`)
}

export const submitReviewApi = ({ reservationId, ...review }) => {
  return axios.patch(`${process.env.REACT_APP_BE_URL}/reservations/${reservationId}/requesterReview`, review)
}

export const submitDataCompletionCallApi = ({ reservationId, ...authData }) => {
  return loginApi(authData).then(response => {
    const { accessToken } = response.data

    return axios.patch(
      `${process.env.REACT_APP_BE_URL}/reservations/${reservationId}/dataCompletionCallRequested`,
      null,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })
  })
}

export const createGuestReservationApi = (data) => {
  return axios.post(`${process.env.REACT_APP_BE_URL}/guests/reserve`, data)
}

export const assignProvidersApi = ({ reservationId, assigneeIds }) => {
  return axios.patch(
    `${process.env.REACT_APP_BE_URL}/reservations/${reservationId}/status`,
    { assigneeIds, status: ReservationStatus.PENDING_SPR }
  )
}

import axios from 'axios'

export const acceptApi = ({ id }) => {
  return axios.patch(
    `${process.env.REACT_APP_BE_URL}/providerAssignment/${id}/status`,
    null,
    { params: { status: 'Accepted' } }
  )
}

export const rejectApi = ({ id }) => {
  return axios.patch(
    `${process.env.REACT_APP_BE_URL}/providerAssignment/${id}/status`,
    null,
    { params: { status: 'Rejected' } }
  )
}

import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'

import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict'
import { arSA } from 'date-fns/locale'

import Nav from 'react-bootstrap/Nav'
import Button from 'react-bootstrap/Button'

import Screen from '../components/layout/Screen'
import Header from '../components/layout/Header'
import Main from '../components/layout/Main'
import Footer from '../components/layout/Footer'

import Svg from '../components/basic/Svg'
import PersonCard from '../components/PersonCard'

import {ReactComponent as AddPatientImage} from '../svg/add_patient.svg'
import {ReactComponent as EmptyInactivePatientsImage} from '../svg/empty_inactive_patients.svg'

import { RequesterActions, selectRequesterSlice } from '../redux/reducers/requesterSlice'
import { selectAuth } from '../redux/reducers/authSlice'

import NotificationButton from '../components/basic/NotificationButton'

export const EmptyActivePatientsBody = ({ requesterId, history, children, state }) => {
  return (
    <div>
      <Svg as={AddPatientImage} className='add-patient-svg' />
      {children}
      <Button onClick={() => history.push(`/requester/${requesterId}/patient/new`, state)}>إضافة مريض</Button>
    </div>
  )
}

const EmptyInactivePatientsBody = () => {
  return (
    <>
      <Svg as={EmptyInactivePatientsImage} className='empty-inactive-patient-svg' />
      <p className='empty-patients-title'>
        <b>لا يوجد حسابات غير مفعلة</b>
      </p>
      <p className='empty-patients-message'>الحسابات الغير مفعلة هي تم تحويلها من مفعله إلي غير مفعلة من قبل النظام</p>
    </>
  )
}

const MyPatientsScreen = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [visiblePatients, setVisiblePatients] = useState('active')
  const { userId: requesterId } = useSelector(selectAuth)
  const { patients } = useSelector(selectRequesterSlice)

  const activePatients = patients.filter(patient => patient.status.toLowerCase() === 'active')
  const inactivePatients = patients.filter(patient => patient.status.toLowerCase() === 'inactive')

  useEffect(() => dispatch(RequesterActions.listRequesterPatientsRequest({ requesterId })), [dispatch, requesterId])

  const rightItem = activePatients.length ? <Link to={`/requester/${requesterId}/patient/new`}>إضافة مريض</Link> : null

  const openPatientDetails = (patientId) => {
    history.push(`/requester/${requesterId}/patient/${patientId}`)
  }

  const renderBody = () => {
    switch (visiblePatients) {
      case 'active':
        if (activePatients.length) {
          return (
            <PersonCard.Group>
              {activePatients.map(patient => {
                return (
                  <PersonCard relaxed key={patient.id} onClick={() => openPatientDetails(patient.id)}>
                    <PersonCard.Title>{patient.name}</PersonCard.Title>
                    <PersonCard.Content>{patient.birthDate ? formatDistanceToNowStrict(Date.parse(patient.birthDate), { locale: arSA }) : patient.age}</PersonCard.Content>
                    <PersonCard.Content>{patient.mobileNumber}</PersonCard.Content>
                    <PersonCard.Content>{patient.city.arabicName} - {patient.area.arabicName} - {patient.address}</PersonCard.Content>
                  </PersonCard>
                )
              })}
            </PersonCard.Group>
          )
        }
        return (
          <EmptyActivePatientsBody history={history} requesterId={requesterId}>
            <p className='empty-patients-title'>
              <b>لا يوجد مرضى مضافة</b>
            </p>
            <p className='empty-patients-message'>حتى تتمكن من حجز خدمة لابد من إضافة مريض جديد</p>
          </EmptyActivePatientsBody>
        )
      default:
        if (inactivePatients.length) {
          return inactivePatients.map(() => {
            return <PersonCard></PersonCard>
          })
        }
        return <EmptyInactivePatientsBody />
    }
  }

  const handleSelection = (e) => {
    setVisiblePatients(e.target.dataset.rrUiEventKey)
  }

  return (
    <Screen>
      <Header
        leftItem={<NotificationButton />}
        rightItem={rightItem}
        middleItem={<b>المرضى</b>}
      />
      <Main>
        <Nav variant="pills" defaultActiveKey="active">
          <Nav.Item>
            <Nav.Link eventKey='active' onClick={handleSelection}>مفعلة</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey='inactive' onClick={handleSelection}>غير مفعلة</Nav.Link>
          </Nav.Item>
        </Nav>
        {renderBody()}
      </Main>
      <Footer />
    </Screen>
  )
}

export default MyPatientsScreen

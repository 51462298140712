import {ReactComponent as Logo} from '../../svg/logo_white.svg'
import Svg from '../basic/Svg'
import { withProps } from '../common'

const Header = ({ leftItem, middleItem, rightItem, floatingIcon, direction }) => {
  return (
    <div className='header'>
      { floatingIcon && <Svg as={Logo} className='header-floating-icon' /> }
      <div className='header-right-item'>
        {withProps(rightItem, { direction })}
      </div>
      <div className='header-middle-item'>
        {withProps(middleItem, { direction, className: 'header-middle-item' })}
      </div>
      <div className='header-left-item'>
        {withProps(leftItem, { direction })}
      </div>
    </div>
  )
}

export default Header
import { Formik } from 'formik'
import * as yup from 'yup'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'

import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import BottomSheet from './basic/BottomSheet'
import Success from './Success'

import { ReservationActions, selectReservation } from '../redux/reducers/reservationSlice'
import { ProviderTypes, ReservationStatus, ServiceTypes } from './common'

const CoinsBanner = ({ children }) => <div className='coins-banner'>{children}</div>

CoinsBanner.Title = ({ children }) => <div className='coins-banner-title'>{children}</div>

CoinsBanner.Body = ({ points }) => (
  <div>على التقييم الشامل وإكسب <span className='label-hint'>{points} نقطة</span> تقدر تستخدمها في تخفيض قيمة الخدمات الممقبلة </div>
)

const schema = yup.object().shape({
  rate: yup.number().integer().min(1).max(4).required('من فضلك قم بادخال المعلومات كاملة').default(4),
  comment: yup.string()
})

const Rate = ({ value, onChange, name }) => {
  return (
    <>
      <Row>
        <Col>
          <Form.Check
            type='radio'
            label='ممتاز'
            name={name}
            value={4}
            onChange={onChange}
            checked={parseInt(value) === 4}
          />
        </Col>
        <Col>
          <Form.Check
            type='radio'
            label='جيد'
            name={name}
            value={3}
            onChange={onChange}
            checked={parseInt(value) === 3}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Check
            type='radio'
            label='مقبول'
            name={name}
            value={2}
            onChange={onChange}
            checked={parseInt(value) === 2}
          />
        </Col>
        <Col>
          <Form.Check
            type='radio'
            label='سيء'
            name={name}
            value={1}
            onChange={onChange}
            checked={parseInt(value) === 1}
          />
        </Col>
      </Row>
    </>
  )
}

const ServiceSurvey = ({ onSubmit, isLoading }) => {
  return (
    <Formik
      validationSchema={schema}
      initialValues={schema.default()}
      onSubmit={onSubmit}
      validateOnChange={false}
    >
      {({
        values,
        handleChange,
        handleSubmit
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Rate
            value={values.rate}
            name='rate'
            onChange={handleChange}
          />
          <Form.Group>
            <Form.Label>اترك ملاحظات اخرى</Form.Label>
            <Form.Control
              as='textarea'
              placeholder='اترك ملاحظات اخرى . . . .'
              name='comment'
              value={values.comment || ''}
              onChange={handleChange}
            />
          </Form.Group>
          <CoinsBanner>
            <CoinsBanner.Title>جاوب وإكسب</CoinsBanner.Title>
            <CoinsBanner.Body points={25}/>
          </CoinsBanner>
          <div className='vertical-button-group'>
            <Button type='submit' onClick={() => values.continue = true}>التقييم وإستمرار التقييم الشامل</Button>
            <Button type='submit' variant='outline-primary'>
              {isLoading ? <Spinner animation="border" size='sm' /> : 'تقييم الخدمه والإنهاء'}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

ServiceSurvey.Title = 'ماهو تقييمك العام للخدمة'

const Questionaire = ({ onSubmit, reservation, isLoading }) => {
  const [index, setIndex] = useState(0)
  const [answers, setAnswers] = useState({})
  const questions = (() => {
    switch (reservation.providerType) {
      case ProviderTypes.DOCTOR.value:
        return [
          'ما هو تقييمك لالتزام الطبيب بممارسة الوقاية و الصحة العامة (ارتداء الكمامة، استعمال المطهرات و القفزات الطبية، الخ) ؟',
          'ما هو تقييمك لالتزام الطبيب بموعده وأسلوبه في التعامل مع الاسرة؟',
          'ما هو تقييمك العام للطبيب في الكشف والتعامل مع المريض؟',
          'ما هو تقييمك العام لمسئول خدمة العملاء؟',
          'ما هو تقييمك العام للتطبيق؟',
          'بناءاً على آخر تعامل مع خدمتنا، ما مدى ترشيحك لخدماتنا؟'
        ]
      case ProviderTypes.NURSE.value:
        return [
          'ما هو تقييمك لالتزام الممرض/الممرضة بممارسة الوقاية و الصحة العامة (ارتداء الكمامة، استعمال المطهرات و القفزات الطبية، الخ)؟',
          'ما هو تقييمك لالتزام الممرض/الممرضة بموعده وأسلوبه في التعامل مع الاسرة؟',
          'ما هو تقييمك العام للممرض/الممرضة في التعامل مع المريض والتزامه بارشادات الطبيب؟',
          'ما هو تقييمك العام لمسئول خدمة العملاء؟',
          'ما هو تقييمك العام للتطبيق؟',
          'بناءاً على آخر تعامل مع خدمتنا، ما مدى ترشيحك لخدماتنا؟'
        ]
      case ProviderTypes.HOME_CARE_SPECIALIST.value:
        return [
          'ما هو تقييمك لالتزام الجليس/الجليسة بممارسة الوقاية و الصحة العامة (ارتداء الكمامة، استعمال المطهرات و القفزات الطبية، الخ) أثناء التعامل مع المريض؟',
          'ما هو تقييمك لالتزام الجليس/الجليسة بتوجيهات نظام المنزل؟',
          'ماهو تقييمك لأسلوب الجليس/الجليسة في التعامل مع باقي أفراد الأسرة؟',
          'ما هو تقييمك العام لآداء الجليس/الجليسة الخاص بالأعمال المطلوبة في رعاية المريض؟',
          'ما هو تقييمك العام لمسئول خدمة العملاء؟',
          'ما هو تقييمك العام للتطبيق؟',
          'بناءاً على آخر تعامل مع خدمتنا، ما مدى ترشيحك لخدماتنا؟'
        ]
      case ProviderTypes.PHYSICAL_THERAPIST.value:
        return [
          'ما هو تقييمك لالتزام دكتور العلاج الطبيعي بممارسة الوقاية و الصحة العامة (ارتداء الكمامة، استعمال المطهرات و القفزات الطبية، الخ)   ؟',
          'ما هو تقييمك لالتزام دكتور العلاج الطبيعي بموعده وأسلوبه في التعامل مع الاسرة؟',
          'ما هو تقييمك العام لدكتور العلاج الطبيعي في التعامل مع المريض والتزامه بارشادات الطبيب؟',
          'ما هو تقييمك العام لمسئول خدمة العملاء؟',
          'ما هو تقييمك العام للتطبيق؟',
          'بناءاً على آخر تعامل مع خدمتنا، ما مدى ترشيحك لخدماتنا؟'
        ]
      case ProviderTypes.MEDICAL_RAYS_ANALYSIS.value:
      default:
        if (reservation.serviceType === ServiceTypes.MEDICAL_ANALYSIS) {
          return [
            'ما هو تقييمك لالتزام دكتور التحاليل بممارسة الوقاية و الصحة العامة (ارتداء الكمامة، استعمال المطهرات و القفزات الطبية، الخ)؟',
            'ما هو تقييمك لالتزام دكتور التحاليل بموعده وأسلوبه في التعامل مع الاسرة؟',
            'ما هو تقييمك لدكتور التحاليل بمدى التزامه بموعد تسليم النتيجة؟',
            'ما هو تقييمك لمدى سهولة استلام نتيجة التحليل ؟',
            'ما هو تقييمك العام لمسئول خدمة العملاء؟',
            'ما هو تقييمك العام للتطبيق؟',
            'بناءاً على آخر تعامل مع خدمتنا، ما مدى ترشيحك لخدماتنا؟'
          ]
        }
        return [
          'ما هو تقييمك لالتزام مسئول عمل الأشعة بممارسة الوقاية و الصحة العامة (ارتداء الكمامة، استعمال المطهرات و القفزات الطبية، الخ)؟',
          'ما هو تقييمك لالتزام مسئول عمل الأشعة بموعده وأسلوبه في التعامل مع الاسرة؟',
          'ما هو تقييمك لدكتور الأشعة بمدى التزامه بموعد تسليم النتيجة؟',
          'ما هو تقييمك لمدى سهولة استلام تقرير الاشعه ؟',
          'ما هو تقييمك العام لمسئول خدمة العملاء؟',
          'ما هو تقييمك العام للتطبيق؟',
          'بناءاً على آخر تعامل مع خدمتنا، ما مدى ترشيحك لخدماتنا؟'
        ]
    }
  })()

  const incrementThenSubmit = (form, { resetForm }) => {
    const updatedAnswers = {
      ...answers,
      [questions[index]]: { rate: parseInt(form.rate), comment: form.comment }
    }
    if(index < questions.length - 1) {
      setAnswers(updatedAnswers)
      resetForm({ values: answers[questions[index + 1]] || schema.default() })
      setIndex(index + 1)

      onSubmit({ continue: true, questions: updatedAnswers })
    }
    else {
      onSubmit({ continue: false, questions: updatedAnswers })
    }
  }

  const decrement = (resetForm) => {
    resetForm({ values: answers[questions[index - 1]] || schema.default() })
    setIndex(index - 1)
  }

  return (
    <>
      <div className='question-number'>سؤال {index + 1} من {questions.length}</div>
      <Formik
        validationSchema={schema}
        initialValues={schema.default()}
        onSubmit={incrementThenSubmit}
        validateOnChange={false}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          resetForm
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label>{questions[index]}</Form.Label>
              <Rate value={values.rate} name='rate' onChange={handleChange}/>
            </Form.Group>
            <Form.Group>
              <Form.Label>اترك ملاحظات اخرى</Form.Label>
              <Form.Control
                as='textarea'
                placeholder='اترك ملاحظات اخرى . . . .'
                name='comment'
                onChange={handleChange}
                value={values.comment || ''}
              />
            </Form.Group>

            <div className='buttons'>
              <Button type='submit' disabled={isLoading}>
                {isLoading ? <Spinner animation="border" size='sm' /> : (index < questions.length - 1) ? 'السؤال التالى' : 'إرسال'}
              </Button>
              {(index > 0) && <Button variant='outline-primary' onClick={() => decrement(resetForm)}>السؤال السابق</Button>}
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}

Questionaire.Title = 'التقييم الشامل'

const Survey = ({ requesterId }) => {
  const dispatch = useDispatch()
  const [survey, updateSurvey] = useState({})
  const [SurveyForm, setForm] = useState(() => ServiceSurvey)
  const { last, status } = useSelector(selectReservation)

  const [show, setShow] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)

  useEffect(() => {
    if (!show) {
      setShow(last?.status === ReservationStatus.COMPLETED && !last?.requesterReview)
    }
    else if (last.requesterReview) {
      setShow(false)
      setShowSuccess(true)
    }
  }, [last, show])

  const reshapeQuestions = questions => Object.keys(questions || {}).map(question => ({
    question,
    rate: questions[question].rate,
    comment: questions[question].comment
  }))

  const onSubmit = data => {
    const updatedSurvey = { ...survey, ...data }
    updateSurvey(updatedSurvey)

    if (SurveyForm === ServiceSurvey && data.continue) {
      setForm(() => Questionaire)
    }
    else if (!data.continue) {
      updatedSurvey.questions = reshapeQuestions(updatedSurvey.questions)
      dispatch(ReservationActions.submitReviewRequest({ reservationId: last.id, ...updatedSurvey }))
    }
  }

  useEffect(() => {
    dispatch(ReservationActions.getLastReservationRequest({ requesterId }))
  }, [dispatch, requesterId])

  const close = () => {
    dispatch(ReservationActions.submitReviewRequest({
      reservationId: last.id,
      ...survey,
      questions: reshapeQuestions(survey.questions)
    }))
  }

  return (
    <>
      <BottomSheet title={SurveyForm.Title} show={show} close={(SurveyForm === Questionaire) && close}>
        <SurveyForm onSubmit={onSubmit} reservation={last} isLoading={status === 'loading'}/>
      </BottomSheet>
      <Success
        show={showSuccess}
        title='تم الإنتهاء من التقييم بنجاح !'
        message='نحن حريصون دائماً على الحفاظ علي جودة الخدمة من اجل صحة المرضى'
        close={() => setShowSuccess(false)}
      />
    </>
  )
}

export default Survey

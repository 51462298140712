import { Formik } from 'formik'
import Form from 'react-bootstrap/Form'
import * as yup from 'yup'
import { useState, useEffect, useMemo, forwardRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import qs from 'query-string'

import Button from 'react-bootstrap/Button'

import Screen from '../components/layout/Screen'
import Header from '../components/layout/Header'
import Main from '../components/layout/Main'

import Choice from '../components/basic/Choice'
import DatePicker from '../components/basic/DatePicker'
import Select from '../components/basic/Select'

import Success from '../components/Success'
import LocationInfoForm from '../components/LocationInfoForm'

import { Genders, MOBILE_NUMBER_REGEX } from '../components/common'

import { RequesterActions, selectRequesterSlice } from '../redux/reducers/requesterSlice'

import { chronicDiseases } from '../components/common'

const PersonalInfoForm = forwardRef(({ onSubmit, isRequester, requesterId }, ref) => {
  const dispatch = useDispatch()
  const [knowBirthdate, setKnowBirthdate] = useState(true)
  const [initialForm, setInitialForm] = useState({})
  const { details } = useSelector(selectRequesterSlice)

  const ageRanges = useMemo(() => [
    { text: 'أقل من 18 عام', value: 'أقل من 18 عام' },
    { text: 'ما بين 18 حتى 30 عام', value: 'ما بين 18 حتى 30 عام' },
    { text: 'ما بين 30 حتى 45 عام', value: 'ما بين 30 حتى 45 عام' },
    { text: 'ما بين 45 حتى 60 عام', value: 'ما بين 45 حتى 60 عام' },
    { text: 'من 60 عام إلى أعلى ', value: 'من 60 عام إلى أعلى ' }
  ], [])

  useEffect(() => {
    if (isRequester) {
      dispatch(RequesterActions.getRequesterDetailsRequest({ requesterId }))
    }
  }, [isRequester, requesterId, dispatch])

  const schema = yup.object().shape({
    firstName: yup.string().required('من فضلك قم بادخال المعلومات كاملة'),
    lastName: yup.string().required('من فضلك قم بادخال المعلومات كاملة').default(''),
    birthDate: knowBirthdate ? yup.date().required('من فضلك قم بادخال المعلومات كاملة') : yup.date().strip(),
    age: knowBirthdate ? yup.string().strip() : yup.string().required('من فضلك قم بادخال المعلومات كاملة').default(ageRanges[0]),
    mobileNumber: yup.string().matches(MOBILE_NUMBER_REGEX, 'من فضلك أدخل رقم هاتف صحيح').required('من فضلك قم بادخال المعلومات كاملة'),
    gender: yup.string().oneOf(Genders.getValues()).default(Genders.getValues()[0]).required('من فضلك قم بادخال المعلومات كاملة')
  })

  useEffect(() => {
    setInitialForm({
      firstName: details.firstName,
      lastName: details.lastName,
      mobileNumber: details.mobileNumber
    })
  }, [details])

  return (
    <Formik
      validationSchema={schema}
      onSubmit={onSubmit}
      initialValues={{ ...schema.default(), ...initialForm }}
      validateOnChange={false}
      enableReinitialize={true}
      innerRef={ref}
    >
      {({
        handleSubmit,
        handleChange,
        errors,
        setFieldValue,
        values
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          {!isRequester && (
            <>
              <Form.Group>
                <Form.Label>الإسم الأول</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='ادخل الاسم الأول'
                  name='firstName'
                  onChange={handleChange}
                  isInvalid={errors.firstName}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.firstName}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group>
                <Form.Label>الإسم الثاني</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='ادخل الاسم الثاني'
                  name='lastName'
                  onChange={handleChange}
                  isInvalid={errors.lastName}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.lastName}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group>
                <Form.Label>هل تعلم تاريخ ميلاد المريض ؟</Form.Label>
                <Choice
                  options={['نعم', 'لا']}
                  value={knowBirthdate ? 'نعم' : 'لا'}
                  onChange={() => {
                    setKnowBirthdate(!knowBirthdate)
                    setInitialForm(values)
                  }}
                />
              </Form.Group>
            </>
          )}

          {
            knowBirthdate
            ? <Form.Group>
                <Form.Label>تاريخ الميلاد</Form.Label>
                <DatePicker
                  label='تحديد تاريخ الميلاد'
                  onChange={date => setFieldValue('birthDate', date)}
                  isInvalid={errors.birthDate}
                  errorMessage={errors.birthDate}
                  birthDate
                />
              </Form.Group>
            : <Form.Group>
                <Form.Label>السن التقريبى للمريض</Form.Label>
                <Select
                  name='age'
                  label='السن التقريبى للمريض'
                  options={ageRanges}
                  value={values.age}
                  onSelect={value => setFieldValue('age', value)}
                  isInvalid={errors.age}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.age}
                </Form.Control.Feedback>
              </Form.Group>
          }

          {!isRequester && (
            <Form.Group>
              <Form.Label>رقم الهاتف</Form.Label>
              <Form.Control
                type='text'
                placeholder='ادخل رقم الهاتف'
                name='mobileNumber'
                onChange={handleChange}
                isInvalid={errors.mobileNumber}
              />
              <Form.Control.Feedback type='invalid'>
                {errors.mobileNumber}
              </Form.Control.Feedback>
            </Form.Group>
          )}

          <Form.Group>
            <Form.Label>الجنس</Form.Label>
            <Choice
              name='gender'
              options={Genders.getTexts()}
              value={Genders.getTextByValue(values.gender)}
              onChange={value => setFieldValue('gender', Genders.getValueByText(value))}
            />
          </Form.Group>

          <Button type='submit'>
            إستمرار
          </Button>
        </Form>
      )}
    </Formik>
  )
})

const MedicalInfoForm = forwardRef(({ onSubmit }, ref) => {
  const schema = yup.object().shape({
    chronicDiseases: yup.array(yup.string()).default([]),
    medications: yup.string(),
    height: yup.number().min(10, 'الطول الذي ادخلته غير صحيح'),
    weight: yup.number().min(1, 'الوزن الذي ادخلته غير صحيح'),
    medicalHistory: yup.string()
  })

  return (
    <Formik
      validationSchema={schema}
      onSubmit={onSubmit}
      initialValues={schema.default()}
      validateOnChange={false}
      innerRef={ref}
    >
      {({
        handleSubmit,
        handleChange,
        errors,
        setFieldValue,
        values
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label>الأمراض المزمنة</Form.Label>
            <Select
              multiple
              other
              name='chronicDiseases'
              label='الأمراض المزمنة'
              options={chronicDiseases}
              value={values.chronicDiseases}
              onSelect={value => setFieldValue('chronicDiseases', value)}
              isInvalid={errors.chronicDiseases}
              placeholder='الأمراض المزمنة'
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>الأدوية</Form.Label>
            <Form.Control
              type='text'
              placeholder='ابحث عن  اسم الدواء'
              name='medications'
              onChange={handleChange}
              isInvalid={errors.medications}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>الوزن بالكيلو جرام</Form.Label>
            <Form.Control
              type='number'
              placeholder='الوزن بالكيلو جرام'
              name='weight'
              onChange={handleChange}
              isInvalid={errors.weight}
            />
            <Form.Control.Feedback type='invalid'>
              {errors.weight}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group>
            <Form.Label>الطول بالسنتيمتر</Form.Label>
            <Form.Control
              type='number'
              placeholder='الطول بالسنتيمتر'
              name='height'
              onChange={handleChange}
              isInvalid={errors.height}
            />
            <Form.Control.Feedback type='invalid'>
              {errors.height}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group>
            <Form.Label>التاريخ الطبى</Form.Label>
            <Form.Control
              type='text'
              as='textarea'
              placeholder='التاريخ الطبى'
              name='medicalHistory'
              onChange={handleChange}
              isInvalid={errors.medicalHistory}
            />
          </Form.Group>

          <Button type='submit'>
            إستمرار
          </Button>

        </Form>
      )}
    </Formik>
  )
})

const CreatePatientForm = forwardRef(({ requesterId, onDone, wrapper: Wrapper }, ref) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { isRequester, providerType, serviceType } = history.location.state || {}

  const [activeIndex, setActiveIndex] = useState(0)
  const [form, setForm] = useState({})

  const { patients, status } = useSelector(selectRequesterSlice)

  const topics = useMemo(() => [PersonalInfoForm, MedicalInfoForm, LocationInfoForm], [])
  const titles = ['إضافة مريض', 'بيانات طبية', 'تحديد عنوان المريض']

  const Topic = topics[activeIndex]

  const onSubmit = (data) => {
    setForm({ ...form, ...data })
    if (activeIndex < topics.length - 1) {
      setActiveIndex(activeIndex + 1)
      window.scrollTo({ top: 0 }) // to handle small screens
    }
    else {
      dispatch(RequesterActions.createRequesterPatientRequest({ ...form, ...data, requesterId, isRequester }))
    }
  }

  useEffect(() => {
    if (activeIndex === topics.length - 1 && status ==='loading') {
      if (providerType) {
        history.push({
          pathname: '/reservations/new',
          search: qs.stringify({
            providerType,
            serviceType
          })
        })
      }
      else if (isRequester) {
        history.goBack()
      }
      else onDone()
    }
  }, [activeIndex, topics, patients.length, status, isRequester, history, providerType, serviceType])

  return (
    <Wrapper title={titles[activeIndex]}>
      <Topic
          onSubmit={onSubmit}
          data={form}
          isRequester={isRequester}
          requesterId={requesterId}
          ref={ref}
        />
    </Wrapper>
  )
})

const CreatePatientScreen = ({ userId: requesterId }) => {
  const [showSuccess, setShowSuccess] = useState(false)
  const history = useHistory()

  return (
    <CreatePatientForm
      requesterId={requesterId}
      onDone={() => setShowSuccess(true)}
      wrapper={({ title, children }) => (
        <Screen>
          <Header
            middleItem={<b>{title}</b>}
            leftItem={<div onClick={() => history.goBack()}>إلغاء</div>}
          />
          <Main>
            {children}
            <Success
              show={showSuccess}
              title='تم إضافة المريض بنجاح !'
              message='يمكنك الاًن حجز العديد من الخدمات المنزلية والهاتفيه ومتابعتها من خلال تطبيقنا !'
              buttonText='تم'
              onButtonClick={() => history.push(`/requester/${requesterId}/patients`)}
            />
          </Main>
        </Screen>
      )}
    />
  )
}

CreatePatientScreen.Form = CreatePatientForm

export default CreatePatientScreen

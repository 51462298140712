import { call, put, takeLatest } from 'redux-saga/effects'
import * as Api from '../api/dashboardApi'

import { DashboardActions } from '../reducers/dashboardSlice'

function* list(action) {
   try {
      const response = yield call(Api.listApi, action.payload)
      yield put(DashboardActions.listSuccess({ resource: action.payload.resource, body: response.data }))
   } catch (error) {
      yield put(DashboardActions.listFailure(error.response?.data?.error || error))
   }
}

function* create(action) {
   try {
      const { resource } = action.payload
      const response = yield call(Api.createApi, action.payload)
      yield put(DashboardActions.createSuccess({ resource, body: response.data }))
   } catch (error) {
      yield put(DashboardActions.createFailure(error.response?.data?.error || error))
   }
}

function* update(action) {
try {
      const { resource } = action.payload
      const response = yield call(Api.updateApi, action.payload)
      yield put(DashboardActions.updateSuccess({ resource, body: response.data }))
   } catch (error) {
      yield put(DashboardActions.updateFailure(error.response?.data?.error || error))
   }
}

function* detail(action) {
   try {
      const { resource } = action.payload
      const response = yield call(Api.detailApi, action.payload)
      yield put(DashboardActions.detailSuccess({ resource, body: response.data }))
   } catch (error) {
      yield put(DashboardActions.detailFailure(error.response?.data?.error || error))
   }
}

function* updatePatientStatus(action) {
   try {
      const response = yield call(Api.updatePatientStatusApi, action.payload)
      yield put(DashboardActions.updatePatientStatusSuccess({ patient: response.data }))
   } catch (error) {
      yield put(DashboardActions.detailFailure(error.response?.data?.error || error))
   }
}

function* updateServiceProviderStatus(action) {
   try {
      const response = yield call(Api.updateServiceProviderStatusApi, action.payload)
      yield put(DashboardActions.updateServiceProviderStatusSuccess({ patient: response.data }))
   } catch (error) {
      yield put(DashboardActions.detailFailure(error.response?.data?.error || error))
   }
}

export default function* dashboardSaga() {
  // takeEvery(DashboardActions.listRequest, list)
  yield takeLatest(DashboardActions.listRequest, list)
  yield takeLatest(DashboardActions.createRequest, create)
  yield takeLatest(DashboardActions.updateRequest, update)
  yield takeLatest(DashboardActions.detailRequest, detail)
  yield takeLatest(DashboardActions.updatePatientStatusRequest, updatePatientStatus)
  yield takeLatest(DashboardActions.updateServiceProviderStatusRequest, updateServiceProviderStatus)
}

import { useHistory } from 'react-router-dom'

import {ReactComponent as RightArrow} from '../../svg/right_arrow.svg'
import {ReactComponent as LeftArrow} from '../../svg/left_arrow.svg'
import Svg from './Svg'
import { Directions } from '../common'

const BackButton = ({ direction }) => {
  const history = useHistory()
  return <Svg as={direction === Directions.RTL ? RightArrow : LeftArrow} className='back-icon' onClick={history.goBack}/>
}

export default BackButton

import Accordion from 'react-bootstrap/Accordion'
import FormCheck from 'react-bootstrap/FormCheck'
import { useAccordionButton } from 'react-bootstrap/AccordionButton'
import { forwardRef, useState, useEffect, useRef } from 'react'

import Divider from './Divider'

import { GetRef } from '../common'

const CustomToggle = forwardRef(({ title, subTitle, eventKey, initiallyCollapsed, onChange, _formRef }, ref) => {
  const decoratedOnClick = useAccordionButton(eventKey, () =>
    console.log('totally custom!', { eventKey }),
  );

  const [collapsed, setCollapsed] = useState(initiallyCollapsed)

  useEffect(() => {
    setCollapsed(value => {
      if (value !== initiallyCollapsed) {
        return initiallyCollapsed
      }
      return value
    })
  }, [initiallyCollapsed])

  return (
    <>
      <b>{title}</b>
      <FormCheck
        type="switch"
        className='collapsable-switch'
        onClick={decoratedOnClick}
        defaultChecked={!initiallyCollapsed}
        checked={!collapsed}
        onChange={e => {
          if (!e.target.checked) {
            _formRef.current?.reset()
            setCollapsed(true)
          }
          else {
            setCollapsed(false)
          }
          onChange && onChange(!e.target.checked)
        }}
        ref={ref}
      />
      <div className='collapsable-form-sub-title'>{subTitle}</div>
    </>
  );
})

const CollapsableForm = ({ form, title, subTitle, initiallyCollapsed, onChange }) => {
  const formRef = useRef()
  const collapseRef = useRef()
  const checkBoxRef = useRef()

  useEffect(() => {
    if (initiallyCollapsed) {
      collapseRef.current?.performExit()
    }
    else {
      collapseRef.current?.performEnter()
    }
  }, [initiallyCollapsed, collapseRef])

  return (
    <Accordion
      className='collapsable-form'
      activeKey={!initiallyCollapsed && '0'}
    >
      <div>
        <div>
          <CustomToggle
            onChange={onChange}
            eventKey="0"
            title={title}
            subTitle={subTitle}
            initiallyCollapsed={initiallyCollapsed}
            _formRef={formRef}
            ref={checkBoxRef}
          />
        </div>
        <Accordion.Collapse eventKey="0" ref={collapseRef}>
          <div>
            <Divider className='collapsable-form-divider'/>
            <GetRef component={form} ref={formRef}/>
          </div>
        </Accordion.Collapse>
      </div>
    </Accordion>
  )
}

export default CollapsableForm

import { forwardRef } from 'react'
import FormControl from 'react-bootstrap/FormControl'

const InputWithIcon = forwardRef(({ icon, errorMessage, onChange, ...rest }, ref) => {
  return (
    <div className='input-with-icon-parent'>
      <FormControl className='input-with-icon-input'
        onChange={onChange}
        ref={ref}
        {...rest}
      />
      <FormControl.Feedback type="invalid">
        {errorMessage}
      </FormControl.Feedback>
      <div className='input-with-icon-icon'>
        {icon}
      </div>
    </div>
  )
})

export default InputWithIcon

import format from 'date-fns/format'
import { arSA } from 'date-fns/locale'
import Button from 'react-bootstrap/Button'
import _ from 'lodash'

import { useDispatch } from 'react-redux'

import { ServiceTypes, ReservationStatus, ProviderTypes } from './common'

import { ReservationActions } from '../redux/reducers/reservationSlice'

const RenderIf = ({ condition, children }) => condition && <>{children}</>

const BodyRow = ({ children }) => (
  <div className='reservation-card-body-row'>
    {children}
  </div>
)

const Label = ({ children }) => <div className='reservation-card-label'>{children}</div>

const Speciality = ({ children }) => (
  <div className='speciality'>{children}</div>
)


const ReservationCard = ({ reservation, onClick }) => {
  const dispatch = useDispatch()
  const localizeDate = date => date && format(new Date(date), 'eeee  d/M/y  hh:mm a', { locale: arSA })

  const reserveAgain = () => {
    const reshaped = _.pick(reservation, [
      'providerType',
      'serviceType',
      'priceRange',
      'dateRange',
      'agreementId',
      'hasCovid',
      'gender',
      'degree',
      'reason',
      'duration',
      'observingDoctor',
      'questions',
      'followUpCallNeeded',
      'type',
      'notCertainSpeciality'
    ])
    if (reshaped.serviceType?.id) {
      reshaped.serviceType = reshaped.serviceType.id
    }
    reshaped.patientId = reservation.patient.id
    reshaped.requesterId = reservation.requester.id
    reshaped.speciality = reservation.speciality?.id

    dispatch(ReservationActions.createReservationRequest(reshaped))
  }

  const accept = () => {
    dispatch(ReservationActions.acceptReservationRequest({ reservationId: reservation.id }))
  }

  const reject = () => {
    dispatch(ReservationActions.rejectReservationRequest({ reservationId: reservation.id }))
  }

  return (
    <div key={reservation.id} className='reservation-card' onClick={onClick}>
      <div className='reservation-card-body'>
        <RenderIf condition={reservation.status === ReservationStatus.PENDING_OPS}>
          <div className='reservation-no-reply-label'>لم يتم الرد حتي الآن</div>
        </RenderIf>

        <BodyRow>
          <Label>المريض</Label>
          <div>{reservation.patient.name}</div>
        </BodyRow>

        <RenderIf condition={!!reservation.serviceProvider}>
          <BodyRow>
            <Label>الدكتور</Label>
            <div>{reservation.serviceProvider?.firstName} {reservation.serviceProvider?.lastName}</div>
          </BodyRow>
        </RenderIf>

        <RenderIf condition={!!reservation.speciality}>
          <BodyRow>
            <Label>التخصص</Label>
            <Speciality>{reservation.speciality?.arabicName} - {ServiceTypes.getTextByValue(reservation.serviceType)}</Speciality>
          </BodyRow>
        </RenderIf>

        <RenderIf condition={!reservation.speciality && reservation.providerType !== ProviderTypes.MEDICAL_RAYS_ANALYSIS.value}>
          <BodyRow>
            <Label>التخصص</Label>
            <Speciality>{ProviderTypes.getTextByValue(reservation.providerType)}</Speciality>
          </BodyRow>
        </RenderIf>

        <RenderIf condition={reservation.providerType === ProviderTypes.MEDICAL_RAYS_ANALYSIS.value}>
          <BodyRow>
            <Label>التخصص</Label>
            <Speciality>{ServiceTypes.getTextByValue(reservation.serviceType)}</Speciality>
          </BodyRow>
        </RenderIf>

        {/* <RenderIf condition={reservation.providerType === ProviderTypes.PHYSICAL_THERAPIST.value}>
          <BodyRow>
            <Label>التخصص</Label>
            <Speciality>{ProviderTypes.PHYSICAL_THERAPIST.text} - {reservation.degree}</Speciality>
          </BodyRow>
        </RenderIf>

        <RenderIf condition={[ProviderTypes.NURSE.value, ProviderTypes.HOME_CARE_SPECIALIST.value].includes(reservation.providerType)}>
          <BodyRow>
            <Label>التخصص</Label>
            <Speciality>{ProviderTypes.getTextByValue(reservation.providerType)} - {reservation.serviceType?.arabicName}</Speciality>
          </BodyRow>
        </RenderIf> */}

        <BodyRow>
          <Label>التكلفة</Label>
          <div>{reservation.price || reservation.priceRange} جنيه مصرى</div>
        </BodyRow>

        <RenderIf condition={!!reservation.dateRange && (
            reservation.status === ReservationStatus.PENDING_OPS ||
            reservation.status === ReservationStatus.CANCELLED
          )}
        >
          <BodyRow>
            <Label>الزيارة</Label>
            <div>{reservation.dateRange}</div>
          </BodyRow>
        </RenderIf>
        <RenderIf condition={(
            reservation.status === ReservationStatus.PENDING_OPS ||
            reservation.status === ReservationStatus.CANCELLED
          )}
        >
          <BodyRow>
            <Label>تاريخ الطلب</Label>
            <div>{localizeDate(reservation.createdAt)}</div>
          </BodyRow>
        </RenderIf>

        <RenderIf condition={reservation.status === ReservationStatus.REJECTED}>
          <BodyRow>
            <Label>تاريخ الرفض</Label>
            <div>{localizeDate(reservation.rejectedAt)}</div>
          </BodyRow>
          <BodyRow>
            <Label>سبب الرفض</Label>
            <div>{reservation.rejectionReason}</div>
          </BodyRow>
        </RenderIf>

        <RenderIf condition={reservation.status === ReservationStatus.PENDING_RQT}>
          <div className='reservation-card-button-group'>
            <Button variant='outline-primary' onClick={reject}>رفض</Button>
            <Button onClick={accept}>قبول</Button>
          </div>
        </RenderIf>

        <RenderIf condition={reservation.status === ReservationStatus.COMPLETED}>
          <div className='reservation-card-button-group'>
            <Button variant='outline-primary' onClick={reserveAgain}>إعادة طلب الخدمة</Button>
          </div>
        </RenderIf>
      </div>
    </div>
  )
}

ReservationCard.Group = ({ horizontal, children }) => {
  const classes = ['reservation-card-group']
  if (horizontal) {
    classes.push('horizontal')
  }
  return <div className={classes.join(' ')}>{children}</div>
}

export default ReservationCard

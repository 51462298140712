import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import Nav from 'react-bootstrap/Nav'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Screen from '../components/layout/Screen'
import Header from '../components/layout/Header'
import Main from '../components/layout/Main'
import Footer from '../components/layout/Footer'

import Filter from '../components/Filter'
import ReservationCard from '../components/ReservationCard'
import { ProviderTypes, ReservationStatus } from '../components/common'

import { ReservationActions, selectReservation } from '../redux/reducers/reservationSlice'

import NotificationButton from '../components/basic/NotificationButton'

const Tabs = Object.freeze({
  PENDING: 'pending',
  UPCOMING: 'upcoming',
  COMPLETED: 'completed',
  CANCELLED: 'cancelled'
})

const PendingStatuses = Object.freeze([ReservationStatus.PENDING_OPS, ReservationStatus.PENDING_RQT])

const filterGroups = [
  {
    title: 'مقدم الخدمة',
    multiselect: true,
    filters: [
      { name: 'طبيب', apply: reservation => reservation.providerType === ProviderTypes.DOCTOR.value },
      { name: 'تمريض', apply: reservation => reservation.providerType === ProviderTypes.NURSE.value },
      { name: 'جليس', apply: reservation => reservation.providerType === ProviderTypes.HOME_CARE_SPECIALIST.value },
      { name: 'علاج طبيعى', apply: reservation => reservation.providerType === ProviderTypes.PHYSICAL_THERAPIST.value },
      { name: 'تحاليل واشعة', apply: reservation => reservation.providerType === ProviderTypes.MEDICAL_RAYS_ANALYSIS.value },
    ]
  },
  {
    title: 'نوع الخدمة',
    multiselect: false,
    filters: [
      { name: 'خدمة متكررة', apply: reservations => reservations.filter((x, i, arr) => arr.filter(y => y.providerType === x.providerType).length > 1)  },
      { name: 'غير متكررة', apply: reservations => reservations.filter((x, i, arr) => arr.filter(y => y.providerType === x.providerType).length === 1)  }
    ]
  },
  {
    title: 'الترتيب زمنياً',
    multiselect: false,
    filters: [
      { name: 'من الأقرب للأبعد', apply: reservations => reservations.sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt))  },
      { name: 'من الأبعد للأقرب ', apply: reservations => reservations.sort((a, b) => Date.parse(a.createdAt) - Date.parse(b.createdAt))  }
    ]
  }
]

const MyReservationsScreen = ({ requesterId }) => {
  const history = useHistory()

  const dispatch = useDispatch()
  const { reservations } = useSelector(selectReservation)

  console.log({reservations})

  const [visibleReservations, setVisibleReservations] = useState([])
  const [pageReservations, setPageReservations] = useState([])
  const [filter, setFilter] = useState(() => x => x)
  const [title, setTitle] = useState('الزيارات المعلقة')

  useEffect(() => {
    dispatch(ReservationActions.listReservationsRequest({ requesterId }))
  }, [requesterId, dispatch])

  useEffect(() => {
    setVisibleReservations(filter(pageReservations))
  }, [filter, pageReservations])

  useEffect(() => {
    setPageReservations(reservations.filter(r => PendingStatuses.includes(r.status)))
  }, [reservations])

  const counts = reservations.reduce((counts, reservation) => {
    let { status } = reservation
    status = status.toLowerCase()
    if (PendingStatuses.includes(reservation.status)) {
      status = Tabs.PENDING
    }
    counts[status]++
    return counts
  }, Object.values(Tabs).reduce((counts, key) => ({ ...counts, [key]: 0}), {}))

  const handleSelection = e => {
    switch (e.target.dataset.rrUiEventKey) {
      case Tabs.PENDING:
        setPageReservations(reservations.filter(r => PendingStatuses.includes(r.status)))
        setTitle('الزيارات المعلقة')
        break
      case Tabs.UPCOMING:
        setPageReservations(reservations.filter(r => r.status === ReservationStatus.UPCOMING))
        setTitle('الزيارات القادمة')
        break
      case Tabs.COMPLETED:
        setPageReservations(reservations.filter(r => r.status === ReservationStatus.COMPLETED))
        setTitle('الزيارات التى تمت')
        break
      case Tabs.CANCELLED:
      default:
        setPageReservations(reservations.filter(r => r.status === ReservationStatus.CANCELLED))
        setTitle('الزيارات الملغية')
    }
  }
  return (
    <Screen>
      <Header
        middleItem={<b>زياراتى</b>}
        leftItem={<NotificationButton />}
      />
      <Main className='home-main'>
        <Nav variant="pills" defaultActiveKey={Tabs.PENDING}>
          <Nav.Item>
            <Nav.Link eventKey={Tabs.PENDING} onClick={handleSelection}>
              معلقة ({counts[Tabs.PENDING]})
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey={Tabs.UPCOMING} onClick={handleSelection}>
              قادمة ({counts[Tabs.UPCOMING]})
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey={Tabs.COMPLETED} onClick={handleSelection}>
              تمت ({counts[Tabs.COMPLETED]})
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey={Tabs.CANCELLED} onClick={handleSelection}>
              ملغيه ({counts[Tabs.CANCELLED]})
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Row>
          <Col style={{textAlign: 'right'}}><b>{title}</b></Col>
          <Col style={{textAlign: 'left'}}>
            <Filter
              icon
              float='left'
              title='تصنيف الزيارات'
              onFilterChange={filter => setFilter(() => filter)}
              filterGroups={filterGroups}
            />
          </Col>
        </Row>
        <ReservationCard.Group>
          {visibleReservations.map((reservation, index) =>
            <ReservationCard
              key={index}
              reservation={reservation}
              onClick={() => (reservation.status !== ReservationStatus.CANCELLED) && history.push(`/requester/${requesterId}/reservation/${reservation.id}`)}
            />
          )}
        </ReservationCard.Group>
      </Main>
      <Footer />
    </Screen>
  )
}

export default MyReservationsScreen

import { useState } from "react"

import InputWithIcon from "./InputWithIcon"
import Svg from "./Svg"

import {ReactComponent as TogglePasswordIcon} from "../../svg/password_visibility.svg"

const PasswordView = Object.freeze({
  PASSWORD: 'password',
  TEXT: 'text'
})

const PasswordInput = ({ isInvalid, errorMessage, onChange, placeholder='', ...rest}) => {
  const [passwordView, setPasswordView] = useState('password')

  const handleToggleIconClick = () => {
    if (passwordView === PasswordView.PASSWORD) {
      setPasswordView(PasswordView.TEXT)
    } else {
      setPasswordView(PasswordView.PASSWORD)
    }
  }

  return (
    <InputWithIcon
      icon={<Svg as={TogglePasswordIcon} className='login-form-eye' onClick={handleToggleIconClick}/>}
      placeholder={placeholder}
      errorMessage={errorMessage}
      isInvalid={isInvalid}
      type={passwordView}
      onChange={onChange}
      autoComplete='new-password'
      {...rest}
    />
  )
}

export default PasswordInput


import { Formik } from 'formik'
import * as yup from 'yup'
import { Link, useHistory } from 'react-router-dom';
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'

import Svg from './basic/Svg';
import InputWithIcon from './basic/InputWithIcon'
import {ReactComponent as TogglePasswordIcon} from '../svg/password_visibility.svg'

import { AuthActions, selectAuth } from '../redux/reducers/authSlice'

import { PASSWORD_REGEX, MOBILE_NUMBER_REGEX } from './common'


const PasswordView = Object.freeze({
  PASSWORD: 'password',
  TEXT: 'text'
})

const byMobileNumberSchema = yup.object().shape({
  mobileNumber: yup.string().matches(MOBILE_NUMBER_REGEX, 'من فضلك أدخل رقم هاتف صحيح').required('من فضلك أدخل رقم الهاتف').typeError('من فضلك أدخل رقم الهاتف'),
  password: yup.string().matches(PASSWORD_REGEX, 'من فضلك أدخل كلمة مرور صحيحة').required('من فضلك أدخل كلمة المرور').typeError('من فضلك أدخل كلمة المرور')
})

const byEmailSchema = yup.object().shape({
  email: yup.string().email('من فضلك أدخل بريد الإلكتروني صحيح').required('من فضلك أدخل البريد الإلكتروني').typeError('من فضلك أدخل بريد الإلكتروني صحيح'),
  password: yup.string().matches(PASSWORD_REGEX, 'من فضلك أدخل كلمة مرور صحيحة').required('من فضلك أدخل كلمة المرور').typeError('من فضلك أدخل كلمة المرور')
})

const Types = Object.freeze({
  MOBILE_NUMBER: 'mobileNumber',
  EMAIL: 'email'
})

const LoginForm = ({ redirect='/' }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const by = window.location.pathname.split('/')[1] === 'dashboard' ? Types.EMAIL : Types.MOBILE_NUMBER

  const [passwordView, togglePasswordView] = useState(PasswordView.PASSWORD)
  const [isLoading, setIsLoading] = useState(false)
  const { status, error, isLoggedIn } = useSelector(selectAuth)

  useEffect(() => {
    setIsLoading(status === 'loading')
  }, [status])

  useEffect(() => {
    if (isLoggedIn) {
      history.push(history.location.state?.redirect || redirect)
    }
  }, [isLoggedIn])

  const handleToggleIconClick = () => {
    if (passwordView === PasswordView.PASSWORD) {
      togglePasswordView(PasswordView.TEXT)
    } else {
      togglePasswordView(PasswordView.PASSWORD)
    }
  }

  const handleSubmit = (data) => {
    dispatch(AuthActions.loginRequest(data))
  }

  return (
    <div>
      <Formik
        validationSchema={by === Types.MOBILE_NUMBER ? byMobileNumberSchema : byEmailSchema}
        onSubmit={handleSubmit}
        initialValues={{
          [by]: null,
          password: null
        }}
        validateOnChange={false}
      >
        {({
          handleSubmit,
          handleChange,
          errors
        }) => (
          <Form className='login-form' noValidate onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label>{by === Types.MOBILE_NUMBER ? 'رقم الهاتف' : 'البريد الإلكتروني'}</Form.Label>
              <Form.Control
                type={by === Types.MOBILE_NUMBER ? 'text' : 'email'}
                placeholder={by === Types.MOBILE_NUMBER ? 'ادخل رقم الهاتف' : 'ادخل البريد الإلكتروني'}
                name={by}
                onChange={handleChange}
                isInvalid={errors[by]}
              />
              <Form.Control.Feedback type='invalid'>
                {errors[by]}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group>
              <Form.Label>كلمة المرور</Form.Label>
              <InputWithIcon
                icon={<Svg as={TogglePasswordIcon} className='login-form-eye' onClick={handleToggleIconClick}/>}
                placeholder='ادخل كلمة المرور'
                errorMessage={error ? 'رقم الهاتف أو كلمة المرور غير صحيحة' : errors.password}
                isInvalid={errors.password || !!error}
                type={passwordView}
                name='password'
                onChange={handleChange}
              />
              <Button variant='link' as={Link} to='/forgotPassword'>
                نسيت كلمة المرور
              </Button>
            </Form.Group>
            <Button type='submit' className='login-form-submit' disabled={isLoading}>
              {isLoading ? <Spinner animation="border" size='sm' /> : <span>تسجيل الدخول</span>}
            </Button>
          </Form>
        )}
      </Formik>
      {by === Types.MOBILE_NUMBER && <p className='login-form-bottom'>لا يوجد لديك حساب <Link to='/signUp'>إنشاء حساب جديد</Link></p>}
    </div>
  )
}

LoginForm.Types = Types

export default LoginForm

import { Directions, withProps } from '../common'

const Main = ({ children, className='', direction=Directions.RTL }) => {
  const classNames = ['main', direction]
  if (className) classNames.push(...className.split(' '))

  return <div className={classNames.join(' ')}>{withProps(children, { direction })}</div>
}

export default Main

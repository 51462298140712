import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  status: 'idle',
  target: null,
  error: null,
  timestamps: {}
}

export const cacheSlice = createSlice({
  name: 'cache',
  initialState,
  reducers: {
    saveCacheRequest: (state, action) => {
      state[action.payload.target] = action.payload.payload
      action.payload.payload = JSON.stringify(action.payload.payload)

      state.timestamps[action.payload.target] = action.payload.timestamp
    },

    loadCacheRequest: (state, action) => {
      state.status = 'loading'
      state.target = action.payload.target
    },
    loadCacheSuccess: (state, action) => {
      state.status = 'idle'

      if (!state.timestamps[state.target] ||
        (Date.parse(action.payload.updatedAt) > state.timestamps[state.target])) {
        state[state.target] = JSON.parse(action.payload.payload)
      }

      state.target = null
    },
    loadCacheFailure: (state, action) => {
      state.status = 'idle'
      state.error = action.payload
      state.target = null
    },

    deleteCacheRequest: (state, action) => {
      state[action.payload.target] = null
    },

    reset: state => {
      state.status = 'idle'
      state.target = null
      state.error = null
      state.timestamps = {}
    }
  }
});

export const selectCache = (state) => state.cache

export const CacheActions = cacheSlice.actions

export default cacheSlice.reducer;

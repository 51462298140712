import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'
import { useSelector } from 'react-redux';

import AuthenticationManager from './interceptors/AuthenticationManager'
import NotificationManager from './interceptors/NotificationManager';
import Interceptors from './interceptors/Interceptors'

import Direction from './components/basic/Direction';

import LoginScreen from './screens/LoginScreen';
import HomeScreen from './screens/HomeScreen';
import SignupScreen from './screens/SignupScreen';
import ForgotPasswordScreen from './screens/ForgotPasswordScreen';
import MyPatientsScreen from './screens/MyPatientsScreen'
import CreatePatientScreen from './screens/CreatePatientScreen'
import CreateReservationScreen from './screens/CreateReservationScreen'
import MyReservationsScreen from './screens/MyReservationsScreen'
import ProfileScreen from './screens/ProfileScreen';
import EditProfileScreen from './screens/EditProfileScreen'
import ChangePasswordScreen from './screens/ChangePasswordScreen'
import PatientScreen from './screens/PatientScreen';
import ProvidersScreen from './screens/ProvidersScreen';
import ReservationScreen from './screens/ReservationScreen'
import NotificationsScreen from './screens/NotificationsScreen'
import GuestReservationScreen from './screens/GuestReservationScreen';

import DashboardLayout from './dashboard/layout/DashboardLayout';
import CreateProvider from './screens/provider/CreateProvider/CreateProvider'
import { Directions } from './components/common';

import Operators from './dashboard/subScreens/Operators';
import Doctors from './dashboard/subScreens/Doctors';
import PhysicalTherapists from './dashboard/subScreens/PhysicalTherapists';
import Nurses from './dashboard/subScreens/Nurses';
import HomeCareSpecialists from './dashboard/subScreens/HomeCareSpecialists';
import MedicalCenters from './dashboard/subScreens/MedicalCenters';
import Lookups from './dashboard/subScreens/Lookups';
import RequestersAndPatients from './dashboard/subScreens/RequestersAndPatients';
import Reservations from './dashboard/subScreens/Reservations';
import Pricing from './dashboard/subScreens/Pricing';
import HomePage from './dashboard/subScreens/HomePage';

const CommonRoutes = ({ children }) => (
  <Switch>
    <Route path='/signIn'>
      <LoginScreen />
    </Route>
    <Route path='/forgotPassword'>
      <ForgotPasswordScreen />
    </Route>
    {children}
  </Switch>
)

const DashboardRouter = () => (
  <Router basename='/dashboard'>
    <AuthenticationManager>
      {/* <NotificationManager /> */}
      <Direction direction={Directions.LTR}>
        <CommonRoutes>
          <DashboardLayout>
            <Switch>
              <Route exact path='/'>
                <HomePage />
              </Route>
              <Route exact path='/operators'>
                <Operators />
              </Route>
              <Route exact path='/doctors'>
                <Doctors />
              </Route>
              <Route exact path='/doctors/:id'>
                <Doctors.Detail />
              </Route>
              <Route exact path='/physicalTherapists'>
                <PhysicalTherapists />
              </Route>
              <Route exact path='/physicalTherapists/:id'>
                <PhysicalTherapists.Detail />
              </Route>
              <Route exact path='/nurses'>
                <Nurses />
              </Route>
              <Route exact path='/nurses/:id'>
                <Nurses.Detail />
              </Route>
              <Route exact path='/homeCareSpecialists'>
                <HomeCareSpecialists />
              </Route>
              <Route exact path='/homeCareSpecialists/:id'>
                <HomeCareSpecialists.Detail />
              </Route>
              <Route exact path='/medicalCenters'>
                <MedicalCenters />
              </Route>
              <Route exact path='/medicalCenters/:id'>
                <MedicalCenters.Detail />
              </Route>
              <Route exact path='/lookups'>
                <Lookups />
              </Route>
              <Route exact path='/requestersAndPatients'>
                <RequestersAndPatients />
              </Route>
              <Route exact path='/patients/:id'>
                <RequestersAndPatients.DetailPatient />
              </Route>
              <Route exact path='/reservations'>
                <Reservations />
              </Route>
              <Route exact path='/reservations/:id'>
                <Reservations.Detail />
              </Route>
              <Route exact path='/pricing'>
                <Pricing />
              </Route>
            </Switch>
          </DashboardLayout>
        </CommonRoutes>
      </Direction>
    </AuthenticationManager>
  </Router>
)

const ProviderRouter = () => (
  <Router basename='/provider'>
    <AuthenticationManager>
      <Direction>
        <CommonRoutes>
          <Route path='/signUp'>
            <CreateProvider />
          </Route>
        </CommonRoutes>
      </Direction>
    </AuthenticationManager>
  </Router>
)

const RequesterRouter = () => {
  const { userId, isLoggedIn } = useSelector(state => state.auth)
  return (
    <Router>
      <AuthenticationManager>
        <Direction>
          <CommonRoutes>
            <Route path='/signUp'>
              <SignupScreen />
            </Route>
            <Route path={`/requester/${userId}/patient/new`}>
              <CreatePatientScreen userId={userId} />
            </Route>
            <Route path={`/requester/${userId}/patients`}>
              <MyPatientsScreen />
            </Route>
            <Route path={`/requester/${userId}/edit`}>
              <EditProfileScreen requesterId={userId} />
            </Route>
            <Route path={`/requester/${userId}/password`}>
              <ChangePasswordScreen requesterId={userId} />
            </Route>
            <Route path={`/requester/${userId}/profile`}>
              <ProfileScreen requesterId={userId} />
            </Route>
            <Route path={`/requester/${userId}/providers`}>
              <ProvidersScreen requesterId={userId} />
            </Route>
            <Route path='/reservations/new'>
              {isLoggedIn ? <CreateReservationScreen requesterId={userId} /> : <GuestReservationScreen />}
            </Route>
            <Route path={`/requester/${userId}/reservations`}>
              <MyReservationsScreen requesterId={userId} />
            </Route>
            <Route path={`/requester/${userId}/reservation/:reservationId`}>
              <ReservationScreen requesterId={userId} />
            </Route>
            <Route path='/requester/:requesterId/patient/:patientId'>
              <PatientScreen />
            </Route>
            <Route path='/notifications'>
              <NotificationsScreen requesterId={userId} />
            </Route>
            <Route exact path='/'>
              <HomeScreen requesterId={userId} />
            </Route>
          </CommonRoutes>
        </Direction>
      </AuthenticationManager>
    </Router>
  )
}

const App = () => {
  const { direction } = useSelector(state => state.language)

  return (
    <Interceptors>
      <Router>
        <Route path='/dashboard'>
          <DashboardRouter />
        </Route>
        <Route path='/provider'>
          <ProviderRouter />
        </Route>
        <Route
          render={({ location }) => {
            if (!['dashboard', 'provider'].includes(location.pathname.split('/')[1])) {
              return <RequesterRouter />
            }
          }}
        >
        </Route>
      </Router>
    </Interceptors>
  );
}

export default App;

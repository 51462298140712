import { useRef, useEffect, forwardRef, cloneElement, Children, isValidElement } from 'react'
import * as yup from 'yup'

import parse from 'date-fns/parse'
import formatDate from 'date-fns/format'
import isAfter from 'date-fns/isAfter'
import get from 'lodash/get'

import { checkExistingApi } from '../redux/api/authApi'

export const chronicDiseases = [
  { text: 'ضغط الدم', value: 'ضغط الدم' },
  { text: 'السكري', value: 'السكري' },
  { text: 'أمراض القلب والأوعية الدموية', value: 'أمراض القلب والأوعية الدموية' },
  { text: 'التهاب المفاصل / روماتيزم', value: 'التهاب المفاصل / روماتيزم' },
  { text: 'أمراض الكلي', value: 'أمراض الكلي' },
  { text: 'الزهايمر', value: 'الزهايمر' },
  { text: 'أمراض رئوية', value: 'أمراض رئوية' },
  { text: 'السكتة الدماغية', value: 'السكتة الدماغية' },
  { text: 'سرطان', value: 'سرطان' }
]

/**
 *
 * @template T
 * @param {T} object
 * @returns {T|{
 *    getValues: () => string[],
 *    getTexts: () => string[],
 *    getTextByValue: string => string,
 *    getValueByText: string => string,
 *    options: () => [{ text: string, value: string }]
 * }}
 */
export const enrichEnumObject = object => Object.freeze({
  ...object,
  getValues: () => Object.values(object).map(item => item.value),
  getTexts: () => Object.values(object).map(item => item.text),
  getTextByValue: (value) => Object.values(object).find(item => item.value === value)?.text,
  getValueByText: (text) => Object.values(object).find(item => item.text === text)?.value,
  options: () => Object.values(object)
})

const _Genders = {
  MALE: {
    text: 'ذكر',
    value: 'Male'
  },
  FEMALE: {
    text: 'أنثي',
    value: 'Female'
  }
}

export const Genders = enrichEnumObject(_Genders)

export const GenderChoices = enrichEnumObject({
  ..._Genders,
  ANY: {
    text: 'أي منهم',
    value: 'Any'
  }
})

export const ProviderTypes = enrichEnumObject({
  DOCTOR: {
    text: 'كشف طبي',
    value: 'Doctor'
  },
  NURSE: {
    text: 'تمريض',
    value: 'Nurse'
  },
  PHYSICAL_THERAPIST: {
    text: 'علاج طبيعي',
    value: 'PhysicalTherapist'
  },
  HOME_CARE_SPECIALIST: {
    text: 'جليس',
    value: 'HomeCareSpecialist'
  },
  MEDICAL_RAYS_ANALYSIS: {
    text: 'أشعة وتحاليل',
    value: 'MedicalRaysAnalysis'
  }
})

export const ServiceTypes = enrichEnumObject({
  HOME_VISIT: {
    text: 'كشف منزلى',
    value: 'HomeVisit',
    description: 'للكشف ومتابعة المريض سريرياً'
  },
  CALL_CONSULTATION: {
    text: 'إستشارة هاتفية',
    value: 'CallConsultation',
    description: 'لمتابعة الكشف أو الكشف هاتفياً'
  },
  MEDICAL_RAYS: {
    text: 'آشعة طبية',
    value: 'MedicalRays',
    description: 'جميع أنواع الأشعة الطبية المتاح عملها بالمنزل'
  },
  MEDICAL_ANALYSIS: {
    text: 'تحاليل طبية',
    value: 'MedicalAnalysis',
    description: 'جميع أنواع التحاليل الطبية المتاح عملها بالمنزل'
  }
})

export const ReservationStatus = Object.freeze({
  PENDING_OPS: 'PendingOPS',
  PENDING_SPR: 'PendingSPR',
  PENDING_RQT: 'PendingRQT',
  UPCOMING: 'Upcoming',
  CANCELLED: 'Cancelled',
  COMPLETED: 'Completed',
  REJECTED: 'Rejected'
})

export const DoctorDegrees = enrichEnumObject({
  GENERAL_PRACTITIONER: {
    text: 'ممارس عام',
    value: 'GeneralPractitioner'
  },
  SPECIALIST: {
    text: 'أخصائى',
    value: 'Specialist'
  },
  CONSULTANT: {
    text: 'إستشاري',
    value: 'Consultant'
  }
})

export const ScientificDegrees = enrichEnumObject({
  MASTERS: {
    text: 'ماجيستير',
    value: 'Masters'
  },
  PHP: {
    text: 'دكتوراه',
    value: 'PhP'
  }
})

export const parseAttachment = url => {
  const _url = new URL(url)
  const pathnameParts = _url.pathname.slice(1).split('/')
  const key = pathnameParts[pathnameParts.length - 1]
  const bucket = _url.hostname.split('.')[0]

  return { bucket, key, url }
}

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current || value;
}

export const PASSWORD_REGEX = /(?=.*[!@#$%^&*()])[a-zA-Z\d!@#$%^&*()]{8,}$/

export const MOBILE_NUMBER_REGEX = /^[0-9٠-٩]{11}$/

export const GetRef = forwardRef(({component}, ref) => {
  return cloneElement(
    component,
    { ref }
  )
})

export const attachmentSchema = yup.object().shape({
  id: yup.number().required(),
  url: yup.string().url().required()
})

export const multipleAtachmentSchema = yup.array().of(attachmentSchema)


export const Directions = Object.freeze({
  RTL: 'rtl',
  LTR: 'ltr'
})

export const withProps = (children, props) => Children.map(children, child => {
  if (isValidElement(child)) {
    const className = []
    if (child.props.className) className.push(child.props.className)
    if (child.props.direction) className.push(child.props.direction)
    return cloneElement(child, { ...props, className: className.join(' ') });
  }
  return child
})

export const ServiceProviderStatus = enrichEnumObject({
  ACTIVE: { text: 'Active', value: 'Active' },
  IN_ACTIVE: { text: 'In Active', value: 'InActive' },
  PENDING: { text: 'Pending', value: 'Pending' },
  BLOCKED: { text: 'Blocked', value: 'Blocked' }
})

export const shiftSchema = (options) => {
  const shiftMin = get(options, 'shift.min')
  const shiftMax = get(options, 'shift.max')

  const format = 'HH:mm'

  const _isAfter = (a, b) => {
    const date = new Date()
    return isAfter(parse(b, format, date), parse(a, format, date))
  }

  return yup.object().nullable().shape({
    from: yup.string()
      .required('من فضلك قم بادخال المعلومات كاملة')
      .test('from-to', 'From must be before To', (from, { parent: { to } }) => {
        return to ? _isAfter(from, to) : true
      })
      .test('shift-min', (from, { path, from: [_, { value: context }], createError }) => {
        if (from && shiftMin) {
          const bound = get(context, shiftMin)
          if (bound && !_isAfter(bound, from)) {
            return createError({
              message: `From must be after ${formatDate(parse(bound, format, new Date()), 'h:mm b')}`,
              path
            })
          }
        }
        return true
      }),
    to: yup.string()
      .required('من فضلك قم بادخال المعلومات كاملة')
      .test('to-from', 'To must be after From', (to, { parent: { from } }) => {
        return from ? _isAfter(from, to) : true
      })
      .test('shift-max', (to, { path, from: [_, { value: context }], createError }) => {
        if (to && shiftMax) {
          const bound = get(context, shiftMax)
          if (bound && !_isAfter(to, bound)) {
            return createError({
              message: `To must be before ${formatDate(parse(bound, format, new Date()), 'h:mm b')}`,
              path
            })
          }
        }
        return true
      })
  })
}

// const Roles = Object.freeze({
//   ADMIN: 'Admin',
//   OPS: 'OperationsSpecialist',
//   REQ: 'Requester',
//   SPR: 'ServiceProvider'
// })

export const checkExistingTestConfig = (target, userType, { message, positive = true } = {}) => {
  const keyName = (() => {
    switch(target) {
      case 'mobileNumber':
        return 'Mobile Number'
      case 'email':
        return 'Email'
      default:
        return 'Code'
    }
  })()

  const name = `unique-${target}`
  return {
    name,
    message: message || `${keyName} already taken`,
    test: async (value, { parent: { id }, schema }) => {
      const _schema = schema.clone()
      _schema.tests = _schema.tests.filter(t => t.OPTIONS.name !== name)
      if (value && _schema.isValidSync(value)) {
        const ignore = id ? { ignore: id } : null
        const response = await checkExistingApi({ target, value, userType, ...ignore })
        return positive ? !response.data : response.data
      }
      return true
    }
  }
}


export const getUserTypeFromId = id => {
  const [prefix] = id.split('-')
  switch (prefix) {
    case 'DR':
      return 'Doctor'
    case 'NU':
      return 'Nurse'
    case 'PH':
      return 'Physical Therapist'
    case 'MC':
      return 'Medical Center'
    case 'CO':
      return 'Home Care Specialist'
    case 'SU':
      return 'System User'
    case 'C':
      return 'Requester'
    case 'P':
      return 'Patient'
  }
}

export const HowDidYouKnowUs = Object.freeze({
  SOCIAL_MEDIA: 'منصات التواصل الإجتماعي',
  FRIEND: 'عن طريق صديق',
  SEARCH_ENGINES: 'من خلال محركات البحث'
})

import ReactDatePicker, { CalendarContainer } from 'react-datepicker'
import { useState, useRef, useMemo, useEffect, cloneElement, forwardRef, Children } from 'react'
import format from 'date-fns/format'
import range from 'lodash/range'

import Accordion from 'react-bootstrap/Accordion'

import BottomSheet from './BottomSheet'
import InputWithIcon from './InputWithIcon'
import Svg from './Svg'

import {ReactComponent as CalendarIcon} from '../../svg/calendar.svg'
import {ReactComponent as LeftArrow} from '../../svg/left_arrow.svg'
import {ReactComponent as RightArrow} from '../../svg/right_arrow.svg'

const MonthPlusYearPicker = ({ date, onChange, containerRef }) => {
  const selectedYearRef = useRef()
  const [active, setActive] = useState(false)

  const [weekdaysHeight, setWeekdaysHeight] = useState()
  const [monthContainerHeight, setMonthContainerHeight] = useState()

  const selectedYear = date.getFullYear()
  const currentYear = new Date().getFullYear()

  useEffect(() => {
    if (active) {
      setImmediate(() => selectedYearRef.current.scrollIntoView({ behavior: "smooth" }))
    }
  }, [active])

  useEffect(() => {
    setWeekdaysHeight(document.getElementsByClassName(containerRef.current.firstChild.lastChild.className)[0].clientHeight)
    setMonthContainerHeight(document.getElementsByClassName(containerRef.current.lastChild.className)[0].clientHeight)
  }, [containerRef])

  useEffect(() => {
    containerRef.current.style.transition = 'all 0.4s'
    containerRef.current.firstChild.lastChild.style.transition = 'all 0.3s'
    containerRef.current.firstChild.lastChild.style.overflowY = 'hidden'
    containerRef.current.firstChild.lastChild.style.height = `${weekdaysHeight}px`

    containerRef.current.lastChild.style.transition = 'all 0.3s'
    containerRef.current.lastChild.style.overflowY = 'hidden'
    containerRef.current.lastChild.style.height = `${monthContainerHeight}px`

    const bottomSheet = containerRef.current.parentElement.parentElement.parentElement
    bottomSheet.style.maxHeight = `${bottomSheet.clientHeight}px`
    bottomSheet.lastChild.style.overflowY = 'hidden'
  }, [weekdaysHeight, monthContainerHeight, containerRef])

  const toggle = () => {
    setActive(!active)
    containerRef.current.firstChild.lastChild.style.height = !active ? 0 : `${weekdaysHeight}px`
    containerRef.current.lastChild.style.height = !active ? 0 : `${monthContainerHeight}px`
  }

  return (
    <Accordion activeKey={active} className='year-picker-accordion'>
      <Accordion.Item eventKey={true} onClick={toggle}>
        <Accordion.Header>
          <b>
            {format(date, 'MMMM yyyy')}
          </b>
        </Accordion.Header>
        <Accordion.Body>
          <div className='years-flex'>
            {range(currentYear, currentYear - 100, -1).map(year => {
              const extra = {
                onClick: () => {
                  onChange(year)
                  toggle()
                }
              }
              if (year === selectedYear) {
                extra.className = 'selected'
                extra.ref = selectedYearRef
              }
              return <div {...extra}>{year}</div>
            })}
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}

const CalendarHeader = (startDate, containerRef) => ({
  date,
  changeYear,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled
}) => {
  return (
    <div className='custom-datepicker-header'>
      {prevMonthButtonDisabled ? <div /> : <Svg as={LeftArrow} iconn onClick={decreaseMonth}/>}
      <MonthPlusYearPicker
        date={startDate || new Date(date)}
        onChange={changeYear}
        containerRef={containerRef}
      />
      {nextMonthButtonDisabled ? <div /> : <Svg as={RightArrow} iconn onClick={increaseMonth}/>}
    </div>
  )
}

const BottomCalendarContainer = (label, showCalendar, close, ref) => forwardRef(({ className, children }) => {
  return (
    <BottomSheet
      title={label}
      show={showCalendar}
      close={close}
    >
      <CalendarContainer className={className}>
        {Children.map(children, child => {
          if (child) {
            return cloneElement(child, { ref })
          }
          return child
        })}
      </CalendarContainer>
    </BottomSheet>
  )
})

const DatePicker = ({
  label,
  onChange,
  onClose,
  isInvalid,
  errorMessage = '',
  value,
  birthDate,
  container=BottomCalendarContainer,
  header=CalendarHeader
}) => {
  const [startDate, setStartDate] = useState(value ? new Date(value) : null)
  const [showContainer, setShowContainer] = useState(false)
  const target = useRef()
  const containerRef = useRef()

  const onCloseContainer = () => {
    setShowContainer(false)
    if (target.current) {
      target.current.setBlur()
    }
    if (onClose) onClose()
  }

  const onCalendarOpen = () => {
    setShowContainer(true)
  }

  const internalOnChange = (date) => {
    setShowContainer(false)
    setStartDate(date)
    onChange(date)
  }

  const handleYearChange = (date) => {
    const _d = new Date(startDate || new Date())
    _d.setFullYear(date.getFullYear())

    setStartDate(_d)
    onChange(_d)
  }

  const customContainer = useMemo(() => container(label, showContainer, onCloseContainer, containerRef), [showContainer])
  const customHeader = useMemo(() => header(startDate, containerRef), [startDate])

  return (
    <ReactDatePicker
      maxDate={birthDate && (Date.now() - 24 * 60 * 60 * 1000)}
      open
      selected={startDate}
      onChange={internalOnChange}
      onInputClick={onCalendarOpen}
      calendarContainer={customContainer}
      ref={target}
      customInput={
        <InputWithIcon
          icon={<Svg as={CalendarIcon} icon />}
          readOnly
          isInvalid={isInvalid}
          errorMessage={errorMessage}
        />
      }
      renderCustomHeader={customHeader}
      placeholderText='يوم / شهر / سنه'
      dateFormat='dd/MM/yyyy  '
      formatWeekDay={dayName => dayName.slice(0, 3).toLocaleUpperCase()}
      onMonthChange={() => setStartDate('')}
      shouldCloseOnSelect
      onYearChange={handleYearChange}
    />
  )
}

export default DatePicker

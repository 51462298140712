import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  notifications: [],
  status: 'idle',
  error: null
}

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    listNotificationsRequest: state => {
      state.status = 'loading'
      state.error = null
    },
    listNotificationsSuccess: (state, action) => {
      state.status = 'idle'
      state.notifications = action.payload.notifications
    },
    listNotificationsFailure: (state, action) => {
      state.status = 'idle'
      state.error = action.payload
    }
  }
});

export const selectNotifications = (state) => state.notifications

export const NotificationsActions = notificationsSlice.actions

export default notificationsSlice.reducer;

import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import qs from 'query-string'

import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import ListGroup from 'react-bootstrap/ListGroup'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Spinner from 'react-bootstrap/Spinner'

import * as yup from 'yup'
import { Formik } from 'formik'

import { searchApi } from '../../redux/api/dashboardApi'

const HomePage = () => {
  const history = useHistory()

  const [search, setSearch] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const schema = yup.object().shape({
    name: yup.string(),
    mobileNumber: yup.string().matches(/^[0-9٠-٩]*$/)
  })

  const getType = id => {
    const [prefix] = id.split('-')
    switch (prefix) {
      case 'DR': return 'Doctor'
      case 'NU': return 'Nurse'
      case 'PH': return 'Physical Therapist'
      case 'C': return 'Requester'
      case 'P': return 'Patient'
      case 'CO': return 'Home Care Specialist'
      default: return 'Medical Center'
    }
  }

  const navigate = id => () => {
    const [prefix] = id.split('-')
    let params = {}
    switch (prefix) {
      case 'DR':
        params = { pathname: 'doctors' }
        break
      case 'NU':
        params = { pathname: 'nurses' }
        break
      case 'PH':
        params = { pathname: 'physicalTherapists' }
        break
      case 'C':
        params = { pathname: 'requestersAndPatients', hash: 'requesters' }
        break
      case 'P':
        params = { pathname: 'requestersAndPatients', hash: 'patients' }
        break
      case 'CO':
        params = { pathname: 'homeCareSpecialists' }
        break
      default:
        params = { pathname: 'medicalCenters' }
        break
    }
    history.push({ ...params, search: qs.stringify({ id })})
  }

  return (
    <div>
      <div className='sub-screen-title'>
        <h1>Homepage</h1>
      </div>
      <Formik
        validationSchema={schema}
        initialValues={{}}
        onSubmit={form => {
          setIsLoading(true)
          searchApi(form).then(response => {
            setIsLoading(false)
            setSearch(response.data)
          })
        }}
      >
        {({ handleChange, handleSubmit, values, errors }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <InputGroup hasValidation>
              <InputGroup.Text>Search by Name</InputGroup.Text>
              <Form.Control
                placeholder="Search by Name"
                onChange={handleChange}
                name='name'
                isInvalid={errors.name}
                value={values.name}
              />
              <InputGroup.Text>Search by Mobile Number</InputGroup.Text>
              <Form.Control
                placeholder="Search by Mobile Number"
                onChange={handleChange}
                name='mobileNumber'
                isInvalid={errors.mobileNumber}
                value={values.mobileNumber}
              />
              <Button type='submit'>Search</Button>
              <Form.Control.Feedback type='invalid'>
                Mobile Number must contain only numbers
              </Form.Control.Feedback>
            </InputGroup>
          </Form>
        )}
      </Formik>
      <br />
      {isLoading ? <Spinner animation='border' /> : (
        <ListGroup>
          {!!search.length && (
            <ListGroup.Item variant="primary">
              <Row>
                  <Col>Type</Col>
                  <Col>ID</Col>
                  <Col>Name</Col>
                  <Col>Mobile Number</Col>
                </Row>
            </ListGroup.Item>
          )}
          {search.map(item => (
              <ListGroup.Item key={item.id} action onClick={navigate(item.id)}>
                <Row>
                  <Col>{getType(item.id)}</Col>
                  <Col>{item.id}</Col>
                  <Col>{item.name}</Col>
                  <Col>{item.mobileNumber}</Col>
                </Row>
              </ListGroup.Item>
          ))}
        </ListGroup>
      )}
    </div>
  )
}

export default HomePage

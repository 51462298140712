import { call, put, takeLatest } from 'redux-saga/effects'
import * as Api from '../api/cacheApi'

import { CacheActions } from '../reducers/cacheSlice'

function* saveCache(action) {
   try {
      yield call(Api.saveCacheApi, action.payload)
   } catch (error) {
      console.log(error)
   }
}

function* loadCache(action) {
   try {
      const response = yield call(Api.loadCacheApi, action.payload)
      yield put(CacheActions.loadCacheSuccess(response.data))
   } catch (error) {
      yield put(CacheActions.loadCacheFailure(error.response?.data?.error || error))
   }
}

function* deleteCache(action) {
   try {
      yield call(Api.deleteCacheApi, action.payload)
   } catch (error) {
      console.log(error)
   }
}

export default function* cacheSaga() {
  yield takeLatest(CacheActions.saveCacheRequest, saveCache)
  yield takeLatest(CacheActions.loadCacheRequest, loadCache)
  yield takeLatest(CacheActions.deleteCacheRequest, deleteCache)
}

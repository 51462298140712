import { useEffect, useState, useRef, useCallback } from 'react'
import Form from 'react-bootstrap/Form'
import Collapse from 'react-bootstrap/Collapse'
import Button from 'react-bootstrap/Button'
import _ from 'lodash'

import BottomSheet from './BottomSheet'
import InputWithIcon from './InputWithIcon'
import Svg from './Svg'
import Divider from './Divider'

import {ReactComponent as DownArrowIcon} from '../../svg/down_arrow.svg'

import Tag from './Tag'

const Select = ({ label, options, onSelect, other, value, initialValue, multiple, placeholder, disabled, isInvalid, errorMessage, readOnly, wrapper: Wrapper = BottomSheet }) => {
  const [show, setShow] = useState(false)
  const [selection, setSelection] = useState(multiple ? (initialValue || []) : (initialValue || options[0]?.value))
  const [showOther, setShowOther] = useState(false)
  const [invalidOther, setInvalidOther] = useState(false)
  const otherTextAreaRef = useRef()

  const type = multiple ? 'checkbox' : 'radio'

  const refs = []
  console.log({ label, selection})

  const open = () => {
    if (!readOnly) {
      setShow(true)
      if (!options.map(option => option.value).includes(selection) && !multiple) {
        setShowOther(true)
      }
    }
  }

  const close = useCallback(() => {
    if (selection) {
      setShow(false)
      setShowOther(false)
    } else if (showOther) {
      setInvalidOther(true)
      otherTextAreaRef.current?.focus()
    }
  }, [selection, showOther])

  useEffect(() => {
    if (show) {
      if (refs.length && !multiple) {
        let index = options.findIndex(item => item.value === selection)
        if (index === -1) index = options.length
        if (index >= 0 && !!refs[index]) refs[index].checked = true
      }
      if (multiple) {
        selection.forEach(item => {
          const exists = options.map(option => option.value).includes(item)
          if (exists) {
            refs[options.map(option => option.value).indexOf(item)].checked = true
          }
          if (!exists) {
            refs[options.length].checked = true
            setShowOther(true)
          }
        })
      }
    }
  }, [selection, multiple, options, show, refs])

  useEffect(() => {
    if (!_.isEqual(value, selection)) {
      onSelect(selection)
    }

    if (options.map(option => option.value).includes(selection)) {
      close()
    }
  }, [close, options, onSelect, selection, value])

  useEffect(() => {
    setSelection(selection => {
      const optionsValues = options.map(o => o.value)
      if (multiple) {
        if (selection === []) return initialValue || []
        else {
          const originalSelection = selection.filter(v => optionsValues.includes(v))
          const otherSelection = _.difference(selection, optionsValues)
          if (other && otherSelection.length) {
            setShowOther(true)
            return _.union(originalSelection, otherSelection)
          }
          else return originalSelection
        }
      }
      else {
        if (optionsValues.includes(selection)) return selection
        else if (showOther) return otherValue()
        else return optionsValues[0]
      }
    })
  }, [options, multiple])

  const handleRadioSelection = (e, { index }) => {
    refs.forEach(ref => ref.checked = false)
    refs[index].checked = true

    if (index < options.length) {
      setShowOther(false)
      setSelection(options[index].value)
      close()
    } else {
      setSelection('')
      setShowOther(true)
    }
  }

  const handleCheckboxSelection = (e, { index }) => {
    if (index < options.length) {
      refs[index].checked = !refs[index].checked
      const selected = (other ? refs.slice(0, refs.length - 1) : refs)
        .map((ref, index) => ({ value: options[index]?.value, checked: ref.checked }))
        .filter(({ checked }) => checked)
        .map(({ value }) => {
          return value
        })

      const otherValue = selection.find(item => !options.map(option => option.value).includes(item))
      if (otherValue) selected.push(otherValue)
      setSelection(selected)
    } else {
      const target = !showOther
      refs[index].checked = target
      setShowOther(target)
      if (target) setImmediate(() => otherTextAreaRef.current?.focus())
      else setSelection(selection.filter(s => options.map(o => o.value).includes(s)))
    }
  }

  const handleSelection = multiple ? handleCheckboxSelection : handleRadioSelection

  const handleRadioOtherChange = (e) => {
    const { value } = e.target
    setSelection(value)
  }

  const handleCheckboxOtherChange = (e) => {
    const { value } = e.target
    const oldOtherIndex = selection.findIndex(item => !options.map(option => option.value).includes(item))

    const selected = selection.filter((x, i) => i !== oldOtherIndex)

    setSelection([...selected, value])
  }

  const handleOtherChange = multiple ? handleCheckboxOtherChange : handleRadioOtherChange

  const onConfirm = () => {
    close()
    onSelect(selection)
  }

  const otherValue = () => {
    if (multiple) {
      return selection.filter(item => !options.map(option => option.value).includes(item)).join(', ')
    }
    else {
      return options.map(option => option.value).includes(selection) ? '' : selection
    }
  }

  const onDelete = (index) => {
    setSelection([
      ...selection.filter((x, i) => i !== index)
    ])
  }

  return (
    <>
      <InputWithIcon
        icon={<Svg as={DownArrowIcon} className='icon' onClick={open}/>}
        value={multiple ? '' : options.find(item => item.value === selection)?.text || (other && selection)}
        readOnly
        onClick={open}
        style={{background: 'white'}}
        placeholder={placeholder}
        disabled={disabled}
        isInvalid={isInvalid}
        errorMessage={errorMessage}
      />
      {
        multiple && !!selection.length && <Tag.Group>
          {selection.map(option => options.find(o => o.value === option) || (other && ({ text: option, value: option }))).map((option, index) =>
            <Tag key={option?.value} onDelete={() => onDelete(index)}>{option?.text}</Tag>
          )}
        </Tag.Group>
      }
      <Wrapper show={show} close={close} title={label}>
        <div className='select-options-group'>
          {options.map((option, index) => {
            const { text, value, tag } = option
            return (
              <div key={`select-option-${index}`}>
                <div className='select-option' onClick={e => handleSelection(e, { index, value })}>
                  <Form.Check.Input
                    ref={ref => refs.push(ref)}
                    type={type}
                    isValid
                    data={value}
                    onClick={e => handleSelection(e, { index, value })}
                  />
                  <div>{text} {tag}</div>
                </div>
                <Divider />
              </div>
            )
          })}
          { other && (
            <Form.Group className='other-selection'>
              <div className='select-option' onClick={e => handleSelection(e, { index: options.length })}>
                <Form.Check.Input
                  ref={ref => refs.push(ref)}
                  type={type}
                  isValid
                  onClick={e => handleSelection(e, { index: options.length })}
                />
                <div>أخرى</div>
              </div>
              <Collapse in={showOther}>
                <Form.Control
                  {
                    ...!multiple && { as: 'textarea'}
                  }
                  placeholder='....'
                  onChange={handleOtherChange}
                  value={otherValue()}
                  isInvalid={invalidOther}
                  ref={otherTextAreaRef}
                />
              </Collapse>
              { !multiple && <Divider /> }
            </Form.Group>
          )}
          { multiple && <Button onClick={onConfirm}>تأكيد</Button>}
        </div>
      </Wrapper>
    </>
  )
}

export default Select

import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import Svg from '../basic/Svg'

import {ReactComponent as HomeActive} from '../../svg/home_active.svg'
import {ReactComponent as HomeDefault} from '../../svg/home_default.svg'
import {ReactComponent as CalendarActive} from '../../svg/calendar_active.svg'
import {ReactComponent as CalendarDefault} from '../../svg/calendar_default.svg'
import {ReactComponent as PatientsActive} from '../../svg/patients_active.svg'
import {ReactComponent as PatientsDefault} from '../../svg/patients_default.svg'
import {ReactComponent as ProfileActive} from '../../svg/profile_active.svg'
import {ReactComponent as ProfileDefault} from '../../svg/profile_default.svg'

const FooterItem = (props) => {
  const { name, icon: Icon, text, active, onClick } = props
  const wrapperClass = `footer-icon-wrapper${active ? '-active' : ''}`
  return (
    <div onClick={() => onClick(name)}>
      { active && <div className='footer-activity-bar' /> }
      <div className={wrapperClass}>
        <Icon active={active} />
        <p>
          {text}
        </p>
      </div>
    </div>
  )
}

const Footer = () => {
  const history = useHistory()
  const { userId } = useSelector(state => state.auth)

  const Items = Object.freeze({
    HOME: '/',
    CALENDAR: `/requester/${userId}/reservations`,
    PATIENTS: `/requester/${userId}/patients`,
    PROFILE: `/requester/${userId}/profile/`
  })

  const [activeItem, setActiveItem] = useState(Items[history.location.pathname])

  const handleItemClick = (name) => {
    if (activeItem !== name) {
      history.push(name)
    }
  }

  useEffect(() => {
    if (Object.values(Items).includes(history.location.pathname)) {
      setActiveItem(history.location.pathname)
    }
  }, [history, Items])

  const Icon = {
    Home: ({ active }) => active ? <Svg as={HomeActive} /> : <Svg as={HomeDefault} />,
    Calendar: ({ active }) => active ? <Svg as={CalendarActive} /> : <Svg as={CalendarDefault} />,
    Patients: ({ active }) => active ? <Svg as={PatientsActive} /> : <Svg as={PatientsDefault} />,
    Profile: ({ active }) => active ? <Svg as={ProfileActive} /> : <Svg as={ProfileDefault} />
  }
  return (
    <div className='footer'>
      <FooterItem
        name={Items.HOME}
        icon={Icon.Home}
        text='الرئيسية'
        active={activeItem === Items.HOME}
        onClick={handleItemClick}
      />
      <FooterItem
        name={Items.CALENDAR}
        icon={Icon.Calendar}
        text='زياراتي'
        active={activeItem === Items.CALENDAR}
        onClick={handleItemClick}
      />
      <FooterItem
        name={Items.PATIENTS}
        icon={Icon.Patients}
        text='المرضى'
        active={activeItem === Items.PATIENTS}
        onClick={handleItemClick}
      />
      <FooterItem
        name={Items.PROFILE}
        icon={Icon.Profile}
        text='حسابي'
        active={activeItem === Items.PROFILE}
        onClick={handleItemClick}
      />
    </div>
  )
}

export default Footer

import Divider from './Divider'

const FormSplitter = ({ label }) => {
  return (
    <div className='form-splitter'>
      <Divider />
      <b>{label}</b>
      <Divider />
    </div>
  )
}

export default FormSplitter

import { useState, useRef } from 'react'
import Countdown, { zeroPad } from 'react-countdown'

import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'

import Screen from '../../components/layout/Screen'
import Header from '../../components/layout/Header'
import Main from '../../components/layout/Main'

import DiscreteInput from '../../components/basic/DiscreteInput'
import BackButton from '../../components/basic/BackButton'

import { VerificationCodeActions } from '../../redux/reducers/verificationCodeSlice'

export const Hash = 'verifyCode'

const verificationCodeLength = process.env.REACT_APP_VERIFICATION_AUTHORITY === 'Firebase' ? 6 : 5

export const Component = ({ dispatch, mobileNumber, failed, isLoading, target }) => {
  const submitRef = useRef()
  const [code, setCode] = useState(null)
  const [currentTime, setCurrentTime] = useState(Date.now() + 60000)
  const [currentTimeIndex, setCurrentTimeIndex] = useState(0)

  const refreshTimer = () => {
    setCurrentTimeIndex(index => index + 1)
    setCurrentTime(Date.now() + 60000)
  }

  const handleVerify = () => {
    dispatch(VerificationCodeActions.verifyRequest({ mobileNumber, code }))
  }

  const handleResendCode = () => {
    dispatch(VerificationCodeActions.resendCodeRequest({ mobileNumber, target }))
    refreshTimer()
  }

  const onFinish = (code) => {
    submitRef.current.focus()
    setCode(code)
  }

  return (
    <Screen className='code-verification-screen'>
      <Header
        middleItem={<b>التحقق من رقم الهاتف</b>}
        rightItem={<BackButton />}
      />
      <Main>
        {/* <Svg as={VerificationCodeImage} className='verification-code-image' /> */}
        <img src='/verification_code.svg' alt='Verification Code' />
        <br />
        <b>تم إرسال كود التحقق بنجاح !</b>
        <p>تم إرسال كود التحقق علي رقم <span>{mobileNumber}</span> برجاء كتابة الكود حتى تتمكن من إستكمال بياناتك</p>
        <Countdown
          date={currentTime}
          key={currentTimeIndex}
          precision={1000}
          renderer={({ minutes, seconds }) => <div className='countdown'>{zeroPad(minutes)}:{zeroPad(seconds)}</div>}
        />
        <DiscreteInput
          onFinish={onFinish}
          isInvalid={failed}
          errorMessage='الكود الذى أدخلته غير صحيح'
          emptyMessage='من فضلك ادخل كود التحقق'
          size={verificationCodeLength}
        />
        <Button variant='primary' ref={submitRef} onClick={handleVerify}>
          {isLoading ? <Spinner animation="border" size='sm' /> : <span>التحقق من رقمي</span>}
        </Button>
        <p className='verify-code-form-bottom-link'>الكود لم يصلك ؟ <span className='link' onClick={handleResendCode}>إعادة إرسال الكود مره اخرى</span></p>
      </Main>
    </Screen>
  )
}

import React from 'react'
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';

import Button from 'react-bootstrap/Button'

import BottomSheet from './basic/BottomSheet';
import Svg from './basic/Svg';

import {ReactComponent as DetectLocationIcon} from '../svg/auto_detect_location.svg'
import MarkerIcon from '../svg/map_marker.svg'

const containerStyle = {
  height: '50vh'
};

function MyComponent({ onConfirm }) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyDRAXwvRiKxH0l1EMeTNhzx7k5r73WEO9c"
  })

  const [map, setMap] = React.useState(null)
  const [showMap, setShowMap] = React.useState(false)
  const center = {
    lat: 30.0596185,
    lng: 31.1884238
  }

  const markerRef = React.useRef()

  const location = center

  // const marker = new window.google.maps.Marker({
  //   icon: MarkerIcon,
  //   position: location,

  // })

  const [zoom, setZoom] = React.useState(10)

  // React.useEffect(() => {
  //   navigator.geolocation.getCurrentPosition(position => {
  //     const object = {
  //       lng: position.coords.longitude,
  //       lat: position.coords.latitude
  //     }
  //     setCenter(object)
  //     // setLocation(object)
  //   })
  // })

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(
      new window.google.maps.LatLng(29.983069032906112, 31.146038725046257),
      new window.google.maps.LatLng(30.144116394111993, 31.297100736765007)
    );
    map.fitBounds(bounds);
    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback() {
    setMap(null)
  }, [])

  // const handleMarkerDrag = e => {
  //   setLocation({
  //     lat: e.latLng.lat(),
  //     lng: e.latLng.lng()
  //   })
  //   setZoom(20)
  // }

  const animateZoom = target => {
    for (let i = 1; i < Math.abs(zoom - target); i++) {
      setTimeout(() => setZoom(target > zoom ? zoom + i : zoom - i),  i * 100)
    }
  }

  const detectCurrentLocation = () => {
    console.log('detectCurrentLocation clicked!')
    navigator.geolocation.getCurrentPosition(position => {
      const { coords } = position
      const latLng = new window.google.maps.LatLng(coords.latitude, coords.longitude)
      map.setCenter(latLng)
      markerRef.current?.marker.setPosition(latLng)
      // setCenter({ lng: coords.longitude, lat: coords.latitude })
    })
    animateZoom(20)
  }

  // const animateMarker = ({ lat, lng }) => {
  //   const numDeltas = 50;
  //   const delay = 10; //milliseconds
  //   let i = 0;
  //   const deltaLat = (lat - location.lat) / numDeltas;
  //   const deltaLng = (lng - location.lng) / numDeltas;
  //   moveMarker();

  //   function moveMarker(){
  //       location.lat += deltaLat;
  //       location.lng += deltaLng;
  //       var latlng = new window.google.maps.LatLng(location.lat, location.lng);
  //       // const newLat = markerRef.current?.marker.position.lat() + deltaLat
  //       // const newLng = markerRef.current?.marker.position.lng() + deltaLng
  //       // var latlng = new window.google.maps.LatLng(newLat, newLng);
  //       markerRef.current?.marker.setPosition(latlng);
  //       if(i !== numDeltas){
  //           i++;
  //           setTimeout(moveMarker, delay);
  //       }
  //   }
  // }

  if (map) map.addListener('click', click => console.log({lng: click.latLng.lng(), lat: click.latLng.lat() }))

  const handleConfirmation = () => {
    setShowMap(false)
    onConfirm({
      longitude: markerRef.current?.marker.position.lng(),
      latitude: markerRef.current?.marker.position.lat(),
    })
  }
  return (
    <div>
      <Button variant='outline-primary' onClick={() => setShowMap(true)}>تحديد العنوان من الخريطة</Button>
      <BottomSheet
        title='تحديد العنوان علي الخريطة'
        show={showMap}
        close={() => setShowMap(false )}
      >
        <div className='maps'>
          {
            isLoaded
            ? <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={zoom}
                onLoad={onLoad}
                onUnmount={onUnmount}
                // onDragEnd={() => animateMarker({ lat: map.center.lat(), lng: map.center.lng() })}
                onBoundsChanged={() => markerRef.current?.marker.setPosition(new window.google.maps.LatLng(map.center.lat(), map.center.lng()))}
              >
                { /* Child components, such as markers, info windows, etc. */ }
                <Marker
                  ref={markerRef}
                  position={new window.google.maps.LatLng(location.lat, location.lng)}
                  // draggable
                  // onDragEnd={handleMarkerDrag}
                  icon={MarkerIcon}
                />
                <></>
              </GoogleMap>
            : <></>
          }
          <div className='maps-detect-location' onClick={detectCurrentLocation}>
            <Svg as={DetectLocationIcon} iconn />
            <div>تحديد موقعي أوتوماتيكياً</div>
          </div>
          <Button onClick={handleConfirmation}>تأكيد</Button>
        </div>
      </BottomSheet>
    </div>
  )
}

export default React.memo(MyComponent)
import axios from 'axios'
import { getUserType } from '../util'

export const createApi = ({ mobileNumber, target }) => {
  return axios.post(`${process.env.REACT_APP_BE_URL}/verificationCode`, {
    mobileNumber,
    userType: getUserType(),
    target
  })
}

export const verifyApi = ({ mobileNumber, code }) => {
  return axios.get(`${process.env.REACT_APP_BE_URL}/verificationCode`, {
    params: { mobileNumber, code }
  })
}

export const resendApi = ({ mobileNumber, target }) => {
  return axios
    .delete(`${process.env.REACT_APP_BE_URL}/verificationCode`, {
      params: { mobileNumber, target, userType: getUserType() }
    })
    .then(() => createApi({ mobileNumber, target }))
    .catch(() => createApi({ mobileNumber, target }))
}

import Image from 'react-bootstrap/Image'

const PersonCard = ({ relaxed, onClick, ...props }) => {
  const classNames = ['person-card']
  if (relaxed) classNames.push('relaxed')

  const avatar = props.children.find(child => child.type === Avatar)

  if (avatar) classNames.push('avatar-grid')

  const children = avatar ? props.children.filter(child => child !== avatar) : props.children

  return (
    <div className={classNames.join(' ')} onClick={onClick}>
      {avatar}
      <div>
        {children}
      </div>
    </div>
  )
}

const Group = props => {
  return <div className='person-card-group'>{props.children}</div>
}

const Title = props => <div className='person-card-title'>{props.children}</div>

const Content = ({ className, ...props }) => {
  const classNames = ['person-card-content']
  if (className) classNames.push(className)

  return <div className={classNames.join(' ')}>{props.children}</div>
}

const FadedContent = props => <Content className='faded'>{props.children}</Content>

const Avatar = ({ src }) => {
  return (
    <Image
      className='person-card-avatar'
      src={src || '/empty-profile-picture.png'}
      style={!src ? { background: 'white', boxShadow: '0px 0px 9px 2px rgb(0 0 0 / 10%)' } : {}}
      roundedCircle
    />
  )
}

PersonCard.Group = Group
PersonCard.Title = Title
PersonCard.Content = Content
PersonCard.FadedContent = FadedContent
PersonCard.Avatar = Avatar

export default PersonCard

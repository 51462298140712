import axios from 'axios'
import qs from 'query-string'

export const listCitiesApi = () => {
  return axios.get(
    `${process.env.REACT_APP_BE_URL}/lookups/cities`
  )
}

export const listAreasApi = () => {
  return axios.get(
    `${process.env.REACT_APP_BE_URL}/lookups/areas`
  )
}

export const listDoctorSpecialitiesApi = () => {
  return axios.get(
    `${process.env.REACT_APP_BE_URL}/lookups/doctorSpecialities`
  )
}

export const listNurseTasksApi = () => {
  return axios.get(
    `${process.env.REACT_APP_BE_URL}/lookups/nurseTasks`
  )
}

export const listHomeCareSpecialistTasksApi = () => {
  return axios.get(
    `${process.env.REACT_APP_BE_URL}/lookups/homeCareSpecialistTasks`
  )
}

export const listMedicalCenterServicesApi = ({ type }) => {
  return axios.get(
    `${process.env.REACT_APP_BE_URL}/lookups/medicalCenterServices`,
    {
      params: { type }
    }
  )
}

export const listAreasByCitiesApi = ({ cities }) => {
  return axios.get(
    `${process.env.REACT_APP_BE_URL}/lookups/areas`,
    {
      params: { cities },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    }
  )
}

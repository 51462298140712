import { Formik } from 'formik'
import * as yup from 'yup'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'

import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

import Editable from '../../components/basic/Editable'
import LocationInfoForm from '../../components/LocationInfoForm'

import { selectReservation, ReservationActions } from '../../redux/reducers/reservationSlice'
import BottomSheet from '../../components/basic/BottomSheet'
import { RequesterActions } from '../../redux/reducers/requesterSlice'

const ReservationConfirmation = ({ onSubmit, data }) => {
  const dispatch = useDispatch()
  const { pricing, agreement } = useSelector(selectReservation)
  const [showConditions, setShowConditions] = useState(false)

  useEffect(() => {
    console.log({ data })
    dispatch(ReservationActions.getPricingRequest({
      areaId: data.patient.area.id,
      providerType: data.reservation.providerType,
      tag: data.tag
    }))

    dispatch(ReservationActions.getAgreementRequest({ agreementType: data.reservation.providerType }))

  }, [data, dispatch])

  const schema = yup.object().shape({
    acceptedAgreement: yup.boolean().oneOf([true]).required('من فضلك قم بادخال المعلومات كاملة')
  })

  const toggleConditions = () => setShowConditions(!showConditions)

  const updateReservationAndSubmit = (form) => {
    if (!_.isEqual(form.patient, data.patient)) {
      dispatch(RequesterActions.updatePatientRequest({
        requesterId: form.patient.requester.id,
        patientId: form.patient.id,
        ...form.patient
      }))
    }
    onSubmit({
      reservation: {
        ...form.reservation,
        agreementId: agreement.id,
        priceRange: `${pricing.normalPriceFrom} - ${pricing.normalPriceTo}`
      }
    })
  }

  return pricing && (
    <Formik
      validationSchema={schema}
      onSubmit={updateReservationAndSubmit}
      initialValues={{...schema.default(), ...data}}
      validateOnChange={false}
      enableReinitialize={true}
    >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        values
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label>سعر الخدمة <span className='label-hint'>هذا السعر قد يكون تقريبياً </span></Form.Label>
            <Editable
              value={<div>يتراوح بين <b>{pricing.normalPriceFrom} - {pricing.normalPriceTo}</b> جنيه مصري</div>}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>معلومات الخدمه</Form.Label>
            <Editable
              value={data.serviceInformation}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>اسم المريض</Form.Label>
            <Editable
              value={values.patient.name}
              editTitle='اسم المريض'
            >
              {() => (
                <Form.Control
                  value={values.patient.name}
                  name='patient.name'
                  onChange={handleChange}
                />
              )}
            </Editable>
          </Form.Group>
          {!!data.reservation.reason && (
            <Form.Group>
              <Form.Label>الشكوى المرضيه</Form.Label>
              <Editable
                name='reservation.reason'
                value={values.reservation.reason}
                editTitle='الشكوى المرضيه'
              >
                {() => (
                  <Form.Group>
                    <Form.Label>الشكوى المرضيه</Form.Label>
                    <Form.Control
                      name='reservation.reason'
                      value={values.reservation.reason || ''}
                      onChange={handleChange}
                    />
                  </Form.Group>
                )}
              </Editable>
            </Form.Group>
          )}
          <Form.Group>
            <Form.Label>عنوان تقديم الخدمة</Form.Label>
            <Editable
              value={[values.patient.city.arabicName, values.patient.area.arabicName, values.patient.address].filter(x => x).join(' - ')}
              editTitle='تغيير العنوان'
            >
              {close => (
                <LocationInfoForm
                  data={values.patient}
                  onSubmit={(form) => {
                    setFieldValue('patient.city.id', form.cityId)
                    setFieldValue('patient.area.id', form.areaId)
                    setFieldValue('patient.address', form.address)
                    close()
                  }}
                />
              )}
            </Editable>
          </Form.Group>
          {data.patient.observer && (
            <Form.Group>
              <Form.Label>بيانات المسؤول عن المريض</Form.Label>
              <Editable
                value={{'الاسم': values.patient.observer?.name, 'رقم الهاتف': values.patient.observer?.mobileNumber}}
              >
                {() => (
                  <Form>
                    <Form.Group>
                      <Form.Label> اسم المسؤول عن المريض</Form.Label>
                      <Form.Control
                        name='patient.observer.name'
                        value={values.patient.observer?.name}
                        onChange={handleChange}
                        placeholder='اسم المسؤول عن المريض'
                      />
                    </Form.Group>

                    <Form.Group>
                      <Form.Label>رقم الهاتف</Form.Label>
                      <Form.Control
                        name='patient.observer.mobileNumber'
                        value={values.patient.observer?.mobileNumber}
                        onChange={handleChange}
                        placeholder='رقم الهاتف'
                      />
                    </Form.Group>
                  </Form>
                )}
              </Editable>
            </Form.Group>
          )}
          <Form.Check
            checked={values.acceptedAgreement}
            onChange={e => setFieldValue('acceptedAgreement', e.target.checked)}
            label={<div>اقر بإنني قرأت وأوافق علي <Link to='#' onClick={toggleConditions}>الشروط والأحكام</Link></div>}
          />
          <Button type='submit' disabled={!values.acceptedAgreement}>تأكيد الطلب</Button>
          <BottomSheet
            title='الشروط والأحكام'
            close={toggleConditions}
            show={showConditions}
          >
            <div style={{textAlign: 'right'}}>{agreement && agreement.details}</div>
            <br />
            <Button
              onClick={() => {
                setFieldValue('acceptedAgreement', true)
                toggleConditions()
              }}
            >
              أوافق علي الشروط والأحكام
            </Button>
          </BottomSheet>
        </Form>
      )}
    </Formik>
  )
}

export default ReservationConfirmation

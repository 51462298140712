import format from 'date-fns/format'
import { arSA } from 'date-fns/locale'
import _ from 'lodash'

import { Formik } from 'formik'
import * as yup from 'yup'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

import Info from '../../../../components/Info'
import DatePicker from '../../../../components/basic/DatePicker'
import Attachments from '../../../../components/basic/Attachments'

import Appender from './Appender'

import Select from '../../../../components/basic/Select'
import { ScientificDegrees } from '../../../../components/common'
import TaggedFormLabel from '../../../../components/basic/TaggedFormLabel'

const ScientificCertificates = ({ onSubmit, data }) => {
  const schema = yup.object().shape({
    scientificDegree: yup.string(),
    scientificSpeciality: yup.string().required('من فضلك قم بادخال المعلومات كاملة'),
    grantedOn: yup.date().required('من فضلك قم بادخال المعلومات كاملة'),
    certificatePhoto: yup.number()
  })

  const scientificDegreesOptions = ScientificDegrees.options()

  return (
    <Appender
      onSubmit={scientificCertificates => onSubmit({ scientificCertificates })}
      mapper={study => (
        <div>
          <b>{ScientificDegrees.getTextByValue(study.scientificDegree)}</b>
          <div>{study.scientificSpeciality}</div>
          <div>تاريخ الحصول {study.grantedOn && format(study.grantedOn, 'MMMM yyyy', { locale: arSA })}</div>
        </div>
      )}
      initialValue={data.studies}
    >
      {({ initialValue, onSubmit }) => (
        <Formik
          // validationSchema={schema}
          initialValues={initialValue}
          validateOnChange={false}
          onSubmit={form => {
            if(_.keys(form).length < 3) {
              onSubmit(null)
            }
            else {
              onSubmit(form)
            }
          }}
          enableReinitialize={true}
          validate={async (form) => {
            const { errors, inner } = await schema.validate(form, { abortEarly: false })
              .then(() => ({}))
              .catch(error => error)
            const fields = inner?.map(error => error.path)
            const e = errors?.length && _.zipObject(fields, errors)

            if (['grantedOn', 'scientificSpeciality'].every(v => fields?.includes(v))) {
              return {}
            }
            return e
          }}
        >
          {({ handleSubmit, handleChange, setFieldValue, values, errors }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Info>برجاء ادخال البيانات بداية من الأكبر قيمة</Info>
              <Form.Group>
                <Form.Label>الدراسة العلمية</Form.Label>
                <Select
                  name='scientificDegree'
                  options={scientificDegreesOptions}
                  value={values.scientificDegree}
                  onSelect={value => {console.log('setting scientificDegree select', value); setFieldValue('scientificDegree', value)}}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>تخصص الدراسة العلمية</Form.Label>
                <Form.Control
                  name='scientificSpeciality'
                  onChange={handleChange}
                  value={values.scientificSpeciality}
                  isInvalid={errors.scientificSpeciality}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.scientificSpeciality}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label>تاريخ الحصول</Form.Label>
                <DatePicker
                  onChange={date => setFieldValue('grantedOn', date)}
                  value={values.grantedOn}
                  isInvalid={errors.grantedOn}
                  errorMessage={errors.grantedOn}
                />
              </Form.Group>
              <Form.Group>
                <TaggedFormLabel>صورة من الشهادة</TaggedFormLabel>
                <Attachments
                  single
                  onChange={value => setFieldValue('certificatePhoto', value?.id)}
                />
              </Form.Group>
              <Button type='submit'>تأكيد</Button>
            </Form>
          )}
        </Formik>
      )}
    </Appender>
  )
}

ScientificCertificates.title = <TaggedFormLabel>الدراسات العلمية</TaggedFormLabel>

export default ScientificCertificates

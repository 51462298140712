import Tab from 'react-bootstrap/Tab'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Nav from 'react-bootstrap/Nav'
import Image from 'react-bootstrap/Image'
import { ChevronRight, ChevronLeft, PersonFill } from 'react-bootstrap-icons'
import qs from 'query-string'
import merge from 'lodash/merge'

import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, withRouter } from 'react-router-dom'

import {ReactComponent as HomeIcon} from '../../svg/dashboard/icons8-touch-access-of-homepage-isolated-on-a-white-background-100.svg'
import {ReactComponent as OperatorsIcon} from '../../svg/dashboard/icons8-woman-60.svg'
import {ReactComponent as DoctorsIcon} from '../../svg/dashboard/icons8-doctor-64.svg'
import {ReactComponent as PhysicalTherapistsIcon} from '../../svg/dashboard/icons8-physical-therapy-80.svg'
import {ReactComponent as NursesIcon} from '../../svg/dashboard/icons8-nurse-256.svg'
import {ReactComponent as HomeCareSpecialistsIcon} from '../../svg/dashboard/icons8-person-at-home-80.svg'
import {ReactComponent as MedicalCentersIcon} from '../../svg/dashboard/icons8-hospital-3-96.svg'
import {ReactComponent as LookupsIcon} from '../../svg/dashboard/icons8-add-folder-80.svg'
import {ReactComponent as RequestersAndPatientsIcon} from '../../svg/dashboard/icons8-patient-64.svg'
import {ReactComponent as ReservationsIcon} from '../../svg/dashboard/icons8-combi-ticket-96.svg'
import {ReactComponent as LogoutIcon} from '../../svg/dashboard/icons8-logout-rounded-left-64.svg'
import {ReactComponent as PricingIcon} from '../../svg/dashboard/money.svg'

import { AuthActions, selectAuth } from '../../redux/reducers/authSlice'
import { DashboardActions } from '../../redux/reducers/dashboardSlice'

const HorizontalCollapser = ({ children, collapsed }) => {
  const style = { minHeight: '10px', transition: 'all 0.5s', whiteSpace: 'nowrap', overflow: 'hidden' }
  const shown = { ...style, width: '200px', marginLeft: '10px' }
  const hidden = { ...style, width: 0, flexWrap: 'nowrap', margin: 0   }
  return (
    <div style={collapsed ? hidden : shown}>
      {children}
    </div>
  )
}

const nonAuthenticatedPaths = [
  '/signIn',
  '/forgotPassword'
]

const DashboardLayout = ({ children, location: { search } }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [collapsed, setCollapsed] = useState(qs.parse(search).collapsed === 'true')
  const { userDetails, role } = useSelector(selectAuth)

  // if (history.location.pathname === '/') {
  //   history.push('operators')
  // }

  const signOut = () => {
    dispatch(DashboardActions.reset())
    dispatch(AuthActions.signOut())
    history.push('/signIn')
  }

  if (!localStorage.getItem('accessToken') && !nonAuthenticatedPaths.includes(history.location.pathname)) {
    history.push('/signIn')
  }

  const parsedSearch = qs.parse(search)

  useEffect(() => {
    history.replace(merge(history.location, { search: qs.stringify({ ...parsedSearch, collapsed }) }))
  }, [collapsed])

  useEffect(() => {
    if (parsedSearch.collapsed === undefined) {
      history.replace(merge(history.location, { search: qs.stringify({ ...parsedSearch, collapsed })}))
    }
  }, [parsedSearch])

  const isActive = key => {
    const parts = history.location.pathname.split('/')
    const part = parts[1]
    return key === part
  }

  return (
      <Tab.Container
        defaultActiveKey={history.location.pathname.slice(1)}
        onSelect={(activeKey, e) => {
          let element = e.target
          while (!element.classList.contains('nav') && !!element.parentElement) {
            element = element.parentElement
          }
          if(element.id === 'dashboard-navigator') {
            history.push(`/${activeKey}`)
          }
        }}>
        <Row style={{direction:'ltr'}} className='dashboard-layout dashboard ltr'>
          <Col>
            <div className='toggle-wrapper'>
              <button
                type="button"
                onClick={() => setCollapsed(!collapsed)}
              >
                {collapsed ? <ChevronRight /> : <ChevronLeft />}
              </button>
              <div className='authenticated-user'>
                {
                  userDetails?.profilePicture
                  ? <Image src={userDetails.profilePicture?.url} roundedCircle />
                  : <PersonFill radius='50%'/>
                }
                <HorizontalCollapser collapsed={collapsed}>
                  <b>{userDetails?.fullName}</b>
                  <div>{role}</div>
                </HorizontalCollapser>
              </div>
            </div>
            <Nav id='dashboard-navigator' className="flex-column dashboard-navigator">
              <Nav.Item>
                <Nav.Link eventKey="" active={isActive('')}>
                  <HomeIcon className='icon' />
                  <HorizontalCollapser collapsed={collapsed}>
                    <div>Home</div>
                  </HorizontalCollapser>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="operators" active={isActive('operators')}>
                  <OperatorsIcon className='icon' />
                  <HorizontalCollapser collapsed={collapsed}>
                    <div>Operators</div>
                  </HorizontalCollapser>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="doctors" active={isActive('doctors')}>
                  <DoctorsIcon className='icon' />
                  <HorizontalCollapser collapsed={collapsed}>
                    <div>Doctors</div>
                  </HorizontalCollapser>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="physicalTherapists" active={isActive('physicalTherapists')}>
                  <PhysicalTherapistsIcon className='icon' />
                  <HorizontalCollapser collapsed={collapsed}>
                    <div>Physical Therapists</div>
                  </HorizontalCollapser>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="nurses" active={isActive('nurses')}>
                  <NursesIcon className='icon' />
                  <HorizontalCollapser collapsed={collapsed}>
                    <div>Nurses</div>
                  </HorizontalCollapser>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="homeCareSpecialists" active={isActive('homeCareSpecialists')}>
                  <HomeCareSpecialistsIcon className='icon' />
                  <HorizontalCollapser collapsed={collapsed}>
                    <div>Home Care Specialists</div>
                  </HorizontalCollapser>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="medicalCenters" active={isActive('medicalCenters')}>
                  <MedicalCentersIcon className='icon' />
                  <HorizontalCollapser collapsed={collapsed}>
                    <div>Medical Centers</div>
                  </HorizontalCollapser>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="lookups" active={isActive('lookups')}>
                  <LookupsIcon className='icon' />
                  <HorizontalCollapser collapsed={collapsed}>
                    <div>Lookups</div>
                  </HorizontalCollapser>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="pricing" active={isActive('pricing')}>
                  <PricingIcon className='icon' />
                  <HorizontalCollapser collapsed={collapsed}>
                    <div>Pricing</div>
                  </HorizontalCollapser>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="requestersAndPatients" active={isActive('requestersAndPatients')}>
                  <RequestersAndPatientsIcon className='icon' />
                  <HorizontalCollapser collapsed={collapsed}>
                    <div>Requesters & Patients</div>
                  </HorizontalCollapser>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="reservations" active={isActive('reservations')}>
                  <ReservationsIcon className='icon' />
                  <HorizontalCollapser collapsed={collapsed}>
                    <div>Reservations</div>
                  </HorizontalCollapser>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link onClick={signOut}>
                  <LogoutIcon className='icon' />
                  <HorizontalCollapser collapsed={collapsed}>
                    <div>Logout</div>
                  </HorizontalCollapser>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col>
            {children}
          </Col>
        </Row>
      </Tab.Container>
  )
}

export default withRouter(DashboardLayout)

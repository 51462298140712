import { fork } from "@redux-saga/core/effects"

import auth from './authSaga'
import verificationCode from './verificationCodeSaga'
import requester from './requesterSaga'
import lookupSaga from './lookupsSaga'
import reservationSaga from './reservationSaga'
import cacheSaga from './cacheSaga'
import notificationsSaga from './notificationsSaga'
import attachmentsSaga from './attachmentsSaga'
import dashboardSaga from './dashboardSaga'
import providerSaga from './providerSaga'
import visitSaga from './visitSaga'
import providerAssignmentSaga from './providerAssignmentSaga'

export default function* rootSaga() {
  yield fork(auth)
  yield fork(verificationCode)
  yield fork(requester)
  yield fork(lookupSaga)
  yield fork(reservationSaga)
  yield fork(cacheSaga)
  yield fork(notificationsSaga)
  yield fork(attachmentsSaga)
  yield fork(dashboardSaga)
  yield fork(providerSaga)
  yield fork(visitSaga)
  yield fork(providerAssignmentSaga)
  // code after fork-effect
}
import { useRef, useEffect } from "react";

const Svg = ({ as: Component, icon, iconn, largeIcon, className, ...rest }) => {
  const target = useRef()


  //round to 2 decimal places
  //https://stackoverflow.com/a/11832950
  function r(num) {
    return Math.round((num + Number.EPSILON) * 100) / 100;
  }

  //remove svg whitespace
  function svgRemoveWhitespace(svg) {
    let box = svg.getBBox(),
        viewBox = iconn ? '0 0 24 24' : [
          r(box.x),
          r(box.y),
          r(box.width),
          r(box.height)
        ].join(' ');

    svg.setAttribute('viewBox', viewBox)
    svg.removeAttribute('width')
    svg.removeAttribute('height')
  }

  useEffect(() => {
    if (target.current) {
      svgRemoveWhitespace(target.current)
    }
  }, [target, iconn])

  const classes = []
  if (icon) classes.push('icon')
  if (className) classes.push(className)
  if (iconn) {
    rest = { ...rest, style: { height: '24px' } }
  }

  if (largeIcon) {
    rest = { ...rest, style: { height: '32px', width: '32px' } }
  }

  return <Component ref={target} className={classes.join(' ')} {...rest} />
}

export default Svg

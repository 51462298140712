import { useHistory } from 'react-router-dom'

import {ReactComponent as NotificationIcon} from '../../svg/notification_default.svg'
import Svg from './Svg'

const NotificationButton = () => {
  const history = useHistory()
  const navigate = () => {
    if (history.location.pathname !== '/notifications') {
      history.push('/notifications')
    }
  }
  return <Svg as={NotificationIcon} icon onClick={navigate}/>
}

export default NotificationButton

import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import qs from 'query-string'

import { ProviderTypes, ServiceTypes } from './common'

import { selectCache, CacheActions } from '../redux/reducers/cacheSlice'

import { selectAuth } from '../redux/reducers/authSlice'

const ResumeReservation = ({ requesterId }) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const { isLoggedIn } = useSelector(selectAuth)
  const { cachedReservation } = useSelector(selectCache)

  useEffect(() => {
    dispatch(CacheActions.loadCacheRequest({ id: requesterId, target: 'cachedReservation' }))
  }, [dispatch, requesterId])

  const reservation = cachedReservation?.reservation

  const details = [
    ProviderTypes.getTextByValue(reservation?.providerType),
    ServiceTypes.getTextByValue(reservation?.serviceType)
  ]

  const resumeReservation = () => history.push({
    pathname: `/reservations/new`,
    search: qs.stringify({
      providerType: reservation?.providerType,
      serviceType: reservation?.serviceType
    }),
    state: cachedReservation
  })

  return isLoggedIn && !!reservation && (
    <div className='resume-reservation' onClick={resumeReservation}>
      <div className='resume-title'>إستكمال عملية الحجز</div>
      <div className='resume-details'>
        {details.filter(d => !!d).join(' - ')}
      </div>
    </div>
  )
}

export default ResumeReservation

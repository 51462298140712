import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

import { Formik } from 'formik'

import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Image from 'react-bootstrap/Image'
import format from 'date-fns/format'

import Screen from '../components/layout/Screen'
import Header from '../components/layout/Header'
import Main from '../components/layout/Main'

import Editable from '../components/basic/Editable'
import Divider from '../components/basic/Divider'

import { ReservationActions, selectReservation } from '../redux/reducers/reservationSlice'

import BackButton from '../components/basic/BackButton'
import PersonCard from '../components/PersonCard'
import { RequesterActions } from '../redux/reducers/requesterSlice'
import LocationInfoForm from '../components/LocationInfoForm'
import { DoctorDegrees, ProviderTypes, ReservationStatus } from '../components/common'
import BottomSheet from '../components/basic/BottomSheet'
import Select from '../components/basic/Select'
import NotificationButton from '../components/basic/NotificationButton'

const ReservationScreen = ({ requesterId }) => {
  const { reservationId } = useParams()
  const dispatch = useDispatch()
  const history = useHistory()

  const [showCancelReasons, setShowCancelReasons] = useState()
  const [cancellationReason, setCancellationReason] = useState()

  const { detail: reservation } = useSelector(selectReservation)

  useEffect(() => {
    dispatch(ReservationActions.getReservationDetailsRequest({ reservationId }))
  }, [dispatch, reservationId])

  const fieldTitles = {
    date: 'ميعاد الزيارة',
    price: 'تكلفة الخدمة'
  }

  const assignee = reservation?.assignee
  const assigneeName = assignee && (assignee.type === 'HUMAN' ? [assignee.provider.firstName, assignee.provider.middleName, assignee.provider.lastName].filter(n => n).join(' ') : assignee.provider.name)

  const cancellable = [ReservationStatus.PENDING_OPS, ReservationStatus.PENDING_RQT,
    ReservationStatus.UPCOMING].includes(reservation?.status) && (
      !reservation?.date || Date.parse(reservation?.date) - Date.now() > 30 * 60 * 1000)

  const cancel = () => {
    dispatch(ReservationActions.cancelReservationRequest({ reservationId, reason: cancellationReason }))
    history.goBack()
  }

  const accept = () => {
    dispatch(ReservationActions.acceptReservationRequest({ reservationId }))
  }

  const reject = () => {
    dispatch(ReservationActions.rejectReservationRequest({ reservationId }))
  }

  const toggleCancelReasons = () => setShowCancelReasons(!showCancelReasons)

  return (
    <Screen>
      <Header
        leftItem={<NotificationButton />}
        rightItem={<BackButton />}
        middleItem={<b>تفاصيل الخدمة</b>}
      />
      <Main>
        <Form>
          <Form.Group>
            <Form.Label>تفاصيل الزيارة</Form.Label>
            <Editable
              value={{
                ...(reservation?.date || reservation?.dateRange)
                  ? {
                      [fieldTitles.date]: reservation?.date && format(new Date(reservation?.date), 'dd/MM/yyyy') || reservation?.dateRange,
                      '': ''
                    }
                  : {},
                [fieldTitles.price]: `${reservation?.price || reservation?.priceRange} جنيه مصري`
              }}
            />
          </Form.Group>

          {assignee && (
            <Form.Group>
              <Form.Label>معلومات عن الطبيب</Form.Label>
              <PersonCard>
                <PersonCard.Avatar src={assignee?.provider.profilePicture} />
                <PersonCard.Title>{assigneeName}</PersonCard.Title>
                {assignee?.provider.degree && (
                  <PersonCard.Content>{DoctorDegrees.getTextByValue(assignee?.provider.degree)}</PersonCard.Content>
                )}
                {assignee?.provider.detailedSpeciality && (
                  <PersonCard.FadedContent>{assignee?.provider.detailedSpeciality}</PersonCard.FadedContent>
                )}
              </PersonCard>
            </Form.Group>
          )}

          <Form.Group>
            <Form.Label>اسم المريض</Form.Label>
            <Editable value={reservation?.patient.name} />
          </Form.Group>

          <Form.Group>
            <Form.Label>رقم الهاتف</Form.Label>
            <Editable value={reservation?.patient.mobileNumber}>
              {![ReservationStatus.CANCELLED, ReservationStatus.COMPLETED].includes(reservation?.status) && (close => (
                <Formik
                  initialValues={{ mobileNumber: reservation?.patient.mobileNumber }}
                  validateOnChange={false}
                  onSubmit={form => {
                    RequesterActions.updatePatientRequest({
                      requesterId,
                      patientId: reservation?.patient.id,
                      ...form
                    })
                    close()
                  }}
                >
                  {({ handleChange, values, errors }) => (
                    <Form>
                      <img src='bannerWhatsApp' alt=''/>
                      <Form.Group>
                        <Form.Label>رقم الهاتف</Form.Label>
                        <Form.Control
                          value={values.mobileNumber}
                          isInvalid={errors.mobileNumber}
                          onChange={handleChange}
                        />
                      </Form.Group>
                      <Button type='submit'>تأكيد</Button>
                    </Form>
                  )}
                </Formik>
              ))}
            </Editable>
          </Form.Group>

          <Form.Group>
            <Form.Label>عنوان تقديم الخدمة</Form.Label>
            <Editable value={[reservation?.patient.city.arabicName, reservation?.patient.area.arabicName, reservation?.patient.address].filter(x => x).join(' - ')}>
              {![ReservationStatus.CANCELLED, ReservationStatus.COMPLETED].includes(reservation?.status) && (close => (
                <LocationInfoForm
                  data={{
                    cityId: reservation?.patient.city.id,
                    areaId: reservation?.patient.area.id,
                    address: reservation?.patient.address
                  }}
                  onSubmit={form => {
                    RequesterActions.updatePatientRequest({
                      requesterId,
                      patientId: reservation?.patient.id,
                      ...form
                    })
                    close()
                  }}
                />
              ))}
            </Editable>
          </Form.Group>

          {
            reservation?.providerType === ProviderTypes.DOCTOR.value && (
              <Form.Group>
                <Form.Label>الشكوى المرضيه</Form.Label>
                <Editable value={reservation?.reason} />
              </Form.Group>
            )
          }

          {!!reservation?.visits.length && [
            <Form.Group>
              <Form.Label>التشخيص</Form.Label>
              <Editable value={reservation?.visits[0].diagnosis} />
            </Form.Group>,
            reservation?.visits[0].moreInvestigations && (
              <Form.Group>
                <Form.Label>المزيد من الفحوصات</Form.Label>
                <Editable value={reservation?.visits[0].moreInvestigations} />
              </Form.Group>
            ),
            reservation?.visits[0].medicines && (
              <Form.Group>
                <Form.Label>الأدوية</Form.Label>
                <Editable value={reservation?.visits[0].medicines} />
              </Form.Group>
            ),
            reservation?.visits[0].instructions && (
              <Form.Group>
                <Form.Label>التعليمات</Form.Label>
                <Editable value={reservation?.visits[0].instructions} />
              </Form.Group>
            ),
            reservation?.visits[0].nextVisitDate && (
              <Form.Group>
                <Form.Label>تاريخ الزيارة القادمة</Form.Label>
                <Editable value={format(new Date(reservation?.visits[0].nextVisitDate), 'dd/MM/yyyy')} />
              </Form.Group>
            ),
            reservation?.visits[0].otherServicesNeeded && (
              <Form.Group>
                <Form.Label>الخدمات الأخرى المطلوبة</Form.Label>
                <Editable value={reservation?.visits[0].otherServicesNeeded} />
              </Form.Group>
            ),
            !!reservation?.visits[0].attachments.length && (
              <Form.Group>
                <Form.Label>المرفقات</Form.Label>
                <Editable
                  value={reservation?.visits[0].attachments.reduce((a, b, i) => ({...a, [' '.repeat(i)]: <Image src={b.url} />}), {})}
                />
              </Form.Group>
            )
          ]}

          {
            reservation?.status === ReservationStatus.PENDING_RQT
            ? (
                <div className='vertical-button-group'>
                  <Button type='submit' onClick={accept}>قبول</Button>
                  <Button type='submit' variant='outline-primary' onClick={reject}>رفض</Button>
                </div>
              )
            : cancellable && <Button variant='outline-danger' onClick={toggleCancelReasons}>إلغاء طلب الزيارة</Button>
          }
        </Form>
        <BottomSheet title='سبب إلغاء الزيارة' show={showCancelReasons} close={toggleCancelReasons}>
          <Select
            other
            options={[
              { text: 'الميعاد غير مناسب، أريد ميعاد اخر', value: 'الميعاد غير مناسب، أريد ميعاد اخر' },
              { text: 'سعر الخدمة غير مناسب', value: 'سعر الخدمة غير مناسب' },
              { text: 'المريض لا يحتاج الخدمة نهائياً', value: 'المريض لا يحتاج الخدمة نهائياً' }
            ]}
            onSelect={setCancellationReason}
          />
          <Divider />
          <Button onClick={cancel}>تأكيد</Button>
        </BottomSheet>
      </Main>
    </Screen>
  )
}

export default ReservationScreen

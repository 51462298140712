import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Button from 'react-bootstrap/Button'

import NumberedList from '../../../components/NumberedList'
import PopupModal from '../../../components/PopupModal'
import PersonCard from '../../../components/PersonCard'
import {ReactComponent as ProviderDone} from '../../../svg/provider_done.svg'

import PersonalInfo from './topics/PersonalInfo'
import SpecialityInfo from './topics/SpecialityInfo'
import CitiesAreasAndFees from './topics/CitiesAreasAndFees'
import WeekdaysAndShifts from './topics/WeekdaysAndShifts'
import ScientificCertificates from './topics/ScientificCertificate'
import Experiences from './topics/Experiences'
import ClinicData from './topics/ClinicData'
import Summary from './topics/Summary'
import Header from '../../../components/layout/Header'
import Screen from '../../../components/layout/Screen'
import Main from '../../../components/layout/Main'
import BackButton from '../../../components/basic/BackButton'
import { ProviderTypes } from '../../../components/common'

import { ProviderActions, selectProvider } from '../../../redux/reducers/providerSlice'
import TaggedFormLabel from '../../../components/basic/TaggedFormLabel'


const CreateProviderForm = ({ initialValues={}, onDone, onError, wrapper: Wrapper }) => {
  const dispatch = useDispatch()

  const [showSuccess, setShowSuccess] = useState(false)
  const [activeIndex, setActiveIndex] = useState(0)
  const topics = [
    PersonalInfo,
    SpecialityInfo,
    CitiesAreasAndFees,
    WeekdaysAndShifts,
    ScientificCertificates,
    Experiences,
    ClinicData,
    Summary
  ]
  const Topic = topics[activeIndex]

  const [form, setForm] = useState({})

  const { provider, isLoading, error } = useSelector(selectProvider)

  const onSubmit = (data) => {
    if (activeIndex < topics.length - 1) {
      setForm({ ...form, ...data })
      setActiveIndex(activeIndex + 1)
    }
    else {
      dispatch(ProviderActions.signUpRequest({
        ...data,
        providerType: ProviderTypes.DOCTOR.value,
        profilePicture: data.profilePicture?.id,
        nationalIds: data.nationalIds.map(nid => nid.id),
        syndicateId: data.syndicateId.id
      }))
    }
  }

  useEffect(() => {
    if (provider) {
      setShowSuccess(true)
    }
  }, [provider])

  useEffect(() => {
    if (error) {
      onError(error, form)
    }
  }, [error])

  useEffect(() => {
    setForm(initialValues)
  }, [initialValues])

  return (
    <Wrapper title={Topic.title}>
      <Topic onSubmit={onSubmit} dispatch={dispatch} data={form} isLoading={isLoading} />

      <PopupModal
        title='تم استكمال بيانات الحساب بنجاح'
        show={showSuccess}
      >
        <PopupModal.Body>
          <p>
            <ProviderDone />
            <br />
            <br />
            <p>
              سيكون حسابك الشخصي معلق حتى يتم مراجعة بعض بياناتك التي أدخلتها من طرف فريق دكتور فوراً
            </p>
          </p>
        </PopupModal.Body>
        <PopupModal.Footer>
          <Button variant="primary" onClick={() => {
            setShowSuccess(false)
            onDone()
          }}>
            تم
          </Button>
        </PopupModal.Footer>
      </PopupModal>
    </Wrapper>
  )
}

const CreateProvider = () => {
  const [showNoteA, setShowNoteA] = useState(true)
  const [showNoteB, setShowNoteB] = useState(false)

  return (
    <CreateProviderForm
      onDone={() => {}} // redirect to provider home
      onError={() => {}}
      wrapper={({ title, children }) => (
        <Screen>
          <Header
            middleItem={title}
            rightItem={<BackButton />}
          />
          <Main>
            {children}
            <PopupModal
              title='إستكمال البيانات'
              show={showNoteA}
            >
              <PopupModal.Body>
                <p>
                  حتى تتمكن من استكمال استخدامك للتطبيق يتوجب عليك ملئ بياناتك
                </p>

                <NumberedList
                  list={[
                    <b>بيانات شخصية</b>,
                    <b>بيانات التخصص التوثيق</b>,
                    <b>محافظات العمل للزيارات المنزلية</b>,
                    <TaggedFormLabel>مواعيد العمل للزيارات المنزلية</TaggedFormLabel>,
                    <TaggedFormLabel>الدراسات العلمية</TaggedFormLabel>,
                    <TaggedFormLabel>الخبرات السابقة</TaggedFormLabel>,
                    <TaggedFormLabel>بيانات العيادة</TaggedFormLabel>,
                    <b>ملخص عام</b>
                  ]}
                />
              </PopupModal.Body>
              <PopupModal.Footer>
                <Button variant="primary" onClick={() => {
                  setShowNoteA(false)
                  setShowNoteB(true)
                }}>
                  استمرار
                </Button>
              </PopupModal.Footer>
            </PopupModal>
            <PopupModal
              title='ملحوظة'
              show={showNoteB}
            >
              <PopupModal.Body>
                <p>
                  البيانات التي سوف تقوم بإدخالها هي بيانات خاصة بالشركه فقط ولا يمكن ان نقوم بعرضها علي المرضى علي الإطلاق
                </p>

                <p>
                  علي سبيل المثال هذه البيانات فقط التي يحق للمريض ان يراها
                </p>
                <PersonCard>
                  <PersonCard.Avatar></PersonCard.Avatar>
                  <PersonCard.Title>د / عبدالهادى محمد</PersonCard.Title>
                  <PersonCard.Content>إستشاري أمراض الباطنة</PersonCard.Content>
                  <PersonCard.Content>ماجيستير في جراحة الأوعية الدموية</PersonCard.Content>
                </PersonCard>
              </PopupModal.Body>
              <PopupModal.Footer>
                <Button variant="primary" onClick={() => {
                  setShowNoteB(false)
                }}>
                  إبدأ الآن
                </Button>
              </PopupModal.Footer>
            </PopupModal>
          </Main>
        </Screen>
      )}
    />
  )
}

CreateProvider.Form = CreateProviderForm

export default CreateProvider

import { useEffect, useState, useRef, forwardRef, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { PlusCircleFill, PenFill } from 'react-bootstrap-icons'
import { Formik } from 'formik'
import * as yup from 'yup'
import _ from 'lodash'
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import startOfToday from 'date-fns/startOfToday'
import qs from 'query-string'

import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Spinner from 'react-bootstrap/Spinner'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import DynamicTable from '../../components/DynamicTable'
import PasswordInput from '../../components/basic/PasswordInput'
import { DashboardActions, DashboardResources, selectDashboard } from '../../redux/reducers/dashboardSlice'
import { checkExistingTestConfig, chronicDiseases, Genders, MOBILE_NUMBER_REGEX, PASSWORD_REGEX, usePrevious } from '../../components/common'
import Tabs from '../components/Tabs'

import DatePicker from '../../components/basic/DatePicker'
import Select from '../../components/basic/Select'
import Choice from '../../components/basic/Choice'
import { CityAreaSubform } from '../../components/LocationInfoForm'
import PatientScreen from '../../screens/PatientScreen'
// import { Component as CreateRequesterForm } from '../../screens/topics/SignupForm'

const CreateRequesterForm = forwardRef(({ dispatch, initialValues }, ref) => {
  const schema = yup.object().shape({
    firstName: yup.string().required().default(''),
    middleName: yup.string().nullable(),
    lastName: yup.string().required().default(''),
    mobileNumber: yup.string().required()
      .matches(MOBILE_NUMBER_REGEX)
      .test(checkExistingTestConfig('mobileNumber', 'Requester')),
    ...initialValues ? null : {
      password: yup.string().required().matches(PASSWORD_REGEX).default('').strip(!!initialValues),
      confirmPassword: yup.string().required().oneOf([yup.ref('password')]).default('').strip(!!initialValues)
    },
    highlighted: yup.boolean().default(false),
    status: yup.string()
  })

  const submit = form => {
    const action = initialValues ? DashboardActions.updateRequest : DashboardActions.createRequest
    let body = initialValues ? _.pickBy(form, (v, k) => v !== initialValues[k]) : form

    body.id = initialValues?.id || body.id
    dispatch(action({ resource: DashboardResources.REQUESTERS, body }))
  }

  return (
    <Formik
      validationSchema={schema}
      initialValues={initialValues || schema.default()}
      onSubmit={submit}
      validateOnChange={false}
      innerRef={ref}
    >
      {({ handleChange, handleSubmit, setFieldValue, values, errors }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Form.Group className='requester-form-switches'>
            <Row>
              <Col>
                <Form.Label>Highlighted</Form.Label>
              </Col>
              <Col>
                <Form.Check
                  type='switch'
                  name='highlighted'
                  defaultChecked={values.highlighted}
                  value={values.highlighted}
                  onChange={handleChange}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Label>Active</Form.Label>
              </Col>
              <Col>
                <Form.Check
                  type='switch'
                  name='status'
                  defaultChecked={values.status === 'Active'}
                  value={values.status === 'Active'}
                  onChange={e => setFieldValue('status', e.target.checked ? 'Active' : 'Inactive')}
                />
              </Col>
            </Row>
          </Form.Group>
          <Form.Group required>
            <Form.Label>First Name</Form.Label>
            <Form.Control
              name='firstName'
              value={values.firstName}
              isInvalid={errors.firstName}
              onChange={handleChange}
            />
            <Form.Control.Feedback type='invalid'>
              {errors.firstName}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Middle Name</Form.Label>
            <Form.Control
              name='middleName'
              value={values.middleName}
              isInvalid={errors.middleName}
              onChange={handleChange}
            />
            <Form.Control.Feedback type='invalid'>
              {errors.middleName}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group required>
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              name='lastName'
              value={values.lastName}
              isInvalid={errors.lastName}
              onChange={handleChange}
            />
            <Form.Control.Feedback type='invalid'>
              {errors.lastName}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group required>
            <Form.Label>Mobile Number</Form.Label>
            <Form.Control
              name='mobileNumber'
              value={values.mobileNumber}
              isInvalid={errors.mobileNumber}
              onChange={handleChange}
            />
            <Form.Control.Feedback type='invalid'>
              {errors.mobileNumber}
            </Form.Control.Feedback>
          </Form.Group>
          {!initialValues && (
            <Form.Group required>
              <Form.Label>Password</Form.Label>
              <PasswordInput
                name='password'
                onChange={handleChange}
                isInvalid={errors.password}
                errorMessage={errors.password}
                value={values.password}
              />
            </Form.Group>
          )}
          {!initialValues && (
            <Form.Group required>
              <Form.Label>Confirm Password</Form.Label>
              <PasswordInput
                name='confirmPassword'
                onChange={handleChange}
                isInvalid={errors.confirmPassword}
                errorMessage={errors.confirmPassword}
                value={values.confirmPassword}
              />
            </Form.Group>
          )}
        </Form>
      )}
    </Formik>
  )
})

const CreatePatientForm = forwardRef(({ dispatch, action, onDone = _.identity }, ref) => {
  const { patients } = useSelector(selectDashboard)
  const previousPatients = usePrevious(patients)
  const [knowBirthdate, setKnowBirthdate] = useState(true)

  const ageRanges = useMemo(() => [
    { text: 'أقل من 18 عام', value: 'أقل من 18 عام' },
    { text: 'ما بين 18 حتى 30 عام', value: 'ما بين 18 حتى 30 عام' },
    { text: 'ما بين 30 حتى 45 عام', value: 'ما بين 30 حتى 45 عام' },
    { text: 'ما بين 45 حتى 60 عام', value: 'ما بين 45 حتى 60 عام' },
    { text: 'من 60 عام إلى أعلى ', value: 'من 60 عام إلى أعلى ' }
  ], [])

  const schema = yup.object().shape({
    requesterId: yup.string().required(),
    firstName: yup.string().required('من فضلك قم بادخال المعلومات كاملة'),
    lastName: yup.string().required('من فضلك قم بادخال المعلومات كاملة').default(''),
    birthDate: knowBirthdate ? yup.date().required('من فضلك قم بادخال المعلومات كاملة') : yup.date().strip(),
    age: knowBirthdate ? yup.string().strip() : yup.string().required('من فضلك قم بادخال المعلومات كاملة').default(ageRanges[0]),
    mobileNumber: yup.string().matches(MOBILE_NUMBER_REGEX, 'من فضلك أدخل رقم هاتف صحيح').required('من فضلك قم بادخال المعلومات كاملة'),
    gender: yup.string().oneOf(Genders.getValues()).default(Genders.getValues()[0]).required('من فضلك قم بادخال المعلومات كاملة'),
    chronicDiseases: yup.array(yup.string()).default([]),
    medications: yup.string(),
    height: yup.number().min(10, 'الطول الذي ادخلته غير صحيح'),
    weight: yup.number().min(1, 'الوزن الذي ادخلته غير صحيح'),
    medicalHistory: yup.string(),
    cityId: yup.number().required('من فضلك قم بادخال المعلومات كاملة'),
    areaId: yup.number().required('من فضلك قم بادخال المعلومات كاملة'),
    address: yup.string().required('من فضلك قم بادخال المعلومات كاملة')
      .transform(v => v === '' ? undefined : v)
  })

  const submit = body => {
    dispatch(DashboardActions.createRequest({ resource: DashboardResources.PATIENTS, body }))
  }

  useEffect(() => {
    if (patients.items.length > previousPatients.items.length) {
      onDone()
    }
  }, [patients])

  return (
    <Formik
      validationSchema={schema}
      initialValues={schema.default()}
      onSubmit={submit}
      innerRef={ref}
      validateOnChange={false}
    >
      {({ handleSubmit, handleChange, errors, values, setFieldValue }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Form.Group required>
            <Form.Label>Requester Id</Form.Label>
            <Form.Control
              name='requesterId'
              value={values.requesterId}
              isInvalid={errors.requesterId}
              onChange={handleChange}
            />
            <Form.Control.Feedback type='invalid'>
              {errors.requesterId}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group required>
            <Form.Label>First Name</Form.Label>
            <Form.Control
              name='firstName'
              value={values.firstName}
              isInvalid={errors.firstName}
              onChange={handleChange}
            />
            <Form.Control.Feedback type='invalid'>
              {errors.firstName}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group required>
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              name='lastName'
              value={values.lastName}
              isInvalid={errors.lastName}
              onChange={handleChange}
            />
            <Form.Control.Feedback type='invalid'>
              {errors.lastName}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>هل تعلم تاريخ ميلاد المريض ؟</Form.Label>
            <Choice
              options={['نعم', 'لا']}
              value={knowBirthdate ? 'نعم' : 'لا'}
              onChange={() => {
                setKnowBirthdate(!knowBirthdate)
              }}
            />
          </Form.Group>
          {
            knowBirthdate
            ? <Form.Group required>
                <Form.Label>تاريخ الميلاد</Form.Label>
                <DatePicker
                  label='تحديد تاريخ الميلاد'
                  onChange={date => setFieldValue('birthDate', date)}
                  isInvalid={errors.birthDate}
                  errorMessage={errors.birthDate}
                  birthDate
                />
              </Form.Group>
            : <Form.Group required>
                <Form.Label>السن التقريبى للمريض</Form.Label>
                <Select
                  name='age'
                  label='السن التقريبى للمريض'
                  options={ageRanges}
                  value={values.age}
                  onSelect={value => setFieldValue('age', value)}
                  isInvalid={errors.age}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.age}
                </Form.Control.Feedback>
              </Form.Group>
          }
          <Form.Group required>
              <Form.Label>رقم الهاتف</Form.Label>
              <Form.Control
                type='text'
                placeholder='ادخل رقم الهاتف'
                name='mobileNumber'
                onChange={handleChange}
                isInvalid={errors.mobileNumber}
              />
              <Form.Control.Feedback type='invalid'>
                {errors.mobileNumber}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>الجنس</Form.Label>
              <Choice
                name='gender'
                options={Genders.getTexts()}
                value={Genders.getTextByValue(values.gender)}
                onChange={value => setFieldValue('gender', Genders.getValueByText(value))}
              />
            </Form.Group>
            <Form.Group>
            <Form.Label>الأمراض المزمنة</Form.Label>
            <Select
              multiple
              other
              name='chronicDiseases'
              label='الأمراض المزمنة'
              options={chronicDiseases}
              value={values.chronicDiseases}
              onSelect={value => setFieldValue('chronicDiseases', value)}
              isInvalid={errors.chronicDiseases}
              placeholder='الأمراض المزمنة'
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>الأدوية</Form.Label>
            <Form.Control
              type='text'
              placeholder='ابحث عن  اسم الدواء'
              name='medications'
              onChange={handleChange}
              isInvalid={errors.medications}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>الوزن بالكيلو جرام</Form.Label>
            <Form.Control
              type='number'
              placeholder='الوزن بالكيلو جرام'
              name='weight'
              onChange={handleChange}
              isInvalid={errors.weight}
            />
            <Form.Control.Feedback type='invalid'>
              {errors.weight}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group>
            <Form.Label>الطول بالسنتيمتر</Form.Label>
            <Form.Control
              type='number'
              placeholder='الطول بالسنتيمتر'
              name='height'
              onChange={handleChange}
              isInvalid={errors.height}
            />
            <Form.Control.Feedback type='invalid'>
              {errors.height}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group>
            <Form.Label>التاريخ الطبى</Form.Label>
            <Form.Control
              type='text'
              as='textarea'
              placeholder='التاريخ الطبى'
              name='medicalHistory'
              onChange={handleChange}
              isInvalid={errors.medicalHistory}
            />
          </Form.Group>
          <CityAreaSubform
            values={values}
            errors={errors}
            onAreaChange={value => setFieldValue('areaId', value)}
            onCityChange={value => setFieldValue('cityId', value)}
          />

          <Form.Group required>
            <Form.Label>العنوان بالتفصيل</Form.Label>
            <Form.Control
              type='text'
              placeholder='العنوان بالتفصيل'
              name='address'
              onChange={handleChange}
              isInvalid={errors.address}
              value={values.address}
            />
            <Form.Control.Feedback type='invalid'>
              {errors.address}
            </Form.Control.Feedback>
          </Form.Group>
        </Form>
      )}
    </Formik>
  )
})

const RequestersAndPatients = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { id } = qs.parse(history.location.search)

  const { requesters, patients, isLoading } = useSelector(selectDashboard)
  const previousRequesters = usePrevious(requesters)

  const [showCreate, setShowCreate] = useState(false)
  const [showUpdate, setShowUpdate] = useState(false)
  const [editObject, setEditObject] = useState()
  const submitRef = useRef()

  const handleCancel = () => {
    setShowCreate(false)
    setShowUpdate(false)
    setEditObject(null)
  }

  const birthDateQueryMapper = query => {
    const custom = {}
    if (query.birthDate) {
      custom.birthDate = parse(query.birthDate, 'dd/MM/yyyy', startOfToday()).toISOString()
    }

    if (query.isHighlighted) {
      query.isHighlighted = query.isHighlighted.toLowerCase().startsWith('y')
    }

    return { ...query, ...custom }
  }

  useEffect(() => {
    if (!_.isEqual(requesters, previousRequesters)) {
      setShowCreate(false)
      setShowUpdate(false)
    }
  }, [previousRequesters, requesters])

  const action = showCreate ? 'Create' : 'Update'

  const DesiredForm = history.location.hash === '#requesters' ? CreateRequesterForm : CreatePatientForm

  const edit = item => {
    setEditObject(item)
    setShowUpdate(true)
  }

  return (
    <div>
      <div className='sub-screen-title'>
        <h1>Requesters</h1>
        <Button float='right' onClick={() => setShowCreate(true)}><PlusCircleFill /> Create</Button>
      </div>
      <Tabs>
        <Tabs.Tab title='Patients'>
          <DynamicTable
            search
            sortable
            resource={DashboardResources.PATIENTS}
            data={patients.items}
            headers={[
              { key: 'requesterId', name: "Requester's ID", sort: 'requester' },
              { key: 'requesterName', name: "Requester's Name", sort: 'requester.firstName,requester.middleName,requester.lastName' },
              { key: 'id', name: "Patient's ID" },
              { key: 'name', name: "Patient's Name" },
              { key: 'mobileNumber', name: "Contact Number" },
              { key: 'gender', name: "Gender" },
              { key: 'birthDate', name: "Birthdate" },
              { key: 'areaName', name: "Area", sort: 'area.englishName' },
              { key: 'chronicDisease', name: "Chronic Diseases" },
              { key: 'requesterMobileNumber', name: "Requester's Phone", sort: 'requester.mobileNumber' },
              { key: '$medicalHistory', name: "" },
              { key: '$previousProviders', name: "" }
            ]}
            mapper={item => {
              return [
                { key: 'requesterId', value: item.requester.id },
                { key: 'requesterName', value: [item.requester.firstName, item.requester.middleName, item.requester.lastName].join(' ') },
                { key: 'id', value: item.id },
                { key: 'name', value: item.name },
                { key: 'mobileNumber', value: item.mobileNumber },
                { key: 'gender', value: item.gender },
                { key: 'birthDate', value: item.birthDate && format(new Date(item.birthDate), 'dd/MM/yyyy') },
                { key: 'areaName', value: item.area?.englishName },
                { key: 'chronicDisease', value: item.chronicDiseases?.join(', ') },
                { key: 'requesterMobileNumber', value: item.requester.mobileNumber },
                { key: '$medicalHistory', value: <img src="https://img.icons8.com/external-becris-lineal-becris/64/000000/external-medical-literary-genres-becris-lineal-becris.png"/> },
                { key: '$previousProviders', value: <img src="https://img.icons8.com/external-those-icons-lineal-those-icons/24/000000/external-prescription-medical-healthcare-those-icons-lineal-those-icons-1.png"/>}
              ]
            }}
            pagesCount={patients.pagesCount}
            queryMapper={birthDateQueryMapper}
            detail
            initialFilters={[_.omitBy({ id }, _.isNil)]}
          />
        </Tabs.Tab>
        <Tabs.Tab title='Requesters'>
          <DynamicTable
            search
            sortable
            resource={DashboardResources.REQUESTERS}
            data={requesters.items}
            headers={[
              { key: 'id', name: 'ID', sort: 'id.prefix,id.order' },
              { key: 'name', name: 'Name' },
              { key: 'mobileNumber', name: 'Number' },
              { key: 'gender', name: 'Gender' },
              { key: 'birthDate', name: 'Birthday', },
              { key: 'email', name: 'Email' },
              { key: 'howDidYouKnowUs', name: 'How did you know us?' },
              { key: 'patientsCount', name: 'No. of Patients' },
              { key: 'activeReservationsCount', name: 'Active Requests' },
              { key: 'reservationsCount', name: 'Total Visits Amount' },
              { key: 'isHighlighted', name: 'Highlighted' },
              { key: '$edit', name: '' },
            ]}
            mapper={item => {
              return [
                { key: 'id', value: item.id },
                { key: 'name', value: [item.firstName, item.middleName, item.lastName].join(' ') },
                { key: 'mobileNumber', value: item.mobileNumber },
                { key: 'gender', value: item.gender },
                { key: 'birthDate', value: item.birthDate && format(new Date(item.birthDate), 'dd/MM/yyyy') },
                { key: 'email', value: item.email },
                { key: 'howDidYouKnowUs', value: item.howDidYouKnowUs  },
                { key: 'patientsCount', value: item.patientsCount },
                { key: 'activeReservationsCount', value: item.activeReservationsCount },
                { key: 'reservationsCount', value: item.reservationsCount },
                { key: 'isHighlighted', value: item.highlighted ? 'Yes' : 'No' },
                { key: '$edit', value: <div onClick={() => edit(item)}><PenFill /></div>}
              ]
            }}
            pagesCount={requesters.pagesCount}
            queryMapper={birthDateQueryMapper}
            initialFilters={[_.omitBy({ id }, _.isNil)]}
          />
        </Tabs.Tab>
      </Tabs>
      <Modal show={showCreate || showUpdate} dialogClassName='ltr dashboard-layout' onHide={handleCancel}>
        <Modal.Header closeButton>
          <Modal.Title>{action} {_.capitalize(history.location.hash.slice(1, -1))}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DesiredForm ref={submitRef} dispatch={dispatch} onDone={handleCancel} initialValues={editObject}/>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => submitRef.current?.submitForm()}>
            {isLoading ? <Spinner animation="border" size='sm' /> : action}
          </Button>
          <Button variant="outline-primary" onClick={handleCancel}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

RequestersAndPatients.DetailPatient = () => {
  const { id } = useParams()
  const { patients } = useSelector(selectDashboard)
  const dispatch = useDispatch()

  const requesterId = patients.detail?.requester.id
  const patientId = patients.detail?.id

  useEffect(() => {
    dispatch(DashboardActions.detailRequest({ resource: DashboardResources.PATIENTS, body: { id } }))
  }, [dispatch])

  const updateStatus = ({ status }) => {
    dispatch(DashboardActions.updatePatientStatusRequest({
      requesterId,
      patientId,
      status
    }))
  }

  return (
    !patients.detail
    ? <Spinner animation='border' />
    : (
      <div>
        <Formik
          onSubmit={updateStatus}
          validationSchema={yup.object().shape({ status: yup.string() })}
          initialValues={{ status: patients.detail?.status }}
        >
          {({ handleSubmit, handleChange, values, submitForm }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Form.FloatingLabel label='Status'>
                <Form.Select
                  defaultValue={values.status}
                  value={values.status}
                  name='status'
                  onChange={e => {
                    handleChange(e)
                    setImmediate(submitForm)
                  }}
                >
                  <option value='Active'>Active</option>
                  <option value='Inactive'>In Active</option>
                </Form.Select>
              </Form.FloatingLabel>
            </Form>
          )}
        </Formik>
        <br />
        <PatientScreen.Form
          requesterId={requesterId}
          patientId={patientId}
        />
      </div>
    )
  )
}

export default RequestersAndPatients

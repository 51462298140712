import addDays from 'date-fns/addDays'
import format from 'date-fns/format'
import startOfWeek from 'date-fns/startOfWeek'
import { arSA } from 'date-fns/locale'

import { Formik } from 'formik'
import * as yup from 'yup'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

import Editable from '../../../../components/basic/Editable'

import Select from '../../../../components/basic/Select'
import FormSplitter from '../../../../components/basic/FormSplitter'
import TaggedFormLabel from '../../../../components/basic/TaggedFormLabel'
import TimeInput from '../../../../components/basic/TimeInput'
import { shiftSchema } from '../../../../components/common'

const WeekdaysAndShifts = ({ onSubmit, data }) => {
  const firstDOW = startOfWeek(new Date())
  const weekDayOptions = Array.from(Array(7)).map((e, i) => {
    return {
      text: format(addDays(firstDOW, i), 'EEEEEE', { locale: arSA }),
      value: i
    }
  })

  const schema = yup.object().shape({
    weekDays: yup.array(yup.number().integer().min(0).max(6))
      .required('من فضلك قم بادخال المعلومات كاملة'),
    morningShift: shiftSchema({ shift: { max: 'eveningShift.from' } }).default(null),
    eveningShift: shiftSchema({ shift: { min: 'morningShift.to' } }).default(null)
  })

  return (
    <Formik
      validationSchema={schema}
      initialValues={{
        weekDays: data.weekDays || weekDayOptions.map(({ value }) => value),
        morningShift: data.morningShift,
        eveningShift: data.eveningShift
      }}
      validateOnChange={false}
      onSubmit={onSubmit}
      enableReinitialize={true}
    >
      {({ handleSubmit, handleChange, setFieldValue, values, errors }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Editable
            value={
              'لم يتم تحديد مواعيد العمل'
            }
          />
          <Form.Group>
            <Form.Label>الايام التي تفضل ان تقوم بزيارات منزليه فيها</Form.Label>
            <Select
              multiple
              options={weekDayOptions}
              name='weekDays'
              value={weekDayOptions.map(({ value }) => value).filter(day => !values.weekDays.includes(day))}
              onSelect={weekDays => setFieldValue('weekDays', weekDayOptions.map(({ value }) => value).filter(day => !weekDays.includes(day)))}
              placeholder='حدد الأيام التي لا يمكنك العمل فيها'
            />
          </Form.Group>
          <FormSplitter label='الفترة الصباحية' />
          <Form.Group>
            <Form.Label>العمل بداية من توقيت</Form.Label>
            <TimeInput
              name="morningShift.from"
              placeholder='من ميعاد'
              value={values.morningShift?.from}
              onChange={TimeInput.onChange(values.morningShift, 'morningShift.from', setFieldValue)}
              isInvalid={errors?.morningShift?.from}
            />
            <Form.Control.Feedback type='invalid'>
              {errors?.morningShift?.from}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>حتى توقيت</Form.Label>
            <TimeInput
              name='morningShift.to'
              placeholder='حتى ميعاد'
              value={values.morningShift?.to}
              onChange={TimeInput.onChange(values.morningShift, 'morningShift.to', setFieldValue)}
              minTime={values.morningShift?.from}
              isInvalid={errors?.morningShift?.to}
            />
            <Form.Control.Feedback type='invalid'>
              {errors?.morningShift?.to}
            </Form.Control.Feedback>
          </Form.Group>
          <FormSplitter label='الفترة المسائية' />
          <Form.Group>
            <Form.Label>العمل بداية من توقيت</Form.Label>
            <TimeInput
              name='eveningShift.from'
              placeholder='من ميعاد'
              value={values.eveningShift?.from}
              onChange={TimeInput.onChange(values.eveningShift, 'eveningShift.from', setFieldValue)}
              isInvalid={errors?.eveningShift?.from}
            />
            <Form.Control.Feedback type='invalid'>
              {errors.eveningShift?.from}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>حتى توقيت</Form.Label>
            <TimeInput
              name='eveningShift.to'
              placeholder='حتى ميعاد'
              value={values.eveningShift?.to}
              onChange={TimeInput.onChange(values.eveningShift, 'eveningShift.to', setFieldValue)}
              isInvalid={errors?.eveningShift?.to}
            />
            <Form.Control.Feedback type='invalid'>
              {errors.eveningShift?.to}
            </Form.Control.Feedback>
          </Form.Group>
          <Button type='submit'>استمرار</Button>
        </Form>
      )}
    </Formik>
  )
}

WeekdaysAndShifts.title = <TaggedFormLabel>مواعيد العمل</TaggedFormLabel>

export default WeekdaysAndShifts

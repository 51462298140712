import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { ForgotPasswordTopics } from './topics'
import Success from '../components/Success'

import { AuthActions, selectAuth } from '../redux/reducers/authSlice'
import { selectVerificationCode, VerificationCodeActions } from '../redux/reducers/verificationCodeSlice'

const ForgotPasswordScreen = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [ActiveTopic, setActiveTopic] = useState(ForgotPasswordTopics.VerifyMobileNumber)
  const [showSuccess, setShowSuccess] = useState(false)

  const verificationSlice = useSelector(selectVerificationCode)
  const authSlice = useSelector(selectAuth)

  useEffect(() => {
    const { isCreated, isVerified } = verificationSlice
    if (!isCreated && !isVerified) {
      setActiveTopic(ForgotPasswordTopics.VerifyMobileNumber)
    }
    else if (isVerified) {
      setActiveTopic(ForgotPasswordTopics.ResetPasswordForm)
    }
    else {
      setActiveTopic(ForgotPasswordTopics.VerifyCode)
    }
  }, [verificationSlice])

  const onSuccessClick = () => {
    dispatch(VerificationCodeActions.resetVerificationCode())
    history.push('/signIn')
  }

  useEffect(() => {
    dispatch(VerificationCodeActions.resetVerificationCode())
    dispatch(AuthActions.resetStatus())
  }, [dispatch])

  useEffect(() => {
    if (authSlice.status === 'success') {
      setShowSuccess(true)
      dispatch(AuthActions.resetStatus())
    }
  }, [authSlice.status, dispatch])

  return (
    <>
      <ActiveTopic.Component
        dispatch={dispatch}
        header='نسيت كلمة المرور'
        captions={{
          caption: 'إدخال رقم الهاتف !',
          subCaption: 'برجاء كتابة رقم الهاتف حتى نتمكن التحقق منه ثم تسطيع من إعادة كلمة المرور الخاصه بك',
          small: true
        }}
        target='ResetPassword'
        mobileNumber={verificationSlice.mobileNumber}
        isLoading={verificationSlice.status === 'loading' || authSlice.status === 'loading'}
        failed={authSlice.error || verificationSlice.error}
      />
      <Success
        show={showSuccess}
        title='تمت إعادة التعيين بنجاح'
        message='من فضلك قم بتسجيل الدخول إلي حسابك بإستخدام كلمة المرور الجديده حتي تتمكن من حجز الخدمات'
        buttonText='تسجيل الدخول'
        onButtonClick={onSuccessClick}
      />
    </>
  )

}

export default ForgotPasswordScreen

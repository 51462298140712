import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import * as yup from 'yup'

import { checkExistingTestConfig } from '../../components/common'

const IdentityInput = ({ prefix, value, errorMessage, onChange, ...rest }) => {
  const internalOnChange = (e, ...args) => {
    const { valueAsNumber: order } = e.target
    if (isNaN(order)) {
      onChange(null)
    }
    else {
      onChange(`${prefix}-${order}`)
    }
  }

  const parseValue = v => {
    if (typeof v === 'string' || v instanceof String ) {
      return v.split('-')[1]
    }
    else {
      return v?.order
    }
  }

  return (
    <InputGroup hasValidation>
      <InputGroup.Text id="basic-addon1">{prefix}</InputGroup.Text>
      <FormControl
        type='number'
        placeholder="Code"
        onChange={internalOnChange}
        value={parseValue(value)}
        { ...rest }
      />
      <FormControl.Feedback type='invalid'>
        {errorMessage}
      </FormControl.Feedback>
    </InputGroup>
  )
}

IdentityInput.schema = yup.string().nullable().default(null).test(checkExistingTestConfig('id', 'ServiceProvider'))

export default IdentityInput

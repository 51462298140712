import FormLabel from 'react-bootstrap/FormLabel'

const TaggedFormLabel = ({ tag = 'إختياري', children }) => {
  return (
    <FormLabel className='optional-form-label'>
      {children}
      <span>{tag}</span>
    </FormLabel>
  )
}

export default TaggedFormLabel

import { call, put, takeLatest } from 'redux-saga/effects'
import * as Api from '../api/verificationCodeApi'

import { VerificationCodeActions } from '../reducers/verificationCodeSlice'


function* create(action) {
   try {
      yield call(Api.createApi, action.payload);
      yield put(VerificationCodeActions.creationSuccess());
   } catch (error) {
      yield put(VerificationCodeActions.creationFailure(error.response?.data?.error || error));
   }
}

function* verify(action) {
  try {
      const { data: { token } } = yield call(Api.verifyApi, action.payload);
      yield put(VerificationCodeActions.verificationSuccess({ token }));
  } catch (error) {
      yield put(VerificationCodeActions.verificationFailure(error.response?.data?.error || error));
  }
}

function* resend(action) {
   try {
      yield call(Api.resendApi, action.payload)
      yield put(VerificationCodeActions.resendCodeSuccess())
   } catch (error) {
      // yield put(VerificationCodeActions.resendCodeFailure(error.response?.data?.error || error))
   }
}

export default function* verificationCodeSaga() {
  yield takeLatest(VerificationCodeActions.createRequest, create)
  yield takeLatest(VerificationCodeActions.verifyRequest, verify)
  yield takeLatest(VerificationCodeActions.resendCodeRequest, resend)
}



import { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom'
import debounce from 'lodash/debounce'

import { VerificationCodeActions, selectVerificationCode } from '../redux/reducers/verificationCodeSlice'
import { selectAuth } from '../redux/reducers/authSlice'

import { SignupTopics } from './topics'

import Success from '../components/Success'

const SignupScreen = () => {
  const dispatch = useDispatch()
  const { status, isCreated, isVerified, error: verificationCodeError } = useSelector(selectVerificationCode)
  const { status: authStatus, error: authError, isLoggedIn } = useSelector(selectAuth)

  const [isLoading, setIsLoading] = useState(false)
  const [ActiveTopic, setActiveTopic] = useState(SignupTopics.VerifyMobileNumber)
  const [form, setForm] = useState({})
  const [isInvalid, setIsInvalid] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)

  const history = useHistory()

  const updateForm = (key, value) => {
    setForm({ ...form, [key]: value })
  }

  useEffect(() => {
    if(isLoggedIn) {
      setShowSuccess(isLoggedIn)
      dispatch(VerificationCodeActions.resetVerificationCode())
    }
  }, [isLoggedIn, dispatch])

  useEffect(() => {
    setIsInvalid(!!authError || !!verificationCodeError)
  }, [authError, verificationCodeError])

  useEffect(() => {
    setIsLoading(status === 'loading' || authStatus === 'loading')

    if (!(isCreated || isVerified)) {
      setActiveTopic(SignupTopics.VerifyMobileNumber)
    }
    else if (isVerified) {
      setActiveTopic(SignupTopics.SignupForm)
    }
    else {
      setActiveTopic(SignupTopics.VerifyCode)
    }
  }, [status, isCreated, isVerified, authStatus, history])


  const debouncedResetVerificationCode = useMemo(() =>
    debounce(() => dispatch(VerificationCodeActions.resetVerificationCode()), 500),
    []
  )

  useEffect(() => {
    debouncedResetVerificationCode.cancel()
  }, [debouncedResetVerificationCode])

  useEffect(() => () => {
    debouncedResetVerificationCode()
  }, [debouncedResetVerificationCode])

  return (
    <>
      <ActiveTopic.Component
        isLoading={isLoading}
        dispatch={dispatch}
        mobileNumber={form.mobileNumber}
        updateForm={updateForm}
        failed={isInvalid}
        header='تسجيل حساب جديد'
        target='Signup'
        captions={{
          caption: 'DoktorCare',
          subCaption: 'دكتورك لحد بيتك'
        }}
        underButton={<p className='login-form-bottom'>لدي بالفعل حساب <Link to='/signIn'>تسجيل الدخول</Link></p>}
      />
      <Success
        show={showSuccess}
        close={() => history.push('/')}
        title='تم تسجيل حساب جديد بنجاح'
        message='يمكنك الاًن حجز العديد من الخدمات المنزلية والهاتفيه ومتابعتها من خلال تطبيقنا !'
        buttonText='حجز خدمة الآن'
        onButtonClick={() => history.push('/')}
      />
    </>
  )
}

export default SignupScreen

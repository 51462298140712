import Modal from 'react-bootstrap/Modal'
import { Directions } from './common'

const PopupModal = ({ title, show, onHide, className, children, direction=Directions.RTL }) => {
  return (
    <Modal centered show={show} onHide={onHide} dialogClassName={`mobile ${direction} ${className}`}>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      {children}
    </Modal>
  )
}

PopupModal.Footer = Modal.Footer
PopupModal.Body = Modal.Body

export default PopupModal

import { useState, isValidElement } from 'react'

import BottomSheet from './BottomSheet'

const Edit = ({ open }) => (
  <div
    className='edit-form-input'
    onClick={open}
  >تعديل</div>
)

const Editable = ({ value, editTitle, children, wrapper: Wrapper = BottomSheet, editComponent: EditComponent = Edit }) => {
  const [edit, setEdit] = useState(false)

  const close = () => setEdit(false)
  const open = () => setEdit(true)

  const renderValue = () => {
    if (isValidElement(value)) {
      return value
    }
    if (value instanceof Array) {
      return value.join(' , ')
    }

    if (value instanceof Object) {
      const getValue = key => isValidElement(value[key]) ? value[key] : <b>{value[key] || (key ? 'لا يوجد' : '')}</b>
      const keys = Object.keys(value)
      const odd = keys.length % 2 === 1

      const evenKeys = odd ? keys.slice(0, keys.length - 1) : keys
      return (
        <>
          {
            evenKeys.map(key => {
              return (
                <div className='editable-one' key={key}>
                  {key}
                  <br />
                  {getValue(key)}
                </div>
              )
            })
          }
          {
            odd && (
              <div>
                {keys[keys.length - 1]}
                <br />
                {getValue(keys[keys.length - 1])}
              </div>
            )
          }
        </>
      )
    }
    return value
  }

  return (
    <>
      {children && <EditComponent open={open} />}
      <div className='editable'>
        {renderValue()}
      </div>
      {children &&
        <Wrapper show={edit} title={editTitle} close={close}>
          {children(close)}
        </Wrapper>
      }
    </>
  )
}

export default Editable

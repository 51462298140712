import * as originalVerificationCodeApi from './verificationCodeApi'
import * as firebaseVerificationCodeApi from './verificationCodeViaFirebaseApi'

console.log('Verification Authority ---', process.env.REACT_APP_VERIFICATION_AUTHORITY)

const isFirebase = process.env.REACT_APP_VERIFICATION_AUTHORITY === 'Firebase'
const Api = isFirebase ? firebaseVerificationCodeApi : originalVerificationCodeApi

export const createApi =  Api.createApi
export const verifyApi =  Api.verifyApi
export const resendApi =  Api.resendApi

import { useState, useEffect, useRef, forwardRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { PenFill, PlusCircleFill } from 'react-bootstrap-icons'
import { Formik } from 'formik'
import * as yup from 'yup'
import _ from 'lodash'

import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Spinner from 'react-bootstrap/Spinner'

import DynamicTable from '../../components/DynamicTable'
import { DashboardActions, DashboardResources, selectDashboard } from '../../redux/reducers/dashboardSlice'
import Tabs from '../components/Tabs'
import { LookupsActions, selectLookups } from '../../redux/reducers/lookupsSlice'
import { enrichEnumObject, ServiceTypes, usePrevious } from '../../components/common'

const LookupForm = forwardRef(({ type = 'create', initialValue }, ref) => {
  const [show, setShow] = useState(false)
  const dispatch = useDispatch()
  const formRef = useRef()
  const history = useHistory()
  const [resource, setResource] = useState(history.location.hash.slice(1))
  const [internalIsLoading, setInternalIsLoading] = useState(false)

  const { cities } = useSelector(selectLookups)
  const { isLoading, error } = useSelector(selectDashboard)
  const previousIsLoading = usePrevious(isLoading)

  const MedicalCenterServiceTypes = enrichEnumObject({
    RAYS: ServiceTypes.MEDICAL_RAYS,
    ANALYSIS: ServiceTypes.MEDICAL_ANALYSIS
  })

  const areaClasses = ['A', 'B', 'C', 'D', 'E', 'F']
  const schema = yup.object().shape({
    englishName: yup.string().required(),
    arabicName: yup.string().required(),
    active: yup.boolean().default(true),
    ...(resource === DashboardResources.AREAS && cities.values.length && {
      cityId: yup.number().default(cities.values[0].value),
      areaClass: yup.string().oneOf(areaClasses).default(areaClasses[0])
    }),
    ...(resource === DashboardResources.MEDICAL_CENTER_SERVICES && {
      type: yup.string().required().oneOf(MedicalCenterServiceTypes.getValues()).default(MedicalCenterServiceTypes.getValues()[0])
    })
  })

  const getLookupName = () => {
    switch(resource) {
      case DashboardResources.CITIES:
        return 'City'
      case DashboardResources.AREAS:
        return 'Area'
      case DashboardResources.DOCTOR_SPECIALITIES:
        return 'Doctor Speciality'
      case DashboardResources.MEDICAL_CENTER_SERVICES:
        return 'Medical Center Service'
      case DashboardResources.NURSE_TASKS:
        return 'Nurse Task'
      case DashboardResources.HOME_CARE_SPECIALIST_TASKS:
        return 'Home Care Specialist Task'
    }
  }

  const hide = () => {
    setShow(false)
  }

  console.log(initialValue?.id, { show })

  const submit = (body) => {
    if (type === 'create') {
      dispatch(DashboardActions.createRequest({ resource, body }))
      setInternalIsLoading(true)
    } else if (type === 'update') {
      dispatch(DashboardActions.updateRequest({ resource, body }))
      setInternalIsLoading(true)
    }
  }

  useEffect(() => {
    if (resource === DashboardResources.AREAS) {
      dispatch(LookupsActions.listCitiesRequest())
    }
  }, [dispatch, resource])

  useEffect(() => {
    // need to show the error message if lookup with the same english/arabic name is being created.
    if (internalIsLoading && !isLoading && previousIsLoading && !error) {
      setShow(false)
      setInternalIsLoading(false)
    }
  }, [isLoading, previousIsLoading])

  useEffect(() => {
    if (show) {
      setResource(history.location.hash.slice(1))
    }
  }, [show])

  return (
    <div>
      {
        type === 'create'
        ? (
          <Button className='full-width-horizontal-group' onClick={() => setShow(true)}>
            <PlusCircleFill />
            <div>Create</div>
          </Button>
        )
        : <div ref={ref} onClick={() => setShow(true)}><PenFill /></div>
      }
      <Modal show={show} onHide={hide} dialogClassName='ltr dashboard-layout'>
        <Modal.Header closeButton>
          <Modal.Title>{_.capitalize(type)} {getLookupName()}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            validationSchema={schema}
            initialValues={{areaClass: 'A', ...schema.default(), ...initialValue}}
            onSubmit={submit}
            innerRef={formRef}
          >
            {({ handleChange, handleSubmit, values, errors }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Group required>
                  <Form.Label>English Name</Form.Label>
                  <Form.Control
                    name='englishName'
                    value={values.englishName}
                    isInvalid={errors.englishName || error?.startsWith('englishName')}
                    onChange={handleChange}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.englishName || 'English Name must be unique'}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group required>
                  <Form.Label>Arabic Name</Form.Label>
                  <Form.Control
                    name='arabicName'
                    value={values.arabicName}
                    isInvalid={errors.arabicName || error?.startsWith('arabicName')}
                    onChange={handleChange}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.arabicName || 'Arabic Name must be unique'}
                  </Form.Control.Feedback>
                </Form.Group>
                {schema.fields.cityId && cities.values.length && (
                  <Form.Group required>
                    <Form.Label>City</Form.Label>
                    <Form.Select
                      name='cityId'
                      value={values.cityId}
                      onChange={handleChange}
                    >
                      {cities.values.map(city => (
                        <option key={city.value} value={city.value}>{city.text}</option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                )}
                {schema.fields.areaClass && (
                  <Form.Group required>
                    <Form.Label>Area Class</Form.Label>
                    <Form.Select
                      name='areaClass'
                      value={values.areaClass}
                      onChange={handleChange}
                      defaultValue={areaClasses[0]}
                    >
                      {areaClasses.map(areaClass => (
                        <option key={areaClass} value={areaClass}>{areaClass}</option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                )}
                {schema.fields.type && (
                  <Form.Group required>
                    <Form.Label>Type</Form.Label>
                    <Form.Select
                      name='type'
                      value={values.type}
                      onChange={handleChange}
                    >
                      {MedicalCenterServiceTypes.getValues().map(type => (
                        <option key={type} value={type}>{MedicalCenterServiceTypes.getTextByValue(type)}</option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                )}
                <Form.Group>
                  <Form.Label>Status</Form.Label>
                  <Form.Select
                    name='active'
                    value={values.active}
                    onChange={handleChange}
                  >
                    <option value={true}>Active</option>
                    <option value={false}>In Active</option>
                  </Form.Select>
                </Form.Group>
              </Form>
            )}
          </Formik>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => formRef.current?.submitForm()}>
            {isLoading ? <Spinner animation='border' size='sm' /> : 'Submit'}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
})

const Lookups = () => {
  const {
    cities,
    areas,
    doctorSpecialities,
    nurseTasks,
    homeCareSpecialistTasks,
    medicalCenterServices
  } = useSelector(selectDashboard)

  const queryMapper = query => {
    if ('active'.includes(query.active?.toLowerCase())) {
      query.active = true
    }
    else if ('in active'.includes(query.active?.toLowerCase())) {
      query.active = false
    }
    else {
      delete query.active
    }
    return query
  }

  return (
    <div>
      <div className='sub-screen-title'>
        <h1>Lookups</h1>
        <LookupForm />
      </div>

      <Tabs>
        <Tabs.Tab title='Cities'>
          <DynamicTable
            search
            sortable
            resource={DashboardResources.CITIES}
            queryMapper={queryMapper}
            data={cities.items}
            headers={[
              { key: 'id', name: 'ID' },
              { key: 'englishName', name: 'English Name' },
              { key: 'arabicName', name: 'Arabic Name' },
              { key: 'active', name: 'Status' },
              { key: '$update', name: '' }
            ]}
            mapper={item => {
              return [
                { key: 'id', value: item.id },
                { key: 'englishName', value: item.englishName },
                { key: 'arabicName', value: item.arabicName },
                { key: 'active', value: item.active ? 'Active' : 'In Active' },
                { key: '$update', value: <LookupForm type='update' initialValue={item} />}
              ]
            }}
            pagesCount={cities.pagesCount}
          />
        </Tabs.Tab>
        <Tabs.Tab title='Areas'>
          <DynamicTable
            search
            sortable
            resource={DashboardResources.AREAS}
            queryMapper={queryMapper}
            data={areas.items}
            headers={[
              { key: 'id', name: 'ID' },
              { key: 'englishName', name: 'English Name' },
              { key: 'arabicName', name: 'Arabic Name' },
              { key: 'cityId', sort: 'city.id', name: "City's ID" },
              { key: 'cityName', sort: 'city.englishName', name: "City's Name" },
              { key: 'areaClass', name: 'Class' },
              { key: 'active', name: 'Status' },
              { key: '$update', name: '' }
            ]}
            mapper={item => {
              return [
                { key: 'id', value: item.id },
                { key: 'englishName', value: item.englishName },
                { key: 'arabicName', value: item.arabicName },
                { key: 'cityId', value: item.city.id },
                { key: 'cityName', value: item.city.englishName },
                { key: 'areaClass', value: item.areaClass },
                { key: 'active', value: item.active ? 'Active' : 'In Active' },
                { key: '$update', value: <LookupForm type='update' initialValue={item} /> }
              ]
            }}
            pagesCount={areas.pagesCount}
          />
        </Tabs.Tab>
        <Tabs.Tab title='Doctor Specialities'>
          <DynamicTable
            search
            sortable
            resource={DashboardResources.DOCTOR_SPECIALITIES}
            queryMapper={queryMapper}
            data={doctorSpecialities.items}
            headers={[
              { key: 'id', name: 'ID' },
              { key: 'englishName', name: 'English Name' },
              { key: 'arabicName', name: 'Arabic Name' },
              { key: 'active', name: 'Status' },
              { key: '$update', name: '' }
            ]}
            mapper={item => {
              return [
                { key: 'id', value: item.id },
                { key: 'englishName', value: item.englishName },
                { key: 'arabicName', value: item.arabicName },
                { key: 'active', value: item.active ? 'Active' : 'In Active' },
                { key: '$update', value: <LookupForm type='update' initialValue={item} /> }
              ]
            }}
            pagesCount={doctorSpecialities.pagesCount}
          />
        </Tabs.Tab>
        <Tabs.Tab title='Nurse Tasks'>
          <DynamicTable
            search
            sortable
            resource={DashboardResources.NURSE_TASKS}
            queryMapper={queryMapper}
            data={nurseTasks.items}
            headers={[
              { key: 'id', name: 'ID' },
              { key: 'englishName', name: 'English Name' },
              { key: 'arabicName', name: 'Arabic Name' },
              { key: 'active', name: 'Status' },
              { key: '$update', name: '' }
            ]}
            mapper={item => {
              return [
                { key: 'id', value: item.id },
                { key: 'englishName', value: item.englishName },
                { key: 'arabicName', value: item.arabicName },
                { key: 'active', value: item.active ? 'Active' : 'In Active' },
                { key: '$update', value: <LookupForm type='update' initialValue={item} /> }
              ]
            }}
            pagesCount={nurseTasks.pagesCount}
          />
        </Tabs.Tab>
        <Tabs.Tab title='Home Care Specialist Tasks'>
          <DynamicTable
            search
            sortable
            resource={DashboardResources.HOME_CARE_SPECIALIST_TASKS}
            queryMapper={queryMapper}
            data={homeCareSpecialistTasks.items}
            headers={[
              { key: 'id', name: 'ID' },
              { key: 'englishName', name: 'English Name' },
              { key: 'arabicName', name: 'Arabic Name' },
              { key: 'active', name: 'Status' },
              { key: '$update', name: '' }
            ]}
            mapper={item => {
              return [
                { key: 'id', value: item.id },
                { key: 'englishName', value: item.englishName },
                { key: 'arabicName', value: item.arabicName },
                { key: 'active', value: item.active ? 'Active' : 'In Active' },
                { key: '$update', value: <LookupForm type='update' initialValue={item} /> }
              ]
            }}
            pagesCount={homeCareSpecialistTasks.pagesCount}
          />
        </Tabs.Tab>
        <Tabs.Tab title='Medical Center Services'>
          <DynamicTable
            search
            sortable
            resource={DashboardResources.MEDICAL_CENTER_SERVICES}
            queryMapper={queryMapper}
            data={medicalCenterServices.items}
            headers={[
              { key: 'id', name: 'ID' },
              { key: 'type', name: 'Type' },
              { key: 'englishName', name: 'English Name' },
              { key: 'arabicName', name: 'Arabic Name' },
              { key: 'active', name: 'Status' },
              { key: '$update', name: '' }
            ]}
            mapper={item => {
              return [
                { key: 'id', value: item.id },
                { key: 'type', value: item.type },
                { key: 'englishName', value: item.englishName },
                { key: 'arabicName', value: item.arabicName },
                { key: 'active', value: item.active ? 'Active' : 'In Active' },
                { key: '$update', value: <LookupForm type='update' initialValue={item} /> }
              ]
            }}
            pagesCount={medicalCenterServices.pagesCount}
          />
        </Tabs.Tab>
      </Tabs>
    </div>
  )
}

export default Lookups

import { initializeApp } from 'firebase/app'

const firebaseConfig = {
  apiKey: "AIzaSyBZjBYfl9gJNU4pa5UuZcCy3CIsbHbwjgg",
  authDomain: "sehatena-937dc.firebaseapp.com",
  projectId: "sehatena-937dc",
  storageBucket: "sehatena-937dc.appspot.com",
  messagingSenderId: "352793355413",
  appId: "1:352793355413:web:a2248880bec141fb1c279c",
  measurementId: "G-R17G8QV4MN"
}

export const firebaseApp = initializeApp(firebaseConfig);

import axios from 'axios'

export const saveCacheApi = ({ id, payload }) => {
  return axios.post(
    `${process.env.REACT_APP_BE_URL}/cache`,
    { id, payload }
  )
}

export const loadCacheApi = ({ id }) => {
  return axios.get(
    `${process.env.REACT_APP_BE_URL}/cache/${id}`
  )
}

export const deleteCacheApi = ({ id }) => {
  return axios.delete(
    `${process.env.REACT_APP_BE_URL}/cache/${id}`
  )
}

import { useMemo } from 'react'
import * as yup from 'yup'
import { Formik } from 'formik'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'

import BackButton from '../../components/basic/BackButton'
import Select from '../../components/basic/Select'
import PasswordInput from '../../components/basic/PasswordInput'

import Screen from '../../components/layout/Screen'
import Header from '../../components/layout/Header'
import Main from '../../components/layout/Main'

import { AuthActions } from '../../redux/reducers/authSlice'

import { PASSWORD_REGEX } from '../../components/common'

export const Hash = 'form'

export const Component = ({ isLoading, mobileNumber, dispatch }) => {

  const signUpFormSchema = yup.object().shape({
    firstName: yup.string().required('من فضلك قم بادخال المعلومات كاملة'),
    lastName: yup.string().required('من فضلك قم بادخال المعلومات كاملة'),
    password: yup.string().matches(PASSWORD_REGEX, 'كلمة المرور ضعيفة').required('من فضلك قم بادخال المعلومات كاملة'),
    repeatPassword: yup.string().required('من فضلك قم بادخال المعلومات كاملة').oneOf([yup.ref('password')], 'كلمة المرور غير متطابقة'),
    howDidYouKnowUs: yup.string().required('من فضلك قم بادخال المعلومات كاملة')
  })

  const HowDidYouKnowUs = Object.freeze({
    SOCIAL_MEDIA: 'منصات التواصل الإجتماعي',
    FRIEND: 'عن طريق صديق',
    SEARCH_ENGINES: 'من خلال محركات البحث'
  })

  const handleSubmit = (data) => {
    dispatch(AuthActions.signUpRequest(data))
  }

  return (
    <Screen>
       <Header
        middleItem={<b>تسجيل حساب جديد</b>}
        rightItem={<BackButton />}
      />
      <Main>
      <Formik
          validationSchema={signUpFormSchema}
          onSubmit={handleSubmit}
          initialValues={{ mobileNumber }}
          validateOnChange={false}
        >
          {({
            handleSubmit,
            handleChange,
            errors,
            setFieldValue,
            values
          }) => {
            const howDidYouKnowUsSection = useMemo(() => (
              <Form.Group>
                <Form.Label>كيف عرفتنا</Form.Label>
                <Select
                  name='howDidYouKnowUs'
                  label='كيف عرفتنا'
                  options={Object.values(HowDidYouKnowUs).map(value => ({ text: value, value }))}
                  value={values.howDidYouKnowUs}
                  onSelect={value => setFieldValue('howDidYouKnowUs', value, false)}
                  isInvalid={errors.howDidYouKnowUs}
                  other
                />
              </Form.Group>
            ), [values.howDidYouKnowUs, errors.howDidYouKnowUs])
            return (
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Group>
                  <Form.Label>الإسم الأول</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='ادخل الاسم الأول'
                    name='firstName'
                    onChange={handleChange}
                    isInvalid={errors.firstName}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.firstName}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group>
                  <Form.Label>الإسم الثاني</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='ادخل الاسم الثاني'
                    name='lastName'
                    onChange={handleChange}
                    isInvalid={errors.lastName}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.lastName}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group>
                  <Form.Label>كلمة المرور</Form.Label>
                  <PasswordInput
                    placeholder='ادخل كلمة المرور'
                    id='password'
                    name='password'
                    onChange={handleChange}
                    isInvalid={errors.password}
                    errorMessage={errors.password}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label>إعادة كتابة كلمة المرور</Form.Label>
                  <PasswordInput
                    placeholder='كلمة المرور'
                    id='repeatPassword'
                    name='repeatPassword'
                    onChange={handleChange}
                    isInvalid={errors.repeatPassword}
                    errorMessage={errors.repeatPassword}
                  />
                </Form.Group>

                {howDidYouKnowUsSection}

                <Button type='submit' className='login-form-submit' disabled={isLoading}>
                  {isLoading ? <Spinner animation="border" size='sm' /> : <span>تسجيل الحساب</span>}
                </Button>
              </Form>
            )
          }}
        </Formik>
      </Main>
    </Screen>
  )
}

import { call, put, takeLatest } from 'redux-saga/effects'
import * as Api from '../api/attachmentApi'

import { AttachmentsActions } from '../reducers/attachmentsSlice'

function* upload(action) {
  const { target, policy } = action.payload
  try {
    const responses = []
    for (const file of action.payload.files) {
      responses.push(yield call(Api.uploadApi, { file, policy }))
    }
    yield put(AttachmentsActions.uploadSuccess({
      attachments: responses.map(response => response.data),
      target
    }))
  } catch (error) {
    yield put(AttachmentsActions.uploadFailure({ error: error.response?.data?.error || error, target }))
  }
}

function* dropAttachment(action) {
  const { target } = action.payload
  try {
    yield call(Api.dropAttachmentApi, action.payload)
    yield put(AttachmentsActions.dropAttachmentSuccess({ url: action.payload.url, target }))
  } catch (error) {
    yield put(AttachmentsActions.dropAttachmentFailure(error.response?.data?.error || error))
  }
}

export default function* attachmentsSaga() {
  yield takeLatest(AttachmentsActions.uploadRequest, upload)
  yield takeLatest(AttachmentsActions.dropAttachmentRequest, dropAttachment)
}

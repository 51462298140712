import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Formik } from 'formik'
import * as yup from 'yup'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

import Link from '../../../../components/basic/Link'
import Select from '../../../../components/basic/Select'
import Choice from '../../../../components/basic/Choice'
import Attachments from '../../../../components/basic/Attachments'

import { DoctorDegrees, attachmentSchema } from '../../../../components/common'
import { LookupsActions, selectLookups } from '../../../../redux/reducers/lookupsSlice'

import TermsAndConditions from '../../../../components/TermsAndConditions'
import TaggedFormLabel from '../../../../components/basic/TaggedFormLabel'

const SpecialityInfo = ({ onSubmit, dispatch, data, edit }) => {
  const [showConditions, setShowConditions] = useState(false)
  const [acceptConditions, setAcceptConditions] = useState(false)

  const { doctorSpecialities } = useSelector(selectLookups)

  const schema = yup.object().shape({
    degree: yup.string().oneOf(DoctorDegrees.getValues()),
    syndicateId: attachmentSchema,
    specialityId: yup.number(),
    detailedSpeciality: yup.string(),
    description: yup.string()
  })

  const toggleConditions = () => setShowConditions(!showConditions)

  useEffect(() => {
    dispatch(LookupsActions.listDoctorSpecialitiesRequest())
  }, [dispatch])

  return (
    <Formik
      validationSchema={schema}
      initialValues={{
        degree: data.degree,
        syndicateId: data.syndicateId ? { ...data.syndicateId } : data.syndicateId,
        specialityId: data.specialityId,
        detailedSpeciality: data.detailedSpeciality,
        description: data.description
      }}
      validateOnChange={false}
      enableReinitialize={true}
      onSubmit={onSubmit}
    >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        values,
        errors
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Form.Group>
            <TaggedFormLabel>الدرجة المهنية</TaggedFormLabel>
            <Choice
              options={DoctorDegrees.getTexts()}
              value={DoctorDegrees.getTextByValue(values.degree)}
              onChange={v => setFieldValue('degree', DoctorDegrees.getValueByText(v))}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>صورة كارنية النقابة</Form.Label>
            <Attachments
              single
              initialValue={values.syndicateId}
              onChange={value => setFieldValue('syndicateId', value)}
              isInvalid={errors.syndicateId}
              errorMessage='من فضلك قم بادخال المعلومات كاملة'
            />
          </Form.Group>
          {!!doctorSpecialities.values.length && (
            <Form.Group>
              <Form.Label>التخصص العام</Form.Label>
              <Select
                name='specialityId'
                label='التخصص العام'
                value={values.specialityId}
                options={doctorSpecialities.values}
                onSelect={value => setFieldValue('specialityId', value)}
              />
            </Form.Group>
          )}
          <Form.Group>
            <Form.Label>التخصص الدقيق</Form.Label>
            <Form.Control
              name='detailedSpeciality'
              value={values.detailedSpeciality}
              isInvalid={errors.detailedSpeciality}
              onChange={handleChange}
            />
            <Form.Control.Feedback type='invalid'>
              {errors.detailedSpeciality}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <TaggedFormLabel>وصف عام</TaggedFormLabel>
            <Form.Control
              as='textarea'
              name='description'
              value={values.description}
              placeholder='وصف عام عن تخصص ومهام الطبيب'
              onChange={handleChange}
            />
          </Form.Group>
          {
            edit
            ? <Button type='submit'>تأكيد</Button>
            : <>
                <Form.Check
                  checked={acceptConditions}
                  onChange={e => setAcceptConditions(e.target.checked)}
                  label={
                    <div>
                      اقر بإنني قرأت وأوافق علي <TermsAndConditions agreementType='Doctor' onAccept={() => setAcceptConditions(true)} />
                    </div>
                  }
                />
                <Button type='submit' disabled={!acceptConditions}>استمرار</Button>
              </>
          }
        </Form>
      )}
    </Formik>
  )
}

SpecialityInfo.title = <b>بيانات التخصص والتوثيق</b>

export default SpecialityInfo

import FormControl from 'react-bootstrap/FormControl'
import TimePicker from 'react-time-picker';
import isEmpty from 'lodash/isEmpty'

const TimeInput = ({ name, value, onChange, isInvalid }) => {
  const classNames = ['time-input']
  if (isInvalid) {
    classNames.push('invalid')
  }

  return (
    <>
      <div className={classNames.join(' ')}>
        <TimePicker
          name={name}
          value={value}
          onChange={onChange}
          disableClock
          closeClock={false}
        />
      </div>
      <FormControl hidden isInvalid={isInvalid} />
    </>
  )
}

TimeInput.onChange = (parent, name, setFieldValue) => value => {
  if (value) setFieldValue(name, value)
  else {
    const [parentName, fieldName] = name.split('.')
    if (parent) delete parent[fieldName]
    if (isEmpty(parent)) setFieldValue(parentName, null)
    else setFieldValue(parentName, parent)
  }
}

export default TimeInput

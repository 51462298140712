import { createSlice } from '@reduxjs/toolkit';
import { isNull } from 'lodash-es';

const initialState = {
  cities: { values: [], sources: [], isLoading: false },
  areas: { values: [], sources: [], isLoading: false },
  doctorSpecialities: { values: [], sources: [], isLoading: false },
  nurseTasks: { values: [], sources: [], isLoading: false },
  homeCareSpecialistTasks: { values: [], sources: [], isLoading: false },
  medicalCenterServices: { values: [], sources: [], isLoading: false },
  status: 'idle',
  error: null
}

/**
 * @typedef {Object} Lookup
 * @property {number} id
 * @property {string} arabicName
 * @property {string} englishName
 * @property {Lookup?} city
 */

/**
 * @param {Lookup[]} lookups
 * @returns {{
 *    id: Lookup,
 *    values: { text: string, value: number }[],
 *    sources: Lookup[],
 *    isLoading: boolean
 * }}
 */
const reshape = lookups => lookups.reduce((acc, ds) => {
  return {
    ...acc,
    [ds.id]: ds.arabicName,
    values: acc.values.concat({
      text: ds.arabicName,
      value: ds.id
    }),
    sources: acc.sources.concat(ds)
  }
}, { values: [], sources: [], isLoading: false })

export const lookupsSlice = createSlice({
  name: 'lookups',
  initialState,
  reducers: {
    listCitiesRequest: state => {
      state.status = 'loading'
      state.cities.isLoading = true
    },
    listCitiesSuccess: (state, action) => {
      state.cities = reshape(action.payload.cities)
      state.status = 'idle'
      state.cities.isLoading = false
      state.error = null
    },
    listCitiesFailure: (state, action) => {
      state.error = action.payload
      state.status = 'idle'
      state.cities.isLoading = false
    },

    listAreasRequest: state => {
      state.status = 'loading'
      state.areas.isLoading = true
    },
    listAreasSuccess: (state, action) => {
      state.areas = reshape(action.payload.areas)
      state.status = 'idle'
      state.areas.isLoading = false
      state.error = isNull
    },
    listAreasFailure: (state, action) => {
      state.error = action.payload
      state.status = 'idle'
      state.areas.isLoading = false
    },

    listDoctorSpecialitiesRequest: state => {
      state.status = 'loading'
    },
    listDoctorSpecialitiesSuccess: (state, action) => {
      state.doctorSpecialities = reshape(action.payload.doctorSpecialities)
      state.status = 'idle'
      state.error = null
    },
    listDoctorSpecialitiesFailure: (state, action) => {
      state.error = action.payload
      state.status = 'idle'
    },

    listNurseTasksRequest: state => {
      state.status = 'loading'
    },
    listNurseTasksSuccess: (state, action) => {
      state.nurseTasks = reshape(action.payload.nurseTasks)
      state.status = 'idle'
      state.error = null
    },
    listNurseTasksFailure: (state, action) => {
      state.error = action.payload
      state.status = 'idle'
    },


    listHomeCareSpecialistTasksRequest: state => {
      state.status = 'loading'
    },
    listHomeCareSpecialistTasksSuccess: (state, action) => {
      state.homeCareSpecialistTasks = reshape(action.payload.homeCareSpecialistTasks)
      state.status = 'idle'
      state.error = null
    },
    listHomeCareSpecialistTasksFailure: (state, action) => {
      state.error = action.payload
      state.status = 'idle'
    },

    listMedicalCenterServicesRequest: state => {
      state.status = 'loading'
    },
    listMedicalCenterServicesSuccess: (state, action) => {
      state.medicalCenterServices = reshape(action.payload.medicalCenterServices)
      state.status = 'idle'
      state.error = null
    },
    listMedicalCenterServicesFailure: (state, action) => {
      state.error = action.payload
      state.status = 'idle'
    },

    listAreasByCitiesRequest: state => {
      state.status = 'loading'
      state.error = null
      state.areas.isLoading = true
    },
    listAreasByCitiesSuccess: (state, action) => {
      state.status = 'idle'
      state.areas = reshape(action.payload.areas)
      state.areas.isLoading = false
    },
    listAreasByCitiesFailure: (state, action) => {
      state.status = 'idle'
      state.error = action.payload
      state.areas.isLoading = false
    },

    listCitiesAndAreasRequest: (state, action) => {
      state.status = 'loading'
      if (!state.cities.values.length) {
        state.cities.isLoading = true
      }
      state.areas.isLoading = true
      state.error = null
      action.payload.fetchCities = state.cities.isLoading
    },
  }
});

export const selectLookups = (state) => state.lookups

export const LookupsActions = lookupsSlice.actions

export default lookupsSlice.reducer;

import Offcanvas from 'react-bootstrap/Offcanvas'
import { useEffect, useRef } from 'react'

import Select from './Select'

import { Directions } from '../common'

const BottomSheet = ({ title, children, show, close, action, onExited, direction=Directions.RTL }) => {
  const _ref = useRef()
  if (children.type === Select) {
    // console.log('######################', children)
  }

  const getMaxZIndex = () => {
    return Math.max(
      [...document.getElementsByClassName('show')]
        .filter(element => element.getAttribute('role') === 'dialog')
        .map(element => document.defaultView.getComputedStyle(element).getPropertyValue('z-index'))
        .map(parseInt)
    )
  }

  useEffect(() => {
    if (show && _ref.current) {
      const maxCurrentZIndex = getMaxZIndex()
      _ref.current.backdrop.style.zIndex = maxCurrentZIndex + 1
      _ref.current.dialog.style.zIndex = maxCurrentZIndex + 2
    }
  }, [show, _ref.current])

  return (
    <Offcanvas
      placement='bottom'
      scroll
      show={show}
      className='bottom-sheet'
      onHide={close}
      onExited={onExited}
      ref={_ref}
    >
      <div className='bottom-sheet-notch'/>
      <Offcanvas.Title>
        {title}
        {close && <div onClick={close}>إلغاء</div>}
        {action}
      </Offcanvas.Title>
      <Offcanvas.Body>
        {children}
      </Offcanvas.Body>
    </Offcanvas>
  )
}

export default BottomSheet

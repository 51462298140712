const NumberedElement = ({ number, text }) => (
  <div className='numbered-element'>
    <div>{number}</div>
    <div>{text}</div>
  </div>
)

const NumberedList = ({list}) => {
  return (
    <div className='numbered-list'>
      {list.map((element, index) => <NumberedElement key={index} number={index+1} text={element} />)}
    </div>
  )
}

export default NumberedList

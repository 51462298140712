import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import startOfDay from 'date-fns/startOfDay'
import format from 'date-fns/format'
import { arSA } from 'date-fns/locale'
import isToday from 'date-fns/isToday'
import isYesterday from 'date-fns/isYesterday'

import Screen from '../components/layout/Screen'
import Header from '../components/layout/Header'
import Main from '../components/layout/Main'
import Footer from '../components/layout/Footer'

import Divider from '../components/basic/Divider'

import { NotificationsActions, selectNotifications } from '../redux/reducers/notificationsSlice'
import PersonCard from '../components/PersonCard'

import NotificationButton from '../components/basic/NotificationButton'

const NotificationsScreen = ({ requesterId }) => {
  const dispatch = useDispatch()
  const { notifications } = useSelector(selectNotifications)

  const notificationsGroupedByDay =
    _.groupBy(notifications, notification => startOfDay(new Date(notification.createdAt)))

  console.log({ notificationsGroupedByDay })

  useEffect(() => {
    dispatch(NotificationsActions.listNotificationsRequest({ ownerId: requesterId }))
  }, [dispatch, requesterId])

  const renderMessage = action => {
    switch(action) {
      case 'SPRAccept':
        return 'تم قبول طلب الزيارة المنزليه بنجاح المقدمه من'
      case 'Reminder':
      default:
        return 'يتبقي ساعه واحده لتلقي الاستشاره المنزليه المقدمه من'
    }
  }

  const renderDay = day => {
    const _day = new Date(day)
    if (isToday(_day)) return 'اليوم'
    if (isYesterday(_day)) return 'أمس'
    return format(_day, 'EEEE', { locale: arSA })
  }

  return (
    <Screen>
      <Header
        leftItem={<NotificationButton />}
        middleItem={<b>الإشعارات</b>}
      />
      <Main className='home-main'>
        <div className='notification-group'>
          {_.keys(notificationsGroupedByDay).sort((a, b) => Date.parse(b) - Date.parse(a)).map((day, index) =>
            <div key={index}>
              <h3>{renderDay(day)} ({notificationsGroupedByDay[day].length})</h3>
              {notificationsGroupedByDay[day].map((notification, index, array) => {
                const { serviceProvider, price, date } = notification.reservation
                const { profilePicture, firstName, lastName, degree, detailedSpeciality } = serviceProvider
                return (
                  <div key={notification.id} className='notification-group'>
                    <div className='notification-time'>{format(Date.parse(notification.createdAt), 'hh:mm bb', { locale: arSA })}</div>
                    <div className='notification-message'>{renderMessage(notification.action)}</div>
                    <PersonCard>
                      <PersonCard.Avatar src={profilePicture} />
                      <PersonCard.Content>{firstName} {lastName}</PersonCard.Content>
                      <PersonCard.FadedContent>{degree} {detailedSpeciality}</PersonCard.FadedContent>
                      {price && <PersonCard.Content><span className='notification-card-label'>التكلفة</span> <b>{price} جنيه مصرى</b></PersonCard.Content>}
                      {date && <PersonCard.Content><span className='notification-card-label'>الميعاد</span> {format(Date.parse(date), 'EEEE y/M/d hh:mm b', { locale: arSA })}</PersonCard.Content>}
                    </PersonCard>
                    {(index < (array.length - 1)) && <Divider />}
                  </div>
                )
              })}
            </div>
          )}
        </div>
      </Main>
      <Footer />
    </Screen>
  )
}

export default NotificationsScreen

import { Children, forwardRef, isValidElement } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import Table from 'react-bootstrap/Table'
import Overlay from 'react-bootstrap/Overlay'
import Popover from 'react-bootstrap/Popover'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import ButtonGroup from 'react-bootstrap/ButtonGroup'

import _ from 'lodash'
import { TriangleFill, Dash, XCircleFill, Basket } from 'react-bootstrap-icons'
import { useState, useEffect, useRef } from 'react'
import { DashboardActions } from '../redux/reducers/dashboardSlice'

const Filter = ({ filter, dropFilter }) => {
  return (
    <div className='dynamic-table-filter'>
      <b>{filter.name}</b> {filter.value} <XCircleFill onClick={() => dropFilter(filter)}/>
    </div>
  )
}

Filter.Group = props => <div className='dynamic-table-filter-group'>{props.children}</div>

const SearchPopover = ({ name, onApply }) => {
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null)
  const [value, setValue] = useState();
  const ref = useRef(null);
  const inputRef = useRef()

  const handleClick = (event) => {
    setShow(!show);
    setTarget(event.target)
  };

  const handleApply = (e) => {
    e.preventDefault()
    setShow(!show)
    onApply({ name, value })
  }

  useEffect(() => {
    if (!!inputRef.current && show) {
      inputRef.current.focus()
    }
  }, [inputRef, show])

  return (
    <div ref={ref}>
      <div onClick={handleClick}>{name}</div>

      <Overlay
        show={show}
        target={target}
        placement="bottom"
        container={ref}
        containerPadding={20}
        rootClose={true}
        onHide={() => setShow(false)}
      >
        <Popover id="popover-contained">
          <Popover.Body>
            <Form noValidate onSubmit={handleApply}>
              <Form.Control ref={inputRef} placeholder={name} onChange={e => setValue(e.target.value)}/>
              <Button type='submit'>Apply</Button>
            </Form>
          </Popover.Body>
        </Popover>
      </Overlay>
    </div>
  );
}

const getRows = data => data.map(row => _.mapValues(_.groupBy(row, field => field.key), ([v]) => v.value))

const DynamicTable = forwardRef((
  {
    headers,
    data = [],
    resource,
    initialQuery={},
    initialFilters=[],
    queryMapper=_.identity,
    pagesCount,
    mapper,
    detail,
    search,
    sortable
  }, ref) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [sortBy, setSortBy] = useState()
  const [filters, setFilters] = useState(initialFilters.flatMap(filter =>
    _.keys(filter).map(key =>
      ({ key, value: filter[key], name: headers.find(header => header.key === key)?.name })
    )
  ))
  const [page, setPage] = useState(0)
  const [limit] = useState(10)
  const [query, setQuery] = useState({
    ...initialQuery,
    ...initialFilters.reduce((obj, filter) => ({ ...obj, [filter.key]: filter.value }), {}),
    limit,
    page
  })

  if (ref) {
    ref.current = { ...ref.current, refreshTable: () => dispatch(DashboardActions.listRequest({ resource, query: queryMapper(query) })) }
  }

  // const headers = mapper({}).map(({ key, sort, name }) => ({ key, sort: sort || key, name }))
  // const headers = _.uniqBy(_.flatMap(data.map(mapper), row => row.map(field => ({ key: field.key, sort: field.sort || field.key ,name: field.name }))), value => value.key)

  const [rows, setRows] = useState(getRows(data.map(mapper)))

  const handleSortKey = key => {
    if (sortBy?.key === key) setSortBy({ key, ascending: !sortBy.ascending })
    else setSortBy({ key, ascending: true })
  }

  const appendFilter = key => filter => {
    setFilters([...filters, { ...filter, key }])
  }

  const dropFilter = filter => {
    setFilters(filters => filters.filter(f => f.key !== filter.key))
  }

  useEffect(() => {
    if (sortBy?.key) {
      setQuery({
        ...query,
        page,
        limit,
        sortBy: sortBy.key,
        sortDirection: sortBy.ascending ? 'ASC' : 'DESC'
      })
    }
  }, [sortBy])

  useEffect(() => {
    setQuery({
      page,
      limit,
      sortBy: query.sortBy,
      sortDirection: query.sortDirection,
      ...initialQuery,
      ...filters.reduce((obj, filter) => ({ ...obj, [filter.key]: filter.value }), {})
    })
  }, [filters])

  useEffect(() => {
    setQuery(query => ({
      ...query,
      limit
    }))
  }, [limit])

  useEffect(() => {
    setQuery(query => ({
      ...query,
      page
    }))
  }, [page])

  useEffect(() => {
    dispatch(DashboardActions.listRequest({ resource, query: queryMapper(query) }))
  }, [dispatch, query])


  useEffect(() => setRows(getRows(data.map(mapper))), [data, mapper])

  console.log({ query })

  return (
    <>
      <Filter.Group>
        {filters.map(filter => <Filter key={`${filter.key}_filter`} filter={filter} dropFilter={dropFilter} />)}
      </Filter.Group>
      <Table hover className='dynamic-table'>
        <thead>
          <tr>
            {headers.map(header => ({ ...header, sort: header.sort || header.key })).map(header =>
              <th key={header.key}>
                <div>
                  {
                    header.key.startsWith('$') || !search
                    ? <div>{header.name}</div>
                    : <SearchPopover name={header.name} onApply={appendFilter(header.key)}/>
                  }
                  {sortable && (
                    <div style={{float: 'right', cursor: 'pointer' }} onClick={() => handleSortKey(header.sort)}>
                      { !header.key.startsWith('$') && (
                          sortBy?.key === header.sort
                          ? <TriangleFill
                              style={{
                                transform: sortBy?.key === header.sort && !sortBy?.ascending ? 'rotate(0deg)' : 'rotate(60deg)',
                                float: 'right',
                                width: '10px'
                              }}
                            />
                          : <Dash style={{float: 'right', width: '10px'}}/>
                      )}
                    </div>
                  )}
                </div>
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {
            rows.map((row, index) => {
              const rowExtras = {}
              if (detail) {
                rowExtras.onClick = () => history.push(`/${resource.split('?')[0]}/${row.id}`)
              }
              return (
                <tr key={row.id || index}>
                  {
                    headers.map(({ key }) => {
                      const cellExtras = {}
                      const value = row[key]
                      if (key.startsWith('$') && isValidElement(value)) {
                        cellExtras.onClick = value?.props?.onClick
                        cellExtras.style = { cursor: 'pointer', width: 0 }
                      }
                      return <td key={key} { ...rowExtras } {...cellExtras}>{value}</td>
                    })
                  }
                </tr>
              )
            })
          }
        </tbody>
      </Table>
      {
        rows.length === 0
        ? <div className='no-data'>
            <div>
              <Basket size='large'/>
              <div>No data available</div>
            </div>
          </div>
        : (
          <ButtonGroup>
            <Button variant='outline-primary' disabled={page === 0} onClick={() => setPage(0)}>First</Button>
            <Button variant='outline-primary' disabled={page === 0} onClick={() => setPage(page - 1)}>Previous</Button>
            {_.range(page - 1, page + 2).filter(x => x >= 0 && x < pagesCount).map(x => (
              <Button key={x} variant={page === x ? 'primary' : 'outline-primary'} onClick={() => setPage(x)}>{x + 1}</Button>
            ))}
            <Button variant='outline-primary' disabled={page === (pagesCount - 1) || pagesCount === 0} onClick={() => setPage(page + 1)}>Next</Button>
            <Button variant='outline-primary' disabled={page === (pagesCount - 1) || pagesCount === 0} onClick={() => setPage(pagesCount - 1)}>Last</Button>
          </ButtonGroup>
        )
      }
    </>
  )
})

export default DynamicTable

import { useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Resizer from 'react-image-file-resizer';
import Spinner from 'react-bootstrap/Spinner'
import _ from 'lodash'

import InputWithIcon from './InputWithIcon'
import Svg from './Svg'

import {ReactComponent as CameraIcon} from '../../svg/camera.svg'
import {ReactComponent as DeleteIcon} from '../../svg/close_circle.svg'

import { AttachmentsActions, selectAttachments } from '../../redux/reducers/attachmentsSlice'

import { usePrevious } from '../common';

const getThumbail = (file) => {
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      '64pt',
      '64pt',
      file.type,
      20,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });
}

const reduceQuality = (file) => {
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      300,
      300,
      file.type,
      50,
      0,
      (uri) => {
        resolve(uri);
      },
      "file"
    );
  });
}

const Thumbnail = ({ file, onClick }) => {
  return (
    <div className='attachment-thumbnail-parent' onClick={onClick}>
      <img src={file} alt='' className='attachment-thumbnail'/>
      <Svg as={DeleteIcon} iconn className='attachment-thumbnail-corner' onClick={onClick} />
    </div>
  )
}

Thumbnail.Group = ({ children, isLoading }) => {
  if (!children.length && !isLoading) {
    return <></>
  }

  return (
    <div className='attachment-thumbnail-group'>
      {children}
      {isLoading && <Spinner animation='border' />}
    </div>
  )
}

const Policies = Object.freeze({
  PRIVATE: 'private',
  PUBLIC: 'public'
})

const Attachments = ({ initialValue=[], onChange, multiple, single, isInvalid, errorMessage, target='shared', privacy: policy=Policies.PRIVATE }) => {
  const dispatch = useDispatch()
  const [pendingFiles, setPendingFiles] = useState([])
  const [internalError, setInternalError] = useState()

  const [internalAttachments, setInternalAttachments] = useState(_.castArray(initialValue).filter(_.identity))
  const previousInternalAttachments = usePrevious(internalAttachments)

  const targetRef = useRef()

  const attachmentsSlice = useSelector(selectAttachments)
  const { attachments=[], isLoading, error } = _.mapValues(attachmentsSlice, (value) => value[target])

  const previousAttachments = usePrevious(attachments)

  const addedAttachments = _.difference(attachments, previousAttachments)
  const droppedAttachments = _.difference(previousAttachments, attachments)

  const handleChange = async e => {
    setInternalError(null)
    const newFiles = [...e.target.files]
    if (newFiles.some(file => !file.type.startsWith('image'))) {
      setInternalError('Format is not supported')
    }
    else {
      Promise.all(newFiles.map(getThumbail)).then(setPendingFiles)
      Promise.all(newFiles.map(reduceQuality)).then(files =>
        dispatch(AttachmentsActions.uploadRequest({ files, target, policy }))
      )
    }
  }

  const dropAttachment = (url) => {
    dispatch(AttachmentsActions.dropAttachmentRequest({ url, target }))
  }

  // reset attachments on unmount
  useEffect(() => () => dispatch(AttachmentsActions.reset()), [dispatch])

  // initialize on mount
  useEffect(() => dispatch(AttachmentsActions.initialize({ target, attachments: internalAttachments })), [dispatch])

  useEffect(() => {
    if (addedAttachments.length) {
      setInternalAttachments(myAttachments => {
        const addition = addedAttachments.map((a, i) => ({ ...a, file: pendingFiles[i] }))
        if (single) {
          return addition
        }
        else {
          return myAttachments.concat(addition)
        }
      })
    }
  }, [addedAttachments, pendingFiles, single])

  useEffect(() => {
    if (droppedAttachments.length) {
      setInternalAttachments(myAttachments => {
        const deletedUrls = droppedAttachments.map(a => a.url)
        return myAttachments.filter(a => !deletedUrls.includes(a.url))
      })
    }
  }, [droppedAttachments])

  useEffect(() => {
    if (!_.isEqual(internalAttachments, previousInternalAttachments))
    onChange(single ? internalAttachments[0] : internalAttachments)
  }, [internalAttachments, previousInternalAttachments, single, onChange])

  useEffect(() => {
    if (error) {
      setPendingFiles([])
    }
  }, [error])

  return (
    <div>
      <input
        type='file'
        accept='image/*'
        ref={targetRef}
        style={{display: 'none'}}
        multiple={multiple || !single}
        onChange={handleChange}/>
      <InputWithIcon
        className='attachment-input'
        icon={<Svg as={CameraIcon} iconn onClick={() => targetRef.current?.click()} />}
        placeholder='تحميل الصورة'
        readOnly
        onClick={() => targetRef.current?.click()}
        isInvalid={!!error || isInvalid || internalError}
        errorMessage={internalError || errorMessage || 'حجم الصورة كبير'}
      />
      <Thumbnail.Group isLoading={isLoading}>
        {internalAttachments.map((attachment, i) => <Thumbnail key={i} file={attachment.file || attachment.url} onClick={() => dropAttachment(attachment.url)} />)}
      </Thumbnail.Group>
    </div>
  )
}

Attachments.Policies = Policies

export default Attachments

import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth'

import { firebaseApp } from './base'

const auth = getAuth(firebaseApp)
auth.languageCode = 'ar';

export const sendVerificationCode = (mobileNumber) => {
  const appVerifier = new RecaptchaVerifier(document.getElementById('verify-phone'), {
    size: 'invisible'
  }, auth)
  return signInWithPhoneNumber(auth, `+2${mobileNumber}`, appVerifier)
}

import { createSlice } from '@reduxjs/toolkit';
import camelCase from 'lodash/camelCase'
import { getUserTypeFromId } from '../../components/common';

const initialPaginatedResource = {
  items: [],
  count: 0,
  pagesCount: 0,
  detail: null
}

const initialState = {
  isLoading: false,
  error: null,
  systemUsers: initialPaginatedResource,
  doctors: initialPaginatedResource,
  physicalTherapists: initialPaginatedResource,
  nurses: initialPaginatedResource,
  homeCareSpecialists: initialPaginatedResource,
  medicalCenters: initialPaginatedResource,
  requesters: initialPaginatedResource,
  patients: initialPaginatedResource,
  reservations: initialPaginatedResource,
  cities: initialPaginatedResource,
  areas: initialPaginatedResource,
  doctorSpecialities: initialPaginatedResource,
  doctorDetailedSpecialities: initialPaginatedResource,
  nurseTasks: initialPaginatedResource,
  homeCareSpecialistTasks: initialPaginatedResource,
  medicalCenterServices: initialPaginatedResource,
  providers: initialPaginatedResource,
  pricing: initialPaginatedResource,
}

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    listRequest: (state) => {
      state.isLoading = true
    },
    listSuccess: (state, action) => {
      state[action.payload.resource.split('?')[0]] = action.payload.body
      state.isLoading = false
    },
    listFailure: (state, action) => {
      state.error = action.payload
      state.isLoading = false
    },

    createRequest: (state) => {
      state.error = null
      state.isLoading = true
    },
    createSuccess: (state, action) => {
      const resource = action.payload.resource.split('?')[0]
      state[resource].items = [...state[resource].items, action.payload.body]
      state.isLoading = false
    },
    createFailure: (state, action) => {
      state.error = action.payload
      state.isLoading = false
    },

    updateRequest: (state) => {
      state.error = null
      state.isLoading = true
    },
    updateSuccess: (state, action) => {
      const { body } = action.payload
      const resource = action.payload.resource.split('?')[0]
      state[resource].items = state[resource].items.map(item => {
        if (item.id === body.id) {
          return body
        }
        return item
      })
      if (state[resource].detail?.id === body.id) {
        state[resource].detail = body
      }
      state.isLoading = false
    },
    updateFailure: (state, action) => {
      state.error = action.payload
      state.isLoading = false
    },

    detailRequest: (state) => {
      state.isLoading = true
    },
    detailSuccess: (state, action) => {
      const resource = action.payload.resource.split('?')[0]
      state[resource].detail = action.payload.body
      state.isLoading = false
    },
    detailFailure: (state, action) => {
      state.error = action.payload
      state.isLoading = false
    },

    updatePatientStatusRequest: (state) => {
      state.isLoading = true
    },
    updatePatientStatusSuccess: (state, action) => {
      state.isLoading = false
      state.patients.details = action.payload.patient
    },

    updateServiceProviderStatusRequest: (state) => {
      state.isLoading = true
    },
    updateServiceProviderStatusSuccess: (state, action) => {
      state.isLoading = false
      const resource = `${camelCase(getUserTypeFromId(action.payload.patient.id))}s`
      state[resource].details = action.payload.patient
    },


    reset: () => {
      return initialState
    }
  }
});

export const selectDashboard = (state) => state.dashboard

export const DashboardActions = dashboardSlice.actions

export const DashboardResources = {
  OPERATIONS_SPECIALISTS: 'systemUsers',
  DOCTORS: 'doctors',
  PHYSICAL_THERAPISTS: 'physicalTherapists',
  NURSES: 'nurses',
  HOME_CARE_SPECIALISTS: 'homeCareSpecialists',
  MEDICAL_CENTERS: 'medicalCenters',
  REQUESTERS: 'requesters',
  PATIENTS: 'patients',
  RESERVATIONS: 'reservations',
  CITIES: 'cities',
  AREAS: 'areas',
  DOCTOR_SPECIALITIES: 'doctorSpecialities',
  DOCTOR_DETAILED_SPECIALITIES: 'doctorDetailedSpecialities',
  NURSE_TASKS: 'nurseTasks',
  HOME_CARE_SPECIALIST_TASKS: 'homeCareSpecialistTasks',
  MEDICAL_CENTER_SERVICES: 'medicalCenterServices',
  PROVIDERS: 'providers',
  PRICING: 'pricing'
}

export default dashboardSlice.reducer;

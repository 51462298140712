export const getUserType = () => {
  switch(window.location.pathname.split('/')[1]) {
    case 'dashboard':
      return 'SystemUser'
    case 'provider':
      return 'ServiceProvider'
    default:
      return 'Requester'
  }
}

import axios from 'axios'

export const listPatientsApi = ({ requesterId }) => {
  return axios.get(
    `${process.env.REACT_APP_BE_URL}/requesters/${requesterId}/patients`
  )
}

export const createPatientApi = (requesterId, data) => {
  return axios.post(
    `${process.env.REACT_APP_BE_URL}/requesters/${requesterId}/patients`,
    data
  )
}

export const updatePatientApi = (requesterId, patientId, data) => {
  return axios.put(
    `${process.env.REACT_APP_BE_URL}/requesters/${requesterId}/patients/${patientId}`,
    data
  )
}

export const getRequesterDetailsApi = ({ requesterId }) => {
  return axios.get(
    `${process.env.REACT_APP_BE_URL}/requesters/${requesterId}`
  )
}

export const updateRequesterApi = (data) => {
  return axios.put(
    `${process.env.REACT_APP_BE_URL}/requesters/${data.requesterId}`,
    data
  )
}

export const getRequesterPatientApi = ({ requesterId }) => {
  return axios.get(
    `${process.env.REACT_APP_BE_URL}/requesters/${requesterId}/patients/me`
  )
}

export const getPatientDetailsApi = ({ requesterId, patientId }) => {
  return axios.get(
    `${process.env.REACT_APP_BE_URL}/requesters/${requesterId}/patients/${patientId}`
  )
}

export const listProvidersApi = ({ requesterId }) => {
  return axios.get(
    `${process.env.REACT_APP_BE_URL}/requesters/${requesterId}/providers`
  )
}
